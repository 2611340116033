import React from 'react';
import Login from './MobileOtpModule/MobileOtpModule';
import EmailPasswordLoginModule from './EmailPasswordModule';

const index = () => {
  return (
    <>
      {/* <Login/> */}
      <EmailPasswordLoginModule/>
    </>
  );
}

export default index;

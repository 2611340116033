import { Box, Card } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import BookingList from "./BookingList";
import MainContentLayout from "../../components/Layout/MainContentLayout";

export default function Booking() {
    const menuDispatch = useDispatch();
    // menuDispatch({
    //     type: 'menuSelect',
    //     payload: 'bookings'
    // });
    useEffect(() => {
        menuDispatch({
            type: 'menuSelect',
            payload: 'bookings'
        });
    }, []);
    return (
        <MainContentLayout>
            <BookingList/>
        </MainContentLayout>
        
    )
}

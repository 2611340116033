import React, { useState, useEffect } from 'react';
import { Autocomplete, Box, Typography, FormControl, Select, MenuItem, TextField, Checkbox } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ServiceSelectionComponent = ({ serviceList, categoryList, subCategoryList, providerServiceList }) => {
  const { control, watch, setValue } = useFormContext();
  const [childServices, setChildServices] = useState([]);
  const servicesMasterId = watch('service');
  const categoryId = watch('serviceCategoryId');
  const subCategoryId = watch('subCategoryId');
  const services = watch('services') || [];

  useEffect(() => {
    if (servicesMasterId) {
      const selectedService = serviceList.find(serv => serv.masterServiceId === servicesMasterId);
      setChildServices(selectedService?.childServices || []);
    } else {
      setChildServices([]);
    }
  }, [servicesMasterId, serviceList]);

  const handleServiceSelection = (newValue) => {
    const selectedChildServices = newValue.map(service => {
      // Check if the service already exists in the services array
      const existingService = services.find(s => 
        s.servicesMasterId === servicesMasterId && s.servicesChildId === service.childServiceId
      );

      // If it exists, use its current time and price, otherwise use default values
      return {
        servicesMasterId: servicesMasterId,
        servicesChildId: service.childServiceId,
        categoryId: categoryId,
        subCategoryId: subCategoryId,
        categoryTitle: categoryList.find(cat => cat.id === categoryId)?.title,
        subCategoryTitle: subCategoryList.find(subCat => subCat.id === subCategoryId)?.title,
        masterServiceTitle: serviceList.find(s => s.masterServiceId === servicesMasterId)?.title,
        childServiceTitle: service.title,
        serviceTime: existingService ? existingService.serviceTime : (service.time || ''),
        price: existingService ? existingService.price=='0.00'?'':existingService.price : (service.price=='0.00'?'': service.price|| '')
      };
    });

    // Merge new selections with existing services from other master services
    const updatedServices = services.filter(service => service.servicesMasterId !== servicesMasterId);
    setValue('services', [...updatedServices, ...selectedChildServices]);
  };

  const getSelectedChildServices = () => {
    return services
      .filter(service => service.servicesMasterId === servicesMasterId)
      .map(service => childServices.find(cs => cs.childServiceId === service.servicesChildId))
      .filter(Boolean);
  };

  const isServiceAlreadySelected = (childServiceId) => {
    return providerServiceList?.some(service => service.servicesChildId === childServiceId);
  };

  return (
    <Box sx={{ display: 'flex', gap: '2%', }}>
      <Box sx={{ width: '49%' }}>
        <Typography sx={{fontSize:'0.75rem', fontWeight: 600 }}>Services</Typography>
        <Controller
          name='service'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth>
              <Select
                {...field}
                size='small'
                sx={{
                  // minHeight: '0px',
                  // height: '1.6rem',
                  fontSize: '0.75rem',
                  '& .MuiSelect-select': {
                    // padding: '0 8px',
                    display: 'flex',
                    alignItems: 'center',
                  },
                }}
                error={!!error}
                disabled={!watch('subCategoryId')}
              >
                {serviceList.map((service) => (
                  <MenuItem sx={{fontSize:'0.75rem'}} key={service.masterServiceId} value={service.masterServiceId}>{service.title}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </Box>
      <Box sx={{ width: '49%' }}>
        <Typography sx={{fontSize:'0.75rem', fontWeight: 600 }}>Child Services</Typography>
        <Controller
          name='services'
          control={control}
          rules={{}}
          render={({ field }) => (
            <Autocomplete
              {...field}
              sx={{
                '& .MuiAutocomplete-input': {
                  fontSize: '0.75rem',
                },
              }}
              disabled={!childServices.length>0}
              multiple
              disableCloseOnSelect
              options={childServices.filter(service => !isServiceAlreadySelected(service.childServiceId))}
              getOptionLabel={(option) => option.title}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ 
                      marginRight: 8,
                      transform: 'scale(0.8)',
                    }}
                    checked={selected}
                  />
                  <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                    {option.title}
                  </Typography>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  variant="outlined"
                  placeholder="Select services"
                />
              )}
              onChange={(_, newValue) => {
                handleServiceSelection(newValue);
              }}
              isOptionEqualToValue={(option, value) => option.childServiceId === value.childServiceId}
              value={getSelectedChildServices()}
              renderTags={()=>null}
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default ServiceSelectionComponent;

// import React, { useState, useEffect } from 'react';
// import { Autocomplete, Box, Typography, FormControl, Select, MenuItem, TextField, Checkbox } from '@mui/material';
// import { Controller, useFormContext } from 'react-hook-form';
// import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@mui/icons-material/CheckBox';

// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;

// const ServiceSelectionComponent = ({ serviceList, categoryList, subCategoryList }) => {
//   const { control, watch, setValue } = useFormContext();
//   const [childServices, setChildServices] = useState([]);
//   const servicesMasterId = watch('service');
//   const categoryId = watch('serviceCategoryId');
//   const subCategoryId = watch('subCategoryId');
//   const services = watch('services') || [];

//   useEffect(() => {
//     if (servicesMasterId) {
//       const selectedService = serviceList.find(serv => serv.masterServiceId === servicesMasterId);
//       setChildServices(selectedService?.childServices || []);
//     }
//   }, [servicesMasterId, serviceList]);

//   const handleServiceSelection = (newValue) => {
//     const selectedChildServices = newValue.map(service => ({
//       servicesMasterId: servicesMasterId,
//       servicesChildId: service.childServiceId,
//       categoryId: categoryId,
//       subCategoryId: subCategoryId,
//       categoryTitle: categoryList.find(cat => cat.id === categoryId)?.title,
//       subCategoryTitle: subCategoryList.find(subCat => subCat.id === subCategoryId)?.title,
//       masterServiceTitle: serviceList.find(s => s.masterServiceId === servicesMasterId)?.title,
//       childServiceTitle: service.title,
//       serviceTime: service.time || 0,
//       price: service.price || 0
//     }));

//     // Merge new selections with existing services from other master services
//     const updatedServices = services.filter(service => service.servicesMasterId !== servicesMasterId);
//     setValue('services', [...updatedServices, ...selectedChildServices]);
//   };

//   const getSelectedChildServices = () => {
//     return services
//       .filter(service => service.servicesMasterId === servicesMasterId)
//       .map(service => childServices.find(cs => cs.childServiceId === service.servicesChildId))
//       .filter(Boolean);
//   };

//   return (
//     <Box sx={{ display: 'flex', gap: '2%', }}>
//       <Box sx={{ width: '49%' }}>
//         <Typography sx={{fontSize:'small', fontWeight: 600, textTransform: 'uppercase' }}>Services<span style={{color:'red'}}>*</span></Typography>
//         <Controller
//           name='service'
//           control={control}
//           // rules={{ required: 'Select service' }}
//           render={({ field, fieldState: { error } }) => (
//             <FormControl fullWidth>
//               <Select
//                 {...field}
//                 size='small'
//                 error={!!error}
//               >
//                 {serviceList.map((service) => (
//                   <MenuItem key={service.masterServiceId} sx={{ textTransform: 'capitalize' }} value={service.masterServiceId}>{service.title}</MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//           )}
//         />
//       </Box>
//       <Box sx={{ width: '49%' }}>
//         <Typography sx={{fontSize:'small', fontWeight: 600, textTransform: 'uppercase' }}>Child Services<span style={{color:'red'}}>*</span></Typography>
//         <Controller
//           name='services'
//           control={control}
//           render={({ field }) => (
//             <Autocomplete
//               {...field}
//               multiple
//               options={childServices}
//               getOptionLabel={(option) => option.title}
//               renderOption={(props, option, { selected }) => (
//                 <li {...props}>
//                   <Checkbox
//                     icon={icon}
//                     checkedIcon={checkedIcon}
//                     style={{ marginRight: 8 }}
//                     checked={selected}
//                   />
//                   {option.title}
//                 </li>
//               )}
//               renderInput={(params) => (
//                 <TextField
//                   {...params}
//                   size='small'
//                   variant="outlined"
//                   placeholder="Select services"
//                 />
//               )}
//               onChange={(_, newValue) => {
//                 handleServiceSelection(newValue);
//               }}
//               isOptionEqualToValue={(option, value) => option.childServiceId === value.childServiceId}
//               value={getSelectedChildServices()}
//               renderTags={()=>null}
//             />
//           )}
//         />
//       </Box>
//     </Box>
//   );
// };

// export default ServiceSelectionComponent;



// // import React, { useState, useEffect } from 'react';
// // import { Autocomplete, Box, Typography, FormControl, Select, MenuItem, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Checkbox } from '@mui/material';
// // import { Controller, useFormContext } from 'react-hook-form';
// // import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
// // import CheckBoxIcon from '@mui/icons-material/CheckBox';

// // const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// // const checkedIcon = <CheckBoxIcon fontSize="small" />;

// // const ServiceSelectionComponent = ({ serviceList }) => {
// //   const { control, watch, setValue } = useFormContext();
// //   const [childServices, setChildServices] = useState([]);
// //   const servicesMasterId = watch('service');
// //   const services = watch('services') || [];

// //   useEffect(() => {
// //     if (servicesMasterId) {
// //       const selectedService = serviceList.find(serv => serv.masterServiceId === servicesMasterId);
// //       setChildServices(selectedService?.childServices || []);
// //     }
// //   }, [servicesMasterId, serviceList]);

// //   const handleServiceSelection = (newValue) => {
// //     const selectedChildServices = newValue.map(service => ({
// //       servicesMasterId: servicesMasterId,
// //       servicesChildId: service.childServiceId,
// //       // title: service.title,
// //       serviceTime: service.time || 0,
// //       price: service.price || 0
// //     }));

// //     // Merge new selections with existing services from other master services
// //     const updatedServices = services.filter(service => service.servicesMasterId !== servicesMasterId);
// //     setValue('services', [...updatedServices, ...selectedChildServices]);
// //   };

  

// //   const getSelectedChildServices = () => {
// //     return services
// //       .filter(service => service.servicesMasterId === servicesMasterId)
// //       .map(service => childServices.find(cs => cs.childServiceId === service.servicesChildId))
// //       .filter(Boolean);
// //   };

// //   return (
// //     <>
// //       <Box sx={{ display: 'flex', gap: '2%', }}>
// //         <Box sx={{ width: '49%' }}>
// //           <Typography sx={{fontSize:'small', fontWeight: 600, textTransform: 'uppercase' }}>Services<span style={{color:'red'}}>*</span></Typography>
// //           <Controller
// //             name='service'
// //             control={control}
// //             rules={{ required: 'Select service' }}
// //             render={({ field, fieldState: { error } }) => (
// //               <FormControl fullWidth>
// //                 <Select
// //                   {...field}
// //                   size='small'
// //                   error={!!error}
// //                 >
// //                   {serviceList.map((service) => (
// //                     <MenuItem key={service.masterServiceId} sx={{ textTransform: 'capitalize' }} value={service.masterServiceId}>{service.title}</MenuItem>
// //                   ))}
// //                 </Select>
// //               </FormControl>
// //             )}
// //           />
// //         </Box>
// //         <Box sx={{ width: '49%' }}>
// //           <Typography sx={{fontSize:'small', fontWeight: 600, textTransform: 'uppercase' }}>Child Services<span style={{color:'red'}}>*</span></Typography>
// //           <Controller
// //             name='services'
// //             control={control}
// //             render={({ field }) => (
// //               <Autocomplete
// //                 {...field}
// //                 multiple
// //                 options={childServices}
// //                 getOptionLabel={(option) => option.title}
// //                 renderOption={(props, option, { selected }) => (
// //                   <li {...props}>
// //                     <Checkbox
// //                       icon={icon}
// //                       checkedIcon={checkedIcon}
// //                       style={{ marginRight: 8 }}
// //                       checked={selected}
// //                     />
// //                     {option.title}
// //                   </li>
// //                 )}
// //                 renderInput={(params) => (
// //                   <TextField
// //                     {...params}
// //                     size='small'
// //                     variant="outlined"
// //                     placeholder="Select services"
// //                   />
// //                 )}
// //                 onChange={(_, newValue) => {
// //                   handleServiceSelection(newValue);
// //                 }}
// //                 isOptionEqualToValue={(option, value) => option.childServiceId === value.childServiceId}
// //                 value={getSelectedChildServices()}
// //                 renderTags={()=>null}
// //               />
// //             )}
// //           />
// //         </Box>
// //       </Box>
      
// //     </>
// //   );
// // };

// // export default ServiceSelectionComponent;
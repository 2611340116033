import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box, Stack, Typography, Button, Modal, Grid, Divider, Paper, Skeleton,} from '@mui/material';
import { CategoryService } from './CategoryService';
import AddCategory from './AddCategory';
import { isMobile } from 'react-device-detect';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import { useForm } from 'react-hook-form';
import { Helpers } from '../../services/Helpers';
import { PermissionGuard } from '../../components/PermissionGuard';
import Loading from '../../components/Loader/Loading';
import PageHeaderLayout from '../../components/Layout/PageHeaderLayout';

const style = {
    position: 'absolute',
    top: (isMobile) ? '80%' : '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #ddd',
    boxShadow: 24,
    p: 2,
};


const CategoryTabs = ({ }) => {
    const { control, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const [selectedTab, setSelectedTab] = useState();
    const [categories, setCategories] = useState([]);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const [isLoadingSubCategory, setIsLoadingSubCategory] = useState(false);
    const [subCategorylist,setSubCategorylist] = useState([]);  
    
    // on selecting category tab set subCategorylist according to selected category
    useEffect(() => {
        if(categories && categories[selectedTab]){
            setIsLoadingSubCategory(true);
            setSubCategorylist(categories[selectedTab].childCategories);
            setIsLoadingSubCategory(false);
        }
    }, [selectedTab])
        

    const [loading, setLoading] = useState(false);
    const [controller, setController] = useState({
        searchKey: "",
        status: "",
    });
    const [deleteCat, setDeleteCat] = useState(0);

    /************************  Modal State Manage **************************** */
    const [open, setOpen] = useState(false);
    const [ids, setIds] = useState({});
    const [catType, setCatType] = useState(1);
    const [populateTable, setPopulateTable] = useState(0);

    /************************ VAriation Modal State Manage **************************** */
    const [openVariation, setOpenVaration] = useState(false);
    const [catData, setCatData] = useState([])

    const getData = async () => {
        try {
            setLoading(true);
            const response = await CategoryService.allCategories({
                searchKey: controller.searchKey,
                status: controller.status
            });
            // console.log("category response:",response)
            setCategories(response.data);
            setSelectedTab(0);
        } catch (error) {
            // console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getData();
    }, [controller, deleteCat, populateTable])

    /************************  Modal Manage **************************** */
    const modalOpen = (id, catType) => {
        setOpen(true);
        setIds(id);
        setCatType(catType)
    }

    const handleClose = () => {
        setOpen(false);
        setIds(0);
        setOpenVaration(false);
        setCatData([]);
    };
    const afterAddPop = (data) => {
        setPopulateTable(data);
    }

    return (
        <Box>
            {loading && <Loading/>}
            <Box>
                {/*================= Add Category modal ================*/}
                <Modal
                    open={open}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{ overflow: "scroll" }}
                >
                    <Box sx={style} width={(isMobile) ? "95%" : "35%"}>
                        <AddCategory populateList={afterAddPop} closeEvent={handleClose} catType={catType} rowId={ids} />
                    </Box>
                </Modal>
            </Box>
            {/* ======================= Page  title and menu section ================ */}
            <PageHeaderLayout headerTitle={'Manage Categories'}>
                <PermissionGuard feature='category' permission='canCreate'>
                    <Button variant='contained' size='small' onClick={() => modalOpen({}, 1)} sx={{ marginTop: "10px", marginRight: "10px" }} endIcon={<AddCircleIcon />}>
                        Category
                    </Button>
                </PermissionGuard>
                <PermissionGuard feature='category' permission='canCreate'>
                    <Button variant='contained' size='small' onClick={() => modalOpen({}, 2)} sx={{ marginTop: "10px", marginRight: "10px" }} endIcon={<AddCircleIcon />}>
                        Sub Category
                    </Button>
                </PermissionGuard>
            </PageHeaderLayout>

            {/* ========================= Content Section ======================== */}
            <Box sx={{ marginTop: '.5%' }}>
                <Grid container spacing={1}>
                    {/* ========= category tab section ======== */}
                    <Grid item xs={5} lg={2}>
                        <Tabs
                            value={selectedTab}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            orientation="vertical" // Set the orientation to 'vertical'
                            variant="scrollable" // Use 'scrollable' variant for longer lists
                            scrollButtons="auto"
                            sx={{ backgroundColor: "#fff" }}
                            align="left"
                        >
                            {categories?.map((category, index) => (
                                <Tab key={index} label={category.title} 
                                    sx={{borderBottom:'1px solid lightGray',
                                        fontSize:'.8rem'
                                    }}
                                />
                            ))}
                        </Tabs>
                    </Grid>
                    <Grid item xs={7} lg={10}>
                        <Box sx={{ padding: 1, backgroundColor: "#f9f9f9" }}>
                            <Box>
                                <Stack spacing={1} direction="row">
                                    <PermissionGuard feature='category' permission='canEdit'>
                                        <Button
                                            //variant='contained' 
                                            onClick={(e) => modalOpen(categories[selectedTab], 1)}
                                            sx={{ height: "30px", marginTop: "10px", marginRight: "10px" }}
                                            startIcon={<EditIcon />}
                                            variant="outlined"
                                            color="primary"
                                        >
                                            {isMobile ? "" : "Edit Category"}
                                        </Button>
                                    </PermissionGuard>
                                </Stack>
                            </Box>

                            <Divider sx={{ marginTop: 1, marginBottom: 1 }} />

                            <Grid container spacing={1}>
                                {subCategorylist?.map((subcategory, index) => (
                                    <Grid item xs={12} lg={3} key={index}>
                                        <Paper elevation={1} style={{ padding: 7 }}>
                                            <Grid container spacing={2} >
                                                <Grid item xs={9}>
                                                    <Typography variant="h6" sx={{ fontSize: ".65rem", fontWeight: 600,color:'#3e3e3e' }} gutterBottom>
                                                        {subcategory.title}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Stack spacing={2} direction="row" justifyContent="flex-end">
                                                        <PermissionGuard feature='category' permission='canEdit'>
                                                            <EditIcon
                                                                style={{
                                                                    fontSize: "0.85rem",
                                                                    color: "blue",
                                                                    cursor: "pointer",
                                                                }}
                                                                className="cursor-pointer"
                                                                onClick={(e) => modalOpen(subcategory, 2)}
                                                            />
                                                        </PermissionGuard>
                                                    </Stack>
                                                </Grid>
                                            </Grid>

                                            <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
                                            {subcategory.image && 
                                                <Box>
                                                    {isLoadingSubCategory?
                                                        <Skeleton variant="rectangular" width={210} height={118} />
                                                        :
                                                        <img
                                                            src={`${Helpers.ImagePathUrl()}/category/md/${subcategory.image}`}
                                                            style={{width:'100%'}}
                                                        />
                                                    }
                                                </Box>
                                            }
                                        </Paper>

                                    </Grid>
                                ))}

                            </Grid>
                        </Box>
                    </Grid>
                </Grid>



            </Box>
        </Box>
    );
};

export default CategoryTabs;

import React, { useEffect, useState } from 'react';
import { Box, Typography} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import NoRowsLayout from '../../../components/Layout/NoRowsLayout';
import { ProviderServices } from '../CrudServices';
import { useParams } from 'react-router-dom';
import { BookingService } from '../../Booking/Service';

const ServiceTable = (props) => {
    const {id} = useParams();
    const {} = props;
    const [categoryList,setCategoryList] = useState([]);
    const [subCategoryList,setSubCategoryList] = useState([]);
    const [serviceList,setServiceList] = useState([]);
    const [providerServiceModal,setProviderServiceModal] = useState({});
    /* =====================datagrid table related =========================*/
    const [loading, setLoading] = useState(false);
    const [allRowCount, setAllRowCount] = useState(0);
    const [controller, setController] = useState({
        page: 0,
        pageSize: 5,
        filters: {
          searchKey: "",
          status: 1,
        }
    });

    /*===========fetch servicelist of provider =========== */
    const fetchProviderServiceList = async(fetchParameters)=>{
      setLoading(true);
    
      const providerServiceListResponse = await ProviderServices.fetchProviderServices(fetchParameters);
      // console.log("provider service list Response:",providerServiceListResponse)
      // setProviderServiceList(providerServiceListResponse?.data?.list);
      const fetchedServiceList = providerServiceListResponse?.data?.list;
      const newServiceList = fetchedServiceList.map(item => {
        return {
          servicesMasterId: item.servicesMasterId,
          servicesChildId: item.servicesChildId,
          subCategoryId: item.childService?.ServiceCategoryRels[0]?.serviceCategoryId,
          subCategoryTitle: item.childService?.ServiceCategoryRels[0]?.ServiceCategory?.title,
          masterServiceTitle: item?.ServicesMaster?.title,
          childServiceTitle: item.childService?.title,
          serviceTime: item.serviceTime,
          price: item.price
        };
      });
      setServiceList(newServiceList);
      // setProviderServiceModal({
      //   currentPage: providerServiceListResponse.data?.currentPage,
      //   totalItems:providerServiceListResponse.data?.totalItems,
      //   totalPages:providerServiceListResponse.data?.totalPages
      // })
      setAllRowCount(providerServiceListResponse.data?.totalItems)
      setLoading(false);
    }

    /*=============on compopnent load ============ */
    useEffect(()=>{
      const fetchCategoryList= async()=>{
          try {
            const categoryListResponse = await BookingService.categoryList();
            // console.log("category list Response:",categoryListResponse);
            if(categoryListResponse.success===1){
              setCategoryList(categoryListResponse.data)
            }
          } catch (error) {
            
          }
        }
        fetchCategoryList();
        if (id>0) {
          let fetchParameters = {
            providerId:id,
            page:1,
            pageSize:5,
          }
          fetchProviderServiceList(fetchParameters);
      }
    },[]);
    const handlePaginationChange = (newPagination) => {
        setController({
          ...controller,
          page: newPagination.page,
          pageSize: newPagination.pageSize
        });
    }

    const columns = [
        {
            field: 'serialNumber',
            headerName: 'S.No',
            width: 100,
            valueGetter: (params) => 
              controller.page * controller.pageSize + params.api.getRowIndexRelativeToVisibleRows(params.id)+1,
        },
        {
            field:'subCategoryTitle', headerAlign: 'center', headerName: "Category", flex: 1,editable: false,
        },
        {
            field:'masterServiceTitle', headerAlign: 'center', headerName: "Master Service", flex: 1,editable: false,
        },
        {
            field:'childServiceTitle', headerAlign: 'center', headerName: "Child Service", flex: 1,editable: false,
        },
        {
            field:'serviceTime', headerAlign: 'center', headerName: "Service Time (In mins.)", flex: 1,editable: false,
        },
        {
            field:'price', headerAlign: 'center', headerName: "Price (In rs.)", flex: 1,editable: false,
        },
    ]

  useEffect(()=>{
    fetchProviderServiceList({
        providerId:id,
        page:controller.page+1,
        pageSize:controller.pageSize,
    });
  },[controller]);
  // useEffect(()=>{
  //   setAllRowCount(providerServiceModal?.totalItems);
  // },[])
  return (
    <>
      <Box sx={{p:'.5% 2%',border:'2px solid lightGray',bgcolor:'lightGray',width:'100%'}}>
        <Typography sx={{fontWeight:'600',fontSize:'.85rem'}}>Service List</Typography>
      </Box>
        <Box sx={{ overflow: "auto" }}>
            <Box sx={{ width: "100%", display: "table", tableLayout: "fixed", backgroundColor: "#fff",borderRadius:'.5rem' }}>
                <DataGrid
                    rows={serviceList}
                    columns={columns}
                    getRowId={(row) => row.servicesChildId}
                    //checkboxSelection={true}
                    rowCount={allRowCount}  // Use the length of the filtered list
                    pageSizeOptions={[5, 10, 20, 50, 100]}
                    paginationModel={controller}
                    paginationMode="server"
                    onPaginationModelChange={handlePaginationChange}

                    loading={loading}

                    disableColumnMenu
                    disableRowSelectionOnClick
                    slots={{
                        // toolbar: GridToolbar,
                        noRowsOverlay: NoRowsLayout,
                    }}

                    getRowHeight={() => 'auto'}
                    autoHeight
                    sx={{
                    '--DataGrid-overlayHeight': '300px',
                    "& .MuiDataGrid-cell": {
                        border: 1,
                        borderRight: 0,
                        borderTop: 0,
                        borderColor: 'lightgrey',
                        p: 1,
                        textAlign: 'center',
                        justifyContent: 'center',
                        fontSize:'.75rem'
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        borderBottom: '2px solid rgba(224, 224, 224, 1)',
                        borderTop: '2px solid rgba(224, 224, 224, 1)',
                    },
                    '& .MuiDataGrid-columnHeader': {
                        borderLeft: '1px solid rgba(224, 224, 224, 1)',
                        '&:last-child': {
                        borderRight: 'none',
                        },
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: '600',
                        color: '#3e3e3e',
                        fontSize:'.75rem'
                    },
                    '& .MuiDataGrid-row': {
                        '&:last-child .MuiDataGrid-cell': {
                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                        },
                        '& .MuiDataGrid-cell:last-child': {
                        borderRight: 'none',
                        },
                    },
                    '& .MuiDataGrid-columnSeparator': {
                        display: 'none',
                    },
                    }}
                />
            </Box>
        </Box>
    </>
  );
}

export default ServiceTable;

import { BookingService } from '../../Booking/Service';
import { Box, Button, Card, CardContent, Chip, CircularProgress, Grid, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import SkeletonLoader from '../../../components/Loader/SkeletonLoader';
import { formatDateTime } from '../../../utils/dateUtils';
import { isMobile } from 'react-device-detect';
import BookingChart from './bookingChart';

const BookingReport = () => {
  // const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // const handleChangePage = (event, newPage) => {
  //     setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //     setRowsPerPage(+event.target.value);
  //     setPage(0);
  // };

  const [isloading, setIsLoading] = useState(false);
  const [bookingData, setBookingData] = useState([]);
  const fetchBookingData = async () => {
      setBookingData([]);
      try {
          setIsLoading(true);
          const response = await BookingService.bookingList({
              page: 0,
              limit: rowsPerPage,
              filters: {
                searchKey: '',   // Use the title filter from the paginationModel
                status:  'all',
                sortKey:'',
                sortDirection:'desc'
              }
            });
            if (response.success =="1") {
              setBookingData(response.data.list);
            } 
            // else {
            //   Swal.fire('Request failed','','error')
            // }
      } catch (error) {
          // console.log(error)
          Swal.fire(error.message,'','error')
      } finally{
          setIsLoading(false);
      }
  };
  useEffect(() => {
      fetchBookingData();
  }, []);

return (
  <Grid container spacing={1}>
    <Grid item xs={isMobile?12:8}>
        <Card>
            <CardContent sx={{p:0,pb:'0!important'}}>            
                <TableContainer sx={{minHeight:'68vh',position:'relative'}}>
                    <Table>
                        <TableHead 
                            sx={{
                                backgroundColor: '#dee1e475',
                                '& .MuiTableCell-root': {
                                    backgroundColor: '#ddd',
                                    color: '#333', // Optional: adjust text color if needed
                                    fontSize:'.85rem'
                                }
                            }}
                        >
                            <TableRow>                                            
                                <TableCell sx={{p:'1%'}}>Invoice</TableCell>
                                <TableCell sx={{p:'1%'}}>Username</TableCell>
                                <TableCell sx={{p:'1%'}}>Date</TableCell>
                                <TableCell sx={{p:'1%'}}>Amount</TableCell>
                                <TableCell sx={{p:'1%'}}>Order Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <Box>
                                {isloading &&  <SkeletonLoader rowCount={rowsPerPage} width={710} />}
                            </Box>
                            {bookingData
                            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => (
                                <TableRow key={row.invoice}>
                                    <TableCell sx={{p:'1.15%',fontSize:'0.75rem',color:'#1976d2'}}>#{row.bookingNumber}</TableCell>
                                    <TableCell sx={{p:'1.15%',fontSize:'0.75rem'}}>{row.customerName}</TableCell>
                                    <TableCell sx={{p:'1.15%',fontSize:'0.75rem'}}>{formatDateTime(row.createdAt)}</TableCell>
                                    <TableCell sx={{p:'1.15%',fontSize:'0.75rem'}}>₹{row.total}</TableCell>
                                    {/* colourful chip */}
                                    <TableCell sx={{p:'1.15%',fontSize:'0.75rem'}}>
                                        <Chip
                                            label={row.bookingStatus}
                                            size="small"
                                            sx={{
                                                fontWeight:'500',
                                                fontSize:'x-small',
                                                color:row.bookingStatus == 'requested' ? '#E8832A' : row.bookingStatus == "rejected"?'#F80000':row.bookingStatus == 'completed'?'#009EB4':row.bookingStatus == 'accepted'?'#40B962': row.bookingStatus == 'cancelled'?'#D81A23':row.bookingStatus == 'ongoing'?'#1A44B8':'default',
                                                bgcolor:row.bookingStatus == 'requested' ? '#FFEDD5' : row.bookingStatus == "rejected"?'#f800001f':row.bookingStatus == 'completed'?'#009eb41a':row.bookingStatus == 'accepted'?'#40b96236':row.bookingStatus == 'cancelled'?'#d81a231f':row.bookingStatus == 'ongoing'?'#1a44b838': 'default'
                                                }}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* <TablePagination
                    rowsPerPageOptions={[10,15,20,25]}
                    component="div"
                    count={bookingData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
                {bookingData.length>0 &&<Box sx={{textAlign:'end',p:'.5% 1%'}} > 
                    <Button onClick={()=>window.open('/admin/bookings','_blank')} size='small'>View All</Button>
                </Box>}  
            </CardContent>
        </Card>
    </Grid>
    <Grid item xs={isMobile?12:4}>  
        <BookingChart/>
    </Grid>
  </Grid>
);
}

export default BookingReport;

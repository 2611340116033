import { Cancel, Edit, Save, Send } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { CrudServices } from './CrudServices';
import { Autocomplete, Box, IconButton, TextField, Typography } from '@mui/material';
import { useForm ,Controller} from 'react-hook-form';
import Swal from 'sweetalert2';
import { set } from 'zod';
import { LocalStore } from '../../storage/authStore';

const SacCodeManagement = ({serviceData,sacList}) => {
    const loggedUser = LocalStore.getLoggedUserDetails();
    const {control,watch,setValue} = useForm({
        defaultValues: {
            sacId: serviceData.sacId,
        },
    });
    const sacId = watch('sacId');
    const [editSac,setEditSac] = useState(0);
    const handleSacUpdate = async () => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to update this SAC code?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Change now!',
            cancelButtonText: 'No, Cancel',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCloseButton: false,
        });
         if (result.isConfirmed) {
            try {
                // Show loading state
                Swal.fire({
                    title: 'Please wait...',
                    html: 'Updating SAC code',
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });
                const response = await CrudServices.updateServiceSac(serviceData.id,{
                    sacId: sacId,
                });
                console.log('response', response);
                if (response.success == '1') {
                    // Swal.fire({
                    //     title: 'Success',
                    //     text: 'SAC code updated successfully',
                    //     icon: 'success',
                    // });
                    setEditSac(0);
                    await Swal.fire({
                        title: 'Success',
                        text: 'SAC code updated successfully',
                        icon: 'success',
                        timer: 1500
                    });
                    
                    return true;
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: 'Failed to update SAC code',
                        icon: 'error',
                        timer: 1500
                    });
                    return false;
                }

                // Close loading state
                // Swal.close();
                

            } catch (error) {
                // console.error("Error changing Role :", error);
                setEditSac(0);
                setValue('sacId',serviceData.sacId);
                await Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'An error occurred while Changing the role'
                });
                return false;
            }
        } else {
            setEditSac(0);
            setValue('sacId',serviceData.sacId);
            return false;
        }
                
    }
    useEffect(() => {
        if(sacId && sacId != serviceData.sacId){
            handleSacUpdate();
        }
    }, [sacId])

    const cancelEdit = () => {
        setEditSac(0);
        setValue('sacId',serviceData.sacId);
    }
  return (
    <Box sx={{ width: '100%' ,display:'flex',alignItems:'center'}}>
        {serviceData.sacId ?
            <Typography sx={{flexGrow:1,fontSize:'0.75rem',textAlign:'center',width:'100%',fontWeight:500}}>{sacList.find(item => item.id === serviceData.sacId)?.sac || ''}</Typography>
        :
        <>
            <Controller
                control={control}
                name="sacId"
                render={({ field }) => (
                    <Autocomplete
                        {...field}
                        sx={{
                            '& .MuiAutocomplete-input': {
                            fontSize: '0.75rem',
                            },
                        }}
                        disabled = {editSac != serviceData.id}
                        fullWidth
                        size='small'
                        options={sacList}
                        getOptionLabel={(option) => option.sac.toString()}
                        value={sacList.find(item => item.id === field.value) || null}
                        onChange={(e, data) => {
                            // setValue('sacId', data?.id || null);
                            field.onChange(data?.id || null);
                        }}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                {option.sac}
                                </Typography>
                            </li>
                        )}
                        renderInput={(params) => <TextField fullWidth  size='small' {...params}/>}
                    />
                )}
            />            
            {(loggedUser.role.title == 'Admin' || loggedUser.role.title == 'Super Admin') &&
            <>
                {editSac == serviceData.id &&<IconButton size='small' color={'error'} onClick={()=>cancelEdit()}>
                <Cancel sx={{fontSize:'1rem'}}/>
                </IconButton>}
                {editSac != serviceData.id &&<IconButton size='small' color={'warning'} onClick={()=>setEditSac(serviceData.id)}>
                    <Edit sx={{fontSize:'1rem'}}/>
                </IconButton>}
            </>}
        </>
        
        }
      
    </Box>
  );
}

export default SacCodeManagement;

import React, { useEffect, useState } from 'react';
import { TextField, Button, Typography, IconButton, Grid, FormControl, InputLabel, Select, MenuItem, FormLabel, RadioGroup, FormControlLabel, Radio, Autocomplete, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm ,Controller, useWatch} from 'react-hook-form';
import Swal from "sweetalert2";
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { isMobile } from "react-device-detect";
import ImgUploadDialog from '../../../components/ImgUpload/imgUpload';
import Loading from '../../../components/Loader/Loading';
import { Helpers } from '../../../services/Helpers';
import dayjs from 'dayjs';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { RewardServices } from './RewardServices';

const referenceTypeList = ['REFERAL', 'BOOKING', 'BOOKINGEARN', 'JOINING', 'TOPUP', 'WITHDRAW', 'REWARD', 'BONUS'];
const walletTypeList = ['CASH', 'COIN'];
const rewardTypeList = ['FIXED', 'PERCENTAGE'];
const formatValue = (value) => {
    if (!value) return '';  // Return empty string instead of 0
    const numValue = parseFloat(value);
    return isNaN(numValue) ? '' : numValue.toString();
  };
export default function AddEditProgram({ closeEvent,programData }) {
    const [isEditing,setIsEditing] = useState(false);
    const [programInitialState,setProgramInitialState] = useState({  
        title:'',
        walletType:'',
        referenceType:'',
        rewardType:'',
        rewardValue:'',
        maxRewardValue:'',
        rewardExpiryDays:'',
        issueDate:'',
        expireDate:'',
        status:false,
        description:'',
        isProvider:false,
        web_url:'',
        bookingAmount:'',
        isDisplay:false,
        
    })
    const { register,control, handleSubmit, reset, setValue, watch, formState: { errors,isDirty,dirtyFields } } = useForm({
        // mode: "onChange",
        defaultValues:programInitialState,
        // reValidateMode: "onChange"
    });

   // Watch both dates
    const issueDate = useWatch({
        control,
        name: 'issueDate',
    });

    const expireDate = useWatch({
        control,
        name: 'expireDate',
    });

    useEffect(() => {
        if (issueDate && expireDate) {
            // const start = dayjs(issueDate).startOf('day');
            // const end = dayjs(expireDate).startOf('day'); // Changed to startOf('day')
            const start = dayjs(issueDate);
            const end = dayjs(expireDate);         
            // Calculate the difference in days and add 1 to include both start and end dates
            // const diffDays = end.diff(start, 'day') + 1;
            const diffDays = end.diff(start, 'day');
            
            // Update the rewardExpiryDays field
            setValue('rewardExpiryDays', diffDays);
        }
    }, [issueDate, expireDate, setValue]);

    const [errorMsg, setErrorMsg] = useState("");
    const [modalTtitle, setModalTitle] = useState("Add Reward Program");

    const [imageUploadResponseDetails,setImageUploadResponseDetails] = useState({});
    const [isLoading,setIsLoading] = useState (false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    
    /*============================================*/
    useEffect(() => {
        // categoryList();
        if (programData.id>0) {
            // setProgramInitialState(programData);
            reset(programData);
            setModalTitle("Edit Reward Program");
        }

    }, [programData,reset])
    
           
    const onSubmit=async(data)=> {
        console.log("raw data:",data);
        setIsSubmitting(true);
        try{
            if (programData.id>0) {
                const updateResponse = await RewardServices.updateProgram(data);
                if (updateResponse.success == '1'){
                    Swal.fire(
                        {
                            title: 'Program Update Response',
                            text: `${updateResponse.message}`,
                            icon: 'success'
                        }
                        // updateResponse.message, '', 'success'
                    );
                    closeEvent();
                }else if (updateResponse.success == '0'){
                    Swal.fire('Oops...', updateResponse.message, 'error');
                }
            } else {
                    const createResponse = await RewardServices.createProgram(data);
                    if (createResponse.success == '1'){
                        Swal.fire(
                            {
                                title: 'Program Creation Response',
                                text: `${createResponse.message}`,
                                icon: 'success'
                            }
                            // createResponse.message, '', 'success'
                        );
                        closeEvent();
                    }else if (createResponse.success == '0'){
                        Swal.fire('Oops...', createResponse.message, 'error');
                    }
                
            }
        } catch (error) {
            console.log("error:",error);
            Swal.fire('Oops...', 'Something went wrong!', 'error');                
        }finally{
            setIsSubmitting(false);
        }
        
    }
    /*================== image upload realted ====================== */
    
    const [imgModalOpen, setImgModalOpen] = useState(false);

    const openImgModal = () => {
        setImgModalOpen(true);
    };

    const closeModal = () => {
        setImgModalOpen(false);
        // afterAddPop(1);
    };
    
    const imgData = {
        path: 'service',
        img_type: "single"
    };
    const imgUploadResponse = (data) => {
        setImageUploadResponseDetails(data);
        setValue('image', data.imageName, { shouldDirty: true,shouldValidate: true });
    }
    return (
        <>
            {/* ============== image upload dialog ============= */}
            <ImgUploadDialog open={imgModalOpen} onClose={closeModal} 
                imgData={imgData} 
                imgUploadResponse={imgUploadResponse} 
            />
            <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center',p:'1% 2%',borderBottom:'1px solid lightGray'}}>
                <Typography variant="h5" sx={{fontSize:'0.85rem',fontWeight:600,color:'#3e3e3e'}}>
                    {modalTtitle}
                </Typography>
                <Button
                    onClick={closeEvent}
                    color='error'
                    size='small'
                >
                    Close
                </Button>
            </Box>
            {errorMsg && <Typography color="red" sx={{width:'100%',textAlign:'center',fontSize:'0.55rem',mt:'1%'}}>
                {errorMsg}
            </Typography>}

            <Box component={'form'} onSubmit={handleSubmit(onSubmit)} sx={{p:2}}>
                <Grid container spacing={2}>
                    {isLoading || isSubmitting && <Loading/>}
                    <Grid item sx={{pt:'1%!important'}} xs={isMobile?12:4}>
                        <Typography sx={{color:'#3e3e3e',fontWeight:'500',fontSize:'0.75rem'}}>Select Program Type<Box component={'span'} sx={{color:'red'}}>*</Box></Typography>
                        <Controller
                            name='referenceType'
                            control={control}
                            rules={{
                                required:true
                            }}
                            render={({ field, fieldState: { error } }) => (
                            <FormControl fullWidth>
                                {/* <InputLabel id="wallet-type" sx={{fontSize:'0.75rem'}}>Select Wallet Type</InputLabel> */}
                                <Select
                                    {...field}
                                    size='small'
                                    // label='Select Wallet Type'
                                    placeholder='select wallet Type'
                                    sx={{
                                        // minHeight: '0px',
                                        // height: '1.6rem',
                                        fontSize: '0.75rem',
                                        '& .MuiSelect-select': {
                                        // padding: '0 8px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        },
                                    }}
                                    error={!!error}
                                >
                                    {
                                        referenceTypeList.map((item,index)=>(
                                            <MenuItem key={index} sx={{fontSize:'0.75rem'}} value={item}>{item}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            )}
                        />
                    </Grid>
                    <Grid item sx={{pt:'1%!important'}} xs={isMobile?12:4}>
                        <Typography sx={{color:'#3e3e3e',fontWeight:'500',fontSize:'0.75rem'}}>Select Wallet Type<Box component={'span'} sx={{color:'red'}}>*</Box></Typography>
                        <Controller
                            name='walletType'
                            control={control}
                            rules={{
                                required:true
                            }}
                            render={({ field, fieldState: { error } }) => (
                            <FormControl fullWidth>
                                {/* <InputLabel id="wallet-type" sx={{fontSize:'0.75rem'}}>Select Wallet Type</InputLabel> */}
                                <Select
                                    {...field}
                                    size='small'
                                    // label='Select Wallet Type'
                                    placeholder='select wallet Type'
                                    sx={{
                                        // minHeight: '0px',
                                        // height: '1.6rem',
                                        fontSize: '0.75rem',
                                        '& .MuiSelect-select': {
                                        // padding: '0 8px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        },
                                    }}
                                    error={!!error}
                                >
                                    {
                                        walletTypeList.map((item,index)=>(
                                            <MenuItem key={index} sx={{fontSize:'0.75rem'}} value={item}>{item}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            )}
                        />
                    </Grid>
                    <Grid item sx={{pt:'1%!important'}} xs={isMobile?12:4}>
                        <Typography sx={{color:'#3e3e3e',fontWeight:'500',fontSize:'0.75rem'}}>Select Reward Type<Box component={'span'} sx={{color:'red'}}>*</Box></Typography>
                        <Controller
                            name='rewardType'
                            control={control}
                            rules={{
                                required:true
                            }}
                            render={({ field, fieldState: { error } }) => (
                            <FormControl fullWidth>
                                {/* <InputLabel id="wallet-type" sx={{fontSize:'0.75rem'}}>Select Wallet Type</InputLabel> */}
                                <Select
                                    {...field}
                                    size='small'
                                    // label='Select Wallet Type'
                                    placeholder='select wallet Type'
                                    sx={{
                                        // minHeight: '0px',
                                        // height: '1.6rem',
                                        fontSize: '0.75rem',
                                        '& .MuiSelect-select': {
                                        // padding: '0 8px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        },
                                    }}
                                    error={!!error}
                                >
                                    {
                                        rewardTypeList.map((item,index)=>(
                                            <MenuItem key={index} sx={{fontSize:'0.75rem'}} value={item}>{item}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            )}
                        />
                    </Grid>
                    <Grid item sx={{pt:'1%!important'}} xs={isMobile?12:3}>
                        <Typography sx={{color:'#3e3e3e',fontWeight:'500',fontSize:'0.75rem'}}>For<Box component={'span'} sx={{color:'red'}}>*</Box></Typography>
                        <Controller
                            name='isProvider'
                            control={control}
                            // defaultValue={false}
                            rules={{
                                // required:true
                            }}
                            render={({ field, fieldState: { error } }) => (
                            <FormControl fullWidth>
                                {/* <InputLabel id="wallet-type" sx={{fontSize:'0.75rem'}}>Select Wallet Type</InputLabel> */}
                                <Select
                                    {...field}
                                    size='small'
                                    sx={{
                                        // minHeight: '0px',
                                        // height: '1.6rem',
                                        fontSize: '0.75rem',
                                        '& .MuiSelect-select': {
                                        // padding: '0 8px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        },
                                    }}
                                    error={!!error}
                                    // value={field.value ?? false}
                                >
                                    <MenuItem sx={{fontSize:'0.75rem'}} value={false}>Customer</MenuItem>
                                    <MenuItem sx={{fontSize:'0.75rem'}} value={true}>Service Expert</MenuItem>
                                </Select>
                            </FormControl>
                            )}
                        />
                    </Grid>
                    <Grid item sx={{pt:'1%!important'}} xs={isMobile?12:3}>
                        <Typography sx={{color:'#3e3e3e',fontWeight:'500',fontSize:'0.75rem'}}>Reward Value<Box component={'span'} sx={{color:'red'}}>*</Box></Typography>
                        <Controller
                            control={control}
                            name="rewardValue"
                            rules={{
                                required:true,
                            }}
                            render={({field,fieldState:{error}})=>(
                                // <TextField
                                //     {...field}
                                //     // label="Enter Service Short Title*"
                                //     variant="outlined"
                                //     size="small"
                                //     fullWidth 
                                //     error={!!error}
                                //     inputProps={{
                                //         style:{
                                //             fontSize:'0.75rem'
                                //         }
                                //     }}
                                // />
                                <TextField
                                    {...field}
                                    placeholder='0'
                                    type="number"
                                    inputProps={{
                                        style: { textAlign: 'center', fontSize:'0.75rem'},
                                        onWheel: (e) => e.target.blur(),  // Prevent scroll wheel
                                        onKeyDown: (e) => {
                                            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                                e.preventDefault();
                                            }
                                        }
                                        }}
                                    value={formatValue(field.value)}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
                                            let finalValue = formatValue(inputValue);
                                            const numValue = parseFloat(finalValue);
                                            if (!isNaN(numValue) && numValue > 9999) {
                                                finalValue = '9999';
                                            }
                                            
                                            field.onChange(finalValue);
                                        }
                                        }}
                                    onBlur={(e) => {
                                        let finalValue = formatValue(e.target.value);                  
                                        // Check if the value exceeds 10000 and constrain it
                                        // const numValue = parseFloat(finalValue);
                                        // if (!isNaN(numValue) && numValue > 30) {
                                        //     finalValue = '30';
                                        // }    
                                        field.onBlur();
                                        field.onChange(finalValue);
                                    }}
                                    // type="text"
                                    inputMode="decimal"
                                    size="small"
                                    fullWidth
                                    error={!!error}
                                    // helperText={error && error?.message}
                                /> 
                            )}
                        />
                    </Grid>
                    <Grid item sx={{pt:'1%!important'}} xs={isMobile?12:3}>
                        <Typography sx={{color:'#3e3e3e',fontWeight:'500',fontSize:'0.75rem'}}>Max. Reward Value</Typography>
                        <Controller
                            control={control}
                            name="maxRewardValue"
                            rules={{
                                // required:true,
                            }}
                            render={({field,fieldState:{error}})=>(
                                // <TextField
                                //     {...field}
                                //     // label="Enter Service Short Title*"
                                //     variant="outlined"
                                //     size="small"
                                //     fullWidth 
                                //     error={!!error}
                                //     inputProps={{
                                //         style:{
                                //             fontSize:'0.75rem'
                                //         }
                                //     }}
                                // />
                                <TextField
                                    {...field}
                                    placeholder='0'
                                    type="number"
                                    inputProps={{
                                        style: { textAlign: 'center', fontSize:'0.75rem'},
                                        onWheel: (e) => e.target.blur(),  // Prevent scroll wheel
                                        onKeyDown: (e) => {
                                            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                                e.preventDefault();
                                            }
                                        }
                                        }}
                                    value={formatValue(field.value)}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
                                            let finalValue = formatValue(inputValue);
                                            const numValue = parseFloat(finalValue);
                                            if (!isNaN(numValue) && numValue > 9999) {
                                                finalValue = '9999';
                                            }
                                            
                                            field.onChange(finalValue);
                                        }
                                        }}
                                    onBlur={(e) => {
                                        let finalValue = formatValue(e.target.value);                  
                                        // Check if the value exceeds 10000 and constrain it
                                        // const numValue = parseFloat(finalValue);
                                        // if (!isNaN(numValue) && numValue > 30) {
                                        //     finalValue = '30';
                                        // }    
                                        field.onBlur();
                                        field.onChange(finalValue);
                                    }}
                                    // type="text"
                                    inputMode="decimal"
                                    size="small"
                                    fullWidth
                                    error={!!error}
                                    // helperText={error && error?.message}
                                /> 
                            )}
                        />
                    </Grid>
                    <Grid item sx={{pt:'1%!important'}} xs={isMobile?12:3}>
                        <Typography sx={{color:'#3e3e3e',fontWeight:'500',fontSize:'0.75rem'}}>Booking Amount</Typography>
                        <Controller
                            control={control}
                            name="bookingAmount"
                            // rules={{
                            //     required:true,
                            // }}
                            render={({field,fieldState:{error}})=>(
                                // <TextField
                                //     {...field}
                                //     // label="Enter Service Short Title*"
                                //     variant="outlined"
                                //     size="small"
                                //     fullWidth 
                                //     error={!!error}
                                //     inputProps={{
                                //         style:{
                                //             fontSize:'0.75rem'
                                //         }
                                //     }}
                                // />
                                <TextField
                                    {...field}
                                    placeholder='0'
                                    type="number"
                                    inputProps={{
                                        style: { textAlign: 'center', fontSize:'0.75rem'},
                                        onWheel: (e) => e.target.blur(),  // Prevent scroll wheel
                                        onKeyDown: (e) => {
                                            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                                e.preventDefault();
                                            }
                                        }
                                        }}
                                    value={formatValue(field.value)}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
                                            let finalValue = formatValue(inputValue);
                                            const numValue = parseFloat(finalValue);
                                            if (!isNaN(numValue) && numValue > 9999) {
                                                finalValue = '9999';
                                            }
                                            
                                            field.onChange(finalValue);
                                        }
                                        }}
                                    onBlur={(e) => {
                                        let finalValue = formatValue(e.target.value);                  
                                        // Check if the value exceeds 10000 and constrain it
                                        // const numValue = parseFloat(finalValue);
                                        // if (!isNaN(numValue) && numValue > 30) {
                                        //     finalValue = '30';
                                        // }    
                                        field.onBlur();
                                        field.onChange(finalValue);
                                    }}
                                    // type="text"
                                    inputMode="decimal"
                                    size="small"
                                    fullWidth
                                    error={!!error}
                                    // helperText={error && error?.message}
                                /> 
                            )}
                        />
                    </Grid>
                    <Grid item sx={{pt:'1%!important'}} xs={isMobile?12:5}>
                        <Typography sx={{color:'#3e3e3e',fontWeight:'500',fontSize:'0.75rem'}}>Start Date</Typography>
                        <Controller
                            name="issueDate"
                            control={control}
                            rules={{
                                validate: (value) => {
                                    if(!value) return true; // If no value, validation passes
                                    const date = dayjs(value);
                                    const today = dayjs().startOf('day');
                                    return date.isValid() && (date.isSame(today, 'day') || date.isAfter(today)) || 
                                        "Issue Date must be today or in the future";
                                }
                            }}
                            render={({ field: { onChange, value, ...restField }, fieldState: { error } }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                {...restField}
                                value={value ? dayjs(value) : null}
                                onChange={(newValue) => {
                                    onChange(newValue ? newValue.format("YYYY-MM-DD") : null);
                                }}
                                format="DD-MM-YYYY"
                                minDate={dayjs()}
                                slotProps={{
                                    textField: {
                                        error: !!error,
                                        helperText: error?.message,
                                        fullWidth: true,
                                        required: false, // Changed to false since it's optional
                                        size: 'small',
                                        inputProps:{
                                            style:{
                                                fontSize:'0.75rem'
                                            }
                                        }
                                    }
                                }}
                                />
                            </LocalizationProvider>
                            )}
                        />
                    </Grid>
                    <Grid item sx={{pt:'1%!important'}} xs={isMobile?12:5}>
                        <Typography sx={{color:'#3e3e3e',fontWeight:'500',fontSize:'0.75rem'}}>Expiry Date</Typography>
                        <Controller
                            name="expireDate"
                            control={control}
                            rules={{
                            validate: (value) => {
                                if (!value) return true;
                                // if (value) {
                                    const date = dayjs(value);
                                    const issueDate = dayjs(watch('issueDate'));
                                    return date.isValid() && (date.isAfter(issueDate)) || "Expiry Date must be after Issue Date";
                                // }
                            }
                            }}
                            render={({ field: { onChange, value, ...restField }, fieldState: { error } }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                {...restField}
                                // disabled={!watch('services').length>0 || !watch('customer')}
                                value={value ? dayjs(value) : null}
                                onChange={(newValue) => {
                                    // Store the date in YYYY-MM-DD format for database compatibility
                                    onChange(newValue ? newValue.format("YYYY-MM-DD") : null);
                                }}
                                format="DD-MM-YYYY" // Display format for user
                                //minimum date should be after issue date
                                minDate={watch('issueDate') ? dayjs(watch('issueDate')).add(1, 'day') : dayjs()}
                                disabled={!watch('issueDate')}
                                slotProps={{
                                    textField: {
                                        error: !!error,
                                        helperText: error?.message,
                                        fullWidth: true,
                                        // required: true,
                                        size: 'small',
                                        inputProps:{
                                            style:{
                                                fontSize:'0.75rem'
                                            }
                                        }
                                    }
                                }}
                                />
                            </LocalizationProvider>
                            )}
                        />
                    </Grid>
                    <Grid item sx={{pt:'1%!important'}} xs={isMobile?12:2}>
                    <Typography sx={{color:'#3e3e3e',fontWeight:'500',fontSize:'0.75rem'}}>Offer Period</Typography>
                        
                    <TextField
                        // label="Enter Service Short Title*"
                        value={`${watch('rewardExpiryDays')} days`}
                        variant="outlined"
                        size="small"
                        fullWidth 
                        inputProps={{
                            style:{
                                fontSize:'0.75rem'
                            }
                        }}
                        disabled
                    />
                    </Grid>
                    <Grid item sx={{pt:'1%!important'}} xs={isMobile?12:6}>
                        <Typography sx={{color:'#3e3e3e',fontWeight:'500',fontSize:'0.75rem'}}>Enter Title<Box component={'span'} sx={{color:'red'}}>*</Box></Typography>
                        <Controller
                            control={control}
                            name="title"
                            rules={{
                                required:'title is required',
                            }}
                            render={({field,fieldState:{error}})=>(
                                <TextField
                                    {...field}
                                    // label="Enter Service Short Title*"
                                    variant="outlined"
                                    size="small"
                                    fullWidth 
                                    error={!!error}
                                    inputProps={{
                                        style:{
                                            fontSize:'0.75rem'
                                        }
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item sx={{pt:'1%!important'}} xs={12}>
                        <Typography sx={{color:'#3e3e3e',fontWeight:'500',fontSize:'0.75rem'}}>Enter Description</Typography>
                        <Controller
                            control={control}
                            name="description"
                            rules={{
                                // required:'Category Name is required',
                            }}
                            render={({field,fieldState:{error}})=>(
                                <TextField
                                    {...field}
                                    variant="outlined"
                                    size="small"
                                    fullWidth 
                                    error={!!error}
                                    inputProps={{
                                        style:{
                                            fontSize:'0.75rem'
                                        }
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item sx={{pt:'1%!important'}} xs={12}>
                        <Typography sx={{color:'#3e3e3e',fontWeight:'500',fontSize:'0.75rem'}}>Enter Web Url</Typography>
                        <Controller
                            control={control}
                            name="web_url"
                            rules={{
                                // required:'Category Name is required',
                            }}
                            render={({field,fieldState:{error}})=>(
                                <TextField
                                    {...field}
                                    variant="outlined"
                                    size="small"
                                    fullWidth 
                                    error={!!error}
                                    inputProps={{
                                        style:{
                                            fontSize:'0.75rem'
                                        }
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    
                    {/* <Grid item sx={{pt:'1%!important'}} xs={12}>
                        <Grid container spacing={2} alignItems={'center'}>
                            <Grid item xs={6} textAlign={'center'}>
                                <Button 
                                    disabled={isSubmitting}
                                    fullWidth
                                    onClick={(e) => openImgModal()}
                                    sx={{ marginRight: 2 }}
                                    startIcon={<AddToPhotosIcon />}
                                    variant="outlined"
                                    color="primary"
                                >
                                    Upload Image
                                </Button>                  
                            </Grid>
                            <Grid item xs={6}>
                                {
                                    imageUploadResponseDetails.imageName ? 
                                    <img
                                    src={`${Helpers.ImagePathUrl()}/service/sm/${imageUploadResponseDetails.imageName}`}
                                />
                                :
                                programData.image && <img
                                    src={`${Helpers.ImagePathUrl()}/service/sm/${programData.image}`}
                                />
                                }
                                <Controller
                                    control={control}
                                    name='image'
                                    rules={{
                                        required:'please upload a image'
                                    }}
                                    render={({field})=>(
                                        <>
                                            <TextField
                                                sx={{display:'none'}}
                                            />
                                            <Typography sx={{fontSize:'small',color:'red'}}>{errors.image && errors.image?.message}</Typography>
                                        </>
                                    )}
                                />
                            </Grid>
                            
                        </Grid>
                    </Grid> */}
    
                    <Grid item xs={12}>
                        <Typography variant="h5" align="center">
                            <Button disabled={!isDirty || isSubmitting} variant="outlined" color="secondary" type="submit">
                                {programData.id>0?'Update':'Add'}
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

import { commonRequest } from "../../services/ApiCall";
import { Helpers } from "../../services/Helpers";


export const DashboardServices = {
    bookingCount : async (id) =>{
        return await commonRequest("get", `${Helpers.URL()}/api/admin/dashboard/bookingDetail`, {}, Helpers.token());
    },
    customerCount : async (id) =>{
        return await commonRequest("get", `${Helpers.URL()}/api/admin/dashboard/customerDetail`, {}, Helpers.token());
    },
    storefrontCount : async (id) =>{
        return await commonRequest("get", `${Helpers.URL()}/api/admin/dashboard/storefrontDetail`, {}, Helpers.token());
    },
    providerCount : async (id) =>{
        return await commonRequest("get", `${Helpers.URL()}/api/admin/dashboard/providerDetail`, {}, Helpers.token());
    },
    topServices: async (data) => {
        return await commonRequest("get", `${Helpers.URL()}/api/admin/dashboard/topServices?limit=${data.limit}&month=${data.month}`, data, Helpers.token());
    },
    topCategories: async (data) => {
        return await commonRequest("get", `${Helpers.URL()}/api/admin/dashboard/topCategories?month=${data.month}`, data, Helpers.token());
    },
    saleChart: async (data) => {
        return await commonRequest("get", `${Helpers.URL()}/api/admin/dashboard/yearlySaleDetails?year=${data.year}`, {}, Helpers.token());
    },
    monthlySaleCount: async () => {
        return await commonRequest("get", `${Helpers.URL()}/api/admin/dashboard/monthlySaleDetails`, {}, Helpers.token());
    }
}
import React, { useContext, useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
  Grid,
  Divider,
  IconButton,
  Box,
  CircularProgress,
} from '@mui/material';
import { Cancel as CancelIcon } from '@mui/icons-material';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { RoleAndPermissionServices } from '../RoleAndPermissionServices';
import Swal from 'sweetalert2';
import { RoleDataContext } from '.';
import { LocalStore } from '../../../storage/authStore';

const AddRole = ({ onClose }) => {
  const { actionTab, setActionTab, selectedRole, setSelectedRole } = useContext(RoleDataContext);
  const loggedInUSer = LocalStore.getCurrentUserDetails();
  const { control, watch, reset, handleSubmit, setValue, getValues, formState: { errors, isDirty } } = useForm({
    mode: 'onChange',
    defaultValues: {
      title: '',
      permissionData: []
    }
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const watchPermissionData = useWatch({
    control,
    name: 'permissionData',
    defaultValue: []
  });

  const transformRoleData = (receivedData) => {
    return {
      roleId: receivedData.roleId,
      title: receivedData.roleName,
      permissionData: receivedData.permissions
        .map(permission => ({
          featureId: permission.featureId,
          featureName: permission.featureName,
          canView: permission.canView,
          canCreate: permission.canCreate,
          canEdit: permission.canEdit,
          canDelete: permission.canDelete
        }))
    };
  };

  useEffect(() => {
    if (selectedRole) {
      const transformedData = transformRoleData(selectedRole);
      reset(transformedData);
    }
  }, [selectedRole, reset]);

  const isAllSelected = (index) => {
    const permission = watchPermissionData[index];
    if (!permission) return false;
    
    return permission.canView === true && 
           permission.canCreate === true && 
           permission.canEdit === true && 
           permission.canDelete === true;
  };

  const isIndeterminate = (index) => {
    const permission = watchPermissionData[index];
    if (!permission) return false;

    const selectedCount = [
      permission.canView,
      permission.canCreate,
      permission.canEdit,
      permission.canDelete
    ].filter(Boolean).length;

    return selectedCount > 0 && selectedCount < 4;
  };

  const handleSelectAll = (index, checked) => {
    const currentPermissions = [...getValues('permissionData')];
    const updatedPermission = {
      ...currentPermissions[index],
      canView: checked,
      canCreate: checked,
      canEdit: checked,
      canDelete: checked
    };
    
    currentPermissions[index] = updatedPermission;
    setValue('permissionData', currentPermissions, { 
      shouldDirty: true,
      shouldTouch: true 
    });
  };

  const handleSinglePermissionChange = (index, action, checked) => {
    const currentPermissions = [...getValues('permissionData')];
    const updatedPermission = {
      ...currentPermissions[index],
      [action]: checked
    };
    
    currentPermissions[index] = updatedPermission;
    setValue('permissionData', currentPermissions, { 
      shouldDirty: true,
      shouldTouch: true
    });
  };

  const renderPermissionRow = (permission, index) => (
    <Grid container spacing={2} alignItems="center" sx={{ p: '.25% 1%' }}>
      <Grid item xs={4}>
        <Typography sx={{fontSize:'0.75rem',fontweight:600}}>{permission.featureName}</Typography>
      </Grid>
      <Grid item xs={8}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <FormControlLabel
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize:'.75rem'
                },
                '& .MuiCheckbox-root': {
                  transform:'scale(0.8)',
                  padding:'.25rem'
                }
              }}
              control={
                <Controller
                  name={`permissionData.${index}.allSelected`}
                  control={control}
                  defaultValue={isAllSelected(index)}
                  render={({ field }) => (
                    <Checkbox
                      checked={isAllSelected(index)}
                      indeterminate={isIndeterminate(index)}
                      onChange={(e) => {
                        handleSelectAll(index, e.target.checked);
                      }}
                    />
                  )}
                />
              }
              label="All"
            />
          </Grid>
          {['canView', 'canCreate', 'canEdit', 'canDelete'].map((action) => (
            <Grid item xs={2.5} key={action}>
              <Controller
                name={`permissionData.${index}.${action}`}
                control={control}
                defaultValue={permission[action]}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize:'.75rem'
                      },
                      '& .MuiCheckbox-root': {
                        transform:'scale(0.8)',
                        padding:'.25rem'
                      }
                    }}
                    control={
                      <Checkbox 
                        checked={field.value || false}
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          handleSinglePermissionChange(index, action, e.target.checked);
                        }}
                      />
                    }
                    label={action.replace('can', '')}
                  />
                )}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );

  const onSubmit = async(data) => {
    setIsSubmitted(true);
    
    if (selectedRole) {
      try {
        const {title, ...restAll} = data;
        const modifyFormRoleData = (receivedData) => {
          return {
            roleId: receivedData.roleId,
            // title: receivedData.roleName,
            permissionData: receivedData.permissionData
              .map(permission => ({
                featureId: permission.featureId,
                // featureName: permission.featureName,
                canView: permission.canView,
                canCreate: permission.canCreate,
                canEdit: permission.canEdit,
                canDelete: permission.canDelete
              }))
          };
        };
        const requiredFormattedData = modifyFormRoleData(data);
        // console.log ("submitting data to update role and permissions:",requiredFormattedData)
        const updateResponse = await RoleAndPermissionServices.updateRoleAndPermissions(requiredFormattedData);
        
        if (updateResponse.success == '1') {
          if(data.roleId === loggedInUSer.role) {
            const result = await Swal.fire({
              text: "Do you want to logout now? Changes will be effective immediately. Otherwise, changes will apply after your next login.",
              icon: 'question',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, logout now!',
              cancelButtonText: 'No, stay logged in',
              allowOutsideClick: false,
              allowEscapeKey: false,
              showCloseButton: false,
            });
        
            if (result.isConfirmed) {
              Swal.fire({
                title: 'Logging out...',
                text: 'Please wait while we log you out',
                timer: 1500,
                timerProgressBar: true,
                showConfirmButton: false,
                didOpen: () => {
                  Swal.showLoading();
                }
              }).then(() => {
                LocalStore.doLogout();
              });
              return true;
            } else {
              setIsSubmitted(false);
              onClose();
              reset();
              return false;
            }
          } else {
            setIsSubmitted(false);
            Swal.fire({
              title: 'Role and related Permissions Updated Successfully',
              icon: 'success',
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
            onClose();
            reset();
          }      
        } else {
          Swal.fire('Something Went wrong', '', 'error');
          setIsSubmitted(false);
          onClose();
          reset();
        }
      } catch (error) {
        Swal.fire('Internal Server Error', '', 'error');
      }
    } else {
      const {title} = data;
      try {
        const createResponse = await RoleAndPermissionServices.createRole({title: title});
        if (createResponse.success === '1') {
          Swal.fire(createResponse.message, '', 'success');
          setIsSubmitted(false);
          onClose();
          reset();
        } else {
          Swal.fire('Something Went wrong', '', 'error');
          setIsSubmitted(false);
        }
      } catch (error) {
        Swal.fire('Internal Server Error', '', 'error');
      }
    }
  };

  return (
    <Card sx={{ width: '100%', maxWidth: '1000px'}}>
      <CardHeader 
        title={selectedRole ? `Modify ${watch('title')} Permissions` : 'Create Role'}
        action={
          <IconButton onClick={onClose}>
            <CancelIcon />
          </IconButton>
        }
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          '& .MuiCardHeader-title': {
            fontSize: '0.85rem',
            fontWeight: 600
          },
          p: '0.5% 2%'
        }}
      />
      
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Controller
            name="title"
            control={control}
            rules={{ required: 'Enter role name' }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Role name"
                error={!!error}
                helperText={error?.message}
                size="small"
                sx={{ mb: 1 }}
                inputProps={{
                  style: { fontSize: '0.75rem' }
                }}
              />
            )}
          />

          {selectedRole && (
            <Box>
              {/* <Typography variant="h6" sx={{ p: 1, borderBottom: 1, borderColor: 'divider' }}>
                Role Permissions
              </Typography> */}
              <Box sx={{ maxHeight: '50vh', overflow: 'auto' }}>
                {selectedRole?.permissions.map((permission, index) => (
                  <Box key={permission.featureId}>
                    {renderPermissionRow(permission, index)}
                    {index < selectedRole.permissions.length - 1 && (
                      <Divider sx={{ borderStyle: 'dotted' }} />
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </CardContent>

        <CardActions sx={{ 
          justifyContent: 'flex-end', 
          p: 1, 
          borderTop: 1, 
          borderColor: 'divider' 
        }}>
          {isSubmitted && <CircularProgress/>}
          <Button 
            size='small'
            variant="outlined" 
            color="inherit"
            onClick={onClose}
            sx={{ mr: 1 }}
            disabled={isSubmitted}
          >
            Discard
          </Button>
          <Button
            size='small' 
            variant="contained" 
            color="primary"
            type="submit"
            disabled={!isDirty || isSubmitted}
          >
            Submit
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export default AddRole;


// import React, { useContext, useState } from 'react';
// import {
//   Button,
//   Card,
//   CardActions,
//   CardContent,
//   CardHeader,
//   Checkbox,
//   TextField,
//   Typography,
//   FormControl,
//   FormControlLabel,
//   Grid,
//   Divider,
//   IconButton,
//   Box,
//   CircularProgress,
// } from '@mui/material';
// import { Cancel as CancelIcon } from '@mui/icons-material';
// import { useForm, Controller } from 'react-hook-form';
// import { RoleAndPermissionServices } from '../RoleAndPermissionServices';
// import Swal from 'sweetalert2';
// import { RoleDataContext } from '.';
// import { LocalStore } from '../../../storage/authStore';

// const AddRole = ({ onClose }) => {
//   const {actionTab,setActionTab,selectedRole,setSelectedRole} = useContext(RoleDataContext);
//   const loggedInUSer = LocalStore.getCurrentUserDetails();
//   const { control, watch, reset, handleSubmit, formState: { errors, isDirty } } = useForm({
//     mode: 'onChange',
//     defaultValues: {
//       title: '',
//       permissionData: []
//     }
//   });
//   const [isSubmitted,setIsSubmitted] = useState(false);

//   const transformRoleData = (receivedData) => {
//     return {
//         roleId: receivedData.roleId,
//         title: receivedData.roleName,
//         permissionData: receivedData.permissions
//             .map(permission => ({
//                 featureId: permission.featureId,
//                 canView: permission.canView,
//                 canCreate: permission.canCreate,
//                 canEdit: permission.canEdit,
//                 canDelete: permission.canDelete
//             }))
//     };
// };

//   React.useEffect(() => {
//     if (selectedRole) {
//       const transformedData = transformRoleData(selectedRole);
//       reset(transformedData);
//     }
//   }, [selectedRole, reset]);

//   const onSubmit = async(data) => {
//     setIsSubmitted(true);
//     console.log("Submitted data:", data);
    
//     if (selectedRole) {
      
//       try {
//         const {title,...restAll} =data;
//         const updateResponse = await RoleAndPermissionServices.updateRoleAndPermissions({...restAll});
//         console.log("role and permission update response:",updateResponse);
//         if (updateResponse.success=='1') {
//           // if logged in user role is same as role is editted then
//           if(data.roleId == loggedInUSer.role ){
//             const result = await Swal.fire({
              
//               text: "Do you want to logout now? Changes will be effective immediately. Otherwise, changes will apply after your next login.",
//               icon: 'question',
//               showCancelButton: true,
//               confirmButtonColor: '#3085d6',
//               cancelButtonColor: '#d33',
//               confirmButtonText: 'Yes, logout now!',
//               cancelButtonText: 'No, stay logged in',
//               allowOutsideClick: false,  // Prevent clicking outside to dismiss
//               allowEscapeKey: false,     // Prevent ESC key to dismiss
//               showCloseButton: false,    // Hide the close button
//             });
        
//             if (result.isConfirmed) {
//               // Confirm button action
//               Swal.fire({
//                 title: 'Logging out...',
//                 text: 'Please wait while we log you out',
//                 timer: 1500,
//                 timerProgressBar: true,
//                 showConfirmButton: false,
//                 didOpen: () => {
//                   Swal.showLoading();
//                 }
//               }).then(() => {
//               // Perform logout
//               LocalStore.doLogout();
              
//               });
//               // After logout, the login page will be shown
//               return true;
            
//             } else {
//               // Redirect to dashboard if user chooses not to logout
//               setIsSubmitted(false);
//               // setActionTab(0)
//               onClose();
//               reset();
//               return false;
//             }
//           }else{
//             setIsSubmitted(false);
//             Swal.fire(
//               {
//                 title: 'Role and related Permissions Updated Successfully',
//                 icon:'success',
//                 allowOutsideClick: false,  // Prevent clicking outside to dismiss
//                 allowEscapeKey: false,     // Prevent ESC key to dismiss
//               }
//             );
//             // setActionTab(0)
//             onClose();
//             reset();
//           }      
//         }else{
//           Swal.fire('Something Went wrong','','error');
//           setIsSubmitted(false);
//           // setActionTab(0)
//           onClose();
//           reset();
//         }
//       } catch (error) {
//         Swal.fire('Internal Server Error','','error');
//       }
//     }else{
//       const {title} =data;
//       try {
//         const createResponse = await RoleAndPermissionServices.createRole({title:title});
//         console.log("role creation response:",createResponse);
//         if (createResponse.success=='1') {
//           Swal.fire(createResponse.message,'','success');
//           setIsSubmitted(false);
//           // setActionTab(0)
//           onClose();
//           reset();
//         }else{
//           Swal.fire('Something Went wrong','','error');
//           setIsSubmitted(false);
//         }
//       } catch (error) {
//         Swal.fire('Internal Server Error','','error');
//       }
//     }
//     // reset();
    
//   };

//   const renderPermissionRow = (permission, index) => (
//     <Grid container spacing={2} alignItems="center" sx={{ p: '.25% 1%' }}>
//       <Grid item xs={4}>
//         <Typography> {permission.featureName}</Typography>
//       </Grid>
//       <Grid item xs={8}>
//         <Grid container spacing={2}>
//           {['canView', 'canCreate', 'canEdit', 'canDelete'].map((action) => (
//             <Grid item xs={3} key={action}>
//               <Controller
//                 name={`permissionData.${index}.${action}`}
//                 control={control}
//                 defaultValue={permission[action]}
//                 render={({ field }) => (
//                   <FormControlLabel
//                     control={
//                       <Checkbox 
//                         checked={field.value}
//                         onChange={(e) => {
//                           // Update only this specific permission
//                           field.onChange(e.target.checked);
//                         }}
//                       />
//                     }
//                     label={action.replace('can', '')}
//                   />
//                 )}
//               />
//             </Grid>
//           ))}
//         </Grid>
//       </Grid>
//     </Grid>
//   );

//   return (
//     <Card sx={{ width: '100%', maxWidth: '1000px', mx: 'auto' }}>
//       <CardHeader 
//         title={selectedRole ? 'Modify Role' : 'Create Role'}
//         action={
//           <IconButton onClick={onClose}>
//             <CancelIcon />
//           </IconButton>
//         }
//         sx={{
//           borderBottom: 1,
//           borderColor: 'divider',
//           '& .MuiCardHeader-title': {
//             fontSize: '1.25rem',
//             fontWeight: 600
//           }
//         }}
//       />
      
//       <form onSubmit={handleSubmit(onSubmit)}>
//         <CardContent>
//           <Controller
//             name="title"
//             control={control}
//             rules={{ required: 'Enter role name' }}
//             render={({ field, fieldState: { error } }) => (
//               <TextField
//                 {...field}
//                 label="Role name"
//                 error={!!error}
//                 helperText={error?.message}
//                 size="small"
//                 sx={{ mb: 1 }}
//               />
//             )}
//           />

//           {selectedRole && (
//             <Box sx={{ }}>
//               <Typography variant="h6" sx={{  p: 1, borderBottom: 1, borderColor: 'divider' }}>
//                 Role Permissions
//               </Typography>
//               <Box sx={{ maxHeight: '50vh', overflow: 'auto' }}>
//                 {selectedRole && selectedRole?.permissions.map((permission, index) => (
//                   <Box key={permission.featureId}>
//                     {renderPermissionRow(permission, index)}
//                     {index < selectedRole.permissions.length - 1 && (
//                       <Divider sx={{ borderStyle: 'dotted' }} />
//                     )}
//                   </Box>
//                 ))}
//               </Box>
//             </Box>
//           )}
//         </CardContent>

//         <CardActions sx={{ 
//           justifyContent: 'flex-end', 
//           p: 2, 
//           borderTop: 1, 
//           borderColor: 'divider' 
//         }}>
//           {isSubmitted && <CircularProgress/>}
//           <Button 
//             variant="outlined" 
//             color="inherit"
//             onClick={onClose}
//             sx={{ mr: 1 }}
//             disabled={isSubmitted}
//           >
//             Discard
//           </Button>
//           <Button 
//             variant="contained" 
//             color="primary"
//             type="submit"
//             disabled={!isDirty || isSubmitted}
//           >
//             Submit
//           </Button>
//         </CardActions>
//       </form>
//     </Card>
//   );
// };

// export default AddRole;
import { TextField,
    Button,
    Grid,
    Box,
    Stack,
    Divider,
    Autocomplete,
    FormControl,
    Select,
    MenuItem,
    Typography,
    Card,FormControlLabel,Checkbox,CardContent,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Paper, 
    RadioGroup,
    Radio} from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// import formatValue from '../../../utils/formatNumber';

const formatValue = (value) => {
    if (!value) return '';  // Return empty string instead of 0
    const numValue = parseFloat(value);
    return isNaN(numValue) ? '' : numValue.toString();
  };
    
const ServiceCharges = () => {
    const { control, watch, setValue } = useFormContext();
    const services = watch('services');
    const isRefundable = watch('isRefundable');
    useEffect(()=>{
        if (!isRefundable) {
           setValue('refundableDuration',0);
           setValue('refundableAmount',0)
        }
    },[isRefundable])
  return (
    <Box sx={{p:'.5% 1%'}}>
        <Box sx={{display:'flex',gap:'2%'}}>
            <Box sx={{flex:1}}>
                <Typography sx={{fontWeight:'600',fontSize:'0.75rem'}}>visting Charge (in Rs.)<span style={{color:'red'}}>*</span></Typography>
                    <Controller
                        name='vistingCharge'
                        control={control}
                        rules={{
                            required: "Enter visiting charge",
                            validate: (value) => {
                                const floatValue = parseFloat(value);
                                if (isNaN(floatValue)) return 'Please enter a valid number';
                                if (floatValue > 10000) return 'visiting charge should not exceed ₹10,000';
                                if (floatValue > 0 && floatValue < 1) return 'visiting charge should not be less than ₹1';
                                return floatValue >= 0 || 'visiting charge should not be negative';
                            }
                        }}
                        render={({ field, fieldState: { error } }) => (
                        <TextField
                            disabled={services?.length=='0'}
                            {...field}
                            placeholder='0'
                            fullWidth
                            type="number"
                            value={formatValue(field.value)}
                            onChange={(e) => {
                            const inputValue = e.target.value;
                            if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
                                let finalValue = formatValue(inputValue);
                        
                                // Check if the value exceeds 10000 and constrain it
                                const numValue = parseFloat(finalValue);
                                if (!isNaN(numValue) && numValue > 10000) {
                                    finalValue = '10000';
                                }
                                
                                field.onChange(finalValue);
                            }
                            }}
                            // onBlur={(e) => {
                            //     let finalValue = formatValue(e.target.value);                  
                            //     // Check if the value exceeds 10000 and constrain it
                            //     const numValue = parseFloat(finalValue);
                            //     if (!isNaN(numValue) && numValue > 10000) {
                            //         finalValue = '10000';
                            //     }    
                            //     field.onBlur();
                            //     field.onChange(finalValue);
                            // }}
                            // type="text"
                            inputMode="decimal"
                            size="small"
                            inputProps={{
                                style: { textAlign: 'center', height: '1rem' ,fontSize:'0.75rem'},
                                min: 0,
                                max: 10000,
                                step: 0.01 // Allow two decimal places
                            }}
                            error={!!error}
                            // helperText={error && error?.message}
                        />
                    )}  
                />                    
            </Box>
            <Box sx={{flex:1}}>
                <Typography sx={{fontWeight:'600',fontSize:'0.75rem'}}>Additional Charge (in Rs.)<span style={{color:'red'}}>*</span></Typography>                        
                <Controller
                    name='additionalCharge'
                    control={control}
                    rules={{
                        required: "required",
                        validate: (value) => {
                            const floatValue = parseFloat(value);
                            return floatValue >= 0 || 'Price should not be less than 0';
                        }
                    }}
                    render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        placeholder='0'
                        type="number"
                        inputProps={{
                            style: { textAlign: 'center', height: '1rem' ,fontSize:'0.75rem'},
                            onWheel: (e) => e.target.blur(),  // Prevent scroll wheel
                            onKeyDown: (e) => {
                                if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                    e.preventDefault();
                                }
                            }
                          }}
                        value={formatValue(field.value)}
                        disabled={services?.length=='0'}
                        onChange={(e) => {
                            const inputValue = e.target.value;
                            if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
                                let finalValue = formatValue(inputValue);
                                const numValue = parseFloat(finalValue);
                                if (!isNaN(numValue) && numValue > 9999) {
                                    finalValue = '9999';
                                }
                                
                                field.onChange(finalValue);
                            }
                            }}
                        onBlur={(e) => {
                            let finalValue = formatValue(e.target.value);                  
                            // Check if the value exceeds 10000 and constrain it
                            // const numValue = parseFloat(finalValue);
                            // if (!isNaN(numValue) && numValue > 30) {
                            //     finalValue = '30';
                            // }    
                            field.onBlur();
                            field.onChange(finalValue);
                        }}
                        // type="text"
                        inputMode="decimal"
                        size="small"
                        fullWidth
                        error={!!error}
                        // helperText={error && error?.message}
                    /> 
                    )}  
                />          
            </Box> 
            <Box sx={{flex:1}}>
                <Typography sx={{fontWeight:'600',fontSize:'0.75rem'}}>Cancellation Charge (in %)<span style={{color:'red'}}>*</span></Typography>
                <Controller
                    name='cancellationChargeInPercent'
                    control={control}
                    rules={{
                        required: "required",
                        validate: (value) => {
                            const floatValue = parseFloat(value);
                            if (isNaN(floatValue)) return 'Please enter a valid number';
                            if (floatValue > 99) return 'cancellation charge should not exceed 99%';
                            return floatValue >= 0 || 'cancellation charge should not be less than 0';
                            
                        }
                    }}
                    render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        placeholder='0'
                        type="number"
                        value={formatValue(field.value)}
                        disabled={services?.length=='0'}
                        onChange={(e) => {
                            const inputValue = e.target.value;
                            if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
                                let finalValue = formatValue(inputValue);
                        
                                // Check if the value exceeds 99 and constrain it
                                const numValue = parseFloat(finalValue);
                                if (!isNaN(numValue) && numValue > 99) {
                                    finalValue = '99';
                                }
                                
                                field.onChange(finalValue);
                            }
                            }}
                        onBlur={(e) => {
                            let finalValue = formatValue(e.target.value);                  
                            // Check if the value exceeds 99 and constrain it
                            const numValue = parseFloat(finalValue);
                            if (!isNaN(numValue) && numValue > 99) {
                                finalValue = '99';
                            }    
                            field.onBlur();
                            field.onChange(finalValue);
                        }}
                        // type="text"
                        inputMode="decimal"
                        size="small"
                        fullWidth
                        inputProps={{
                            style: { textAlign: 'center', height: '1rem' ,fontSize:'0.75rem'},
                            min: 0,
                            max:99.00,
                            step: 0.01 // Allow two decimal places
                        }}
                        error={!!error}
                        // helperText={error && error?.message}
                    /> 
                    )}  
                />                    
            </Box>
            <Box sx={{flex:1}}>
                <Typography sx={{fontWeight:'600',fontSize:'0.75rem'}}>Cancellation Time Limit (in Hrs.) <span style={{color:'red'}}>*</span></Typography>                        
                <Controller
                    name='timeLimitForCancellation'
                    control={control}
                    rules={{
                        required: "required",
                        validate: (value) => {
                            const intValue = parseInt(value, 10);
                            if (intValue > 24) return 'cancellation time should not exceed 24 hours';
                            return !isNaN(intValue) && intValue >= 0 || 'cancellation time should be a non-negative integer';
                        }
                        }}
                        render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            placeholder='0'
                            fullWidth
                            disabled={services?.length=='0'}
                            value={formatValue(field.value)}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
                                    let finalValue = formatValue(inputValue);
                            
                                    // Check if the value exceeds 24 and constrain it
                                    const numValue = parseInt(finalValue);
                                    if (!isNaN(numValue) && numValue > 24) {
                                        finalValue = '24';
                                    }
                                    
                                    field.onChange(finalValue);
                                }
                                }}
                            onBlur={(e) => {
                                let finalValue = formatValue(e.target.value);                  
                                // Check if the value exceeds 24 and constrain it
                                const numValue = parseInt(finalValue);
                                if (!isNaN(numValue) && numValue > 24) {
                                    finalValue = '24';
                                }    
                                field.onBlur();
                                field.onChange(finalValue);
                            }}
                            type="number"
                            inputMode="numeric"
                            size="small"
                            inputProps={{
                                style: { textAlign: 'center', height: '1rem' ,fontSize:'0.75rem'},
                            min: 0,
                            max:24,
                            step: 1 // Only allow whole numbers
                            }}
                            error={!!error}
                            // helperText={error ? error.message : ''}
                        />
                    )}  
                />          
            </Box>   
        </Box>
        <Box sx={{my:'1%',display:'flex',gap:'2%'}}>
            <Box sx={{width:'23.5%'}}>
                <Typography sx={{fontWeight:'600',fontSize:'0.75rem'}}>is this Service Refundable </Typography>
                <Controller
                    name="isRefundable"
                    control={control}
                    defaultValue="false" // Default to 'No' as a string
                    render={({ field }) => (
                        <FormControl size='small'
                            sx={{
                                '& .MuiFormControlLabel-root': {
                                    // Adjust the overall size of the label and radio button container
                                    margin: '0 10px', // Horizontal spacing between radio buttons
                                    transform: 'scale(0.8)' // Adjust overall size (can be 0.8 to 1.2)
                                },
                                '& .MuiRadio-root': {
                                    // Specifically target the radio button size
                                    padding: '6px', // Adjust padding
                                    '& .MuiSvgIcon-root': {
                                        // Modify the actual radio button icon size
                                        fontSize: '20px' // Adjust the size of the radio button
                                    }
                                },
                                '& .MuiFormControlLabel-label': {
                                    // Modify label text size
                                    fontSize: '14px', // Adjust font size
                                    marginLeft: '4px' // Adjust space between radio and label
                                }
                            }}
                        >
                        <RadioGroup
                            {...field} // Spread field properties for value and onChange
                            row
                            value={field.value === true ? "true" : "false"} // Convert to string for consistency
                            onChange={(e) => field.onChange(e.target.value === "true")} // Convert back to boolean on change
                        >
                            <FormControlLabel value="true" control={<Radio />} label="Yes" />
                            <FormControlLabel value="false" control={<Radio />} label="No" />
                        </RadioGroup>
                        </FormControl>
                    )}
                />

            </Box>
            <Box sx={{width:'23.5%'}}>
                <Typography sx={{fontWeight:'600',fontSize:'0.75rem'}}>Refundable Amount (in rs.)<span style={{color:'red'}}>*</span></Typography>
                <Controller
                    name='refundableAmount'
                    control={control}
                    rules={{
                        // required: "required", // This can be optional if only required when refundable is true
                        validate: (value) => {
                            const isRefundable = watch('isRefundable');
                            const numValue = parseInt(value, 10);
                            
                            if (isRefundable) {
                                if (isNaN(numValue)) return 'Please enter a valid Amount';
                                if (numValue <= 0) return 'Refundable Amount should be greater than 0';
                                if (numValue > 100000) return 'Refundable Amount cannot exceed Rs.100000';
                            }
                          return true; // Return true if no validation errors
                        }
                      }}
                    render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        placeholder='0'
                        type="number"
                        value={formatValue(field.value)}
                        disabled={services?.length=='0' || !isRefundable}
                        onChange={(e) => {
                        const inputValue = e.target.value;
                        if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
                            // field.onChange(formatValue(inputValue));
                            let finalValue = formatValue(inputValue);
                        
                                // Check if the value exceeds 10000 and constrain it
                                const numValue = parseFloat(finalValue);
                                if (!isNaN(numValue) && numValue > 100000) {
                                    finalValue = '100000';
                                }
                                
                                field.onChange(finalValue);
                        }
                        }}
                        onBlur={(e) => {
                            field.onBlur();
                            field.onChange(formatValue(e.target.value));
                        }}
                        // type="text"
                        inputMode="decimal"
                        size="small"
                        fullWidth
                        inputProps={{
                            style: { textAlign: 'center', height: '1rem' ,fontSize:'0.75rem'},
                            min: 0,
                            step: 0.01 // Allow two decimal places
                        }}
                        error={!!error}
                    /> 
                    )}  
                />                    
            </Box>
            <Box sx={{width:'23.5%'}}>
                <Typography sx={{fontWeight:'600',fontSize:'0.75rem'}}>Refundable Duration (in Days)<span style={{color:'red'}}>*</span></Typography>                        
                <Controller
                    name='refundableDuration'
                    control={control}
                    rules={{
                        validate: (value) => {
                            const isRefundable = watch('isRefundable');
                            const numValue = parseInt(value, 10);
                            
                            if (isRefundable) {
                                if (isNaN(numValue)) return 'Please enter a valid number';
                                if (numValue <= 0) return 'Refundable Duration should be greater than 0';
                                if (numValue > 30) return 'Refundable Duration cannot exceed 30 days';
                            }
                            
                            return true;
                        }
                    }}
                    render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        placeholder='0'
                        type="number"
                        value={formatValue(field.value)}
                        disabled={services?.length=='0' || !isRefundable}
                        onChange={(e) => {
                            const inputValue = e.target.value;
                            if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
                                let finalValue = formatValue(inputValue);
                        
                                // Check if the value exceeds 30 and constrain it
                                const numValue = parseFloat(finalValue);
                                if (!isNaN(numValue) && numValue > 30) {
                                    finalValue = '30';
                                }
                                
                                field.onChange(finalValue);
                            }
                            }}
                        onBlur={(e) => {
                            let finalValue = formatValue(e.target.value);                  
                            // Check if the value exceeds 30 and constrain it
                            const numValue = parseInt(finalValue);
                            if (!isNaN(numValue) && numValue > 30) {
                                finalValue = '30';
                            }    
                            field.onBlur();
                            field.onChange(finalValue);
                        }}
                        // type="text"
                        inputMode="decimal"
                        size="small"
                        fullWidth
                        inputProps={{
                            style: { textAlign: 'center', height: '1rem' ,fontSize:'0.75rem'},
                            min: 0,
                            max:30,
                            step: 1 // Allow two decimal places
                        }}
                        error={!!error}
                        // helperText={error && error?.message}
                    /> 
                    )}  
                />          
            </Box>    
        </Box>                  
    </Box>
  );
}

export default ServiceCharges;

import React, { createContext,useContext, useState } from 'react';
import LogoSection from './LogoSection';
import { Box,Button, Paper, TextField ,Link, Typography, Container, Grid, CssBaseline,} from '@mui/material'
import ResetPassword from './ResetPassword';
const ForgotPassword = () => {
  return (
    <Container component="main" maxWidth="lg">
        <Box >
            <Grid container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{ minHeight: '90vh' }}>
                <CssBaseline />

                <Grid
                    item
                    // xs={12}
                    // sm={8}
                    // md={5}
                    component={Paper}
                    elevation={6}
                    square
                    sx={{
                        width: { xs: "100%", sm: "75%", md: "50%" },  // Responsive widths
                        maxWidth: "600px",  // You can add a maximum width to control it on larger screens
                        // padding: 2,  // Add padding to improve spacing
                        borderRadius:'1rem'
                    }}
                >
                    <LogoSection/>
                    <Paper
                        sx={{
                            my: '3%',
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            p:'3%',
                            borderRadius:'.75rem'
                        }}
                    >
                        <Box sx={{width:'100%'}}>         
                            <ResetPassword/>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
        
    </Container>
  )
}

export default ForgotPassword
import { Avatar, Box, Button, Grid, IconButton, Paper, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { Delete, PlusOne } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import MultipleImageUploadDialog, { MultipleImageUpload } from "../../../components/ImgUpload/MultipleImageUpload";
import { ProviderServices } from '../CrudServices';
import { UserProfileContext } from '../UserProfile';
import { Helpers } from '../../../services/Helpers';
import Swal from 'sweetalert2';
import StoreFrontGallery from './StoreFrontGallery';

const StorefrontWorkImages = () => {
  const {storeFrontData,setStoreFrontData} = useContext(UserProfileContext);
  const [uploadedImages,setUploadedImages] = useState([]);
    const [imgModalOpen,setImgModalOpen] = useState(false);
    const imgData = {
        path: "storefront-gallery",
        // image_name: `img-${GenerateUniqueVal(1000000)}-${personalDetails.id}.jpeg`,
        img_type: "single"
    };
    const closeModal = () => {
        setImgModalOpen(false);
    };
    const imgUploadResponse = (data) => {
        // if (imageType == "profile") {
        //   updateUserProfileImage(data, "profile");
        //   setValue('image',data.imageName,{shouldDirty: true, })
        // }
        // if (imageType == "banner") {
        //   updateUserProfileImage(data, "banner");
        // }
    }
    const handleImageUpload = async(uploadedImageData) => {
      // console.log("multiple image upload response:",uploadedImageData)
      const images = uploadedImageData.map(image=>{
        return image.imageName
      })
      // console.log("images uploaded filename:",images)
      setUploadedImages(images);
        const {providerId,name,image,gallery,description,categories}= storeFrontData;
          const requiredData = {providerId,gallery:[...storeFrontData.gallery,...images]};
          // console.log("data to be submitted for gallery update:",requiredData)
          // console.log("storefront id:",storeFrontData.id);
          try {
            const storefrontGalleryResponse = await ProviderServices.updateStorefrontData(requiredData,storeFrontData.id);
            // console.log(" storefront gallery updation response:",storefrontGalleryResponse);
            if(storefrontGalleryResponse.success=='1'){
                const {id,name,image,gallery,description,StorefrontCategories} =storefrontGalleryResponse.data;
                const categories =  StorefrontCategories?.map(item => String(item.categoryId));
                setStoreFrontData({
                    id,name,image,
                    gallery,
                    description,
                    categories,
                    providerId:storeFrontData.providerId
                })
            }else{
              Swal.fire('failed to upload storefront photos','','error')
            }
          } catch (error) {
            Swal.fire('failed to upload storefront photos','','error')
          }
          

    };
  return (
    <>
        <MultipleImageUploadDialog
            open={imgModalOpen}
            onClose={closeModal}
            imgData={imgData}
            imgUploadResponse={(data) => handleImageUpload(data)}
        />
        <Grid container
            sx={{
                // width: 'max-content',
                position: 'relative',
                // marginTop: -8,
                mx: 'auto',
                my:'1%',
                // px:'2%'
            }}
        >
          <Grid item xs={12} sx={{display:`${storeFrontData.id ?'flex':'none'}`,gap:'2%',flexWrap:'wrap',p:'2%'}}>
            {/* <StoreFrontGallery items={storeFrontData?.gallery} /> */}
            {storeFrontData?.gallery && storeFrontData?.gallery.length>0 && storeFrontData?.gallery.map((item) => (
              <Box 
                key={item}
                sx={{
                  position:'relative',
                  display:'flex',
                  gap:'1%',
                  width:'23.5%',
                  mb:'2%',
                  // border:'1px solid',
                  // borderStyle:'double',
                  // justifyContent:'center',
                  // alignItems:'center',
                  // p:'6% 4%',
                  // borderRadius:'2rem'
                }}
              >
                <img               
                    src={`${Helpers.ImagePathUrl()}/storefront-gallery/sm/${item}`}
                    // src={`https://cudel.s3.amazonaws.com/staging/profile/md/image-1729158785151.jpg`}
                    // alt="storefront_image"
                    style={{ width: 100, height: 100, border: '1px solid lightGray',borderRadius:'1rem' }}
                />
                {/* <IconButton
                    sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: 'white',
                    '&:hover': { backgroundColor: 'white' },
                    }}
                    onClick={() => setImgModalOpen(true)}
                >
                <Delete />
                </IconButton> */}
              </Box>

            ))}  
            <Box 
              sx={{width:'23.5%',height:100,cursor:'pointer',display:'flex',flexDirection:'column',alignItems:'center',gap:'3%',border:'2px solid',borderStyle:'double',justifyContent:'center',p:'6% 4%',borderRadius:'2rem'}}
              onClick={()=>setImgModalOpen(true)}
            >
              <AddIcon/>
              <Typography sx={{textAlign:'center'}}>Add Photos</Typography>
            </Box>
          </Grid>
        </Grid>
    
    </>
  );
}

export default StorefrontWorkImages;

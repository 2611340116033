// export function GenerateUniqueVal(numbers=100){
//     return Math.floor(Math.random() * numbers)
// }
const formatValue = (value) => {
    if (typeof value !== 'string') {
      return value === 0 ? '0' : value?.toString() || '';
    }
    // Remove leading zeros, but keep one zero before decimal point if needed
    const formatted = value.replace(/^0+(?=\d)/, '').replace(/^\./, '0.');
    return formatted === '' ? '0' : formatted;
};
const formatIntegerNumber = (value,maxLength,maxValue)=>{
  let modifiedValue = value
      // Remove any non-digit characters
      .replace(/[^0-9]/g, '')
      // Limit to 10 digits
      .slice(0, maxLength);
  
  // If empty after cleaning, set to empty string
  if (!modifiedValue) {
      modifiedValue = '';
  }

  if (modifiedValue === '0') {
      // field.onChange(0);
      return 0;
  }
  // Remove leading zeros
  modifiedValue = modifiedValue.replace(/^0+/, '');

  // Check if the value exceeds 10000 and constrain it
  const numValue = parseInt(modifiedValue);
  if (!isNaN(numValue) && numValue > maxValue) {
      modifiedValue = maxValue;
  }
  return modifiedValue;
}

const formatDecimalNumber = (value,maxLength,maxValue)=>{
  // Allow digits, single decimal point, and handle negative sign
  let modifiedValue = value
    .replace(/[^0-9.]/g, '')  // Allow digits and decimal point
    .replace(/(\..*)\./g, '$1'); // Allow only one decimal point

  // Handle special cases
  if (!modifiedValue) {
    return '';
  }

   // Split number into integer and decimal parts
  const parts = modifiedValue.split('.');
  // Handle integer part (before decimal)
  if (parts[0]) {
    // Remove leading zeros if it's not just a single zero
    parts[0] = parts[0].replace(/^0+/, '') || '0';
  }

  // Handle decimal part (after decimal)
  if (parts[1]) {
      // Limit to 2 decimal places
      parts[1] = parts[1].slice(0, maxLength);
      modifiedValue = `${parts[0]}.${parts[1]}`;
  } else {
      modifiedValue = parts[0];
  }

  // Convert to number and check range
  const numValue = parseFloat(modifiedValue);
  if (!isNaN(numValue)) {
    if (numValue > maxValue) {
        modifiedValue = maxValue;
    }
  }
  return modifiedValue;
}
export default formatValue;
export  {formatDecimalNumber,formatIntegerNumber};
import React, { useEffect, useState } from 'react';
import {  Box,  Chip,  Tooltip,  Menu,  MenuItem,  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, IconButton, Stack, FormControl, InputLabel, Select} from '@mui/material';
import {  CheckCircle,  Warning,  Block,  Pending, KeyboardArrowDown,  Edit, Cancel} from '@mui/icons-material';
import { PermissionGuard } from '../../../components/PermissionGuard';
import Swal from 'sweetalert2';
import { Controller,useForm } from 'react-hook-form';
import { ProviderServices } from '../CrudServices';
import { useParams } from 'react-router-dom';

const AccountStatusIndicator = ({ status, fetchUserDetails }) => {
  const {id} = useParams();
  
  const statusConfigs = [
    {
        label: 'Active',
        value: true,
        color: 'success',
        colorCode:'#2e7d32',
        icon: <CheckCircle sx={{ fontSize: 16 }} />,
        tooltip: 'Account has been Activated Already'
      },
    {
      label: 'Disabled',
      value: false,
      color: 'warning',
      colorCode:'#ed6c02',
      icon: <Pending sx={{ fontSize: 16 }} />,
      tooltip: 'Account has been Deactivated'
    },
  ];

 
  const handleStatusChange = async(newStatus) => {
    
    const result = await Swal.fire({
        text: `Are you sure you want to ${newStatus?'Activate':'De-Activate'} expert account now?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, change now!',
        cancelButtonText: 'No, Cancel',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCloseButton: false,
    });
    if (result.isConfirmed) {
        try {
            Swal.fire({
              title: 'Processing...',
              text: 'Please wait while we updaing status',
              allowOutsideClick: false,
              allowEscapeKey: false,
              showConfirmButton: false,
              didOpen: () => {
                Swal.showLoading();
              }
            });
            // Call your API to update the status
            const response = await ProviderServices.modifyAccountStatus(id);
            console.log('Response:', response);
            if (response.success == '1') {
              // setAccountStatus(newStatus);
              fetchUserDetails();
              Swal.fire({
                title: 'Status Updated',
                text: 'The account status has been successfully updated',
                icon: 'success',
                timer: 1500
              });
              
              return true;
            } else {
              await Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: response.message || 'Failed to update account status'
              });
              return false;
            }
        } catch (error) {
            await Swal.fire({
              title: 'Update Failed',
              text: error.message || 'Failed to update account status',
              icon: 'error'
            });
        }
    } else {
        return false;
    }
    
  };

  
  return (
    <Stack
        direction="row" 
        spacing={1} 
        alignItems="center" 
        justifyContent="center"
        sx={{ mt:1,ml:'5%'}}
    >
        <PermissionGuard feature="service-expert" permission="canView" fallback>
            <Tooltip title={statusConfigs[status]?.tooltip}>
            <Chip
                icon={status ? statusConfigs[0]?.icon : statusConfigs[1]?.icon}
                label={status ? statusConfigs[0]?.label : statusConfigs[1]?.label}
                color={status ? statusConfigs[0]?.color : statusConfigs[1]?.color}
                size="small"
                variant="outlined"
                sx={{ml:1,fontSize:'0.5rem',height:'fit-content',py:'1%',
                  '& .MuiChip-icon':{
                    fontSize:'.6rem'
                  }
                }}
            />
            </Tooltip>
        </PermissionGuard> 
        <IconButton size='small' sx={{p:0,ml:'1% !important',fontSize:'0.65rem'}} color={'info'}><Edit onClick={()=>handleStatusChange(!status)} sx={{fontSize:'.85rem'}}/></IconButton>
    </Stack>
  );
};

export default AccountStatusIndicator;
import React, { useState, useEffect } from 'react';
import { useForm, Controller, useFormContext } from 'react-hook-form';
import { 
  OutlinedInput, 
  InputLabel, 
  MenuItem, 
  FormControl, 
  ListItemText, 
  Select, 
  Checkbox 
} from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const BookingStatusSelection=(props)=> {
  const {statusOptions} = props;
  const [checkedAll, setCheckedAll] = useState(true);
  const [isIndeterminate, setIsIndeterminate] = useState(false);

  const { control, watch, setValue } = useFormContext();

  // Watch the current status
  const selectedstatus = watch('status');

  // Effect to manage "check all" and indeterminate states
  useEffect(() => {
    if (!selectedstatus || selectedstatus.length === 0) {
      // If no status are selected, default to 'requested'
      setValue('status', ['requested']);
      return;
    }

    // Check if all status are selected
    const allSelected = selectedstatus.length === statusOptions.length;
    setCheckedAll(allSelected);

    // Check for indeterminate state
    const someSelected = selectedstatus.length > 0 && selectedstatus.length < statusOptions.length;
    setIsIndeterminate(someSelected);
  }, [selectedstatus, setValue]);

  // Custom change handler
  const handleStatusChange = (event) => {
    const { value } = event.target;
    let newstatus;

    if (value.includes('all')) {
      // If "All" is selected/deselected, toggle all status
      newstatus = value.includes('all') ? statusOptions : ['requested'];
    } else {
      newstatus = value;
    }

    // Ensure at least 'requested' is selected if no other status are chosen
    if (newstatus.length === 0) {
      newstatus = ['requested'];
    }

    setValue('status', newstatus);
  };

  return (
    <FormControl sx={{ width: "70%" }} size="small">
      <InputLabel 
        id="multiple-status-checkbox-label" 
        sx={{fontSize:'.75em', lineHeight:'2em'}}
      >
        Select Status
      </InputLabel>
      <Controller
        name="status"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            sx={{
                minHeight: '0px',
                height: '1.6rem',
                fontSize: '0.75rem',
                '& .MuiSelect-select': {
                  padding: '0 8px',
                  display: 'flex',
                  alignItems: 'center',
                },
              }}
            labelId="multiple-status-checkbox-label"
            id="multiple-status-checkbox"
            label='Select Status'
            multiple
            // input={<OutlinedInput label="status" />}
            renderValue={(selected) => watch('status')&&watch('status').length === statusOptions.length? 'All':selected.join(', ')}
            MenuProps={MenuProps}
            onChange={handleStatusChange}
          >
            {/* All checkbox */}
            <MenuItem value="all" sx={{fontSize:'0.75rem'}}>
              <Checkbox
                size='small'
                sx={{
                    // '& .MuiSvgIcon-root': { fontSize: '1rem' },
                    padding: '0 8px',
                }}
                checked={checkedAll}
                indeterminate={isIndeterminate}
                onChange={() => {
                  const newValue = checkedAll ? ['requested'] : statusOptions;
                  setValue('status', newValue);
                }}
              />
              <ListItemText primary="Select All" sx={{
                // fontSize: '0.75rem',
                // lineHeight: '1.6rem',
                '& .MuiListItemText-primary': {
                  fontSize: '0.75rem',
                  lineHeight: '1.6rem',
                }
              }}/>
            </MenuItem>

            {/* Status options */}
            {statusOptions.map((status) => (
              <MenuItem key={status} value={status}>
                <Checkbox 
                    size='small'
                    sx={{
                        padding: '0 8px',
                    }}
                  checked={selectedstatus.includes(status)} 
                  // Disable unchecking of 'requested' if it's the only selected status
                  disabled={status === 'requested' && selectedstatus.length === 1}
                />
                <ListItemText primary={status} 
                    sx={{
                        // fontSize: '0.75rem',
                        // lineHeight: '1.6rem',
                        '& .MuiListItemText-primary': {
                            fontSize: '0.75rem',
                            lineHeight: '1.6rem',
                        }
                    }}
                    
                />
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
}

export default BookingStatusSelection;
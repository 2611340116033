import { Close } from '@mui/icons-material';
import { Box, Card, Chip, Dialog, DialogContent, Divider,Grid, IconButton, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DefaultMale from '../../../assets/images/default_customer_male.jpg'
import DefaultFemale from '../../../assets/images/default_customer_female.jpg'
import { Helpers } from '../../../services/Helpers';
import { CustomerService } from '../Service';
import { formatDateTime } from '../../../utils/dateUtils';
import dayjs from 'dayjs';
import { DataGrid } from '@mui/x-data-grid';

const BookingHistory = ({bookingList}) => {
  const bookingHistory = bookingList.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  const columns = [
    // { 
    //   field: 'id', 
    //   headerName: '#S.No.', 
    //   // headerAlign:'center',
    //   flex: .75,
    //   filterable:false,
    //   renderCell: (params) => (
    //     <Typography variant="body2" sx={{fontSize:'.65rem'}} fontWeight="600" color={'#3e3e3e'}>
    //       {params.value}
    //     </Typography>
    //   ),
    // },
    { 
      field: 'bookingNumber', 
      headerName: 'Booking ID', 
      // headerAlign:'center',
      flex: 1.5,
      renderCell: (params) => (
        <Typography variant="body2" color="primary" sx={{fontSize:'.65rem'}}>
          {params.value}
        </Typography>
      ),
    },
      {
        field: 'serviceDate',
        headerName: 'Service Date',
        // headerAlign:'center',
        flex: 1,
        renderCell: (params) => (
          <Typography variant="body2" sx={{fontSize:'.65rem'}} >
            {formatDateTime(params.row?.serviceDate, 'date')}
          </Typography>
        ),
      },
    // {
    //   field: 'timeSlot',
    //   headerName: 'Time Slot',
    //   // headerAlign:'center',
    //   flex: 1.8,
    //   filterable:false,
    //   renderCell: (params) => (
    //     <Typography variant="body2" sx={{fontSize:'.65rem'}}>
    //       {`${dayjs(params.row.bookingStartTime, 'HH:mm:ss').format('h:mm A')} - ${dayjs(params.row.bookingEndTime, 'HH:mm:ss').format('h:mm A')}`}
    //     </Typography>
    //   ),
    // },
    {
      field: 'providerName',
      headerName: 'Expert',
      // headerAlign:'center',
      width: 150,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center',justifyContent:'left' }}>
          <Typography variant="body2" sx={{textTransform:'capitalize',fontSize:'.75rem'}}>{params.value}</Typography>
        </Box>
      ),
    },
    // {
    //   field: 'bookingItems',
    //   headerName: 'Requested Services',
    //   flex: 3,
    //   filterable: false,
    //   renderCell: (params) => {
    //     // Get all services from the first booking item
    //     // const services = params.value[0]?.serviceName.split('+').map(s => s.trim());
    //      const services = params.value;
    //     if (!services?.length) return null;
    
    //     const primaryService = services[0];
    //     const additionalServices = services.slice(1);
        
    //     return (
    //       <Box sx={{ 
    //         display: 'flex', 
    //         alignItems: 'center', 
    //         gap: 1,
    //       }}>
    //         <Typography sx={{fontSize:'.65rem'}}>
    //           {primaryService?.serviceName}
    //         </Typography>
    //         {services.length > 1 && (
    //           <Tooltip
    //             componentsProps={{
    //               tooltip: {
    //                 sx: {
    //                   padding: '0',
    //                   bgcolor: 'transparent'  // if you also want to remove background
    //                 }
    //               }
    //             }}
    //             title={
    //               <Paper sx={{ p: 1 }}>
    //                 {services.map((service, index) => (
    //                   index!=0 && <Box key={index}>
    //                   <Typography variant="caption" sx={{whiteSpace: 'wrap' }}>
    //                     {service?.serviceName}
    //                   </Typography>
    //                   <br/>
    //                   </Box>
    //                 ))}
    //               </Paper>
    //             }
    //             placement="bottom-start"  // You can change this to any valid placement
    //             PopperProps={{
    //               modifiers: [
    //                 {
    //                   name: 'offset',
    //                   options: {
    //                     offset: [0, -10], // Horizontal and vertical offset
    //                   },
    //                 },
    //               ],
    //             }}
    //             enterDelay={200}
    //             leaveDelay={200}
    //           >
    //             <Chip 
    //               label={`+${services.length - 1}`}
    //               size="small"
    //               sx={{
    //                 height: 20,
    //                 backgroundColor: 'primary.ight',
    //                 color: 'error',
    //                 cursor:'pointer',
    //                 fontSize: '0.65rem',
    //                 '&:hover': {
    //                   backgroundColor: 'primary.dark',
    //                   color:'#fff'
    //                 }
    //               }}
    //             />
    //           </Tooltip>
    //         )}
    //       </Box>
    //     );
    //   }
    // },
    {
      field: 'bookingStatus',
      headerName: 'Status',
      // headerAlign:'center',
      flex: 1.2,
      renderCell: (params) => (
        <Chip
          label={params.value}
          size="small"
          sx={{
            fontWeight:'500',
            fontSize:'x-small',
            color:params.value === 'requested' ? '#E8832A' : params.value === "rejected"?'#F80000':params.value === 'completed'?'#009EB4':params.value === 'accepted'?'#40B962': params.value === 'cancelled'?'#D81A23':params.value === 'ongoing'?'#1A44B8':'default',
            bgcolor:params.value === 'requested' ? '#FFEDD5' : params.value === "rejected"?'#f800001f':params.value === 'completed'?'#009eb41a':params.value === 'accepted'?'#40b96236':params.value === 'cancelled'?'#d81a231f':params.value === 'ongoing'?'#1a44b838': 'default'
          }}
        />
      ),
    },    
    {
      field: 'createdAt',headerName: 'Booking Date',headerAlign: 'center',flex:1,filterable:false,
      renderCell: (params) => (
        <Typography variant="body2" sx={{fontSize:'0.6rem',color:'#000000de'}}>{formatDateTime(params.row.createdAt)}</Typography>
      ),
    },
  ]; 
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Card>
          <Box sx={{p:'.5% 1%',border:'2px solid lightGray',bgcolor:'lightGray'}}>
              <Typography sx={{fontWeight:'600',fontSize:'0.85rem'}}>Booking History</Typography>
          </Box>  
          {/* <Box sx={{p:'1%'}}>
            {bookingList.length>0&&bookingList.map((booking,index)=>(
              <Box sx={{display:'flex',gap:1,mb:'1%'}}>
                <Box sx={{width:'25%',height:'3vw',bgcolor:'lightGray'}}>

                </Box>
                <Box sx={{width:'75%',pr:'2%'}}>
                  <Box sx={{display:'flex',justifyContent:'space-between'}}>
                    <Typography sx={{fontWeight:600,fontSize:'0.75rem'}} color={'primary'}><Box component={'span'} sx={{color:'#3e3e3e'}}>Booking No : </Box>{booking.bookingNumber}</Typography>
                    <Typography sx={{fontSize:'0.65rem'}}><Box component={'span'} sx={{color:'#3e3e3e',fontWeight:600,fontSize:'0.75rem'}}>Service Date : </Box>{formatDateTime(booking.serviceDate,'date')}</Typography>
                  </Box>
                </Box>
              </Box>
              

            ))}
            { bookingList.length=='0'&&
              <Paper sx={{p:'2%',textAlign:'center'}}>
                No Booking History
              </Paper>
            }
          </Box> */}
          <DataGrid
              rows={bookingHistory}
              columns={columns}
              getRowId={(row) => row.id}
              // rowCount={bookingHistory}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 15,25]}
              disableColumnMenu
              disableRowSelectionOnClick
              disableDensitySelector
              autoHeight
              getRowHeight={() => 'auto'}
              sx={{   
                '& .MuiDataGrid-row': {
                  '& .MuiDataGrid-cell': {
                    py:1
                  }
                },           
                '& .MuiDataGrid-columnHeaders': {
                  borderBottom: '2px solid rgba(224, 224, 224, 1)',
                  borderTop: '2px solid rgba(224, 224, 224, 1)',
                  fontWeight: 600,
                  fontSize: '0.75rem',
                  p:0,
                  height: 'fit-content',
                  minHeight:'0 !important',
                  height:'2rem'
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 600,
                  color: '#3e3e3e'
                },
                
              }}
            />
        </Card>
      </Grid>
    </Grid>
  );
}

export default BookingHistory;

import React, { useContext, useEffect, useState } from 'react';
import { Box, IconButton, Avatar, Button, Typography } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ImgUploadDialog from '../../../components/ImgUpload/imgUpload';
import { Helpers } from '../../../services/Helpers';
import aadharFrontDefault from '../../../assets/images/aadhar-front-default.png';
import aadharBackDefault from '../../../assets/images/aadhar-back-default.png';
import {Controller, useFormContext} from 'react-hook-form';
import { UserProfileContext } from '../UserProfile';
import Swal from 'sweetalert2';

const AadhaarImageUpload = () => {
    const {businessDetailsData,setBusinessDetailsData} = useContext(UserProfileContext);
    const {control,setValue,watch} =  useFormContext();

    const aadharNumber = watch('aadharNumber');
    const aadharFrontImage = watch('aadharFrontImage');
    const aadharRearImage = watch('aadharRearImage');
    
    const [imgModalOpen, setImgModalOpen] = useState(false);
    const [imageType, setImageType] = useState(null);
 
    const openImageUploadModal = (imgType) => {
        setImageType(imgType);
        setImgModalOpen(true);
    };

    const closeModal = () => {
        setImgModalOpen(false);
    };
    
    const imgData = {
        path: "kyc",
        // image_name: `img-${GenerateUniqueVal(1000000)}-${personalDetails.id}.jpeg`,
        img_type: "single"
    };
    

    const handleImageUpload = (uploadedImageData) => {
        console.log(uploadedImageData)
        // Set the uploaded PAN image URL to the form
        setValue(imageType, uploadedImageData.imageName, { 
            shouldValidate: true,
            shouldDirty: true 
        });
        closeModal();
    };

    const viewImage = (image) => {
        // using swal to view image...not close on outside click
        Swal.fire({
            html: `<div style="text-align:left;font-size: 0.9rem; font-weight: 500; color: #333;">${image}</div><img src="${Helpers.ImagePathUrl()}/kyc/lg/${image}" alt="PAN Image" style="width:100%;height:auto;margin-top:1rem"/>`,
            showCloseButton: true,
            showConfirmButton: false,
            showCancelButton: false,
            focusConfirm: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });
    }
    useEffect(()=>{
        // setProfileImage('')
    },[])
  return (
    <>
      <ImgUploadDialog
        open={imgModalOpen}
        onClose={closeModal}
        imgData={imgData}
        imgUploadResponse={(data) => handleImageUpload(data)}
      />
        <Box sx={{display:'flex',flexDirection:'column' ,pt:'4%',gap:'.25rem'}}>
            <Box sx={{flex:1,textAlign:'center'}}>
                <Controller
                    control={control}
                    name='aadharFrontImage'
                    rules={{
                        // validate: (value) => {
                        //     // Only require image if PAN number is present
                        //     if (aadharNumber && !value) {
                        //         return "Aadhar Front image is required when Aadhar number is provided";
                        //     }
                        //     return true;
                        // }
                    }}
                    render={({ fieldState:{error} }) => (
                        <Box sx={{display:'flex', gap:'3%'}}>
                            {/* <img
                                src={aadharFrontImage && `${Helpers.ImagePathUrl()}/kyc/sm/${aadharFrontImage}` || businessDetailsData?.aadharFrontImage && `${Helpers.ImagePathUrl()}/kyc/sm/${businessDetailsData?.aadharFrontImage}` || aadharFrontDefault}
                                alt="Cover"
                                style={{ width: '100%', height: 'auto',maxWidth:'12rem',maxHeight:'7rem',borderRadius:'1rem' }}
                            /> */}
                            {(businessDetailsData.aadharNumber || aadharNumber) && !businessDetailsData.aadharFrontImage && <Button
                                variant="contained"
                                size='small'
                                sx={{textTransform:'none'}}
                                onClick={() => openImageUploadModal('aadharFrontImage')}
                            >
                                {/* <CameraAltIcon /> */}
                                {aadharFrontImage? 'Re-Select Aadhaar Front':'Select Aadhaar Front'}
                            </Button>}
                            {(aadharFrontImage || businessDetailsData.aadharFrontImage) && <Typography sx={{cursor:'pointer',fontSize:'.75rem',color:'blue'}} onClick={()=>viewImage(aadharFrontImage || businessDetailsData.aadharFrontImage)}>View Aadhaar Front Image</Typography>}
                            {error && ( 
                                <Typography
                                    color="error" 
                                    sx={{ mt: 1, textAlign: 'center',fontSize:'.5rem' }}
                                >
                                    {error.message}
                                </Typography>
                            )}
                        </Box>
                    )}
                />
            </Box>
            <Box sx={{flex:1,textAlign:'center'}}>
                <Controller
                    control={control}
                    name='aadharRearImage'
                    rules={{
                        // validate: (value) => {
                        //     // Only require image if PAN number is present
                        //     if (aadharNumber && !value) {
                        //         return "Aadhar  Rear image is required when Aadhar number is provided";
                        //     }
                        //     return true;
                        // }
                    }}
                    render={({ fieldState:{error} }) => (
                        <Box sx={{display:'flex', gap:'3%'}}>
                            {/* <img
                                src={aadharRearImage && `${Helpers.ImagePathUrl()}/kyc/sm/${aadharRearImage}` || businessDetailsData?.aadharRearImage && `${Helpers.ImagePathUrl()}/kyc/sm/${businessDetailsData?.aadharRearImage}` || aadharBackDefault}
                                alt="Cover"
                                style={{ width: '100%', height: 'auto',maxWidth:'12rem',maxHeight:'7rem',borderRadius:'1rem'}}
                            /> */}
                            {(businessDetailsData.aadharNumber || aadharNumber)  && !businessDetailsData.aadharRearImage && <Button
                                variant="contained"
                                size='small'
                                sx={{textTransform:'none'}}
                                onClick={() => openImageUploadModal('aadharRearImage')}
                            >
                                {/* <CameraAltIcon /> */}
                                {aadharRearImage? 'Re-Select Aadhaar Back':'Select Aadhaar Back'}
                            </Button>
                            }
                            {(aadharRearImage || businessDetailsData.aadharRearImage) && <Typography sx={{cursor:'pointer',fontSize:'.75rem',color:'blue'}} onClick={()=>viewImage(aadharRearImage || businessDetailsData.aadharRearImage)}>View Aadhaar Back Image</Typography>}
                            {error && (
                                <Typography
                                     
                                    color="error" 
                                    sx={{ mt: 1, textAlign: 'center',fontSize:'.5rem' }}
                                >
                                    {error.message}
                                </Typography>
                            )}
                        </Box>
                    )}
                />                  
                
            </Box>
        </Box>
        
    </>
  );
}

export default AadhaarImageUpload;

import React, { useContext, useState, useEffect } from 'react';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { 
  Box, 
  Typography, 
  Checkbox, 
  FormGroup, 
  FormControlLabel, 
  TextField,
  Button,
  Grid,
  IconButton,
  Card
} from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import { UserProfileContext } from '../UserProfile';
import { ProviderServices } from '../CrudServices';
import Swal from 'sweetalert2';
import Loading from '../../../components/Loader/Loading';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { TimeIcon } from '@mui/x-date-pickers';

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const WorkingHours = ({ fetchedData }) => {
  const {id} = useParams();
  const [availabilityDetailData,setAvailabilityDetailData] = useState(
    {
        userId: id,
        dayTimeSlots: [ ]
    }
)
  const [isLoading, setIsLoading] = useState(false);
 
  const prepopulateData = (fetchedData) => {
    return days.map(day => {
      const dayData = fetchedData.dayTimeSlots.find(slot => slot.day === day);
      return {
        day,
        isSelected: !!dayData?.slots.length>0,
        slots: dayData ? dayData.slots : []
      };
    });
  };

  const { control, handleSubmit, setValue, formState: { errors, isDirty }, watch, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      userId: availabilityDetailData.userId || '',
      dayTimeSlots: prepopulateData(availabilityDetailData)
    }
  });
  
  useEffect(() => {
    if (id> 0) {
      const fetchProviderTimeSlots = async () => {
        setIsLoading(true);
        const timeSlotList = await ProviderServices.fetchProviderTimeSlots(id);
        console.log("time slot list:",timeSlotList);
        setAvailabilityDetailData(timeSlotList.data);
        setIsLoading(false);
      }
      fetchProviderTimeSlots();
    }
  }, []);

  useEffect(() => {
    if (availabilityDetailData) {
      reset({
        userId: availabilityDetailData.userId || '',
        dayTimeSlots: prepopulateData(availabilityDetailData)
      });
    }
  }, [availabilityDetailData, reset]);

  const { fields } = useFieldArray({
    control,
    name: "dayTimeSlots"
  });

  return (
    <>
      {isLoading && <Loading />}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{}}>
            <Box sx={{p:'.5% 2%',border:'2px solid lightGray',bgcolor:'#3e3e3e38'}}>
              <Typography sx={{fontWeight:'600',color:'#3e3e3e',fontSize:'.85rem'}}>Working Hours</Typography>
            </Box>
            <Box sx={{p:'2%',display:'flex',flexWrap:'wrap',gap:'2%'}}>
              
                {fields.map((field, index) => {
                  const isSelected = watch(`dayTimeSlots.${index}.isSelected`);
                  return (
                    <Box key={field.id} sx={{ mb: 2,width:'49%' }}>
                      <Controller
                        name={`dayTimeSlots.${index}.isSelected`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <FormControlLabel
                            control={<Checkbox checked={value} size='small'/>}
                            label={field.day}
                            sx={{ 
                              '& ..MuiFormControlLabel-label': { fontSize: '.75rem' },
                               }}
                          />
                        )}
                      />
                      {isSelected && (
                        <Card sx={{ ml:'10%',px: 2, bgcolor: '#dad7d72e' }}>

                        {field.slots.map((slot, slotIndex) => (
                          <Box key={slotIndex} sx={{ display: 'flex', gap: 2, alignItems: 'center',my:1 }}>
                            <TimeIcon fontSize='.75rem'/>
                            <Typography sx={{fontSize:'.75rem'}}>{dayjs(slot.startTime, 'HH:mm:ss').format('h:mm A')} - {dayjs(slot.endTime, 'HH:mm:ss').format('h:mm A')}</Typography>
                          </Box>
                        ))}
                        </Card>
                        // <Controller
                        //   name={`dayTimeSlots.${index}.slots`}
                        //   control={control}
                        //   render={({ field: { value, onChange } }) => (
                        //     <>
                        //       {value.map((slot, slotIndex) => (
                        //         <Grid container spacing={2} key={slotIndex} sx={{ mb: 1, ml: 4 }}>
                        //           <Grid item xs={6}>
                        //             <TextField
                        //               label="Start Time"
                        //               type="time"
                        //               size="small"
                        //               value={slot.startTime}
                        //             />
                        //           </Grid>
                        //           <Grid item xs={6}>
                        //             <TextField
                        //               label="End Time"
                        //               type="time"
                        //               size="small"
                        //               value={slot.endTime}
                        //             />
                        //           </Grid>
                                  
                        //         </Grid>
                        //       ))}
                        //     </>
                        //   )}
                        // />
                      )}
                    </Box>
                  );
                })}
              
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default WorkingHours;
import React, { useContext, useEffect, useState } from 'react';
import {Avatar,Button,  Typography,MenuItem,  Grid,  Box,Card, TextField, FormControl, Select, Chip} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { ProviderServices } from '../CrudServices';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { Helpers } from '../../../services/Helpers';
import StoreFrontGallery from './StoreFrontGallery';


export default function StoreFrontDetails({ storeFrontDetailData}) {
  const [coverImage, setCoverImage] = useState('https://via.placeholder.com/1200x300');
  const [profileImage, setProfileImage] = useState('https://via.placeholder.com/150');
  const [categoryList,setCategoryList] = useState([]);
  useEffect(()=>{
    const fetchCategoryList= async()=>{
        try {
          const categoryListResponse = await ProviderServices.categoryList();
          // console.log("category list Response:",categoryListResponse);
          if(categoryListResponse.success===1){
            setCategoryList(categoryListResponse.data)
          }
        } catch (error) {
          
        }
      }
      fetchCategoryList();
  },[]);
  return (
      <Grid container >
        <Grid item xs={12}>
          <Box sx={{ position: 'relative' }}>
            <img 
              src={coverImage} 
              alt="Cover" 
              style={{ width: '100%', height: 'auto', objectFit: 'cover' }} 
            />
            {/* <IconButton
              sx={{
                position: 'absolute',
                bottom: 8,
                right: 8,
                backgroundColor: 'white',
                '&:hover': { backgroundColor: 'white' },
              }}
              component="label"
            >
              <input
                type="file"
                hidden
                onChange={handleCoverImageChange}
                accept="image/*"
              />
              <CameraAltIcon />
            </IconButton> */}
            <Box sx={{position: 'absolute',
                bottom: 8,
                right: 8,}}>
              <Box 
                sx={{
                  width: 'fit-content',
                  position: 'relative', 
                  marginTop: -8, 
                  mx: 'auto' 
                }}
              >
                <Avatar
                  src={storeFrontDetailData.image ? `${Helpers.ImagePathUrl()}/storefront-logo/md/${storeFrontDetailData.image}`:profileImage}
                  alt="Profile"
                  sx={{ width: 150, height: 150, border: '4px solid white' }}
                />
                {/* <IconButton
                  sx={{
                    position: 'absolute',
                    bottom: 8,
                    right: 8,
                    backgroundColor: 'white',
                    '&:hover': { backgroundColor: 'white' },
                  }}
                  component="label"
                >
                  <input
                    type="file"
                    hidden
                    onChange={handleProfileImageChange}
                    accept="image/*"
                  />
                  <CameraAltIcon />
                </IconButton> */}
              </Box>
            </Box>
          </Box>
        </Grid>
        {/* =======================Basic Info section================== */}
        <Grid item xs={12} sx={{pt:'0%!important',pl:'0%!important'}}>
          <Card>
            <Box sx={{p:'.5% 2%',border:'2px solid lightGray',bgcolor:'lightGray'}}>
                <Typography sx={{fontWeight:'600'}}>Storefront Info</Typography>
            </Box>
            <Box sx={{p:'1%'}}>
              <Box sx={{display:'flex',gap:'2%'}}>
                <Box sx={{my:'',flex:1.5}}>
                  <Typography sx={{width:'60%' ,fontWeight:'600', texttTransform:'uppercase',fontSize:'.75rem'}}>Storefront Name</Typography>
                  <TextField
                    sx={{}}
                    fullWidth
                    size='small'
                    value={`${storeFrontDetailData ? storeFrontDetailData.name:''}`}
                    disabled
                    inputProps={{
                      style: {fontSize:'.75rem'}
                    }}
                  />                    
                </Box> 
                <Box sx={{flex:2}}>
                  <Typography sx={{fontWeight:'600', texttTransform:'uppercase',fontSize:'.75rem'}}>Service Categories </Typography>
                  <Box sx={{my:'', display: 'flex', flexWrap: 'wrap', gap: 1 ,border:'1px solid lightGray',p:'1%',borderRadius:'.25rem',minHeight:'2.5rem'}}>
                    {storeFrontDetailData?.categories.map(id => {
                      const category = categoryList.find(opt => opt.id == id);
                      if (category) {
                        return (
                          <Chip
                            key={id}
                            label={category.title}
                            color="primary"
                            variant="outlined"
                            size="small"
                          />
                        );
                      }
                      return null;
                    })}
                  </Box> 
                </Box>  
              </Box>
              <Box sx={{display:'flex' , gap:'2%',my:''}}>
                <Box sx={{flex:2,my:''}}>
                  <Typography sx={{width:'60%' ,fontWeight:'600', texttTransform:'uppercase',fontSize:'.75rem'}}>Description</Typography>
                  <TextField
                    value={storeFrontDetailData.description}
                    fullWidth
                    size='small'
                    multiline
                    rows={3} 
                    disabled
                    inputProps={{
                      style: {fontSize:'.75rem'}
                    }}
                  />               
                </Box>
              </Box>
            </Box>
          </Card>
        </Grid>
        {/* =======================Gallery section================== */}
        <StoreFrontGallery items={storeFrontDetailData?.gallery}/>
      </Grid>
  );
}

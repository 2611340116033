import React, { useEffect, useState } from 'react';
import { TextField, Button, Container, Stack, Box, Typography, IconButton, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import Swal from "sweetalert2";
import { Product } from "../Product/ProductService";
import { CategoryService } from "./CategoryService";
import { Helpers } from "../../services/Helpers";
import ImgUploadDialog from "../../components/ImgUpload/imgUpload";
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';


export default function AddCategory({ closeEvent, populateList, rowId, catType }) {
    const { register, handleSubmit, reset, setValue, watch, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const [errorMsg, setErrorMsg] = useState("");
    const [modalTtitle, setModalTtitle] = useState("Add Category");

    const [category, setCategory] = useState([]);
    const [categoryValue, setCategoryValue] = useState("");

    const [subCategory, setSubCategory] = useState([]);
    const [subCategoryValue, setSubCategoryValue] = useState("");

    const [imageUploadResponseDetails,setImageUploadResponseDetails] = useState({});

    const [isSubmitting, setIsSubmitting] = useState(false);

    populateList(0);
    const categoryList = async () => {
        const category = await CategoryService.allCategories();
        setCategory(category.data);
    }
    useEffect(() => {
        // console.log("image path url :",Helpers.ImagePathUrl());
        categoryList();
        if (Object.keys(rowId).length) {
            setModalTtitle("Edit")
            if (catType == 1) {
                setValue("title", rowId.title);
            }
            if (catType == 2) {

                setCategoryValue(rowId.parentId)
                setValue("category", rowId.parentId)
                setValue("title", rowId.title);
            }
            if (catType == 3) {
                const subCategoryList = async () => {
                    const subCategory = await Product.getSubCategoryList(rowId.parent_category);
                    setSubCategory(subCategory.data);
                    setSubCategoryValue(rowId.parent_sub_category)
                }
                subCategoryList();
                setCategoryValue(rowId.parent_category)

                setValue("category", rowId.parent_category)
                setValue("subcategory", rowId.parent_sub_category)
                setValue("title", rowId.title);
            }
        }

    }, [rowId])

    const categoryHandel = (e) => {
        setCategoryValue(e.target.value);
        setSubCategoryValue("")
        setValue("subcategory", "")
        const subCategoryList = async () => {
            const subCategory = await Product.getSubCategoryList(e.target.value);
            setSubCategory(subCategory.data);
        }
        subCategoryList();
    }

    const handleCat = (e) => {
        setCategoryValue(e.target.value)
    }

    const subCategoryHandel = (e) => {
        setSubCategoryValue(e.target.value)
    }

    const catForm = (catType == 1) ? (
        <>
        <Grid item xs={12} >
            <TextField
                id="title"
                label="Category Name"
                variant="outlined"
                name="title"
                size="small"
                sx={{ minWidth: "100%" }}
                {...register('title', { required: true })}
                error={errors.title ? true : false}
            />
            <Typography variant="inherit" color="red">
                {errors.title && 'Category Name is required'}
            </Typography>
            
        </Grid>
        <Grid item xs={12}>
            <Grid container spacing={2} alignItems={'center'}>
                <Grid item xs={rowId.image ||imageUploadResponseDetails.imageName? 6 : 12} textAlign={'center'}>
                    <Button 
                        disabled={isSubmitting}
                        fullWidth
                        onClick={(e) => openImgModal()}
                        sx={{ marginRight: 2 }}
                        startIcon={<AddToPhotosIcon />}
                        variant="outlined"
                        color="primary"
                    >
                        Upload Image
                    </Button>                  
                </Grid>
                <Grid item xs={6}>
                    {
                        imageUploadResponseDetails.imageName ? 
                        <img
                        src={`${Helpers.ImagePathUrl()}/category/sm/${imageUploadResponseDetails.imageName}`}
                    />
                    :
                    rowId.image && <img
                        src={`${Helpers.ImagePathUrl()}/category/sm/${rowId.image}`}
                    />
                    }
                </Grid>
            </Grid>
        </Grid>
        </>
    ) : (
        (catType == 2) ? (
            <>
                <Grid item xs={12}>
                    <FormControl sx={{ minWidth: "100%" }} size="small">
                        <InputLabel id="demo-simple-select-autowidth-label">Select Category</InputLabel>
                        <Select
                            labelId="category"
                            id="category"
                            name="category"
                            value={categoryValue}
                            onChange={handleCat}
                            label="Select Category"
                            inputProps={{ ...register('category', { required: true }) }}
                            error={errors.category ? true : false}
                        >
                            {category?.map((category) => (
                                <MenuItem value={category.id} key={category.id}>{category.title}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Typography variant="inherit" color="red">
                        {errors.category && 'Category choose first.'}
                    </Typography>
                </Grid>
                <Grid item xs={12} >
                    <TextField
                        id="title"
                        label="Sub Category"
                        variant="outlined"
                        name="title"
                        size="small"
                        sx={{ minWidth: "100%" }}
                        {...register('title', { required: true })}
                        error={errors.title ? true : false}
                    />
                    <Typography variant="inherit" color="red">
                        {errors.title && 'Sub Category is required'}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} alignItems={'center'}>
                        <Grid item xs={rowId.image ||imageUploadResponseDetails.imageName? 6 : 12} textAlign={'center'}>
                            <Button 
                                disabled={isSubmitting}
                                fullWidth
                                onClick={(e) => openImgModal()}
                                sx={{ marginRight: 2 }}
                                startIcon={<AddToPhotosIcon />}
                                variant="outlined"
                                color="primary"
                            >
                                Upload Image
                            </Button>                  
                        </Grid>
                        <Grid item xs={6}>
                            {
                                imageUploadResponseDetails.imageName ? 
                                <img
                                    src={`${Helpers.ImagePathUrl()}/category/sm/${imageUploadResponseDetails.imageName}`}
                                />
                                :
                                rowId.image && <img
                                    src={`${Helpers.ImagePathUrl()}/category/sm/${rowId.image}`}
                                />
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </>

        ) : (
            <>
                <Grid item xs={12}>
                    <FormControl sx={{ minWidth: "100%" }} size="small">
                        <InputLabel id="demo-simple-select-autowidth-label">Select Category</InputLabel>
                        <Select
                            labelId="category"
                            id="category"
                            name="category"
                            value={categoryValue}
                            onChange={categoryHandel}
                            label="Select Category"
                            inputProps={{ ...register('category', { required: true }) }}
                            error={errors.category ? true : false}
                        >
                            {category?.map((category) => (
                                <MenuItem value={category.id} key={category.id}>{category.title}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Typography variant="inherit" color="red">
                        {errors.category && 'Category choose first.'}
                    </Typography>
                </Grid>
                <Grid item xs={12} >
                    <FormControl sx={{ minWidth: "100%" }} size="small">
                        <InputLabel id="demo-simple-select-autowidth-label">Select Sub Category</InputLabel>
                        <Select
                            labelId="subcategory"
                            id="subcategory"
                            name="subcategory"
                            value={subCategoryValue}
                            onChange={subCategoryHandel}
                            label="Select Sub Category"
                            inputProps={{ ...register('subcategory', { required: true }) }}
                            error={errors.subcategory ? true : false}                            >
                            {subCategory?.map((subCategory) => (
                                <MenuItem value={subCategory.id} key={subCategory.id}>{subCategory.title}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Typography variant="inherit" color="red">
                        {errors.category && 'Sub Category is required.'}
                    </Typography>
                </Grid>
                <Grid item xs={12} >
                    <TextField
                        id="title"
                        label="Sub Sub Category"
                        variant="outlined"
                        name="title"
                        size="small"
                        sx={{ minWidth: "100%" }}
                        {...register('title', { required: true })}
                        error={errors.title ? true : false}
                    />
                    <Typography variant="inherit" color="red">
                        {errors.title && 'Sub Sub Category is required'}
                    </Typography>
                </Grid>
            </>
        )
    );


    
    function onSubmit(data) {
        setIsSubmitting(true)
        // console.log("raw data:",data);
        const addData = {
            title: data.title,
            parentId: (catType == 1) ? 0 : data.category,
            // parent_sub_category: (catType == 1) ? 0 : ((catType == 2) ? 0 : data.subcategory),
            description:'description:'+data.title
        };
        // console.log("modified data:",addData);
        const createRow = async () => {
            setErrorMsg("");
            let createRowRes = "";
            if (Object.keys(rowId).length) {
                addData.image = imageUploadResponseDetails.imageName ? imageUploadResponseDetails.imageName:rowId.image ;
                // console.log("data to be submitted for edit:",addData)
                createRowRes = await CategoryService.updateCategory(rowId.id, addData);
                // console.log("update response:",createRowRes)
            } else {
                // const slugname = `${data.title}`;
                // addData.slug = Helpers.generateSlug(slugname.trim());
                addData.image = imageUploadResponseDetails.imageName ?imageUploadResponseDetails.imageName:'';
                // console.log("data to be submitted for creating new:",addData)
                createRowRes = await CategoryService.addCategory(addData)
            }

            if (createRowRes.success) {
                Swal.fire(createRowRes.message, '', 'success')
                reset();
                closeEvent();
                populateList(1);
                // setIsSubmitting(false)
            } else {
                setErrorMsg(createRowRes.message);
                // setIsSubmitting(false)
            }
        }
        createRow();
    }
    /*================== image upload realted ====================== */
    
    const [trigStatus, setTrigStatus] = useState(null);
    const [imgModalOpen, setImgModalOpen] = useState(false);

    const openImgModal = () => {
        setImgModalOpen(true);
    };

    const closeModal = () => {
        setImgModalOpen(false);
        // afterAddPop(1);
    };
    const handlePImgTrig = (data) => {
        setTrigStatus(data);
    }
    const imgData = {
        path: 'category',
        // image_name: `img-${GenerateUniqueVal(1000000)}-${personalDetails.id}.jpeg`,
        img_type: "single"
    };
    const imgUploadResponse = (data) => {
        setImageUploadResponseDetails(data);

    }
    return (

        <>
            {/* ============== image upload dialog ============= */}
                <ImgUploadDialog open={imgModalOpen} onClose={closeModal} 
                    imgData={imgData} 
                    imgUploadResponse={imgUploadResponse} 
                    trigStatus={handlePImgTrig} 
                />
            <Typography variant="h5" align="center">
                {modalTtitle}
            </Typography>
            <IconButton
                style={{ position: "absolute", top: 0, right: 0 }}
                onClick={closeEvent}
            >
                <CloseIcon />
            </IconButton>
            <Typography variant="inherit" color="red">
                {errorMsg}
            </Typography>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} mt={1}>
                    {catForm}
                    <Grid item xs={12}>
                        <Typography variant="h5" align="center">
                            <Button disabled={isSubmitting} variant="outlined" color="secondary" type="submit">{Object.keys(rowId).length ?'update':'Save'}</Button>
                        </Typography>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

import React, { useContext, useEffect, useState } from 'react';
import {FormProvider, useForm } from 'react-hook-form';
import {Button,  Typography,  Grid,  Box,Card} from '@mui/material';
import Swal from 'sweetalert2';
import { UserProfileContext } from '../UserProfile';
import { ProviderServices } from '../CrudServices';
import Loading from '../../../components/Loader/Loading';
import RegistrationFeeInfo from './RegistrationFeeInfo';

export default function PaymentDetails() {
  const {otrDetail,paymentDetailsData,setPaymentDetailsData} = useContext(UserProfileContext);
      const [isSubmitted,setIsSubmitted] = useState(false);
      
      const paymentDetailFormMethod = useForm({
          mode:'onChange',
          defaultValues:{
              ...paymentDetailsData,
              taxSlab: paymentDetailsData?.igst=='0'? 'gst':'igst',
          },
      })
  
      const {control,watch,setValue,handleSubmit, formState: { errors,isDirty }, setError, clearErrors,reset} = paymentDetailFormMethod;
      
      
      const onSubmitFormData = async (data) => {
          console.log("Payment Detail Data:",data);
          const {taxSlab,...restData} = data;
          // if (data?.upiId?.filter(id => id.trim() !== '').length === 0) {
          //     setError('upiId', {
          //         type: 'manual',
          //         message: 'At least one valid UPI ID is required'
          //     });
          //     return;
          // }
          // console.log("business detail data:", data);
  
          try{
              setIsSubmitted(true);
              const response = await ProviderServices.addUpdateproviderPaymentDetail({...restData,status:'COMPLETED'});
            //   const businessDetailUpdateResponse = await ProviderServices.updateUserBusinessDetail({providerId:otrDetail.id,...data});
              console.log("Payment Detail AddUpdate Response:",response);
              if(response?.success=='1'){
                //   const businessData = businessDetailUpdateResponse.data;
                  setPaymentDetailsData(response.data);
                  // Swal.fire('Business Detail Updated Successfully','','success');
                  Swal.fire({
                      icon: 'success',
                      title: 'Success',
                      text: response.message,
                      confirmButtonText: 'OK'
                  });
                  setIsSubmitted(false)
              }
              else 
              // if(businessDetailUpdateResponse?.success=='0')
                  {
                  Swal.fire(response.message,'','error');
                //   Swal.fire({
                //       icon: 'error',
                //       // title: 'Unauthorized',
                //       text: businessDetailUpdateResponse.message,
                //       confirmButtonText: 'OK'
                //   });
                  setIsSubmitted(false)
              }
          }catch(error){
              Swal.fire({
                  icon: 'error',
                  // title: 'Unauthorized',
                  text: error.message,
                  confirmButtonText: 'OK'
              });
          }
          finally{
              setIsSubmitted(false);
          }
      };
      
      useEffect(()=>{
          reset(paymentDetailsData);
      },[paymentDetailsData,reset])
  
      // Custom validation function to check if at least one field is filled
    //   useEffect(() => {
    //       if (isDirty) {
    //           if (panNumber || aadharNumber) {
    //               clearErrors('atLeastOne');
    //           } else {
    //               setError('atLeastOne', {
    //                   type: 'manual',
    //                   message: 'At least one of PAN or Aadhaar is required'
    //               });
    //           }
    //       }
    //     }, [panNumber, aadharNumber, clearErrors, setError]);
  return (
    <FormProvider {...paymentDetailFormMethod}>
      {isSubmitted && <Loading/>}
      <Grid container spacing={1} component={'form'} >
        {/* =======================Registration Fee Info section================== */}
        <RegistrationFeeInfo />
        <Box sx={{textAlign:'center',width:'100%',mt:1}}>
          <Button disabled={!isDirty || isSubmitted} variant='contained' onClick={handleSubmit(onSubmitFormData)} size='small'>Save</Button>
        </Box>
      </Grid>
    </FormProvider>
  );
}

import React, { useContext, useEffect, useState } from 'react';
import { useForm, Controller, get } from 'react-hook-form';
import {TextField,  FormControl,  Select,  MenuItem,  Button,   Card,  Typography,  Grid,  Box,  InputLabel,  FormGroup,  FormControlLabel,  Checkbox,  FormHelperText,  ListItemText,  OutlinedInput,  Autocomplete,  Paper,  Chip,  Divider,  RadioGroup,  Radio,  TableContainer,  Table,  TableHead,  TableRow,  TableCell,  TableBody,  IconButton, CircularProgress,} from '@mui/material';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { DesktopDatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import BookingResult from '../BookingResult';
import { BookingService } from '../Service';
import { CustomerService } from '../../Customer/Service';
import ServiceArea from '../ServiceAreaData.json'
import { BookingDetailContext } from '../AddBooking';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DeleteIcon from '@mui/icons-material/Delete';
import Loading from '../../../components/Loader/Loading';
import { StepperContext } from '../BookingForm';
import Time24to12 from '../../../utils/Time24to12';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const chargeList = {
  couponId: '0',
  couponAmount: '0.00',
  discount: '0.00',
  platformCharges: '0.00',
}
export default function BookingReview() {
  const {bookingData,setBookingData,chargeDetails} = useContext(BookingDetailContext);
  const {activeStep, setActiveStep,completed, setCompleted,handleNext,handleBack,handleComplete,handleReset,bookingResponseDetails,setBookingResponseDetails} = useContext(StepperContext);
  
  const { control, handleSubmit, setValue, formState: { errors ,isDirty},watch,reset } = useForm({
    mode: "onChange",
    defaultValues:bookingData
  });
  const navigate = useNavigate();  

  const hours = Array.from({ length: 12 }, (_, i) => i.toString().padStart(2, '0'));
  const minutesSeconds = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0'));

  const validatebookingStartTime = (value) => {
    const serviceDate = watch('serviceDate');
    if (!serviceDate) return "Please select a booking date first";

    const selectedDate = dayjs(serviceDate, "DD-MM-YYYY");
    const selectedTime = dayjs(value, 'HH:mm');
    const now = dayjs();
    
    const startOfAllowedTime = dayjs().hour(9).minute(0);
    const endOfAllowedTime = dayjs().hour(21).minute(0);

    // Check if booking is for today
    if (selectedDate.isSame(now, 'day')) {
      const oneHourLater = now.add(1, 'hour');
      if (selectedTime.isBefore(oneHourLater)) {
        return 'Time must be at least one hour from now for today.';
      }
      if (selectedTime.isBefore(startOfAllowedTime) || selectedTime.isAfter(endOfAllowedTime)) {
        return 'Time must be between 9 AM and 9 PM.';
      }
    } else {
      // For future dates
      if (selectedTime.isBefore(startOfAllowedTime) || selectedTime.isAfter(endOfAllowedTime)) {
        return 'Time must be between 9 AM and 9 PM for future days.';
      }
    }

    return true;
  };
  const [isLoading,setIsLoading] = useState(false);
  const [customerList,setCustomerList] = useState([]);
  const [categoryList,setCategoryList] = useState([]);
  const [loadingSubCategoryList,setLoadingSubCategoryList] = useState(false);
  const [subCategoryList,setSubCategoryList] = useState([]);
  const [servicablePincodeList,setServicablePincodeList] = useState([]);
  const [loadingServiceList,setLoadingServiceList] = useState(false);
  const [serviceList,setServiceList] = useState([]);
  const [loadingServiceExpertList,setLoadingServiceExpertList] = useState(false);
  const [serviceExpertList,setServiceExpertList]=useState([]);
  const [loadingTimeSlotList,setLoadingTimeSlotList] = useState(false);
  const [timeSlotList,setTimeSlotList] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  
  const customer= watch('customer');
  const categoryId= watch('categoryId');
  const subCategoryId=watch('subCategoryId');
  const serviceArea = watch('serviceArea');
  const serviceExpert = watch('serviceExpert');
  const services = watch('services');
  const selectedDate= watch('serviceDate');

  useEffect(()=>{
    const fetchCusomerlist = async()=>{
      setIsLoading(true)
      try {
        const customerListResponse = await CustomerService.unsortedCustomerList();
        // console.log("customer list Response:",customerListResponse);
        if(customerListResponse.success===1){
          setCustomerList(customerListResponse.data)
        }
      } catch (error) {
        
      }finally{
        setIsLoading(false);
      }
    }
    const fetchCategoryList= async()=>{
      try {
        const categoryListResponse = await BookingService.categoryList();
        // console.log("category list Response:",categoryListResponse);
        if(categoryListResponse.success===1){
          setCategoryList(categoryListResponse.data)
        }
      } catch (error) {
        
      }
    }
    const fetchServicablePincodes= async()=>{
      try {
        const servicablePincodeListResponse = await BookingService.servicablePincodeList();
        // console.log("servicable pincodes list Response:",servicablePincodeListResponse);
        if(servicablePincodeListResponse.success===1){
          setServicablePincodeList(servicablePincodeListResponse.data)
        }
      } catch (error) {
        
      }
    }
    
    fetchCusomerlist();
    fetchCategoryList();
    fetchServicablePincodes();
  },[])
  
  useEffect(()=>{
    setValue('subCategoryId','')
    if(categoryId){ 
      const fetchSubCategoryList= async()=>{
        setLoadingSubCategoryList(true);
        try {
          const subCategoryListResponse = await BookingService.subCategoryList({categoryId:categoryId});
          // console.log("sub category list Response:",subCategoryListResponse);
          if(subCategoryListResponse.success===1){
            setSubCategoryList(subCategoryListResponse.data)
          }
        } catch (error) {
          
        }finally{
          setLoadingSubCategoryList(false);
        }
      }
      fetchSubCategoryList();
    }
  },[categoryId])

  useEffect(()=>{
    // Function to reset services
    setValue('serviceExpert', '');
  },[subCategoryId])

  useEffect(()=>{
    setValue('serviceExpert','')
    if(serviceArea){
      
      const fetchServiceExpertList= async()=>{
        setLoadingServiceExpertList(true);
        try {
          const serviceExpertListResponse = await BookingService.serviceExpertList({pincode:serviceArea.pincode});
          // console.log("service expert list Response:",serviceExpertListResponse);
          if(serviceExpertListResponse.success===1){
            setServiceExpertList(serviceExpertListResponse.data)
          }
        } catch (error) {
          
        }finally{
          setLoadingServiceExpertList(false);
        }
      }
      fetchServiceExpertList();
    }
  },[serviceArea])
  
  useEffect(()=>{
    // Function to reset services
    const resetServices = () => {
      // setValue('serviceExpert', '');
      setValue('services', []);
      setSelectedServices([]);
    };

    // Reset services when expert
    resetServices();
    if(subCategoryId && serviceExpert){
      // console.log(serviceExpert)
      const fetchServiceList= async()=>{
        setLoadingServiceList(true);
        try {
          const serviceListResponse = await BookingService.serviceList({subCategoryId:subCategoryId,providerId:serviceExpert});
          // console.log("service list Response:",serviceListResponse);
          if(serviceListResponse.success===1){
            setServiceList(serviceListResponse.data)
          }
        } catch (error) {
          
        }finally{
          setLoadingServiceList(false)
        }
      }
      fetchServiceList();
    }
  },[serviceExpert])
  
  // useEffect(() => {
    
  // }, [services]);

  const handleQuantityChange = (id, quantity) => {
    const items = services.map(service => 
      service.id === id ? { ...service, quantity: parseInt(quantity) || 0,serviceTime:(service.serviceTime/service.quantity)* parseInt(quantity) || 0} : service
    );
    setValue('services',items);
  };
  const handleDeleteService = (id) => {
    const updatedServices = services.filter(service => service.id !== id);
    // setSelectedServices(updatedServices);
    setValue('services', updatedServices); // Update the form value
  };

  
  useEffect(()=>{
    setValue('timeSlot','');
    setTimeSlotList([])
    let totalServiceTime = 0;
    if (services.length>0) {
      // setSelectedServices(services.map(service => ({ ...service, quantity: 1 })));
      services.map((service)=>totalServiceTime=totalServiceTime+service.serviceTime);
    }else{
      setValue('serviceDate','')
      setValue('timeSlot','')
    }
    if (selectedDate && services.length>0) {
      // console.log("selected Date:",selectedDate)
        const fetchTimeSlots= async()=>{
          setLoadingTimeSlotList(true);
          const data={    
            customerId: watch('customer')?.id,
            providerId: serviceExpert,
            serviceTimeTotal: totalServiceTime,
            timeSlotDate: selectedDate
          }
          // console.log("required data for getting time slot:",data);
          try {
            const timeSlotResponse = await BookingService.timeSlots(data);
            // console.log("Time slot Response:",timeSlotResponse);
            if(timeSlotResponse.success=="1"){
              if (timeSlotResponse?.data.length>0) {
                setTimeSlotList(timeSlotResponse.data);
              } else {
                Swal.fire('Time Slot for selected Date not available','','error');
                setValue('serviceDate','')
              }
              
            }else{
              Swal.fire(timeSlotResponse.message,'','error');
            }
          } catch (error) {

          }finally{
            setLoadingTimeSlotList(false);
          }
        }
        fetchTimeSlots();
    }
  },[selectedDate,services])
  /*..........related to customer list autocomplete................ */

  const getFullName = (profile) => {
    if (!profile) return '';
    return [profile.firstName, profile.middleName, profile.lastName]
      .filter(Boolean)
      .join(' ');
  };
  
 
  const [isSubmitting,setIsSubmitting] = useState(false);
  const onSubmit = async(data) => {
    setIsSubmitting(true);
    // console.log("submitted booking form data:",data);
    let subTotal =0;
    const items = data.services.map((service)=>{
      subTotal = parseFloat(subTotal)+parseFloat(service.price * service.quantity);
      return {
        providerServiceId: service.id,
        // serviceName: service.title,
        // price: service.price * service.quantity,
        quantity:service.quantity,
      }
    });
    
    const formattedDatatobeSend = {
      customerId: data.customer.id,
      providerId: data.serviceExpert,
      paymentId: 1,
      serviceDate: data.serviceDate,
      bookingStartTime:data.timeSlot.startTime,
      bookingEndTime:data.timeSlot.endTime,
      subTotal: parseFloat(subTotal).toFixed(2),
      couponId: chargeList.couponId,
      couponAmount: chargeList.couponAmount,
      discount: chargeList.discount,
      platformCharges: chargeList.platformCharges,
      total: parseFloat(subTotal).toFixed(2),  
      callbackRequest: true,
      add_customerFullName: `${data.customer.profile.firstName} ${data.customer.profile.middleName} ${data.customer.profile.lastName}`,
      add_mobileNumber: data.customer.mobileNumber,
      add_pincode: data.serviceArea.pincode,
      add_latitude: '',
      add_longitude: '',
      add_city: data.serviceArea.city,
      add_state: data.serviceArea.state,
      add_landmark: data.landmark,
      add_address:data.address ,
      items: items,
    }
  //  console.log("data to be send for booking:",formattedDatatobeSend);
    try {
        const bookingResponse = await BookingService.addBooking(formattedDatatobeSend);
        // console.log("new booking Response:",bookingResponse);
        if(bookingResponse.success=='1'){
          setBookingResponseDetails({status:'true',message:'booking successfull',data:bookingResponse.data})
          setIsSubmitting(true);
        }else{
          setBookingResponseDetails({status:'false',message:'booking failed',data:{}})
          setIsSubmitting(true);
        }
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  const getFullAddress = () => {
    const addressParts = [
      bookingData.houseFlatBlockNo,
      bookingData.apartmentRoadArea,
      // bookingData.address,
      bookingData.landmark,
      bookingData.city,
      bookingData.state,
      bookingData.pincode
    ];
  
    // Filter out empty or undefined parts and join with a comma
    return addressParts
      .filter(part => part && part.trim() !== '')
      .join(', ');
  };
  

  const completeBooking=async()=>{
    // console.log(" submitted Schedule and Address:",data);
    // setBookingData(pre=>{
    //   return {...pre,...data}
    // })
    const result = await Swal.fire({
      text: "Do you want to Confirm Booking now?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Confirm now!',
      cancelButtonText: 'No, Cancel',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCloseButton: false,
    });
    if (result.isConfirmed){
      let subTotal =0;
      const items = bookingData?.services.map((service)=>{
        subTotal = parseFloat(subTotal)+parseFloat(service.price * service.quantity);
        return {
          providerServiceId: service.id,
          // serviceName: service.title,
          // price: service.price * service.quantity,
          quantity:service.quantity,
        }
      });
      const timeJson = JSON.parse(bookingData?.timeSlot);
      const fullAddress = getFullAddress();
      const formattedDatatobeSend = {
        customerId: bookingData?.customer.id,
        providerId: bookingData?.serviceExpert,
        paymentId: 1,
        serviceDate: bookingData?.serviceDate,
        bookingStartTime: timeJson.startTime,
        bookingEndTime: timeJson.endTime,
        subTotal: parseFloat(subTotal).toFixed(2),
        couponId: chargeList.couponId,
        couponAmount: chargeList.couponAmount,
        discount: chargeList.discount,
        platformCharges: chargeList.platformCharges,
        total: parseFloat(subTotal).toFixed(2),  
        callbackRequest: true,
        add_customerFullName: `${bookingData?.customer.profile.firstName} ${bookingData?.customer.profile.middleName} ${bookingData?.customer.profile.lastName}`,
        add_mobileNumber: bookingData?.customer.mobileNumber,
        add_pincode: bookingData?.pincode,
        add_latitude: bookingData?.latitude,
        add_longitude: bookingData?.longitude,
        add_city: bookingData?.city,
        add_state: bookingData?.state,
        add_landmark: bookingData?.landmark||'',
        add_address: bookingData.addressMode =='select_address'? bookingData?.address : fullAddress,
        items: items,
      }
      // console.log("data to be send for booking:",formattedDatatobeSend);

      try {
        // Show loading state
        Swal.fire({
          title: 'Processing...',
          text: 'Please wait while we processing your booking request',
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
  
        // Make the API call
        const bookingResponse = await BookingService.addBooking(formattedDatatobeSend);
        // console.log("new booking Response:",bookingResponse);
  
        // Close loading dialog and show success/error message
        if (bookingResponse.success === 1) {
          // await fetchProviderServiceList({
          //   providerId:serviceDetailsData.providerId,
          //   page:controller.page+1, 
          //   pageSize:controller.pageSize,
          // }); 
          setBookingResponseDetails({status:'true',message:'booking successfull',data:bookingResponse.data})
          await Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: bookingResponse.message || 'Service Booking successfull',
            timer: 1500
          });
          handleComplete();
          handleNext();
          return true;
        } else {
          await Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'Something Went Wrong ... Booking failed'
          });
          return false;
        }
  
      } catch (error) {
        // console.error("Error in Booking:", error);
        await Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'An error occurred while booking'
        });
        return false;
      }
    }else {
      return false;
    }
  }

  const modifyTime = (time) => {
    const timeJson = JSON.parse(time);
    return `${dayjs(timeJson.startTime, 'HH:mm:ss').format('h:mm A')} - ${dayjs(timeJson.endTime, 'HH:mm:ss').format('h:mm A')}`
    // return `${Time24to12(timeJson.startTime)} - ${Time24to12(timeJson.endTime)}`;
  };
  // let visitingCharge = 50;
  return (
    <Box sx={{ overflow: "auto", backgroundColor: "#fff" ,padding:{xs:'2%',md:'1% 3%'}}}>
        <Grid container spacing={2} >
          <Grid item xs={12} sx={{margin:'auto'}}>

            <Grid item xs={12} sx={{mb:'1%'}}>
              <Box>
                <Typography sx={{fontWeight:'600',color:'#494949'}}>Customer Details</Typography>
                <Card sx={{p:'1%',bgcolor:'#dadada4d'}}>
                  <Grid container spacing={1}>
                    <Grid item xs={5}>
                      <Box>
                        <Typography sx={{fontWeight:500,color:'#3E3E3E'}} variant='body1'>Name <Box component={'span'} sx={{fontWeight:600,color:'#6E6E6E',fontSize:'0.875rem'}}>: {bookingData?.customer?.profile?.firstName||''} {bookingData?.customer?.profile?.middleName||''} {bookingData?.customer?.profile?.lastName||''}</Box></Typography>
                        <Typography sx={{fontWeight:500,color:'#3E3E3E'}} variant='body1'>Contact <Box component={'span'} sx={{fontWeight:600}}></Box>: {bookingData?.customer?.mobileNumber||''}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography sx={{fontWeight:500,color:'#3E3E3E'}} variant='body1'>Address <Box component={'span'} sx={{fontWeight:600,color:'#6E6E6E',fontSize:'0.875rem'}}>: {bookingData.addressMode =='select_address'? bookingData?.address : getFullAddress()}</Box></Typography>
                      <Typography sx={{fontWeight:500,color:'#3E3E3E'}} variant='body1'>Landmark <Box component={'span'} sx={{fontWeight:600,color:'#6E6E6E',fontSize:'0.875rem'}}>: {bookingData?.landmark||''}</Box></Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12} sx={{mb:'1%'}}>
              <Box>
                <Typography sx={{fontWeight:'600',color:'#494949'}}>Service Expert</Typography>
                <Card sx={{p:'1%',bgcolor:'#dadada4d'}}>
                  <Grid container spacing={1}>
                    <Grid item xs={5}>
                      <Box>
                        <Typography sx={{fontWeight:500,color:'#3E3E3E'}} variant='body1'>Name <Box component={'span'} sx={{fontWeight:600,color:'#6E6E6E',fontSize:'0.875rem'}}>: {bookingData?.serviceExpertProfile?.firstName||''} {bookingData?.serviceExpertProfile?.middleName||''} {bookingData?.serviceExpertProfile?.lastName||''}</Box></Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography sx={{fontWeight:500,color:'#3E3E3E'}} variant='body1'>Contact <Box component={'span'} sx={{fontWeight:600,color:'#6E6E6E',fontSize:'0.875rem'}}>: {bookingData?.serviceExpertProfile?.mobileNumber||''}</Box></Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12} sx={{mb:'1%'}}>
              <Box>
                <Typography sx={{fontWeight:'600',color:'#494949'}}>Schedule Details</Typography>
                <Card sx={{p:'1%',bgcolor:'#dadada4d'}}>
                  <Grid container spacing={1}>
                    <Grid item xs={5}>
                      <Box>
                        <Typography sx={{fontWeight:500,color:'#3E3E3E'}} variant='body1'>Date <Box component={'span'} sx={{fontWeight:600,color:'#6E6E6E',fontSize:'0.875rem'}}>: {bookingData?.serviceDate||''}</Box></Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography sx={{fontWeight:500,color:'#3E3E3E'}} variant='body1'>Slot Timing <Box component={'span'} sx={{fontWeight:600,color:'#6E6E6E',fontSize:'0.875rem'}}>: {modifyTime(bookingData?.timeSlot)||''}</Box></Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12} sx={{mb:'1%'}}>
              <Box>
                <Typography sx={{fontWeight:'600',color:'#494949'}}>Service Details</Typography>
                <Card sx={{bgcolor:'#dadada4d'}}>
                  <Grid container>
                    <Grid item xs={9}>
                      {/* <Box sx={{p:'2%',display:'flex',justifyContent:'space-between',borderBottom:'1px solid #BEC2C2'}}>
                        <Typography sx={{fontWeight:500,color:'#3E3E3E'}} variant='body1'>Category <Box component={'span'} sx={{fontWeight:600}}>: Men’s Kids’ Salon</Box></Typography>
                        <Typography sx={{fontWeight:500,color:'#3E3E3E'}} variant='body1'>Sub-Category <Box component={'span'} sx={{fontWeight:600}}>: Haircut & Beard Styling</Box></Typography>
                      </Box> */}
                      <Box>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{fontWeight:600,color:'#6E6E6E',p:'1%'}} width="40%" >Service</TableCell>                                
                                <TableCell sx={{fontWeight:600,color:'#6E6E6E',p:'1%'}} width="15%" >Quantity</TableCell>
                                <TableCell sx={{fontWeight:600,color:'#6E6E6E',p:'1%'}} width="15%" >Time</TableCell>
                                <TableCell sx={{fontWeight:600,color:'#6E6E6E',p:'1%'}} width="15%" >Price</TableCell>
                                <TableCell sx={{fontWeight:600,color:'#6E6E6E',p:'1%'}} width="15%" >Total </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {bookingData?.services.map((service) => (
                                <TableRow key={service?.id} sx={{borderBottom:'none'}}>
                                  <TableCell sx={{ p: '1%' ,color:'#494949',borderBottom:'none'}}>{service?.title}</TableCell>
                                  <TableCell sx={{ p: '1% 3%' ,color:'#494949',borderBottom:'none'}}>{service?.quantity}</TableCell>
                                  <TableCell sx={{ p: '1%' ,color:'#494949',borderBottom:'none'}}>{service?.serviceTime}mins</TableCell>
                                  <TableCell sx={{ p: '1%' ,color:'#494949',borderBottom:'none'}}>{service?.price}</TableCell>
                                  <TableCell sx={{ p: '1%' ,color:'#494949',borderBottom:'none'}}>{parseFloat(service?.price*service?.quantity).toFixed(2)}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Grid>
                    <Grid item xs={3} sx={{borderLeft:'1px solid #BEC2C2'}}>
                      <Box sx={{p:'3%',borderBottom:'1px solid #BEC2C2'}}>
                        <Typography sx={{fontWeight:600}}>Payment Summary</Typography>
                      </Box>
                      <Box sx={{ }}>
                        <Box sx={{ p: 1 ,display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <Typography variant="body2" color="text.secondary">
                            Subtotal
                          </Typography>
                          <Typography variant="body2">
                            {/* sum of all price*quantity */}
                            ₹{bookingData?.services.reduce((acc, service) => acc + service.price * service.quantity, 0).toFixed(2)}
                          </Typography>
                        </Box>
                        
                        <Box sx={{px:1 , display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <Typography variant="body2" color="text.secondary">
                            Visiting Charges
                          </Typography>
                          <Typography variant="body2">
                            {/* ₹{parseFloat(chargeDetails?.vistingCharge).toFixed(2)} */}
                            ₹{parseFloat(bookingData?.services.reduce((acc, service) => acc + (service.price * service.quantity), 0)).toFixed(2)>parseFloat(chargeDetails?.waiveVisitCharge)? parseFloat(0).toFixed(2):parseFloat(chargeDetails?.vistingCharge).toFixed(2)}

                          </Typography>
                        </Box>
                        
                        <Divider sx={{ my: .5 }} />
                        
                        <Box sx={{p: 1 , display: 'flex', justifyContent: 'space-between' }}>
                          <Typography variant="body1" sx={{ fontWeight: 600,color:'#3e3e3e' }}>
                            Total Amount
                          </Typography>
                          <Typography variant="body1" sx={{ fontWeight: 600,color:'#3e3e3e' }}>
                          {/* ₹{(parseFloat(bookingData?.services.reduce((acc, service) => acc + service.price * service.quantity, 0)) + parseFloat(chargeList.platformCharges)).toFixed(2)} */}
                          
                          ₹{parseFloat(bookingData?.services.reduce((acc, service) => acc + (service.price * service.quantity), 0)).toFixed(2)>parseFloat(chargeDetails?.waiveVisitCharge)? parseFloat(bookingData?.services.reduce((acc, service) => acc + (service.price * service.quantity), 0)).toFixed(2):parseFloat(bookingData?.services.reduce((acc, service) => acc + (service.price * service.quantity), 0)+parseFloat(chargeDetails?.vistingCharge)).toFixed(2)}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12} sx={{mb:'1%'}}>
              <Box>
                <Typography sx={{fontWeight:'600',color:'#494949'}}>Payment Details</Typography>
                <Card sx={{bgcolor:'#dadada4d'}}>
                  <Grid container>
                    <Grid item xs={9}>
                      <Box sx={{p:'2%'}}>
                        <Typography sx={{fontWeight:500,color:'#3E3E3E'}} variant='body1'>Payment Method <Box component={'span'} sx={{fontWeight:600,textTransform:'capitalize'}}>: {bookingData?.paymentMode||''} </Box></Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
              </Box>
            </Grid>
            
            {/* submit button section */}
            <Grid container>    
              <Grid item xs={12} sx={{my:'2%'}}>
                <Box textAlign={'center'}>
                  <Button onClick={()=>completeBooking()} variant="contained" color="primary">
                    confirm Booking
                  </Button>
                  <Box sx={{ flexGrow: 1 }}></Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
    </Box >
  );
}

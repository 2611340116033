import { createReducer } from "@reduxjs/toolkit";
import { isMobile } from "react-device-detect";

const intialState = {
    menu: (isMobile) ? false : true,
    menuDefaultSelected: "dashboard"
};

export const customReducer = createReducer(intialState, {
    menuStatus: (state) => {
        state.menu = !state.menu;
    },
    menuSelect: (state, action) => {
        state.menuDefaultSelected = action.payload
    }
});

// New reducer for DataGrid state
const initialExpertTableState = {
    gridState: null, // Add this to store column state
    page: 0,
    pageSize: 50,
    filters: {
        searchKey: "",
        sortBy: 'createdAt',
        sortType: 'desc',
        filterOptions: [],
    }
};

export const serviceExpertTableReducer = createReducer(initialExpertTableState, {
    updateExpertTableState: (state, action) => {
        return { ...state, ...action.payload };
    },
    resetExpertTableState: (state) => {
        return initialExpertTableState;
    }
});


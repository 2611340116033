import { Box, Skeleton } from '@mui/material';
import React from 'react';

const SkeletonLoader = ({rowCount,width}) => {
  return (
    <Box sx={{position:'absolute'}}>
                        
        {/* according to number of rowsper page same no. of skeleton */}
        {[...Array(rowCount)].map((e, i) => (
            <Box key={i} sx={{p:'.5%'}}>
                <Skeleton variant="rectangular" width={width} height={'2.125rem'} />
            </Box>
        ))}
    </Box>
  );
}

export default SkeletonLoader;

export const getPermissions = () => {
    const data = JSON.parse(localStorage.getItem('data'));
    const permissions = data.permissions || [];
    return permissions.reduce((acc, permission) => {
      acc[permission.feature.title] = {
        canView: permission.canView,
        canCreate: permission.canCreate,
        canEdit: permission.canEdit,
        canDelete: permission.canDelete
      };
      return acc;
    }, {});
  };
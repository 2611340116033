import React, { useState, useEffect,useCallback } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, CircularProgress, Grid, Typography, LinearProgress } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import Swal from 'sweetalert2';
import { ImageUploadServices } from './imageUploadSrevices';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB in bytes

export default function MultiFileUploadDialog({ open, onClose, imgData, imgUploadResponse }) {
    const { control, handleSubmit, setValue, formState: { errors }, watch, reset } = useForm({
        mode: "onChange",
        defaultValues:{
            folder: imgData.path,
            files: [],
        }
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [previewImages, setPreviewImages] = useState([]);
    const [uploadProgress, setUploadProgress] = useState({});

    useEffect(() => {
        setPreviewImages([]);
        setValue('files', []);
        setUploadProgress({});
    }, [open, setValue]);

    const onSelectedImages = (event, onChange) => {
        const files = Array.from(event.target.files);
        onChange(files);
        const newPreviewImages = files.map(file => URL.createObjectURL(file));
        setPreviewImages(newPreviewImages);
        setUploadProgress(files.reduce((acc, file) => ({ ...acc, [file.name]: 0 }), {}));
    };

    const updateProgress = useCallback((fileName, progress) => {
        // console.log(`Progress for ${fileName}: ${progress}%`); // Debugging log
        setUploadProgress(prev => ({ ...prev, [fileName]: progress }));
      }, []);

    const handleUpload = async (data) => {
        setIsSubmitting(true);
        if (!data.files || data.files.length === 0 || !data.folder) {
            // console.error("Files or folder is missing");
            setIsSubmitting(false);
            return;
        }

        const uploadedImages = [];

        for (const file of data.files) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('folder', data.folder);

            try {
                const imageUploadResponse = await ImageUploadServices.uploadImage(formData, (progress) => {
                    updateProgress(file.name, progress);
                });
                if (imageUploadResponse.success == '1') {
                    uploadedImages.push(imageUploadResponse.data);
                } else {
                    // console.error(`Failed to upload ${file.name}`);
                }
            } catch (error) {
                // console.error(`Error uploading ${file.name}:`, error);
            }
        }

        if (uploadedImages.length > 0) {
            imgUploadResponse(uploadedImages);
            // Swal.fire(`Successfully uploaded ${uploadedImages.length} image(s)`, '', 'success');
        } else {
            Swal.fire('Image upload Failed', '', 'error');
        }

        onClose();
        setIsSubmitting(false);
        reset();
    };

    return (
        <Dialog open={open} maxWidth="sm" fullWidth={true}>
            <DialogTitle sx={{ m: 0, p: 2 }}>
                Multi-File Upload
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Box component="form" onSubmit={handleSubmit(handleUpload)}>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                name="files"
                                rules={{
                                    required: 'Please select at least one image',
                                    validate: {
                                        fileType: (files) => {
                                            const acceptedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'];
                                            return files.every(file => acceptedTypes.includes(file.type)) || 'File type not supported. Please upload PNG, JPEG, or JPG images.';
                                        },
                                        fileSize: (files) => {
                                            return files.every(file => file.size <= MAX_FILE_SIZE) || 'Each file size should be less than 5 MB.';
                                        }
                                    }
                                }}
                                render={({ field: { onChange }, fieldState: { error } }) => (
                                    <>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            id="files"
                                            multiple
                                            onChange={(event) => onSelectedImages(event, onChange)}
                                            style={{ display: 'none' }}
                                        />
                                        <Button sx={{ width: "100%" }} variant="outlined" component="label" htmlFor="files" disabled={isSubmitting}>
                                            Choose Images
                                        </Button>
                                        {error && (
                                            <Typography variant="caption" color="error">
                                                {error.message}
                                            </Typography>
                                        )}
                                    </>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', flexWrap:'wrap', gap: 1 }}>
                                {watch('files').map((file, index) => (
                                    <Box key={index} sx={{  }}>
                                        <Box
                                            component="img"
                                            sx={{
                                                width: 100,
                                                height: 100,
                                                objectFit: 'cover',
                                                borderRadius: "10px",
                                                border:'1px solid lightGray'
                                            }}
                                            alt={`Preview ${index + 1}`}
                                            src={previewImages[index]}
                                        />
                                        <Box sx={{ display:'flex',gap:'2%',alignItems:'baseline'}}>
                                            
                                            <LinearProgress 
                                                variant="determinate" 
                                                value={uploadProgress[file.name] || 0} 
                                                sx={{ mt: 1 ,width:'90%'}}
                                                thickness={10}
                                            />
                                            <Typography sx={{fontSize:'.75rem',color:'#1565c0'}}>{uploadProgress[file.name]}%</Typography>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button type='submit' disabled={isSubmitting}>
                        {isSubmitting ? (
                            <>
                                <CircularProgress size={24} sx={{ marginRight: 1 }} />
                                Uploading...
                            </>
                        ) : (
                            'Upload Images'
                        )}
                    </Button>
                    <Button onClick={onClose} color="primary" disabled={isSubmitting}>
                        Cancel
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
}


// import React, { useState, useEffect } from 'react';
// import Button from '@mui/material/Button';
// import Dialog from '@mui/material/Dialog';
// import DialogTitle from '@mui/material/DialogTitle';
// import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
// import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
// import { Box, CircularProgress, Grid, Typography } from '@mui/material';
// import { useForm, Controller } from 'react-hook-form';
// import Swal from 'sweetalert2';
// import { ImageUploadServices } from './imageUploadSrevices';

// const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB in bytes

// export default function MultiFileUploadDialog({ open, onClose, imgData, imgUploadResponse }) {
//     const { control, handleSubmit, setValue, formState: { errors }, watch, reset } = useForm({
//         mode: "onChange",
//         defaultValues:{
//             folder: imgData.path,
//             files: [],
//         }
//     });
//     const [isSubmitting, setIsSubmitting] = useState(false);
//     const [previewImages, setPreviewImages] = useState([]);

//     useEffect(() => {
//         setPreviewImages([]);
//         setValue('files', []);
//     }, [open, setValue]);

//     const onSelectedImages = (event, onChange) => {
//         const files = Array.from(event.target.files);
//         onChange(files);
//         const newPreviewImages = files.map(file => URL.createObjectURL(file));
//         setPreviewImages(newPreviewImages);
//     };

//     const handleUpload = async (data) => {
//         setIsSubmitting(true);
//         if (!data.files || data.files.length === 0 || !data.folder) {
//             console.error("Files or folder is missing");
//             setIsSubmitting(false);
//             return;
//         }

//         const uploadedImages = [];

//         for (const file of data.files) {
//             const formData = new FormData();
//             formData.append('file', file);
//             formData.append('folder', data.folder);

//             try {
//                 const imageUploadResponse = await ImageUploadServices.uploadImage(formData);
//                 if (imageUploadResponse.success == '1') {
//                     uploadedImages.push(imageUploadResponse.data);
//                 } else {
//                     console.error(`Failed to upload ${file.name}`);
//                 }
//             } catch (error) {
//                 console.error(`Error uploading ${file.name}:`, error);
//             }
//         }

//         if (uploadedImages.length > 0) {
//             imgUploadResponse(uploadedImages);
//             Swal.fire(`Successfully uploaded ${uploadedImages.length} image(s)`, '', 'success');
//         } else {
//             Swal.fire('Image upload Failed', '', 'error');
//         }

//         onClose();
//         setIsSubmitting(false);
//         reset();
//     };

//     return (
//         <Dialog open={open} maxWidth="sm" fullWidth={true}>
//             <DialogTitle sx={{ m: 0, p: 2 }}>
//                 Multi-File Upload
//                 <IconButton
//                     aria-label="close"
//                     onClick={onClose}
//                     sx={{
//                         position: 'absolute',
//                         right: 8,
//                         top: 8,
//                         color: (theme) => theme.palette.grey[500],
//                     }}
//                 >
//                     <CloseIcon />
//                 </IconButton>
//             </DialogTitle>
//             <Box component="form" onSubmit={handleSubmit(handleUpload)}>
//                 <DialogContent dividers>
//                     <Grid container spacing={2}>
//                         <Grid item xs={12}>
//                             <Controller
//                                 control={control}
//                                 name="files"
//                                 rules={{
//                                     required: 'Please select at least one image',
//                                     validate: {
//                                         fileType: (files) => {
//                                             const acceptedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
//                                             return files.every(file => acceptedTypes.includes(file.type)) || 'File type not supported. Please upload PNG, JPEG, or JPG images.';
//                                         },
//                                         fileSize: (files) => {
//                                             return files.every(file => file.size <= MAX_FILE_SIZE) || 'Each file size should be less than 5 MB.';
//                                         }
//                                     }
//                                 }}
//                                 render={({ field: { onChange }, fieldState: { error } }) => (
//                                     <>
//                                         <input
//                                             type="file"
//                                             accept="image/*"
//                                             id="files"
//                                             multiple
//                                             onChange={(event) => onSelectedImages(event, onChange)}
//                                             style={{ display: 'none' }}
//                                         />
//                                         <Button sx={{ width: "100%" }} variant="outlined" component="label" htmlFor="files" disabled={isSubmitting}>
//                                             Choose Images
//                                         </Button>
//                                         {error && (
//                                             <Typography variant="caption" color="error">
//                                                 {error.message}
//                                             </Typography>
//                                         )}
//                                     </>
//                                 )}
//                             />
//                         </Grid>
//                         <Grid item xs={12}>
//                             <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
//                                 {previewImages.map((image, index) => (
//                                     <Box
//                                         key={index}
//                                         component="img"
//                                         sx={{
//                                             width: 100,
//                                             height: 100,
//                                             objectFit: 'cover',
//                                             borderRadius: "10px"
//                                         }}
//                                         alt={`Preview ${index + 1}`}
//                                         src={image}
//                                     />
//                                 ))}
//                             </Box>
//                         </Grid>
//                     </Grid>
//                 </DialogContent>
//                 <DialogActions>
//                     <Button type='submit' disabled={isSubmitting}>
//                         {isSubmitting ? (
//                             <>
//                                 <CircularProgress size={24} sx={{ marginRight: 1 }} />
//                                 Uploading...
//                             </>
//                         ) : (
//                             'Upload Images'
//                         )}
//                     </Button>
//                     <Button onClick={onClose} color="primary" disabled={isSubmitting}>
//                         Cancel
//                     </Button>
//                 </DialogActions>
//             </Box>
//         </Dialog>
//     );
// }


// import React, { useEffect, useState } from 'react';
// import Button from '@mui/material/Button';
// import Dialog from '@mui/material/Dialog';
// import DialogTitle from '@mui/material/DialogTitle';
// import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
// import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
// import DeleteIcon from '@mui/icons-material/Delete';
// import { Box, CircularProgress, Grid, Typography } from '@mui/material';
// import { useForm, Controller } from 'react-hook-form';
// import Swal from 'sweetalert2';
// import { CategoryService } from '../../pages/Category/CategoryService';

// const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB in bytes
// // const MAX_FILES = 5; // Maximum number of files that can be uploaded

// export default function MultipleImageUploadDialog({ open, onClose, imgData, imgUploadResponse }) {
//     const { control, handleSubmit, formState: { errors }, reset, setValue, watch } = useForm({
//         mode: "onChange",
//         defaultValues:{
//             folder: imgData.path,
//             files: [],
//         }
//     });
//     const [isSubmitting, setIsSubmitting] = useState(false);
//     const [previewImages, setPreviewImages] = useState([]);

//     const watchFiles = watch('files');

//     useEffect(() => {
//         setPreviewImages([]);
//         setValue('files', []);
//     }, [open, setValue]);

//     const onSelectedImages = (event, onChange) => {
//         const newFiles = Array.from(event.target.files);
//         const existingFileNames = new Set(watchFiles.map(file => file.name));
        
//         const uniqueNewFiles = newFiles.filter(file => !existingFileNames.has(file.name));
        
//         if (uniqueNewFiles.length < newFiles.length) {
//             Swal.fire('Duplicate files were ignored.', '', 'info');
//         }

//         const updatedFiles = [...watchFiles, ...uniqueNewFiles]
//         // const updatedFiles = [...watchFiles, ...uniqueNewFiles].slice(0, MAX_FILES); //if number of files declared
        
//         // if (updatedFiles.length > MAX_FILES) {
//         //     Swal.fire(`You can only upload a maximum of ${MAX_FILES} images.`, '', 'warning');
//         // }
        
//         const newPreviewImages = updatedFiles.map(file => URL.createObjectURL(file));
//         setPreviewImages(newPreviewImages);
//         onChange(updatedFiles);
//     };

//     const handleRemoveImage = (index) => {
//         const updatedFiles = watchFiles.filter((_, i) => i !== index);
//         const updatedPreviews = previewImages.filter((_, i) => i !== index);
        
//         setValue('files', updatedFiles);
//         setPreviewImages(updatedPreviews);
//     };

//     const handleUpload = async (data) => {
//         setIsSubmitting(true);
//         if (!data.files || data.files.length === 0 || !data.folder) {
//             console.error("Files or folder is missing");
//             return;
//         }
        
//         const uploadPromises = data.files.map(file => {
//             const formData = new FormData();
//             formData.append('file', file);
//             formData.append('folder', data.folder);
//             return CategoryService.uploadImage(formData);
//         });

//         try {
//             const responses = await Promise.all(uploadPromises);
//             const successfulUploads = responses.filter(response => response.success === '1');
            
//             if (successfulUploads.length > 0) {
//                 imgUploadResponse(successfulUploads.map(response => response.data));
//                 Swal.fire(`Successfully uploaded ${successfulUploads.length} image(s)`, '', 'success');
//             } else {
//                 Swal.fire('Image upload Failed', '', 'error');
//             }
//         } catch (error) {
//             console.error("Image Upload failed:", error);
//             Swal.fire('Image upload Failed', '', 'error');
//         } finally {
//             onClose();
//             setIsSubmitting(false);
//             reset();
//         }
//     };

//     return (
//         <Dialog open={open} maxWidth="sm"
//             fullWidth={true} 
//             PaperProps={{
//                 sx: {
//                     width: 'max-content', 
//                     maxWidth: '75%' 
//                 },
//             }}
//         >
//             <DialogTitle sx={{ m: 0, p: 2 }}>
//                 Multiple Image Upload
//                 <IconButton
//                     aria-label="close"
//                     onClick={onClose}
//                     sx={{
//                         position: 'absolute',
//                         right: 8,
//                         top: 8,
//                         color: (theme) => theme.palette.grey[500],
//                     }}
//                 >
//                     <CloseIcon />
//                 </IconButton>
//             </DialogTitle>
//             <Box component="form" onSubmit={handleSubmit(handleUpload)}>
//                 <DialogContent dividers sx={{ minWidth: '420px', minHeight: '300px' }}>
//                     <Grid container spacing={2}>
//                         <Grid item xs={12}>
//                             <Controller
//                                 control={control}
//                                 name="files"
//                                 rules={{
//                                     required: 'Please select at least one image',
//                                     validate: {
//                                         fileType: (value) => {
//                                             if (value && value.length > 0) {
//                                                 const acceptedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
//                                                 return value.every(file => acceptedTypes.includes(file.type)) || 'File type not supported. Please upload PNG, JPEG, or JPG images.';
//                                             }
//                                             return true;
//                                         },
//                                         fileSize: (value) => {
//                                             if (value && value.length > 0) {
//                                                 return value.every(file => file.size <= MAX_FILE_SIZE) || 'Each file size should be less than 5 MB.';
//                                             }
//                                             return true;
//                                         }
//                                     }
//                                 }}
//                                 render={({ field: { onChange }, fieldState: { error } }) => (
//                                     <>
//                                         <input
//                                             type="file"
//                                             accept="image/*"
//                                             id="files"
//                                             multiple
//                                             onChange={(event) => onSelectedImages(event, onChange)}
//                                             style={{ display: 'none' }}
//                                         />
//                                         <Button sx={{ width: "100%" }} variant="outlined" component="label" htmlFor="files" disabled={isSubmitting}>
//                                             Choose Images 
//                                             {/* (Max {MAX_FILES}) */}
//                                         </Button>
//                                         {error && (
//                                             <Typography variant="caption" color="error">
//                                                 {error.message}
//                                             </Typography>
//                                         )}
//                                     </>
//                                 )}
//                             />
//                         </Grid>
//                         <Grid item xs={12}>
//                             <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
//                                 {previewImages.map((image, index) => (
//                                     <Box
//                                         key={index}
//                                         sx={{
//                                             position: 'relative',
//                                             width: 100,
//                                             height: 100,
//                                         }}
//                                     >
//                                         <Box
//                                             component="img"
//                                             sx={{
//                                                 width: '100%',
//                                                 height: '100%',
//                                                 objectFit: 'cover',
//                                                 borderRadius: "10px"
//                                             }}
//                                             alt={`Preview ${index + 1}`}
//                                             src={image}
//                                         />
//                                         <IconButton
//                                             sx={{
//                                                 position: 'absolute',
//                                                 top: 0,
//                                                 right: 0,
//                                                 color:'#cc2e2e',
//                                                 // backgroundColor: 'rgba(255, 255, 255, 0.7)',
//                                                 '&:hover': {
//                                                     color: 'red',
//                                                 }
//                                             }}
//                                             onClick={() => handleRemoveImage(index)}
//                                             size="small"
//                                         >
//                                             <DeleteIcon fontSize="small" color='inherit'/>
//                                         </IconButton>
//                                     </Box>
//                                 ))}
//                             </Box>
//                         </Grid>
//                     </Grid>
//                 </DialogContent>
//                 <DialogActions>
//                     <Button type='submit' disabled={isSubmitting}>
//                         {isSubmitting ? (
//                             <>
//                                 <CircularProgress size={24} sx={{ marginRight: 1 }} />
//                                 Uploading...
//                             </>
//                         ) : (
//                             'Upload Image'
//                         )}
//                     </Button>
//                     <Button onClick={onClose} color="primary" disabled={isSubmitting}>
//                         Cancel
//                     </Button>
//                 </DialogActions>
//             </Box>
//         </Dialog>
//     );
// }
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import React, { useState } from 'react';

const ConfirmationDialog = ({onSave,onDiscard,openConfirmationDialog}) => { 
  return (
    <Dialog open={openConfirmationDialog} >
      <DialogContent>
        <Box>
            <Typography>Selected Services Not saved yet.First save and then select other category.</Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{gap:'2%'}}>
        <Button  variant='contained' color='primary' 
            onClick={()=>onSave()}
        >save & proceed</Button>
        <Button  variant='contained' color='error' 
            onClick={()=>onDiscard()}
            
        >Discard Changes</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;

import React from 'react';
import { usePermission } from '../hooks/usePermission';

export const PermissionGuard = ({ 
  feature, 
  permission = 'canView', 
  fallback = null, 
  children 
}) => {
  const permissions = usePermission(feature);
  
  if (!permissions[permission]) {
    return fallback;
  }
  
  return children;
};
import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import MainContentLayout from "../../../components/Layout/MainContentLayout";
import RewardProgramList from "./RewardProgramList";

export default function RewardPrograms(){
    const menuDispatch = useDispatch();
    useEffect(() => {
        menuDispatch({
            type: 'menuSelect',
            payload: 'wallet-reward'
        });
    }, []);
    return (
        <MainContentLayout>
            <RewardProgramList/>
        </MainContentLayout>
    )
}

import React, { useEffect, useState } from 'react';
import {  Box,  Chip,  Tooltip,  Menu,  MenuItem,  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, IconButton, Stack, FormControl, InputLabel, Select} from '@mui/material';
import {  CheckCircle,  Warning,  Block,  Pending, KeyboardArrowDown,  Edit, Cancel} from '@mui/icons-material';
import Swal from 'sweetalert2';
import { Controller,useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { PermissionGuard } from '../../../components/PermissionGuard';
import { CustomerService } from '../Service';

const AccountStatusIndicator = ({ customerData, fetchUserDetails,setOpenOtpModal }) => {
  const {id} = useParams();
  
  const statusConfigs = [
    {
        label: 'Verified',
        value: true,
        color: 'success',
        colorCode:'#2e7d32',
        icon: <CheckCircle sx={{ fontSize: 16 }} />,
        tooltip: 'Account has been Verified Already'
      },
    {
      label: 'Not Verified',
      value: false,
      color: 'warning',
      colorCode:'#ed6c02',
      icon: <Pending sx={{ fontSize: 16 }} />,
      tooltip: 'Account not yet Verified'
    },
  ];

 
  const handleStatusChange = async () => {
      const result = await Swal.fire({
              text: "Do you want to Verify Customer now?",
              icon: 'question',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, Verify now!',
              cancelButtonText: 'No, Cancel',
              allowOutsideClick: false,
              allowEscapeKey: false,
              showCloseButton: false,
            });
          
            if (result.isConfirmed) {
              try {
                // Show loading state
                const loadingDialog = Swal.fire({
                  title: 'Processing...',
                  text: 'Please wait while sending the otp',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  showConfirmButton: false,
                  didOpen: () => {
                    Swal.showLoading();
                  }
                });
          
                // Make the API call
                const response = await CustomerService.verifyCustomerByMobileNo({ mobileNumber: customerData.mobileNumber });
                // console.log("mobile number submission response:", response);
          
                // Always close the loading dialog
                await Swal.close();
          
                if (response.success == '1') {
                  setOpenOtpModal(true);
                  return true;
                } else {
                  await Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: response.message || 'Failed to send otp'
                  });
                  return false;
                }
          
              } catch (error) {
                // Always close the loading dialog in case of error
                await Swal.close();
                
                // console.error("Error Verifying Customer mobile number:", error);
                await Swal.fire({
                  icon: 'error',
                  title: 'Error!',
                  text: 'An error occurred while Verifying customer'
                });
                return false;
              }
            } else {
              return false;
            }
    };

  
  return (
    <Stack
        direction="row" 
        spacing={1} 
        alignItems="center" 
        justifyContent="center"
        sx={{ mt:1,ml:'5%'}}
    >
        <PermissionGuard feature="customer" permission="canView" fallback>
            <Tooltip title={statusConfigs[customerData?.isVerified]?.tooltip}>
            <Chip
                icon={customerData?.isVerified ? statusConfigs[0]?.icon : statusConfigs[1]?.icon}
                label={customerData?.isVerified ? statusConfigs[0]?.label : statusConfigs[1]?.label}
                color={customerData?.isVerified ? statusConfigs[0]?.color : statusConfigs[1]?.color}
                size="small"
                variant="outlined"
                sx={{ml:1,fontSize:'0.5rem',height:'fit-content',py:'1%',
                  '& .MuiChip-icon':{
                    fontSize:'.6rem'
                  }
                }}
            />
            </Tooltip>
        </PermissionGuard> 
        { !customerData?.isVerified &&
          <PermissionGuard feature="customer" permission="canEdit" fallback>
            <IconButton size='small' sx={{p:0,ml:'1% !important',fontSize:'0.65rem'}} color={'info'}><Edit onClick={()=>handleStatusChange(!customerData?.isVerified)} sx={{fontSize:'.85rem'}}/></IconButton>
          </PermissionGuard>
        }
    </Stack>
  );
};

export default AccountStatusIndicator;
import React from 'react';
import { Navigate } from 'react-router-dom';
import { usePermission } from '../hooks/usePermission';

export const ProtectedRoute = ({ feature, element }) => {
  const { canView } = usePermission(feature);
  
  if (!canView) {
    return <Navigate to="/unauthorized" replace />;
  }
  
  return element;
};
import React, { useEffect, useState } from 'react';
import { TextField, Button, Typography, IconButton, Grid, FormControl, InputLabel, Select, MenuItem, FormLabel, RadioGroup, FormControlLabel, Radio, Autocomplete, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm ,Controller} from 'react-hook-form';
import Swal from "sweetalert2";
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { isMobile } from "react-device-detect";
import ImgUploadDialog from '../../../components/ImgUpload/imgUpload';
import Loading from '../../../components/Loader/Loading';
import { Helpers } from '../../../services/Helpers';
import dayjs from 'dayjs';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { formatDateTime } from '../../../utils/dateUtils';
import { Cancel, Edit } from '@mui/icons-material';
import { PermissionGuard } from '../../../components/PermissionGuard';
import DisplayIndicator from './DisplayIndicator';
import StatusIndicator from './StatusIndicator';

export default function ViewProgram({ closeEvent,programData,handleEdit,fetchList }) {

    const [editStatus,setEditStatus] = useState(false);
    const [editDisplay,setEditDisplay] = useState(false);
    
    return (
        <>
            
            <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center',p:'1% 2%',borderBottom:'1px solid lightGray'}}>
                <Typography variant="h5" sx={{fontSize:'0.85rem',fontWeight:600,color:'#3e3e3e'}}>
                    Program Detail's
                </Typography>
                <Box sx={{display:'flex',gap:1}}>
                    <PermissionGuard feature='reward-program' permission='canEdit'>
                        <Button
                            variant='contained'
                            onClick={handleEdit}
                            color='warning'
                            size='small'
                        >
                            Edit
                        </Button>
                    </PermissionGuard>
                    <Button
                        variant='contained'
                        onClick={closeEvent}
                        color='error'
                        size='small'
                    >
                        Close
                    </Button>
                </Box>
            </Box>

            <Box  sx={{p:2}}>
                <Grid container spacing={2}>
                    <Grid item sx={{pt:'1%!important'}} xs={isMobile?12:4}>                    
                        <Typography sx={{color:'#3e3e3e',fontWeight:'500',fontSize:'0.75rem'}}>Program Type</Typography>
                        <TextField
                            fullWidth
                            value={programData?.referenceType}
                            size='small'
                            inputProps={{
                                style:{fontSize:'0.75rem'}
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid item sx={{pt:'1%!important'}} xs={isMobile?12:4}>
                        <Typography sx={{color:'#3e3e3e',fontWeight:'500',fontSize:'0.75rem'}}>Wallet Type</Typography>
                        <TextField
                            fullWidth
                            value={programData?.walletType}
                            size='small'
                            inputProps={{
                                style:{fontSize:'0.75rem'}
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid item sx={{pt:'1%!important'}} xs={isMobile?12:4}>
                        <Typography sx={{color:'#3e3e3e',fontWeight:'500',fontSize:'0.75rem'}}>Reward Type</Typography>
                        <TextField
                            fullWidth
                            value={programData?.rewardType}
                            size='small'
                            inputProps={{
                                style:{fontSize:'0.75rem'}
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid item sx={{pt:'1%!important'}} xs={isMobile?12:3}>
                        <Typography sx={{color:'#3e3e3e',fontWeight:'500',fontSize:'0.75rem'}}>For</Typography>
                        <TextField
                            fullWidth
                            value={programData?.isProvider?'Service Expert':'Customer'}
                            size='small'
                            inputProps={{
                                style:{fontSize:'0.75rem'}
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid item sx={{pt:'1%!important'}} xs={isMobile?12:3}>
                        <Typography sx={{color:'#3e3e3e',fontWeight:'500',fontSize:'0.75rem'}}>Reward Value</Typography>
                        <TextField
                            fullWidth
                            value={programData?.rewardValue}
                            size='small'
                            inputProps={{
                                style:{fontSize:'0.75rem'}
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid item sx={{pt:'1%!important'}} xs={isMobile?12:3}>
                        <Typography sx={{color:'#3e3e3e',fontWeight:'500',fontSize:'0.75rem'}}>Max. Reward Value</Typography>
                        <TextField
                            fullWidth
                            value={programData?.maxRewardValue}
                            size='small'
                            inputProps={{
                                style:{fontSize:'0.75rem'}
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid item sx={{pt:'1%!important'}} xs={isMobile?12:3}>
                        <Typography sx={{color:'#3e3e3e',fontWeight:'500',fontSize:'0.75rem'}}>Booking Amount</Typography>
                        <TextField
                            fullWidth
                            value={programData?.bookingAmount}
                            size='small'
                            inputProps={{
                                style:{fontSize:'0.75rem'}
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid item sx={{pt:'1%!important'}} xs={isMobile?12:5}>
                        <Typography sx={{color:'#3e3e3e',fontWeight:'500',fontSize:'0.75rem'}}>Start Date</Typography>
                        <TextField
                            fullWidth
                            value={programData.expireDate ? formatDateTime(programData?.issueDate,'date'):''}
                            size='small'
                            inputProps={{
                                style:{fontSize:'0.75rem'}
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid item sx={{pt:'1%!important'}} xs={isMobile?12:5}>
                        <Typography sx={{color:'#3e3e3e',fontWeight:'500',fontSize:'0.75rem'}}>Expiry Date</Typography>
                        <TextField
                            fullWidth
                            value={programData.expireDate ? formatDateTime(programData?.expireDate,'date'):''}
                            size='small'
                            inputProps={{
                                style:{fontSize:'0.75rem'}
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid item sx={{pt:'1%!important'}} xs={isMobile?12:2}>
                    <Typography sx={{color:'#3e3e3e',fontWeight:'500',fontSize:'0.75rem'}}>Offer Period</Typography>
                        
                    <TextField
                        fullWidth
                        // label="Enter Service Short Title*"
                        value={`${programData?.rewardExpiryDays} days`}
                        variant="outlined"
                        size="small"
                        inputProps={{
                            style:{
                                fontSize:'0.75rem'
                            }
                        }}
                        disabled
                    />
                    </Grid>
                    <Grid item sx={{pt:'1%!important'}} xs={isMobile?12:6}>
                        <Typography sx={{color:'#3e3e3e',fontWeight:'500',fontSize:'0.75rem'}}>Program Title</Typography>
                        <TextField
                            fullWidth
                            value={programData?.title}
                            size='small'
                            inputProps={{
                                style:{fontSize:'0.75rem'}
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid item sx={{pt:'1%!important'}} xs={12}>
                        <Typography sx={{color:'#3e3e3e',fontWeight:'500',fontSize:'0.75rem'}}>Program Description</Typography>
                        <TextField
                            fullWidth
                            value={programData?.description}
                            size='small'
                            inputProps={{
                                style:{fontSize:'0.75rem'}
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid item sx={{pt:'1%!important'}} xs={12}>
                        <Typography sx={{color:'#3e3e3e',fontWeight:'500',fontSize:'0.75rem'}}>Web Url</Typography>
                        <TextField
                            fullWidth
                            value={programData?.web_url}
                            size='small'
                            inputProps={{
                                style:{fontSize:'0.75rem'}
                            }}
                            disabled
                        />
                    </Grid>
                    {/* <Grid item sx={{pt:'1%!important'}} xs={isMobile?12:4}>
                        <Typography sx={{color:'#3e3e3e',fontWeight:'500',fontSize:'0.75rem'}}>Status</Typography>
                        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                            <FormControl fullWidth>
                                <Select
                                    size='small'
                                    value={programData?.status}
                                    disabled={!editStatus}
                                    sx={{
                                        // minHeight: '0px',
                                        // height: '1.6rem',
                                        fontSize: '0.75rem',
                                        '& .MuiSelect-select': {
                                        // padding: '0 8px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        },
                                    }}
                                >
                                    <MenuItem value={true}>Active</MenuItem>
                                    <MenuItem value={false}>In-Active</MenuItem>
                                </Select>
                            </FormControl>                            
                            <PermissionGuard feature='reward-program' permission='canEdit'>
                                <IconButton size='small' color={editStatus?'error':'warning'} onClick={()=>setEditStatus(!editStatus)}>
                                    {editStatus ?<Cancel/>:<Edit/>}
                                </IconButton>
                            </PermissionGuard> 
                        </Box>
                    </Grid> */}
                    <StatusIndicator  id={programData.id} activeStatus={programData.status} fetchList={fetchList}/>
                    <DisplayIndicator  id={programData.id} displayStatus={programData.isDisplay} fetchList={fetchList}/>
                </Grid>
            </Box>
        </>
    )
}

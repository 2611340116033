import { AccountCircle, Assignment, Check, Close, CloseOutlined, HighlightOffRounded, HourglassTop, PersonOutline } from '@mui/icons-material';
import { Avatar, Box, Card, Chip, Dialog,Button, DialogContent, IconButton, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Divider } from '@mui/material';
import React, { useContext, useState } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { green, pink } from '@mui/material/colors';
import { BookingDetailContext } from '../AddBooking';
import { useNavigate } from 'react-router-dom';
import { StepperContext } from '../BookingForm';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius:'1rem'
};
dayjs.extend(customParseFormat);

const BookingResponse = () => {
  const {bookingData} = useContext(BookingDetailContext);
  const {activeStep, setActiveStep,completed, setCompleted,handleNext,handleBack,handleComplete,handleReset,bookingResponseDetails,setBookingResponseDetails} = useContext(StepperContext);
  
  const navigate = useNavigate();

  const [open, setOpen] = useState(true);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    handleReset();
    setBookingResponseDetails({});
    setOpen(false);
  };

  return (
    <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 400 ,textAlign:'center'}}>
            <Box sx={{display:'flex',justifyContent:'center'}}>
                <Avatar sx={{ bgcolor: green[500],width:'10vh',height:'10vh',mt:'-15%',border:'.3rem solid #fff' }}>
                    <Check sx={{fontSize:'3rem'}}/>
                </Avatar>
                {/* <Avatar/> */}
            </Box>
           <Box sx={{my:'10%'}}>
            <Box sx={{textAlign:'center',p:''}}>
                  <Typography sx={{fontWeight:600,color:'#181818'}}>Booking successful</Typography>
                  <HourglassTop sx={{color:'#AA7118',my:'5%'}}/>
                  <Typography sx={{fontWeight:600,color:'#262626'}}>Booking ID : <Box component={'span'} sx={{color:'#1976D2'}}>{bookingResponseDetails?.data?.orderDetails?.bookingNumber||''}</Box></Typography>
                  <Typography variant='caption' sx={{color:'#494949',fontWeight:500}}>Pending Confirmation by the partner</Typography>
              
            </Box>
            <Divider sx={{my:'5%',borderBlockStyle:'dashed',borderBlockWidth:'1px'}}/>
            <Box>
                  <Typography sx={{fontWeight:600,color:'#262626'}}>Service Date : <Box component={'span'} sx={{color:'#494949'}}>{bookingResponseDetails?.data?.orderDetails?.serviceDate}</Box></Typography>
                  <Typography sx={{fontWeight:600,color:'#262626',my:'1%'}}>Service Time : <Box component={'span'} sx={{color:'#494949'}}>{dayjs(bookingResponseDetails?.data?.orderDetails?.bookingStartTime, 'HH:mm:ss').format('h:mm A')||''} - {dayjs(bookingResponseDetails?.data?.orderDetails?.bookingEndTime, 'HH:mm:ss').format('h:mm A')||''}</Box></Typography>
                  <Typography sx={{fontWeight:600,color:'#262626'}}>Expert : <Box component={'span'} sx={{color:'#494949'}}>{bookingData?.serviceExpertProfile?.firstName||''} {bookingData?.serviceExpertProfile?.middleName||''} {bookingData?.serviceExpertProfile?.lastName||''}</Box></Typography>
            </Box>
           </Box>
           <Box sx={{justifyContent:'center',display:'flex',gap:2,mt:'5%'}}>
              <Button variant='contained' size='small' onClick={()=>handleClose()}>
                  New Booking
              </Button>
              <Button variant='contained' size='small' onClick={()=>navigate('/admin/bookings')}>
                  Finish
              </Button>
          </Box>
        </Box>
      </Modal>
  );
}

export default BookingResponse;

import { Box, Button,Card,Checkbox,Chip, FormControl, Grid,ListItemText,MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import {FormProvider,Controller, useForm } from 'react-hook-form';
import CancelIcon from '@mui/icons-material/Cancel';
import { useParams } from 'react-router-dom';
import { ProviderServices } from '../CrudServices';
import StoreFrontImage from './StoreFrontImage';
import { UserProfileContext } from '../UserProfile';
import Swal from 'sweetalert2';
import Loading from '../../../components/Loader/Loading';
import StoreFrontGallery from './StoreFrontGallery';
import StorefrontWorkImages from './StorefrontWorkImages';

const StorefrontDetails = () => {
    const { id } = useParams();
    const {storeFrontData,setStoreFrontData} = useContext(UserProfileContext);
    const {control,formState:{isDirty},watch,setValue,handleSubmit,reset} = useForm({
        mode:'onChange',
        defaultValues:storeFrontData,
    }) ;

    const [isSubmitted,setIsSubmitted] = useState(false);
    const [categoryList,setCategoryList] = useState([]);
    
    useEffect(()=>{
        const fetchCategoryList= async()=>{
            try {
              const categoryListResponse = await ProviderServices.categoryList();
              // console.log("category list Response:",categoryListResponse);
              if(categoryListResponse.success===1){
                setCategoryList(categoryListResponse.data)
              }
            } catch (error) {
              
            }
          }
          fetchCategoryList();
    },[]);
    useEffect(()=>{
        if (storeFrontData) {
            reset(storeFrontData);
        }
    },[storeFrontData,reset])

    const onImageUpload = (data)=>{
        
        // console.log(" image upload data:",data);
        setValue('image',data.imageName,{shouldDirty:true,shouldValidate:'true'})
    }
    const onSubmitFormData = async(data)=>{
        setIsSubmitted(true);
        let storefrontCreationResponse = "";
        // console.log(" submitted storefront details Data:",data);
        try {
            if(storeFrontData.id>0){
                const {providerId,name,image,gallery,description,categories}= data;
                const requiredData = {providerId,name,image,gallery,description,categories};
                storefrontCreationResponse = await ProviderServices.updateStorefrontData(requiredData,storeFrontData.id);
                // console.log(" storefront updation response:",storefrontCreationResponse);
            }else{
                storefrontCreationResponse = await ProviderServices.createStorefront(data);
                // console.log(" storefront creation response:",storefrontCreationResponse);
            }

            if (storefrontCreationResponse.success=='1') {
                const {id,name,image,gallery,description,StorefrontCategories} =storefrontCreationResponse.data;
                const categories =  StorefrontCategories?.map(item => String(item.categoryId));
                setStoreFrontData({
                    id,name,image,
                    gallery:[],
                    description,
                    categories,
                    providerId:data.providerId
                })
                setIsSubmitted(false)
                Swal.fire(storefrontCreationResponse.message,'','success');

            } else {
                setIsSubmitted(false)
                Swal.fire('Internal server error...contact to service provider','','error');
            }
        } catch (error) {
            setIsSubmitted(false)            
        }
    }
  return (
    <Grid container spacing={1} component={'form'} >
        {isSubmitted && <Loading/>}
        {/* =======================Basic Info section================== */}
        <Grid item xs={12} lg={7}>
            <Card sx={{}}>
                <Box sx={{p:'.5% 2%',border:'2px solid lightGray',bgcolor:'lightGray'}}>
                    <Typography sx={{fontWeight:'600',fontSize:'0.85rem'}}>Basic Info</Typography>
                </Box>
                <Grid container spacing={1} sx={{px:'2%'}}>
                    <Grid item xs={12} lg={8}>
                        <Box sx={{my:'1%'}}>
                            <Typography sx={{fontWeight:'600', fontSize:'0.75rem'}}>Storefront Name<span style={{color:'red'}}>*</span></Typography>
                            <Controller
                                name='name'
                                control={control}
                                rules={{
                                    required: "required",
                                    pattern: {
                                        value: /^[a-zA-Z0-9&.\-' ]{2,100}$/, // Pattern for Business Name
                                        message: "Invalid Storefront name format",
                                    },
                                    minLength: {
                                        value: 2,
                                        message: "Storefront name should be at least 2 characters long",
                                    },
                                    maxLength: {
                                        value: 100,
                                        message: "Storefront name should not exceed 100 characters",
                                    },
                                }}
                                render={({field,fieldState:{error}})=>(
                                    <TextField
                                        {...field}
                                        fullWidth
                                        sx={{}}
                                        size='small'
                                        InputProps={{
                                            style:{
                                                // height:'2rem',
                                            },
                                            inputProps:{
                                                style:{
                                                    fontSize:'0.75rem',
                                                },
                                            }   
                                        }}
                                        error={!!error}
                                        // helperText={error && error?.message}
                                    /> 
                                )}  
                            />                    
                        </Box>
                        <Box sx={{}}>
                            <Typography sx={{fontWeight:'600',fontSize:'0.75rem'}}>Service Categories <span style={{color:'red'}}>*</span></Typography>
                            <Controller
                                name='categories'
                                control={control}
                                rules={{required:'select category'}}
                                defaultValue={[]}
                                render={({ field, fieldState: { error } }) => (
                                    <FormControl size="small" sx={{ minWidth: "100%" }}>
                                    <Select
                                        {...field}
                                        size='small'
                                        multiple
                                        renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                            <Chip
                                                key={value}
                                                label={categoryList.find(opt => opt.id.toString() === value)?.title}
                                                size="small"
                                                sx={{ fontSize: '0.6rem',
                                                    '& .MuiChip-deleteIcon': {
                                                        color: '#ce0e0e',
                                                        '&:hover': {
                                                        color: 'red',
                                                        },
                                                    },
                                                }}
                                                onDelete={(event) => {
                                                    event.stopPropagation();
                                                    const newValue = field.value.filter(item => item !== value);
                                                    field.onChange(newValue);
                                                }}
                                                deleteIcon={
                                                    <CancelIcon sx={{color:'red'}} onMouseDown={(event) => event.stopPropagation()} />
                                                }
                                            />
                                            ))}
                                        </Box>
                                        )}
                                        error={!!error}
                                        onChange={(event) => {
                                        const selectedValues = event.target.value.map(String);
                                        field.onChange(selectedValues);
                                        }}
                                        value={field.value || []}
                                        sx={{
                                        '& .MuiSelect-select': {
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            gap: 0.5,
                                            // minHeight: '2rem',
                                            alignItems: 'center'
                                        }
                                        }}
                                    >
                                        {categoryList.map((option) => (
                                        <MenuItem key={option.id} value={option.id.toString()} 
                                        sx={{
                                            p:'.5rem',
                                            minHeight: '0',
                                            '& .MuiCheckbox-root': {
                                              // Control Checkbox size
                                              transform: 'scale(0.7)', // Adjust scale as needed
                                              padding: '0'
                                            },
                                            '& .MuiListItemText-primary': {
                                              // Control ListItemText size
                                              fontSize: '0.75rem',
                                            }
                                          }}>
                                            <Checkbox checked={field.value.indexOf(option.id.toString()) > -1} size='small' sx={{p:0,pr:1}}/>
                                            <ListItemText primary={option.title} sx={{fontSize:'0.5rem'}} 
                                                primaryTypographyProps={{
                                                    sx: {
                                                        fontSize: '0.75rem',
                                                        fontWeight: 400
                                                    }
                                                }}  
                                            />
                                        </MenuItem>
                                        ))}
                                    </Select>
                                    </FormControl>
                                )}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <StoreFrontImage onImageUpload = {onImageUpload} />
                    </Grid>
                </Grid>
                <Box sx={{px:'2%'}}>                                       
                    <Box sx={{my:'1%'}}>
                        <Typography sx={{fontWeight:'600', fontSize:'0.75rem'}}>Description<span style={{color:'red'}}>*</span></Typography>
                        <Controller
                            name='description'
                            control={control}
                            rules={{ required: "Required" }}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    size='small'
                                    multiline
                                    rows={3}
                                    // disabled={disabled}
                                    InputProps={{ inputProps: { style: { fontSize:'0.75rem' } } }}
                                    error={!!error}
                                />
                            )}
                        />                  
                    </Box>
                </Box>
            </Card>
            <Box sx={{textAlign:'center',width:'100%',my:'1%'}}>
                <Button disabled={!isDirty || isSubmitted} variant='contained' onClick={handleSubmit(onSubmitFormData)} size='small'>
                    {storeFrontData.id>0 ? 'Update Details':'Save Details'}
                </Button>
            </Box>
        </Grid>
        {/* =======================Image section================== */}
        <Grid item xs={12} lg={5}>
          <Card sx={{}}>
                <Box sx={{p:'.5% 2%',border:'2px solid lightGray',bgcolor:'lightGray'}}>
                    <Typography sx={{fontWeight:'600',fontSize:'0.85rem'}}>Storefront Gallery</Typography>
                </Box>
                <StorefrontWorkImages/>
            </Card>
        </Grid>
      </Grid>
  );
}

export default StorefrontDetails;

import { Box, Button, Card, CardContent, FormControl, InputLabel, MenuItem, Select, Skeleton, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import Swal from 'sweetalert2';
import { CrudServices } from '../../MasterService/CrudServices';
import { Helpers } from '../../../services/Helpers';
import { DashboardServices } from '../Service';

const months = [
    {
        month:1,
        label:'January'
    },
    {
        month:2,
        label:'February'
    },
    {
        month:3,
        label:'March'
    },
    {
        month:4,
        label:'April'
    },
    {
        month:5,
        label:'May'
    },
    {
        month:6,
        label:'June'
    },
    {
        month:7,
        label:'July'
    },
    {
        month:8,
        label:'August'
    },
    {
        month:9,
        label:'September'
    },
    {
        month:10,
        label:'October'
    },
    {
        month:11,
        label:'November'
    },
    {
        month:12,
        label:'December'
    },
]

const TopServices = () => {
    const [selectedMonth, setSelectedMonth] = useState(dayjs().format('M'));
    const [rowsPerPage, setRowsPerPage] = useState(10);

    // Generate past week dates including today
    const pastWeekDates = useMemo(() => {
        const dates = [];
        for (let i = 6; i >= 0; i--) {
        const date = dayjs().subtract(i, 'day');
        dates.push({
            value: date.format('DD-MM-YYYY'),
            label: date.format('MMM D')
        });
        }
        return dates;
    }, []);

    const handleDateChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    const [loading, setLoading] = useState(false);
    const [serviceList, setServiceList] = useState([]);

    //modify and sum up the booking count for services with same ID
    const TopServicesBookings = (services) => {
        // Process and combine booking counts for services with same ID
        const processedServices = (() => {
          const serviceMap = new Map();
      
          services.forEach(service => {
            const key = `${service.providerServices.childServiceId}-${service.providerServices.servicesMasterId}`;
            
            if (!serviceMap.has(key)) {
              serviceMap.set(key, {
                ...service,
                combinedCurrentMonth: parseInt(service.bookingCount.currentMonth || 0),
                combinedPreviousMonth: parseInt(service.bookingCount.previousMonth || 0)
              });
            } else {
              const existingService = serviceMap.get(key);
              existingService.combinedCurrentMonth += parseInt(service.bookingCount.currentMonth || 0);
              existingService.combinedPreviousMonth += parseInt(service.bookingCount.previousMonth || 0);
            }
          });
      
          // Convert map to array and slice according to rowsPerpage and sort by combined current month bookings
          return Array.from(serviceMap.values())
            .sort((a, b) => b.combinedCurrentMonth - a.combinedCurrentMonth)
            .slice(0, rowsPerPage);
        })();

        return processedServices;
    };
        
    const fetchServiceList = async ()=>{
        setServiceList([]);
        try {
            setLoading(true)
            const listResponse = await DashboardServices.topServices({
            //   limit: rowsPerPage,
              month: selectedMonth,
            });
            if (listResponse.success=='1') {
              
              setServiceList(TopServicesBookings(listResponse.data.topServices));
            } 
            else {
            //   Swal.fire('Request failed','','error')
            }
        } catch (error) {
            Swal.fire(error.message,'','error')
        }
        setLoading(false)
    };
    useEffect(() => {
        fetchServiceList();
    }
    , [selectedMonth]);
  return (
    <Card sx={{flex:1 }} >
        <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between',borderBottom:'1px solid lightGray',p:'2% 3%'}}>
            <Typography gutterBottom sx={{ flex:2,fontWeight:600,color:'#3e3e3e',fontSize:'.85rem' }} >
                Top Booking Services
            </Typography>
            <FormControl sx={{flex:1}} variant="outlined">
                <InputLabel sx={{fontSize:'0.65rem',lineHeight: '2.4375em'}}>Selected Month</InputLabel>
                <Select
                    size="small"
                    value={selectedMonth}
                    onChange={handleDateChange}
                    sx={{
                        minHeight: '0px',
                        height: '1.6rem',
                        fontSize: '0.75rem',
                        fontWeight: '600',
                    '& .MuiSelect-select': {
                        display: 'flex',
                        alignItems: 'center',
                    },
                    }}
                    label='Selected Month'
                >
                    {months.map((date) => (
                    <MenuItem key={date.month} value={date.month} sx={{fontSize:'small'}}>
                        {date.label}
                    </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
        <CardContent sx={{p:'0!important',pb:'0!important',position:'relative',height:'92.5%'}} >
            {loading && 
            <Box sx={{position:'absolute',}}>
                        
                {/* according to number of rowsper page same no. of skeleton */}
                {[...Array(rowsPerPage)].map((e, i) => (
                    <Box key={i} sx={{display:'flex',justifyContent:'space-between',p:'.5% 3%'}}>
                        <Stack spacing={1} direction="row" sx={{alignItems:'center'}}>
                        {/* image */}
                            <Skeleton variant="rectangular" width={'2rem'} height={'2rem'} />
                        <Box>
                            <Skeleton variant="rectangular" width={350} height={'2.5rem'} />    
                        </Box>
                    </Stack>
                        
                    </Box>
                ))}
            </Box>        
            }

            <Box sx={{display:'flex',flexDirection:'column',justifyContent:`${serviceList.length>0? 'space-between':'center'}`,alignItems:`${serviceList.length === 0 && 'center'}`,height:'100%'}}>
                {serviceList.length>0 ? 
                <Box>
                    {serviceList.map((service,index)=>(
                        <Box key={index} sx={{display:'flex',justifyContent:'space-between',p:'1.85% 3%'}}>
                            <Stack spacing={1} direction="row" sx={{alignItems:'center'}}>
                                {/* image */}
                                {service?.providerServices?.childService?.images ? (
                                    <img 
                                    //   src={service.images} 
                                    src={`${Helpers.ImagePathUrl()}/service/sm/${service?.providerServices?.childService?.images}`}
                                    alt={service.title}
                                    style={{ width: '2rem', height: '2rem', objectFit: 'cover' ,borderRadius:'.25rem'}}
                                    />
                                ) : (
                                    <Skeleton variant="rectangular" width={'2rem'} height={'2rem'} animation={false}/>
                                )}
                                <Box>
                                    <Typography variant="body2" sx={{width:'max-content',overflow:'hidden' ,fontWeight: 600,color:'#3e3e3e',fontSize:'.7rem' }}>{service?.providerServices?.childService?.title}</Typography>
                                    <Typography sx={{ justifyContent:'center',fontSize:'.5rem',fontWeight:600,color:'#475569' }}>Total Booking : {service?.bookingCount?.currentMonth || 0}</Typography>
                                </Box>
                            </Stack>
                        </Box>
                    ))
                    }                
                </Box> 
                
                :
                !loading &&<Box>
                    <Typography variant="body2" sx={{fontWeight:600,color:'#3e3e3e',fontSize:'.85rem'  }} >
                        No Data Found
                    </Typography>
                </Box>
                }     
                {serviceList.length>0 &&
                    <Box sx={{textAlign:'end',p:'1%'}} > 
                        <Button onClick={()=>window.open('/admin/services','_blank')} size='small'>View All</Button>
                    </Box> 
                }    
            </Box>     
        </CardContent>
    </Card>
  );
}

export default TopServices;

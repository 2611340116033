import { Box, Button, Card, CardContent, FormControl, InputLabel, MenuItem, Select, Skeleton, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import Swal from 'sweetalert2';
import { CrudServices } from '../../MasterService/CrudServices';
import { Helpers } from '../../../services/Helpers';
import { DashboardServices } from '../Service';

const months = [
    {
        month:1,
        label:'January'
    },
    {
        month:2,
        label:'February'
    },
    {
        month:3,
        label:'March'
    },
    {
        month:4,
        label:'April'
    },
    {
        month:5,
        label:'May'
    },
    {
        month:6,
        label:'June'
    },
    {
        month:7,
        label:'July'
    },
    {
        month:8,
        label:'August'
    },
    {
        month:9,
        label:'September'
    },
    {
        month:10,
        label:'October'
    },
    {
        month:11,
        label:'November'
    },
    {
        month:12,
        label:'December'
    },
]
const TopCategories = () => {
    const [selectedMonth, setSelectedMonth] = useState(dayjs().format('M'));
    const [rowsPerPage, setRowsPerPage] = useState(7);


    const handleDateChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    const [loading, setLoading] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const fetchCategoryList = async ()=>{
        setCategoryList([]);
        try {
            setLoading(true)
            const listResponse = await DashboardServices.topCategories({
              limit: rowsPerPage,
              month: selectedMonth,
            });
            if (listResponse.success=='1') {
                //sum of booking count for same serviceMasterId
                const topCategoriesData = listResponse.data.topCategories.reduce((acc, item) => {
                    
                    const existingItem = acc.find((accItem) => accItem?.category?.serviceCategoryId === item?.category?.serviceCategoryId);
                    if (existingItem) {
                        existingItem.bookingCount = parseInt(existingItem.bookingCount)+ parseInt(item.bookingCount);
                    } else {
                        if(item.category){
                            acc.push(item);
                        }
                    }
                    return acc
                    .sort((a, b) => b.bookingCount - a.bookingCount)
                    .slice(0, rowsPerPage);
                }
                , []);
              setCategoryList(topCategoriesData);
            } 
            else {
            //   Swal.fire('Request failed','','error')
            }
        } catch (error) {
            Swal.fire(error.message,'','error')
        }
        setLoading(false)
    };
    useEffect(() => {
        fetchCategoryList();
    }
    , [selectedMonth]);
  return (
    <Card sx={{flex:1 }} >
        <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between',borderBottom:'1px solid lightGray',p:'2% 3%'}}>
            <Typography gutterBottom sx={{ flex:2,fontWeight:600,color:'#3e3e3e',fontSize:'.85rem' }} >
                Top Booking Categories
            </Typography>
            <FormControl sx={{flex:1}} variant="outlined">
                <InputLabel sx={{fontSize:'0.65rem',lineHeight: '2.4375em'}}>Selected Month</InputLabel>
                <Select
                    size="small"
                    value={selectedMonth}
                    onChange={handleDateChange}
                    sx={{
                        minHeight: '0px',
                        height: '1.6rem',
                        fontSize: '0.75rem',
                        fontWeight: '600',
                    '& .MuiSelect-select': {
                        display: 'flex',
                        alignItems: 'center',
                    },
                    }}
                    label='Selected Month'
                >
                    {months.map((date) => (
                    <MenuItem key={date.month} value={date.month} sx={{fontSize:'small'}}>
                        {date.label}
                    </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
        <CardContent sx={{p:'0!important',pb:'0!important',position:'relative',height:'90%'}} >
            {loading && 
            <Box sx={{position:'absolute',}}>
                        
                {/* according to number of rowsper page same no. of skeleton */}
                {[...Array(rowsPerPage)].map((e, i) => (
                    <Box key={i} sx={{display:'flex',justifyContent:'space-between',p:'.5% 3%'}}>
                        <Stack spacing={1} direction="row" sx={{alignItems:'center'}}>
                        {/* image */}
                            <Skeleton variant="rectangular" width={'2rem'} height={'2rem'} />
                        <Box>
                            <Skeleton variant="rectangular" width={350} height={'2.5rem'} />    
                        </Box>
                    </Stack>
                        
                    </Box>
                ))}
            </Box>        
            }
            <Box sx={{display:'flex',flexDirection:'column',justifyContent:`${categoryList.length>0? 'space-between':'center'}`,alignItems:`${categoryList.length === 0 && 'center'}`,height:'100%'}}>
                {categoryList.length>0 ? 
                <Box>
                {categoryList.map((item,index)=>(
                    <Box key={index} sx={{display:'flex',justifyContent:'space-between',borderBottom:'1px solid lightGray',p:'.5% 3%'}}>
                        <Stack spacing={1} direction="row" sx={{alignItems:'center'}}>
                            {/* image */}
                            {item?.category?.servCatId?.image ? (
                                <img 
                                //   src={item.images} 
                                src={`${Helpers.ImagePathUrl()}/category/sm/${item?.category?.servCatId?.image}`}
                                alt={item.title}
                                style={{ width: '2rem', height: '2rem', objectFit: 'cover',borderRadius:'.25rem' }}
                                />
                            ) : (
                                <Skeleton variant="rectangular" width={'2rem'} height={'2rem'} animation={false}/>
                            )}
                            <Box>
                                <Typography variant="body2" sx={{ fontWeight: 600,color:'#3e3e3e',fontSize:'.7rem' }}>{item?.category?.servCatId?.title}</Typography>
                                <Typography variant="caption" sx={{ justifyContent:'center',fontSize:'.5rem',fontWeight:600,color:'#475569' }}>Category : {item?.category?.servCatId?.parentCategory.title}</Typography>
                            </Box>
                        </Stack>
                    </Box>
                ))
                }
                </Box>
                :
                !loading && <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',height:'100%'}}>
                    <Typography variant="body2" sx={{fontWeight:600,color:'#3e3e3e',fontSize:'.85rem'  }} >
                        No Data Found
                    </Typography>
                </Box>
                }
                {categoryList.length>0 &&
                    <Box sx={{textAlign:'end',p:'1%'}} > 
                        <Button onClick={()=>window.open('/admin/category','_blank')} size='small'>View All</Button>
                    </Box> 
                }
            </Box>            
        </CardContent>
    </Card>
  );
}

export default TopCategories;

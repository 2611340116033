import { commonRequest } from "../../services/ApiCall";
import { Helpers } from "../../services/Helpers";

export const CategoryService = {
    allCategories : async (data) =>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/service-category`,{}, Helpers.token());
    },

    addCategory : async (data) =>{
        return await commonRequest("POST", `${Helpers.URL()}/api/admin/service-category/`,data, Helpers.token());
    },

    updateCategory : async (id, data) =>{
        return await commonRequest("PUT",  `${Helpers.URL()}/api/admin/service-category/${id}`, data, Helpers.token());
    },
    uploadImage : async (data) =>{
        return await commonRequest("POST",  `${Helpers.URL()}/api/upload/uploadSingleImage`, data, Helpers.token());
    },
    updateCategoryImage : async (id,data) =>{
        return await commonRequest("PUT",  `${Helpers.URL()}/api/admin/service-category/${id}`, data, Helpers.token());
    },

}

import { Avatar, Box, Button, IconButton } from '@mui/material';
import React, { useContext, useState } from 'react';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ImgUploadDialog from '../../../components/ImgUpload/imgUpload';
import { UserProfileContext } from '../UserProfile';
import { Helpers } from '../../../services/Helpers';
import DefaultStoreFrontImage from'../../../assets/images/storefront-icon.png'

const StoreFrontImage = ({onImageUpload}) => {
    const {storeFrontData,setStoreFrontData} = useContext(UserProfileContext);
    const [imgModalOpen,setImgModalOpen] = useState(false);
    const [uploadedImage,setUploadedImage] = useState(null)
    const imgData = {
        path: "storefront-logo",
        // image_name: `img-${GenerateUniqueVal(1000000)}-${personalDetails.id}.jpeg`,
        img_type: "single"
    };
    const closeModal = () => {
        setImgModalOpen(false);
    };
    const imgUploadResponse = (data) => {
        setUploadedImage(data.imageName);
        onImageUpload(data);
    }
  return (
    <>
        <ImgUploadDialog
            open={imgModalOpen}
            onClose={closeModal}
            imgData={imgData}
            imgUploadResponse={(data) => imgUploadResponse(data)}
        />
        <Box
            sx={{
                width: 'fit-content',
                position: 'relative',
                // marginTop: -8,
                mx: 'auto',
                textAlign:'center',
            }}
        >
            <Avatar               
                src={uploadedImage || storeFrontData.image ? 
                    `${Helpers.ImagePathUrl()}/storefront-logo/md/${uploadedImage?uploadedImage:storeFrontData.image}`
                    : DefaultStoreFrontImage
                }
                // src={`https://cudel.s3.amazonaws.com/staging/profile/md/image-1729158785151.jpg`}
                // alt="storefront_image"
                sx={{ width: 150, height: 150, border: '4px solid white' }}
            />
            <IconButton
                sx={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                backgroundColor: 'white',
                '&:hover': { backgroundColor: 'white' },
                }}
                onClick={() => setImgModalOpen(true)}
            >
            <CameraAltIcon />
          </IconButton>
          {/* <Button variant='outlined' onClick={() => setImgModalOpen(true)} sx={{my:'1.5%'}}>Upload image</Button> */}
        </Box>
    
    </>
  );
}

export default StoreFrontImage;

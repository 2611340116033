import { SaveAlt } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import React from 'react';
import { BookingService } from './Service';
import Swal from 'sweetalert2';
import { formatDateTime } from '../../utils/dateUtils';
import dayjs from 'dayjs';

const ExportAll = (props) => {
  const { paginationModel, columns, statusOptions } = props;

  // Define custom headers and their corresponding data mapping
  const exportMapping = [
    {
      header: 'Booking Id',
      value: (row) => `${row.id}`
    },
    {
      header: 'Booking Number',
      value: (row) => `#${row.bookingNumber}`
    },
    {
      header: 'Booking Date',
      value: (row) => `${row.createdAt ? formatDateTime(row.createdAt) : ''}`
    },
    {
      header: 'Service Date',
      value: (row) => `${row.serviceDate ? formatDateTime(row.serviceDate, 'date') : ''}`
    },
    {
      header: 'Time Slot',
      value: (row) => `${dayjs(row.bookingStartTime, 'HH:mm:ss').format('h:mm A')} - ${dayjs(row.bookingEndTime, 'HH:mm:ss').format('h:mm A')}`
    },
    {
      header: 'Service Status',
      value: (row) => `${row.bookingStatus}`
    },
    {
      header: 'Customer Name',
      value: (row) => `${row?.customerName || ''} (${row.customerId})`
    },
    {
      header: 'Customer Contact No',
      value: (row) => `${row?.customer?.mobileNumber || ''}`
    },
    {
      header: 'Customer Address',
      value: (row) => `${row?.add_address || ''}`
    },
    {
      header: 'Provider Name',
      value: (row) => `${row?.providerName || ''} (${row.providerId})`
    },
    {
      header: 'Provider Contact No',
      value: (row) => `${row?.provider?.mobileNumber || ''}`
    },
    {
      header: 'Total Price',
      value: (row) => `${row.total}`
    },
    {
      header: 'Cancelled By',
      value: (row) => `${row?.cancelBy || ''}`
    },
    {
      header: 'Cancellation Reason',
      value: (row) => `${row?.cancelBy == 'expert' ? (row?.message ||'') : (row?.cancelMessage || '')}`
    }
  ];

  // Custom export function for server-side pagination

  const handleExportAllRows = async (options) => {
    try {
      // Show loading notification
      Swal.fire({
        title: 'Exporting Data',
        text: 'Please wait while we prepare your download...',
        icon: 'info',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
  
      const urlParams = paginationModel.filters.status.map(status => `bookingStatus=${status}`).join('&');
      const listResponse = await BookingService.bookingListToExport({
        filters: {
          searchKey: paginationModel.filters.searchKey,
          status: paginationModel.filters.status.length === statusOptions.length ? 'all' : urlParams,
          sortKey: paginationModel.filters.sortKey,
          sortDirection: paginationModel.filters.sortDirection,
          filterOptions: paginationModel.filters.filterOptions || []
        }
      });
  
      // Prepare export data
      const exportData = listResponse.data.list.map(row => {
                  return exportMapping.reduce((acc, field) => {
                    acc[field.header] = field.value(row);
                    return acc;
                  }, {});
                });
          
                // Create CSV content
                const headers = exportMapping.map(field => `"${field.header}"`).join(',');
                const csvContent = exportData.map(row => 
                  exportMapping.map(field => 
                    `"${String(row[field.header] ?? '').replace(/"/g, '""')}"`
                  ).join(',')
                );
          
                csvContent.unshift(headers);
          
                // Create and download file
                const csvString = csvContent.join('\n');
                const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
                const url = URL.createObjectURL(blob);
          
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Booking-Report.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
          
                Swal.fire({
                  title: 'Export Successful',
                  html: `
                    <p>If download doesn't start automatically, click on provided link:
                    <a href="${url}" download="ServiceExpert-report.csv" style="color: blue; text-decoration: underline;">
                      Download CSV
                    </a></p>
                  `,
                  icon: 'success',
                  showConfirmButton: true,
                  confirmButtonText: 'Close'
                });
          
    } catch (error) {
      console.error('Export failed', error);
      
      Swal.fire({
        title: 'Export Failed',
        text: 'There was an issue exporting the data. Please try again.',
        icon: 'error'
      });
    }
  };
  return (
    <Box>        
        <Button 
            onClick={handleExportAllRows}
            startIcon={<SaveAlt />}
            sx={{textTransform:'none'}}
        >
            Export
        </Button>      
    </Box>
  );
}

export default ExportAll;

// const ExportAll = (props) => {
//     const { paginationModel, columns, statusOptions } = props;
//     // Custom export function for server-side pagination

//     const handleExportAllRows = async (options) => {
//         try {
//           // Show loading notification
//           Swal.fire({
//             title: 'Exporting Data',
//             text: 'Please wait while we prepare your download...',
//             icon: 'info',
//             allowOutsideClick: false,
//             didOpen: () => {
//               Swal.showLoading();
//             }
//           });
      
//           const urlParams = paginationModel.filters.status.map(status => `bookingStatus=${status}`).join('&');
//           const listResponse = await BookingService.bookingListToExport({
//             filters: {
//               searchKey: paginationModel.filters.searchKey,
//               status: paginationModel.filters.status.length === statusOptions.length ? 'all' : urlParams,
//               sortKey: paginationModel.filters.sortKey,
//               sortDirection: paginationModel.filters.sortDirection,
//               filterOptions: paginationModel.filters.filterOptions || []
//             }
//           });
      
//           // Prepare export data
//           const exportData = listResponse.data.list.map(row => {
//             // Custom transformation for specific columns
//             const processedRow = columns.reduce((acc, col) => {
//               if (col.field === 'bookingNumber') {
//                 acc[col.field] = `#${row[col.field]}`;
//               }else if (col.field === 'serviceDate') {
//                 acc[col.field] = formatDateTime(row[col.field], 'date');
  
//               } else if (col.field === 'timeSlot') {
//                 // Format timeSlot with start and end time
//                 acc[col.field] = `${dayjs(row.bookingStartTime, 'HH:mm:ss').format('h:mm A')} - ${dayjs(row.bookingEndTime, 'HH:mm:ss').format('h:mm A')}`;
//               }else if (col.field === 'bookingItems') {
//                 // Format bookingItems with all services separated by comma
//                 if (row.bookingItems && row.bookingItems.length > 0) {
//                   acc[col.field] = row.bookingItems
//                     .map(item => item.serviceName)
//                     .join(', ');
//                 } else {
//                   acc[col.field] = '';
//                 }
//               } else if (col.field === 'createdAt') {
                
//                 acc[col.field] = formatDateTime(row[col.field]);
//               } else if (col.field !== 'action') { // Exclude details column
//                 acc[col.field] = row[col.field] ?? '';
//               }
//               return acc;
//             }, {});
      
//             return processedRow;
//           });
      
//           // Convert to CSV
//           const exportColumns = columns.filter(col => col.field !== 'action'); // Exclude details column
//           const csvContent = exportData.map(row => 
//             exportColumns.map(col => 
//               `"${String(row[col.field] ?? '').replace(/"/g, '""')}"`
//             ).join(',')
//           );
      
//           // Add headers
//           const headers = exportColumns.map(col => `"${col.headerName || col.field}"`).join(',');
//           csvContent.unshift(headers);
      
//           // Create blob
//           const csvString = csvContent.join('\n');
//           const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
//           const url = URL.createObjectURL(blob);
      
//           // Try automatic download
//           const link = document.createElement('a');
//           link.href = url;
//           link.setAttribute('download', 'booking-report.csv');
//           document.body.appendChild(link);
//           link.click();
//           document.body.removeChild(link);
      
//           // Show success with download link as fallback
//           Swal.fire({
//             title: 'Export Successful',
//             html: `
//               <p>If download not start automatically, click on provided link:
//               <a href="${url}" download="all-rows-export.csv" style="color: blue; text-decoration: underline;">
//                 Download CSV
//               </a></p>
//             `,
//             icon: 'success',
//             showConfirmButton: true,
//             confirmButtonText: 'Close'
//           });
      
//         } catch (error) {
//           console.error('Export failed', error);
          
//           // Show error notification
//           Swal.fire({
//             title: 'Export Failed',
//             text: 'There was an issue exporting the data. Please try again.',
//             icon: 'error'
//           });
//         }
//     };
//   return (
//     <Box>        
//         <Button 
//             onClick={handleExportAllRows}
//             startIcon={<SaveAlt />}
//             sx={{textTransform:'none'}}
//         >
//             Export
//         </Button>      
//     </Box>
//   );
// }

// export default ExportAll;

import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import CategoryTabs from "./CategoryTabs";
import MainContentLayout from "../../components/Layout/MainContentLayout";

export default function Category() {
    const menuDispatch = useDispatch();
    // menuDispatch({
    //     type: 'menuSelect',
    //     payload: 'category'
    // });
    useEffect(() => {
        menuDispatch({
            type: 'menuSelect',
            payload: 'category'
        });
    }
    , []);
    return (
        <MainContentLayout>
            <CategoryTabs />
        </MainContentLayout>
    )
}

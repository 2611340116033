import { useState, useEffect } from 'react';
import { Card,Chip, Typography, Divider,MenuItem, Stack, TextField, Grid, Button, Box, Toolbar, TableCell, FormControl, InputLabel, Select, Tooltip, IconButton, Modal, InputAdornment, Skeleton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Swal from 'sweetalert2';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { isMobile } from 'react-device-detect';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { formatDateTime } from '../../utils/dateUtils';
import { useForm, Controller } from 'react-hook-form';
import CustomNoRowsOverlay from '../../components/NoRows';
import DeleteIcon from '@mui/icons-material/Delete';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from '@mui/icons-material/Search';
import AddService from './AddService';
import { CrudServices } from './CrudServices';
import NoRowsLayout from '../../components/Layout/NoRowsLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Helpers } from '../../services/Helpers';
import ViewService from './ViewService';
import { PermissionGuard } from '../../components/PermissionGuard';
import PageHeaderLayout from '../../components/Layout/PageHeaderLayout';
import { Search, Send } from '@mui/icons-material';
import Loading from '../../components/Loader/Loading';
import ExportAll from './filterAndExport/ExportAll';
import MultiColumnFilter from '../../components/Filter/MultiColumnFilter';
import QuickSearchTool from '../../components/QuickSearch/QuickSearchTool';
import Filter from './filterAndExport/Filter';
import { LocalStore } from '../../storage/authStore';
import SacCodeManagement from './SacCodeManagement';

const style = {
    position: 'absolute',
    top: (isMobile) ? '80%' : '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #ddd',
    boxShadow: 24,
    p: 0,
    borderTopLeftRadius: '.5rem',
    borderTopRightRadius: '.5rem',
  };


export default function ServiceList() {
    const loggedUser = LocalStore.getLoggedUserDetails();
    console.log('loggedUser',loggedUser);
    const { control, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const [populateTable, setPopulateTable] = useState(0);
    
    /************************  selected service/subservice data for edit**************************** */
    const [selectedServiceData,setSelectedServiceData] = useState({});

    const [allRowCount, setAllRowCount] = useState(0);
    const [list, setList] = useState([]);
    const [deleteRowData, setDeleteRowData] = useState({});
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 50,
        filters: {
          searchKey: "",
          sortBy:'createdAt',
          sortType:'desc',
          filterOptions: []
        }
    });

  const [loading, setLoading] = useState(false);

  const fetchServiceList = async ()=>{
    try {
        setLoading(true)
        const listResponse = await CrudServices.allServices({
          page: paginationModel.page,
          limit: paginationModel.pageSize,
          filters: {
            searchKey: paginationModel.filters.searchKey,   // Use the title filter from the paginationModel
            sortBy: paginationModel.filters.sortBy,
            sortType: paginationModel.filters.sortType,
            filterOptions: paginationModel.filters.filterOptions || []
          }
        });
        // console.log("Service list Response:",listResponse)
        setValue('status', paginationModel.filters.status)
        if (listResponse.success=='1') {
          setList(listResponse.data.list);
          setAllRowCount(listResponse.data.totalItems);
        } 
        else {
        //   Swal.fire('Request failed','','error')
        }
      } catch (error) {
        Swal.fire(error.message,'','error')
      }
      setLoading(false)
    };
  
  /************************  Manage Use Effect **************************** */
  useEffect(() => {
    fetchServiceList();
  }, [paginationModel, deleteRowData]);
    const [sacList,setSacList] = useState([]);
    const fetchSacList = async () => {
        try {
            const response = await CrudServices.fetchSacCodes();
            console.log("response",response);
            if(response.success == '1'){
                setSacList(response.data);
            }
        } catch (error) {
            console.log("error",error);
        }
    }
    useEffect(() => {
        fetchSacList()
    }, [])
  
    /************************  Table Column Manage **************************** */
    const columns = [
      // { 
      //   field: 'id', 
      //   headerName: 'ID', 
      //   width: 70 
      // },
      // {
      //   field: 'images',
      //   headerName: 'Image',
      //   headerAlign:'center',
      //   flex: .5,
      //   filterable: false,
      //   renderCell: (params) => (
          
      //   ),
      // },
      { 
        field: 'title', 
        headerName: 'Service', 
        headerAlign:'center',
        flex: 2,
        // filterable: false,
        valueGetter: (params) => {
          // Join all subcategory titles into a single searchable string
          const parentServiceTitle = params.row.parentServiceId>0 && params.row.parentService?.title || '';
          const title = params.row.title;
          return parentServiceTitle ? `${title} (${parentServiceTitle})` : title;
        },
        renderCell: (params) => (
          <Box sx={{ display: 'flex',alignItems:'center' ,width:'100%'}}>
            <Box sx={{ width:'15%'}}>
              {params.row.images ? (
                <img 
                //   src={params.row.images} 
                  src={`${Helpers.ImagePathUrl()}/service/sm/${params.row.images}`}
                  alt={params.row.title}
                  style={{ width: '2rem', height: '2rem', objectFit: 'cover' }}
                />
              ) : (
                <Skeleton variant="rectangular" width={35} height={35} animation={false} />
              )}
            </Box>
            <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} sx={{ ml: 1 ,width:'85%'}}>
              <Typography variant="body2" sx={{ fontWeight: 600,color:'#3e3e3e',textAlign:'center',fontSize:'.7rem' }}>{params.row.title} 
                {(loggedUser?.role.title == 'Admin' || loggedUser?.role.title == ' Admin Admin') &&
                  <Box component={'span'} sx={{fontSize:'.6rem',fontWeight:500,color:'#1976d2'}}>
                    {`(${params.row.id})`}
                  </Box>
                }
              </Typography>
              {params.row.parentServiceId>0 && <Typography variant="caption" sx={{ justifyContent:'center',alignItems:'center',fontSize:'.5rem' }}>{`(${params.row.parentService?.title || ''})`}</Typography>}
            </Box>
          </Box>
        ),
      },
      { 
        field: 'category', 
        headerName: 'Category Detail', 
        headerAlign:'center',
        flex: 2,
        sortable: false,
        valueGetter: (params) => {
          // Join all subcategory titles into a single searchable string
          const categoryTitle = params.row.ServiceCategoryRels[0]?.ServiceCategory?.parentCategory.title;
          const subCategoryTitle = params.row.ServiceCategoryRels[0]?.ServiceCategory?.title;
          // return `${subCategoryTitle} (${categoryTitle})`;
          return categoryTitle ? `${subCategoryTitle} (${categoryTitle})` : subCategoryTitle;
          // return categoryTitle;
        },
        renderCell: (params) => (
          <Box sx={{  display: 'flex', flexDirection: 'column', alignItems: 'center',justifyContent:'center' ,width:'100%'}}>
            {/* <Box sx={{ display: 'flex', gap: 1 ,width:'100%'}}> */}
              {/* <Typography sx={{width:'16%',textAlign:'right',fontSize:'.65rem',fontWeight:600,}}>Sub : </Typography> */}
              <Typography variant='body2' sx={{fontWeight:600,fontSize:'0.7rem'}}>{params.row.ServiceCategoryRels[0]?.ServiceCategory?.title}</Typography>
            {/* </Box> */}
            {/* <Box sx={{ display: 'flex', gap: 1 ,width:'100%'}}> */}
              {/* <Typography sx={{width:'16%',textAlign:'right',fontSize:'.65rem',fontWeight:600,}}>Parent : </Typography> */}
              <Typography variant='caption' sx={{fontWeight:400,fontSize:'0.5rem'}}>{`( ${params.row.ServiceCategoryRels[0]?.ServiceCategory?.parentCategory.title} )`}</Typography>
            {/* </Box> */}
            
            {/* {params.row.ServiceCategoryRels.map((rel, index) => (
              <Chip 
                key={index}
                label={rel.ServiceCategory.title} 
                size="small" 
                sx={{ fontSize: '.65rem', margin: '.1rem' }} 
              />
            ))} */}
          </Box>
        ),
      },
      
      { 
        field: 'serviceDescription', 
        headerName: 'Description', 
        headerAlign:'center',
        flex: 2,
        sortable: false,
        renderCell: (params) => (
          <Box sx={{ p: 1 }}>
            <Typography variant="body2" sx={{fontSize:'.75rem'}}>{params.row.description}</Typography>
          </Box>
        ),
      },
      {
        field:'sac',
        headerName:'SAC Code',
        headerAlign:'center',
        flex:1.5,
        sortable: false,
        valueGetter: (params) => {
          const sacCode = params.row.sacId && sacList.find(sac => sac.id == params.row.sacId)?.sac || '';
          return sacCode;
        },
        renderCell: (params) =>(
          <SacCodeManagement serviceData={params.row} sacList={sacList} />
        )
      },
      { 
        field: 'status', 
        headerName: 'Status', 
        headerAlign:'center',
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <Box sx={{ textAlign: 'center' ,width:'100%'}}>
            <Chip 
              label={params.row.isActive ? 'Active' : 'Inactive'} 
              color={params.row.isActive ? 'success' : 'error'} 
              size="small" 
              sx={{fontSize:'0.65rem',height:'fit-content'}}
            />
          </Box>
        ),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        headerAlign:'center',
        flex: 1,
        filterable: false,
        sortable: false,
        renderCell: (params) => (
          <Box sx={{ p: 1, display: 'flex',gap:1 ,justifyContent: 'center',alignItems:'center',width:'100%' }}>
            <IconButton 
              sx={{color: "blue",}}
              onClick={() => modalOpen(params.row,'view')} 
              size="small">
              <RemoveRedEyeIcon sx={{fontSize:'0.95rem'}}/>
            </IconButton>
            <PermissionGuard feature='service' permission='canEdit'>
              <IconButton onClick={() => modalOpen(params.row,'edit')} size="small" color='warning'>
                <EditIcon sx={{fontSize:'0.95rem'}}/>
              </IconButton>
            </PermissionGuard>
            {/* <IconButton onClick={() => deleteService(params.row.id)} size="small">
              <DeleteIcon />
            </IconButton> */}
          </Box>
        ),
      },
    ];
    /************************  Action Handler Related table row **************************** */   
    const handlePaginationChange = (newPagination) => {
        setPaginationModel({
            ...paginationModel,
            page: newPagination.page,
            pageSize: newPagination.pageSize
        });
    }

    function onSubmit(data) {
        const filtersData = getValues();
        setPaginationModel({
            ...paginationModel,
            filters: {
                ...paginationModel.filters,
                searchKey: filtersData.searchKey,
            }
        });
    }

    const clearFilters = () => {
      reset();
      setPaginationModel({
          ...paginationModel,
          filters: {
            ...paginationModel.filters,
            searchKey: "",
            sortBy: 'createdAt',
            sortType: 'desc',
            filterOptions: []
          }
      });
    
      // Trigger clear for MultiColumnFilter
      setClearMultiFilter(true);
  
      // Reset the clear trigger after a short delay
      setTimeout(() => setClearMultiFilter(false), 100);
    }

    /************************  Advance Filter **************************** */
    
  
    const [clearMultiFilter, setClearMultiFilter] = useState(false);

    const handleApplyFilter = (filterData) => {
      // Process filter data
      console.log(filterData);
      setPaginationModel({
        ...paginationModel,
        page: 0, // Reset to first page when applying new filters
        filters: {
          ...paginationModel.filters,
          // Convert MultiColumnFilter options to a format your backend can understand
        filterOptions: filterData.filterOption.filter(filter => filter.fieldName && filter.value) // Remove empty filters
        }
      });
    }

    /************* quick search related **************/
    // Properly structured filter model
    const [filterModel, setFilterModel] = useState({
      items: [],
      quickFilterValues: []
    });

    /******************* Sorting model  ******************************/
    const [sortModel, setSortModel] = useState([
      {
        field: paginationModel.filters.sortBy,
        sort: paginationModel.filters.sortType,
      },
    ]);

    const handleSortingChange = (newSortModel)=>{
      if (newSortModel && newSortModel.length > 0) {
        setSortModel(newSortModel);
        setPaginationModel(prev => ({
          ...prev,
          filters: {
            ...prev.filters,
            sortBy: newSortModel[0].field,
            sortType: newSortModel[0].sort
          }
        }));
      }
    }

    /************************  Modal State Manage **************************** */
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [viewModalOpen, setViewModalOpen] = useState(false);

    /************************ add/Edit/view Modal Manage **************************** */
    const modalOpen = (rowData,action) => {
      
      const manageData =()=>{
        const formattedData = {
          id:rowData?.id,
          title: rowData.title,
          shortTitle: rowData.shortTitle,
          description: rowData.description ,
          image: rowData.images,
          categoryId:rowData?.ServiceCategoryRels[0]?.ServiceCategory.parentId,
          serviceCategoryId: rowData?.ServiceCategoryRels[0]?.serviceCategoryId,
          parentServiceId: rowData.parentServiceId,
          sacId: rowData.sacId,
        }
        setSelectedServiceData(formattedData);
      }
      if(action=='add'){
        setSelectedServiceData({})
        setEditModalOpen(true);
      }else if(action=='edit'){
        manageData();
        setEditModalOpen(true)
      }
      else if(action=='view'){
        manageData();
        setViewModalOpen(true);
      }
    }

    const handleClose = () => {
        fetchServiceList();
        setEditModalOpen(false);
    };

    return (
        <>
            {/* =========== pop up models like edit services and sub services=============== */} 
            <Modal
                open={editModalOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ overflow: "scroll" }}
            >
                <Box sx={style} width={(isMobile) ? "95%" : "50%"}>
                    <AddService 
                        closeEvent={handleClose} 
                        serviceData = {selectedServiceData}
                        sacList = {sacList}
                    />
                </Box>
            </Modal>
            {/* =========== pop up models like view services and sub services=============== */}
            <Modal
                open={viewModalOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ overflow: "scroll" }}
            >
                <Box sx={style} width={(isMobile) ? "95%" : "50%"}>
                    <ViewService 
                      closeEvent={()=>setViewModalOpen(false)} 
                      handleEdit={()=>{
                        setEditModalOpen(true);
                        setViewModalOpen(false);
                      }}
                      serviceData = {selectedServiceData}
                      sacList = {sacList}
                    />
                </Box>
            </Modal>
            
            {/* ================= page title section and containing add service/sub service button============ */}
            {/* <Card sx={{ minWidth: "100%", marginBottom: "10px" }}>
                <Stack direction="row" spacing={2} className="" alignItems={'center'} sx={{p:'2%'}}>
                    <Typography
                        gutterBottom
                        variant='h5'
                        component='div'
                        // sx={{ padding: '20px' }}
                    >
                        Manage Master Services
                    </Typography>
                    <Typography
                        variant='h3'
                        component='div'
                        sx={{ flexGrow: 1 }}
                    ></Typography>
                    
                </Stack>
            </Card> */}
            <PageHeaderLayout headerTitle={'Manage Master Services'}>
              <PermissionGuard feature='service' permission='canCreate'>
                  <Button variant='contained' onClick={() => modalOpen({},'add')} sx={{ height: "30px", marginTop: "10px" }} endIcon={<AddCircleIcon />}>
                    Add Service
                  </Button>
                </PermissionGuard>
            </PageHeaderLayout>
            {/* <Card sx={{ minWidth: "100%", marginBottom: "1%" ,padding:'.5% 1%'}}>
                <Stack direction="row" spacing={2} sx={{justifyContent:'center',alignItems:'center'}}>
                <Typography
                    gutterBottom
                    variant='body1'
                    component='div'
                    sx={{fontSize:'1rem',fontWeight:'600',color:'#3e3e3e'}}
                    // sx={{ padding: '20px' }}
                >
                    Manage Master Services
                </Typography>
                <Typography
                    variant='h3'
                    component='div'
                    sx={{ flexGrow: 1 }}
                />
                
                <PermissionGuard feature='service' permission='canCreate'>
                  <Button variant='contained' onClick={() => modalOpen({},'add')} sx={{ height: "30px", marginTop: "10px" }} endIcon={<AddCircleIcon />}>
                    Add Service
                  </Button>
                </PermissionGuard>
                

                </Stack>
            </Card> */}
            {/* =================== Searching and sorting section ================== */}
            <Card sx={{ minWidth: "100%", marginBottom: ".5%" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1} sx={{ p: '1%' }}>
                        <Grid item xs={12} lg={3}>
                            <Controller
                                name="searchKey"
                                control={control}
                                // defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        placeholder="Search here....."
                                        // label="Search"
                                        size="small"
                                        fullWidth
                                        sx={{
                                          '& .MuiOutlinedInput-root': {                              
                                            paddingLeft: '0px',                              
                                          }
                                        }}
                                        InputProps={{  // Changed from slotProps to InputProps
                                          inputProps:{
                                            style:{
                                                height:'0.6rem',
                                                textTransform:'uppercase',   
                                                fontSize:'x-small'                       
                                            },
                                          },
                                          startAdornment: (
                                              <InputAdornment position="start">
                                                <IconButton>
                                                  <Search sx={{fontSize:'x-small'}}/>
                                                </IconButton>
                                              </InputAdornment>
                                          ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        {/* <Grid item xs={6} lg={2}>
                            <FormControl sx={{ minWidth: "100%" }} size="small">
                                <InputLabel id="demo-simple-select-autowidth-label">Select Status</InputLabel>
                                <Controller
                                    name="status"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            label="Select Status"
                                        >
                                            <MenuItem value="2">All</MenuItem>
                                            <MenuItem value="1">Active</MenuItem>
                                            <MenuItem value="0">Inactive</MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid> */}
                        <Grid item xs={12} lg={2}>
                          <Tooltip title="Search" sx={{ml:1,p:0}}>
                      <IconButton
                        size='small'
                        color="primary"
                        type="submit"
                        sx={{ border: "1px solid", borderRadius: "7px", padding: "1%" ,ml:1}}
                        >
                        <SearchIcon sx={{fontSize:'.85rem'}} />
                      </IconButton>
                          </Tooltip>
                          <Tooltip title="Clear All Filters">
                              <IconButton
                                size='small'
                                color="primary"
                                sx={{ marginLeft: "10px", border: "1px solid", color: "#f00", borderRadius: "7px", padding: "1%" }}
                                onClick={clearFilters}
                              >
                                <RestartAltIcon sx={{fontSize:'.85rem'}}/>
                              </IconButton>
                          </Tooltip>
                        </Grid>
                    </Grid>
                </form>
            </Card>

            <Box sx={{ overflow: "auto" }}>
              {loading && <Loading/>}
                <Box sx={{ width: "100%", display: "table", tableLayout: "fixed", backgroundColor: "#fff", borderRadius: '.5rem' }}>
                  <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between',alignItems:'center',px:'1%' }}>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Filter
                        onApplyFilter={handleApplyFilter}
                        externalClear={clearMultiFilter}
                      />
                      <ExportAll paginationModel={paginationModel} />
                    </Box>
                    <QuickSearchTool filterModel={filterModel} setFilterModel={setFilterModel} />
                  </Box>
                  <DataGrid
                    rows={list}
                    columns={columns}
                    getRowId={(row) => row.id}
                    //checkboxSelection={true}
                    rowCount={allRowCount}  // Use the length of the filtered list
                    pageSizeOptions={[5, 10, 20, 50, 100]}
                    paginationModel={paginationModel}
                    paginationMode="server"
                    onPaginationModelChange={handlePaginationChange}

                    filterModel={filterModel}

                    sortingMode="server"
                    sortModel={sortModel}                  
                    onSortModelChange={handleSortingChange}

                    loading={loading}

                    disableColumnMenu
                    disableRowSelectionOnClick
                    slots={{
                        // toolbar: GridToolbar,                        
                        loadingOverlay: Loading,
                        noRowsOverlay: NoRowsLayout,
                    }}
                    autoHeight
                    getRowHeight={() => 'auto'}
                    sx={{
                        '& .MuiDataGrid-cell': {
                        border: 1,
                        borderRight: 0,
                        borderTop: 0,
                        borderColor: 'lightgrey',
                        display: 'flex',
                        alignItems: 'center',
                        // justifyContent: 'center',
                        },
                        '& .MuiDataGrid-columnHeaders': {
                          borderBottom: '2px solid rgba(224, 224, 224, 1)',
                          borderTop: '2px solid rgba(224, 224, 224, 1)',
                          fontWeight: 600,
                          fontSize: '0.75rem',
                          p:0,
                          height: 'fit-content',
                          minHeight:'0 !important',
                          height:'2rem'
                        },
                        '& .MuiDataGrid-columnHeader': {
                        borderLeft: '1px solid rgba(224, 224, 224, 1)',
                        '&:last-child': {
                            borderRight: 'none',
                        },
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 600,
                        color: '#3e3e3e'
                        },
                        '& .MuiDataGrid-row': {
                        '&:last-child .MuiDataGrid-cell': {
                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                        },
                        '& .MuiDataGrid-cell:last-child': {
                            borderRight: 'none',
                        },
                        },
                        '& .MuiDataGrid-columnSeparator': {
                        display: 'none',
                        },
                    }}
                    />
                </Box>
            </Box>
        </>
    )
}
import React, { createContext, useEffect, useState } from 'react';
import { Tabs, Tab, Box, Card, Stack, Typography, Button, Modal, Grid, Divider, Paper, TextField } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useDispatch } from "react-redux";
import PersonalDetails from './personalDetails/PersonalDetail';
import { useNavigate, useParams } from 'react-router-dom';
import { LocalStore } from '../../storage/authStore';
import getDeviceType from '../../utils/deviceType';
import BankDetails from './bankDetails/BankDetails';
import ServiceDetails from './serviceDetails/ServiceDetails';
import OtrDetails from './otrDetails/OtrDetails';
import SubscriptionDetails from './subscriptionDetails/SubscriptionDetails';
import BusinessDetails from './businessDetails/BusinessDetails';
import WorkingHours from './availability/AvailabilityDetails';
import { ProviderServices } from './CrudServices';
import Swal from 'sweetalert2';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ArrowBack } from '@mui/icons-material';
import  StorefrontWorkImages  from "./storefront/StorefrontWorkImages";
import StorefrontDetails from './storefront/StorefrontDetails';
import Loading from '../../components/Loader/Loading';
import PageHeaderLayout from '../../components/Layout/PageHeaderLayout';
import PaymentDetails from './paymentDetails/PaymentDetails';

const style = {
    position: 'absolute',
    top: (isMobile) ? '80%' : '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: (isMobile) ? "95%" : "25%",
    bgcolor: 'background.paper',
    border: '2px solid #ddd',
    boxShadow: 24,
    p: 2,
};

const UserProfileContext = createContext();
export default function UserProfile() {
    const { id } = useParams();
    const {step} = useParams();
    const deviceType = getDeviceType();

    const navigate = useNavigate();
    const userDetail = LocalStore.getCurrentUserDetails();
    const role = (userDetail) ? userDetail.role : 0;

    // if(role==2){
    //     if(id != userDetail.id){
    //         navigate(`/admin/userProfile/${userDetail.id}`);
    //     }
    // }

    const menuDispatch = useDispatch();
    menuDispatch({
        type: 'menuSelect',
        payload: 'user'
    });
    const [tabValue, setTabValue] = useState(id== '0' ? '0' : step || '1');

    const [providerPersonalDetails, setProviderPersonalDetails] = useState(
        {
            providerId:'',
            firstName: '',
            middleName: '',
            lastName: '',
            email: '',
            dob: '',
            serviceCategories: [],
            waiveVisitCharge: '',
            gender: '',
            image: '',
            isDeviceType: deviceType,      //  'mobile', 'tablet', 'desktop'
            isUserType: 'provider',      // 'user', 'admin', 'provider'
            selfAddress: {
                serviceArea: '2',                // '1: served area, 2: self address'
                fullName: '',
                mobileNumber: '',
                pincode: '',
                latitude: '',
                longitude: '',
                city: '',
                state: '',
                landmark: '',
                address: '',
                addressType: '1'
            },
            serviceAddress: {
                serviceArea: '1',                      // '1: Service Address, 2: Self Address'
                fullName: '',
                mobileNumber: '',
                pincode: '',
                latitude: '',
                longitude: '',
                city: '',
                state: '',
                landmark: '',
                address: '',
                addressType: '1'
            },
        }
    );
    const [otrDetail,setOtrDetail] = useState({});
    const [businessDetailsData,setBusinessDetailsData] = useState({
        providerId: '',
        businessName:'' ,
        businessEmail: '',
        businessMobileNumber: '',
        upiId: [''],
        aadharNumber: '',
        panNumber: '',
        gstNumber: ''
    });
    const [serviceDetailsData,setServiceDetailsData]= useState(
        {
        providerId: '',         // required for admin
        serviceCategoryId: '',
        vistingCharge: '',
        additionalCharge: '',
        cancellationChargeInPercent: '',
        timeLimitForCancellation: '',
        isRefundable: false,
        refundableAmount: '',
        refundableDuration: '',
        services: []
      }
    );
    const [bankDetailsData,setBankDetailsData] = useState({
        providerId: '',
        bankName:'',
        beneficiaryName: '',
        ifscCode: '',
        accountNumber: '',
        accountType: '1'
    })
    const [availabilityDetailData,setAvailabilityDetailData] = useState(
        {
            userId: '',
            dayTimeSlots: [ ]
        }
    )
    const [storeFrontData,setStoreFrontData] = useState(
        {
            providerId: '',
            id:'',
            name: '',
            categories: [],
            image: '',
            gallery: [],
            description: '',
        }
    )
    const [paymentDetailsData,setPaymentDetailsData] = useState(
        {
            id: 0,
            providerId: '',
            txnId: '',
            paymentMethod: '',  //'CASH', 'CARD', 'WALLET', 'ONLINE', 'NEFT', 'UPI'
            paymentType: "REGISTRATION_FEE",
            baseAmount: '',
            discountPercent: 10,
            discount: 0,
            taxableAmount: '',
            igst: 0,
            cgst: 0,
            sgst: 0,
            gstAmount: '',
            totalAmount: '',
            comment:'',
            txnDate: '',
            status: 'PENDING'   //'PENDING', 'COMPLETED', 'FAILED', 'REFUND'
        }
    )

    const [isLoading, setIsLoading] = useState(false)

    const fetchUserDetails = async () => {
        setIsLoading(true);
        const userDetailResponse = await ProviderServices.fetchProviderDetailsById(id);
        if (userDetailResponse.success == '1' && userDetailResponse.data.userType=="4") {
            // console.log("user details:",userDetailResponse.data)
            //set otr details
            setOtrDetail({
                id:userDetailResponse.data.id,
                mobileNumber:userDetailResponse.data.mobileNumber,
                isVerified:userDetailResponse.data.isVerified,
            })
            const {userId,firstName,middleName,lastName,email,dob,gender,profileImage,waiveVisitCharge} = userDetailResponse.data?.UserProfile ?? {};
            const transformAddresses = (currentState, userAllAddress) => {
                // Create a copy of the current state to avoid direct mutation
                const updatedState = { ...currentState };
              
                // Safely iterate through userAllAddress
                userAllAddress?.forEach(addr => {
                  if (addr.serviceArea === '1') {
                    updatedState.serviceAddress = {
                      serviceArea: '1',
                      fullName: addr.fullName || '',
                      mobileNumber: addr.mobileNumber || '',
                      pincode: addr.pincode || '',
                      latitude: addr.latitude || '',
                      longitude: addr.longitude || '',
                      city: addr.city || '',
                      state: addr.state || '',
                      landmark: addr.landmark || '',
                      address: addr.address || '',
                      addressType: addr.addressType || '1'
                    };
                  } else if (addr.serviceArea === '2') {
                    updatedState.selfAddress = {
                      serviceArea: '2',
                      fullName: addr.fullName || '',
                      mobileNumber: addr.mobileNumber || '',
                      pincode: addr.pincode || '',
                      latitude: addr.latitude || '',
                      longitude: addr.longitude || '',
                      city: addr.city || '',
                      state: addr.state || '',
                      landmark: addr.landmark || '',
                      address: addr.address || '',
                      addressType: addr.addressType || '1'
                    };
                  }
                });
              
                return updatedState;
              };
            const { selfAddress, serviceAddress } = transformAddresses(providerPersonalDetails,userDetailResponse.data.userAllAddress??[]);
            
            const providerCat = userDetailResponse.data?.providerCat??[];
            // Extract serviceCategoryId and convert to strings
            const transformserviceCategories = providerCat?.map(item => String(item.serviceCategoryId));
            setProviderPersonalDetails(prev=>{
                return {...prev,
                    // providerId:userId,
                    image:profileImage,
                    firstName,middleName,lastName,email,dob,gender,waiveVisitCharge,
                    isUserType: 'provider',
                    serviceCategories: transformserviceCategories, 
                    serviceAddress,
                    
                    selfAddress,
                }
            });
            // console.log('Self Address:', selfAddress);
            // console.log('Service Address:', serviceAddress);
            const {id,createdBy,updatedBy,createdAt,updatedAt,...restAll} = userDetailResponse.data?.UserBusinessDetail ?? {};
            setBusinessDetailsData(restAll);
            // setServiceDetailsData();
            const providerStorefrontData = userDetailResponse.data?.Storefront;
            // console.log("storefront Data:",providerStorefrontData);
            if (providerStorefrontData) {
                const categories =  providerStorefrontData.StorefrontCategories?.map(item => String(item.categoryId));
                const {StorefrontCategories,...restAll} = providerStorefrontData;
            // setStoreFrontDetailData()
                setStoreFrontData(pre=>({...pre,...restAll,categories}))
            }
            // setStoreFrontData();
            setIsLoading(false);
        }else{
            setIsLoading(false);
            Swal.fire(userDetailResponse,'','error')
        }
    };

    useEffect(() => {
        if (id > 0) {
            fetchUserDetails();
            // setOtrDetail({id})
        }
    }, [id]);
    useEffect(()=>{
        if (otrDetail && otrDetail.id>0) {
            if (otrDetail.isVerified) {
                setTabValue(step ||'1');
            }else{
                setTabValue('0');
            }
            setProviderPersonalDetails(pre=>{
                return {...pre,providerId:otrDetail.id}
            })
            setBusinessDetailsData(pre=>{
                return {...pre,providerId:otrDetail.id}
            })
            setServiceDetailsData(pre=>{
                return {...pre,providerId:otrDetail.id}
            })
            setBankDetailsData(pre=>{
                return {...pre,providerId:otrDetail.id}
            })
            setAvailabilityDetailData(pre=>{
                return {...pre,userId:otrDetail.id}
            })
            setStoreFrontData(pre=>{
                return {...pre,providerId:otrDetail.id}
            })
            setPaymentDetailsData(pre=>{
                return {...pre,providerId:otrDetail.id}
            })
        }
        
    },[otrDetail]);
    
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const handleFormSubmit = (e) => {
        e.preventDefault();
    };
    const handlePreviousClick = () => {
        setTabValue('0');
    };
    const handlePreviousClickForAdd = () => {
        setTabValue(1);
    };



    const handlePersonalSubmit = (data) => {
        setProviderPersonalDetails(data);
    };

    const handleNextTab = (tab) => {
        if(id>0 || otrDetail?.id){
            setTabValue(tab);
        }
    }

    const handlePrivousTab = (tab) => {
        setTabValue(tab);
    }
    return (
        <UserProfileContext.Provider value={{providerPersonalDetails,setProviderPersonalDetails,businessDetailsData,setBusinessDetailsData,setTabValue,serviceDetailsData,setServiceDetailsData,bankDetailsData,setBankDetailsData,availabilityDetailData,setAvailabilityDetailData,storeFrontData,setStoreFrontData,handleNextTab,otrDetail,fetchUserDetails,paymentDetailsData,setPaymentDetailsData}}>
            {isLoading && <Loading/>}
            <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
                <PageHeaderLayout headerTitle={id > 0 ? 'Edit Service Expert Info':'Add New Service Expert'}>
                    <Button variant='contained' 
                        onClick={() => {
                            if (parseInt(tabValue)>1) {
                                setTabValue(`${tabValue - 1}`)
                            }else{
                                navigate(-1)
                            }                                        
                        }} 
                        sx={{ height: "30px", marginTop: "10px" }} endIcon={<ArrowBack />}
                        disabled={tabValue<2}
                    >
                        Back
                    </Button>
                </PageHeaderLayout>
                <Box sx={{ marginTop: 1 }}>
                    <Grid container spacing={1}>

                        <Grid item xs={12} lg={12}>
                            <Tabs
                                value={tabValue}
                                onChange={handleTabChange}
                                sx={{ width: "100%" , backgroundColor: "#fff",minHeight:0 }}
                            >
                                {((id==0 && !otrDetail?.isVerified) || (id>0 && !otrDetail?.isVerified)) && <Tab value='0' label="Otp" disabled={otrDetail?.id && otrDetail.isVerified}/>}
                                <Tab value='1' label="Personal Details" disabled={!otrDetail?.isVerified}  sx={{fontSize:'.8rem',minHeight:0,padding:'.5rem'}} />
                                <Tab value='2' label="Business Details" disabled={!otrDetail?.isVerified}  sx={{fontSize:'.8rem',minHeight:0,padding:'.5rem'}} />
                                <Tab value='3' label='Service Details' disabled={!otrDetail?.isVerified}   sx={{fontSize:'.8rem',minHeight:0,padding:'.5rem'}} />
                                <Tab value='4' label='Availability Details' disabled={!otrDetail?.isVerified }  sx={{fontSize:'.8rem',minHeight:0,padding:'.5rem'}} />
                                <Tab value='5' label='Storefront Details' disabled={!otrDetail?.isVerified }  sx={{fontSize:'.8rem',minHeight:0,padding:'.5rem'}} />
                                <Tab value='6' label='Payment Details' disabled={!otrDetail?.isVerified }  sx={{fontSize:'.8rem',minHeight:0,padding:'.5rem'}} />
                                {/* <Tab value='6' label='Storefront Photos' disabled={id==0 && !otrDetail?.isVerified || !storeFrontData.id }  sx={{fontSize:'.8rem',minHeight:0,padding:'.5rem'}} /> 
                                <Tab value='1' label="Personal Details" disabled={!otrDetail?.id}  sx={{fontSize:'.8rem',minHeight:0,padding:'.5rem'}} />
                                <Tab value='2' label="Business Details" disabled={!otrDetail?.id}  sx={{fontSize:'.8rem',minHeight:0,padding:'.5rem'}} />
                                <Tab value='3' label='Service Details' disabled={!otrDetail?.id}   sx={{fontSize:'.8rem',minHeight:0,padding:'.5rem'}} />
                                <Tab value='4' label='Availability Details' disabled={!otrDetail?.id }  sx={{fontSize:'.8rem',minHeight:0,padding:'.5rem'}} />
                                <Tab value='5' label='Storefront Details' disabled={!otrDetail?.id }  sx={{fontSize:'.8rem',minHeight:0,padding:'.5rem'}} />
                                {/* <Tab value='6' label='Storefront Photos' disabled={!otrDetail?.id || !storeFrontData.id }  sx={{fontSize:'.8rem',minHeight:0,padding:'.5rem'}} />                                 */}
                            </Tabs>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            {tabValue == '0' &&
                            //  !otrDetail?.id &&
                             (
                                <Box elevation={3}>
                                    <OtrDetails otrDetail={otrDetail} setOtrDetail={setOtrDetail}/>
                                </Box>
                            )}
                            {tabValue == '1' && 
                            (
                                <Box elevation={3}>
                                    <PersonalDetails userIdProp={id} personalDetails={providerPersonalDetails} onSubmitPersonal={handlePersonalSubmit} tabNextChange={handleNextTab} />
                                </Box>
                            )}

                            {tabValue == '2' && 
                            (
                                <Box elevation={3}>
                                    <BusinessDetails />
                                </Box>
                            )}

                            {tabValue == '3' && 
                            (
                                <Box elevation={3}>
                                    <ServiceDetails />
                                </Box>
                            )}
                            
                            {tabValue == '4' && 
                            (
                                <Box elevation={3}>
                                    <WorkingHours />
                                </Box>
                            )}
                            {tabValue == '5' && 
                            (
                                <Box elevation={3}>
                                    <StorefrontDetails />
                                </Box>
                            )}
                            {tabValue == '6' && 
                            (
                                <Box elevation={3}>
                                    <PaymentDetails />
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </UserProfileContext.Provider>
    );
};



export {UserProfileContext}
import React, { useEffect, useState } from 'react';
import { FilterList, KeyboardArrowDown, KeyboardArrowUp, Close, Add, DeleteForever } from '@mui/icons-material';
import { 
  Badge, 
  Button, 
  Popover, 
  Typography, 
  Box, 
  Grid, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  TextField,
  IconButton,
  Autocomplete
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FormProvider, useForm, Controller, useFieldArray } from 'react-hook-form';
import dayjs from 'dayjs';
import { CalendarIcon, DesktopDatePicker } from '@mui/x-date-pickers';
import { CrudServices } from '../CrudServices';



const Filter = (props) => {
  const { 
    onApplyFilter, 
    externalClear = false, // to handle external clear
    defaultFilterCount = 1 ,
  } = props;

  const filterFormMethod = useForm({
    mode: "onChange",
    defaultValues: {
      filterOption: Array(defaultFilterCount).fill().map(() => ({ 
        fieldName: '', 
        operator: 'contains', 
        value: null 
      }))
    }
  });

  const { control, handleSubmit, reset, setValue, getValues, watch } = filterFormMethod;

  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [serviceList, setServiceList] = useState([]);

  useEffect(()=>{
      const fetchCategoryList= async()=>{
          try {
            const categoryListResponse = await CrudServices.categoryList();
            console.log("category list Response:",categoryListResponse);
            if(categoryListResponse.success===1){
              setCategoryList(categoryListResponse.data)
            }
          } catch (error) {
            
          }
        }
        fetchCategoryList();
    },[]);

    const categoryId = watch('filterOption').find(row => row.fieldName === 'category')?.value;
    useEffect(()=>{
      const fetchSubCategoryList= async()=>{
          try {
            const subCategoryListResponse = await CrudServices.subCategoryList({categoryId});
            console.log("subCategory list Response:",subCategoryListResponse);
            if(subCategoryListResponse.success===1){
              setSubCategoryList(subCategoryListResponse.data)
            }
          } catch (error) {
            
          }
        }
        if(categoryId){ 
          fetchSubCategoryList();
        }else{
          setSubCategoryList([]);
          //remove subcategory from filterOption
          setValue('filterOption',watch('filterOption').filter(row => row.fieldName !== 'subCategory'));
        }
    },[categoryId]);

    const subCategoryId = watch('filterOption').find(row => row.fieldName === 'subCategory')?.value;
    useEffect(()=>{
      const fetchServiceList= async()=>{
          try {
            const serviceListResponse = await CrudServices.serviceListBySubCategory(subCategoryId);
            console.log("service list Response:",serviceListResponse);
            if(serviceListResponse.success===1){
              setServiceList(serviceListResponse.data)
            }
          } catch (error) {
            
          }
        }
        if(subCategoryId){
          fetchServiceList();
        }else{
          setServiceList([]);
          //remove service from filterOption
          setValue('filterOption',watch('filterOption').filter(row => row.fieldName !== 'service'));
        }
    },[subCategoryId]);

  const filterableColumns = [
    {
      key: 'category',
      label: 'Category',
      type: 'autoComplete',
      options: categoryList
    },
    {
      key:'subCategory',
      label:'Sub Category',
      type:'autoComplete',
      options: subCategoryList  ,
    },
    {
      key:'service',
      label:'Service',
      type:'autoComplete',
      options: serviceList?.map(service => ({
        ...service,
        id: service.masterServiceId // Map masterServiceId to id for consistency
      }))
    },
    {
      key: 'isActive',
      label: 'Status',
      type: 'select',
      options: [
        { value: 'true', label: 'Active' },
        { value: 'false', label: 'Inactive' }
      ] 
    },
    {
      key: 'createdAt',
      label: 'Created Date',
      type: 'date'
    }
  ];
  
  const [anchorEl, setAnchorEl] = useState(null);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "filterOption"
  });

  // React to external clear prop
  useEffect(() => {
    if (externalClear) {
      reset({
        filterOption: [{ 
          fieldName: '', 
          operator: 'contains', 
          value: null 
        }]
      });
    }
  }, [externalClear, reset]);

  const filterOption = watch('filterOption');

  const activeBadgeContent = filterOption.filter(
    row => row.fieldName && row.value
  ).length;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if(activeBadgeContent > 0){
      onApplyFilter(getValues());
    }
  };

  const openFilterForm = Boolean(anchorEl);

  const getAvailableColumns = (currentIndex) => {
    const selectedColumns = filterOption
      .filter((_, index) => index !== currentIndex)
      .map(option => option.fieldName)
      .filter(Boolean);

    return filterableColumns.filter(column => 
      !selectedColumns.includes(column.key) && !shouldHideColumn(column.key)
    );
  };

  const handleColumnChange = (e, index, columnField) => {
    const selectedColumn = filterableColumns.find(col => col.key === e.target.value);
    
    // Reset the value
    setValue(`filterOption.${index}.value`, null);
    
    // Set default operator based on column type
    if (selectedColumn?.type === 'date') {
      setValue(`filterOption.${index}.operator`, 'equals');
    }else if(selectedColumn?.type === 'email'){
      setValue(`filterOption.${index}.operator`, 'equals');
    }
    else if(selectedColumn?.type === 'select'){
      setValue(`filterOption.${index}.operator`, 'equals');
    }
    else if(selectedColumn?.type === 'autoComplete'){
      setValue(`filterOption.${index}.operator`, 'equals');
    } 
    else {
      setValue(`filterOption.${index}.operator`, 'contains');
    }
    
    // Call the original onChange handler
    columnField.onChange(e);
  };

  // if no value for category then hide subCategory and service , if category value is selected then show subCategory and hide service, if subCategory value is selected then show service in filterablecolumn

  const shouldHideColumn = (columnName)=>{
    if(columnName === 'subCategory' || columnName === 'service'){
      if(!filterOption.find(row => row.fieldName === 'category' && row.value)){
        return true;
      }
      if(columnName === 'service' && !filterOption.find(row => row.fieldName === 'subCategory' && row.value)){
        return true;
      }
    }
  }
  
  
  const renderValueField = (field, index) => {
    const selectedColumn = filterableColumns.find(
      col => col.key === filterOption[index]?.fieldName
    );

    const currentOperator = filterOption[index]?.operator;
  
    // Handle different input types
    switch(selectedColumn?.type) {
      case 'date':
        // If operator is 'between', render two date pickers
        if (currentOperator === 'between') {
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box sx={{ display: 'flex', gap: 1, width: '100%' }}>
                <DesktopDatePicker
                  // disabled={!watch('services').length>0 || !watch('customer')}
                  
                  label="Start Date"
                  value={field.value? dayjs(field.value) : null}
                  onChange={(newValue) => {
                    field.onChange(newValue ? dayjs(newValue).endOf('day').toISOString() : null);
                  }}
                  format="DD-MM-YYYY" // Display format for user
                  // minDate={dayjs()}
                  slots={{
                    openPickerIcon: (props) => (
                      <CalendarIcon 
                        {...props} 
                        sx={{ 
                          fontSize: '1rem', // Control icon size
                          color: 'gray',    // Optional: control icon color
                          padding:0
                        }} 
                      />
                    ),
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      required: true,
                      size: 'small',
                      variant: 'standard', // Use standard variant to match existing design
                      InputProps: {
                        // disableUnderline: true, // Remove underline if using standard variant
                        // inputProps: {
                          style: {
                            height: '1.6rem',  
                            fontSize: '0.75rem',                        
                          },
                        // },
                      },
                      InputLabelProps: {
                        style: {
                          fontSize: '0.75em'
                        }
                      }
                    }
                  }}
                />
                {/* <DatePicker
                  label="Start Date"
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue ? newValue.format("DD-MM-YYYY") : null);
                  }}
                  format="DD-MM-YYYY"
                  renderInput={(params) => (
                    <TextField 
                      {...params} 
                      variant='standard' 
                      size='small'
                      InputProps={{
                        inputProps: {
                          style: {
                            height: '1.2rem',  
                            fontSize: '0.75rem',                        
                          },
                        },
                      }}  
                      InputLabelProps={{
                          style: {
                              fontSize: '0.75em'
                          }
                      }}  
                    />
                  )}
                /> */}
                <DesktopDatePicker
                  label="End Date"
                  value={filterOption[index]?.endValue ? dayjs(filterOption[index]?.endValue) : null}
                  onChange={(newValue) => {
                    setValue(`filterOption.${index}.endValue`, newValue ? dayjs(newValue).endOf('day').toISOString() : null);
                  }}
                  format="DD-MM-YYYY"
                  slots={{
                    openPickerIcon: (props) => (
                      <CalendarIcon 
                        {...props} 
                        sx={{ 
                          fontSize: '1rem', // Control icon size
                          color: 'gray',    // Optional: control icon color
                          padding:0
                        }} 
                      />
                    ),
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      required: true,
                      size: 'small',
                      variant: 'standard', // Use standard variant to match existing design
                      InputProps: {
                        // disableUnderline: true, // Remove underline if using standard variant
                        // inputProps: {
                          style: {
                            height: '1.6rem',  
                            fontSize: '0.75rem',                        
                          },
                        // },
                      },
                      InputLabelProps: {
                        style: {
                          fontSize: '0.75em'
                        }
                      }
                    },            
                  }}
                  componentsProps={{
                    actionBar: {
                      actions: [], // Remove action buttons in calendar
                    },
                  }}
                />
              </Box>
            </LocalizationProvider>
          );
        }
        
        // Default single date picker for other date operators
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label={selectedColumn.label}
              value={field.value ? dayjs(field.value) : null}
              onChange={(newValue) => {
                field.onChange(newValue? dayjs(newValue).endOf('day').toISOString(): null);
              }}
              format="DD-MM-YYYY"
              slots={{
                openPickerIcon: (props) => (
                  <CalendarIcon 
                    {...props} 
                    sx={{ 
                      fontSize: '1rem', // Control icon size
                      color: 'gray',    // Optional: control icon color
                      padding:0
                    }} 
                  />
                ),
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  required: true,
                  size: 'small',
                  variant: 'standard', // Use standard variant to match existing design
                  InputProps: {
                    // disableUnderline: true, // Remove underline if using standard variant
                    // inputProps: {
                      style: {
                        height: '1.6rem',  
                        fontSize: '0.75rem',                        
                      },
                    // },
                  },
                  InputLabelProps: {
                    style: {
                      fontSize: '0.75em'
                    }
                  }
                }
              }}
            />
          </LocalizationProvider>
        );
      
      case 'select':
        return (
          <FormControl variant='standard' sx={{ width:'100%' }} size="small">
            <InputLabel id={`select-label-${index}`} sx={{fontSize:'.75em', lineHeight:'2em'}}>
              {selectedColumn.label}
            </InputLabel>
            <Select
              {...field}
              labelId={`select-label-${index}`}
              value={field.value || ''}
              onChange={(e) => {
                field.onChange(e);
              }}
              sx={{
                minHeight: '0px',
                height: '1.6rem',
                fontSize: '0.75rem',
                fontWeight: '600',
                textTransform:'capitalize'
              }}
            >
              {selectedColumn.options.map(option => (
                <MenuItem key={option.value} value={option.value} sx={{fontSize:'0.75rem'}}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      
      case 'autoComplete':
        return (
          <FormControl variant='standard' sx={{ width:'100%' }} size="small">
            {/* <InputLabel id={`autoComplete-label-${index}`} sx={{fontSize:'.75em', lineHeight:'2em'}}>
              {selectedColumn.label}
            </InputLabel> */}
            <Autocomplete
              {...field}
              options={selectedColumn.options || []}
              getOptionLabel={(option) => {
                // Handle both option objects and direct values
                if (typeof option === 'object' && option !== null) {
                  return option.title || '';
                }
                // Find the matching option from options array if value is just the ID
                const matchingOption = selectedColumn.options?.find(opt => opt.id === option);
                return matchingOption ? matchingOption.title : '';
              }}
              onChange={(e, value) => {
                // Pass the id as the value
                field.onChange(value?.id || '');
              }}
              isOptionEqualToValue={(option, value) => {
                // Handle comparison between option objects and values
                if (typeof value === 'object' && value !== null) {
                  return option.id === value.id;
                }
                return option.id === value;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size='small'
                  variant='standard'
                  label={selectedColumn.label}
                  InputProps={{
                    ...params.InputProps,
                    inputProps: {
                      ...params.inputProps,
                      style: {
                        height: '1.2rem',
                        fontSize: '0.75rem',
                      },
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      fontSize: '0.75em'
                    }
                  }}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.id} style={{ fontSize: '0.75rem' }}>
                  {option.title}
                </li>
              )}
            />
          </FormControl>
        );
      
        case 'number':
        return (
          <TextField 
            {...field} 
            type="number"
            size='small'
            variant='standard'
            label='Value'
            InputProps={{
              inputProps: {
                style: {
                  height: '1.2rem',     
                  fontSize: '0.75rem',                     
                },
              },
            }}
            InputLabelProps={{
                style: {
                    fontSize: '0.75em'
                }
            }}    
          />
        );
      
      case 'boolean':
        return (
          <FormControl variant='standard' sx={{ width:'100%' }} size="small">
            <InputLabel id={`boolean-label-${index}`} sx={{fontSize:'.75em', lineHeight:'2em'}}>
              {selectedColumn.label}
            </InputLabel>
            <Select
              {...field}
              labelId={`boolean-label-${index}`}
              value={field.value || ''}
              onChange={(e) => {
                field.onChange(e);
              }}
              sx={{
                minHeight: '0px',
                height: '1.6rem',
                fontSize: '0.75rem',
                fontWeight: '600',
                textTransform:'capitalize'
              }}
            >
              <MenuItem value="true" sx={{fontSize:'.75rem'}}>Yes</MenuItem>
              <MenuItem value="false" sx={{fontSize:'.75rem'}}>No</MenuItem>
            </Select>
          </FormControl>
        );
      
      // Default to text input
      default:
        return (
          <TextField 
            {...field} 
            size='small'
            variant='standard'
            label='Value'
            InputProps={{
              inputProps: {
                style: {
                  height: '1.2rem',  
                  fontSize: '0.75rem',                        
                },
              },
            }}  
            InputLabelProps={{
                style: {
                    fontSize: '0.75em'
                }
            }}  
          />
        );
    }
  };

  const getOperators = (columnType) => {
    switch(columnType) {
      case 'select':
        return [
          { value: 'equals', label: 'Is' },
        ];
      case 'autoComplete':
        return [
          { value: 'equals', label: 'Is' },
        ];
      case 'email':
        return [
          // { value: 'contains', label: 'Contains' },
          { value: 'equals', label: 'Equals' },
        ];
      case 'number':
        return [
          { value: 'contains', label: 'Contains' },
          // { value: 'equals', label: 'Equals' },
          // { value: 'greaterThan', label: 'Greater Than' },
          // { value: 'lessThan', label: 'Less Than' }
        ];
      case 'date':
        return [
          { value: 'equals', label: 'Equals' },
          // { value: 'before', label: 'Before' },
          // { value: 'after', label: 'After' },
          { value: 'between', label: 'Between' }
        ];
      case 'text':
      default:
        return [
          { value: 'contains', label: 'Contains' },
          // { value: 'equals', label: 'Equals' },
          // { value: 'startsWith', label: 'Starts With' },
          // { value: 'endsWith', label: 'Ends With' }
        ];
    }
  };


  // Modify onApplyFilter to handle date range
  const handleApplyFilter = (data) => {
    // Transform data to handle date range
    const transformedFilterOptions = data.filterOption.map(option => {
      if (option.fieldName && filterableColumns.find(col => col.key === option.fieldName)?.type === 'date') {
        if (option.operator === 'between') {
          return {
            ...option,
            value: option.value ? dayjs(option.value).toISOString() : null,
            endValue: option.endValue ? dayjs(option.endValue).toISOString() : null
          };
        }

        console.log("transformedFilterOptions:",{
          ...option,
          value: option.value ? dayjs(option.value).toISOString() : null
        });
        return {
          ...option,
          value: option.value ? dayjs(option.value).toISOString() : null
        };
      }
      return option;
    });

    onApplyFilter({ ...data, filterOption: transformedFilterOptions });
  };

  return (
    <>
      <Button
        onClick={handleClick}
        // endIcon={openFilterForm ? <KeyboardArrowUp/> : <KeyboardArrowDown />}
        sx={{
          textTransform: 'none',
          // fontSize:'x-small',
          // p:'3% 10%'
        }}
        startIcon={ 
        <Badge 
          sx={{
            '& .MuiBadge-badge': {
              fontSize: '0.5rem',
              fontWeight: '600',
              minWidth: '0',
              height: 'auto',
              padding: '10% 17%',
              borderRadius: '50%'
            },
            width: 'fit-content'
          }}  
          badgeContent={activeBadgeContent} 
          color="primary"
          invisible={activeBadgeContent === 0}
        >
          <FilterList />
        </Badge>
        }
      >
        Filters
      </Button>
      <Popover
        open={openFilterForm}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: { 
            width: '50%',
            p: '0.5rem 1rem',
            // mt: 1
          }
        }}
      >
        <FormProvider {...filterFormMethod}> 
        <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 500,
              mb: 1
            }}
          >
            Filter Options:
          </Typography>
          {activeBadgeContent > 1 &&<Button 
            size='small' 
            onClick={() => {
              reset()
              handleClose()
            } }
            disabled={fields.length === 1}  
            startIcon={<DeleteForever/>}
          >
            Remove all
          </Button>}

        </Box>
          <Box component={'form'} onSubmit={handleSubmit(handleApplyFilter)}>
            {fields.map((field, index) => (
              <Grid container spacing={1} key={field.id} sx={{}}>
                {index === 0 ? (
                  <>
                    {fields.length > 1 && <Grid item xs={2}/>}
                    <Grid item xs={3}>
                      <Controller
                        name={`filterOption.${index}.fieldName`}
                        control={control}
                        render={({ field: columnField }) => (
                          <FormControl variant='standard' sx={{ width:'100%' }} size="small">
                            <InputLabel id={`column-select-label-${index}`} sx={{fontSize:'.75em', lineHeight:'2em'}}>
                              Column
                            </InputLabel>
                            <Select
                              {...columnField}
                              labelId={`column-select-label-${index}`}
                              value={columnField.value || ''}
                              // onChange={(e) => {
                              //   // Reset the value and operator when fieldName changes
                              //   setValue(`filterOption.${index}.value`, null);
                              //   setValue(`filterOption.${index}.operator`, 'contains');
                              //   columnField.onChange(e);
                              // }}
                              
                              onChange={(e) => handleColumnChange(e, index, columnField)}
                              sx={{
                                minHeight: '0px',
                                height: '1.6rem',
                                fontSize: '0.75rem',
                                fontWeight: '600',
                                textTransform:'capitalize'
                              }}
                            >
                              {getAvailableColumns(index).map((column) => (
                                <MenuItem key={column.key} value={column.key} sx={{textTransform:'capitalize',fontSize:'0.75rem'}}>
                                  {column.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name={`filterOption.${index}.operator`}
                        control={control}
                        defaultValue='contains'
                        render={({ field: operatorField }) => {
                          const selectedColumn = filterableColumns.find(
                            col => col.key === filterOption[index]?.fieldName
                          );
                          const operators = getOperators(selectedColumn?.type || 'text');

                          return (
                            <FormControl variant='standard' sx={{ width:'100%' }} size="small">
                              <InputLabel id={`operator-select-label-${index}`} sx={{fontSize:'.75em', lineHeight:'2em'}}>
                                Operator
                              </InputLabel>
                              <Select
                                {...operatorField}
                                labelId={`operator-select-label-${index}`}
                                value={operatorField.value || 'contains'}
                                sx={{
                                  minHeight: '0px',
                                  height: '1.6rem',
                                  fontSize: '0.75rem',
                                  fontWeight: '600',
                                }}
                              >
                                {operators.map(op => (
                                  <MenuItem key={op.value} value={op.value} sx={{fontSize:'0.75rem'}}>
                                    {op.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={4.5}>
                      <Controller
                        name={`filterOption.${index}.value`}
                        control={control}
                        render={({ field: valueField }) => renderValueField(valueField, index)}
                      />
                    </Grid>
                    <Grid item xs={1} sx={{display:"flex", alignItems:'self-end'}}>
                      {fields.length === 1 && (
                        <IconButton 
                          size='small' 
                          sx={{p:0}}
                          onClick={() => {
                            setValue(`filterOption.${index}.fieldName`, '');
                            setValue(`filterOption.${index}.value`, null);
                            handleClose();
                          }}
                        >
                          <Close/>
                        </IconButton>
                      )}
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={2}>
                      <Controller
                        name={`filterOption.${index}.andOr`}
                        control={control}
                        defaultValue='and'
                        render={({ field }) => (
                          // <FormControl variant='standard' sx={{ width:'100%' }} size="small">
                          //   <InputLabel id={`logic-select-label-${index}`} sx={{fontSize:'.75em', lineHeight:'2em'}}>
                          //     Logic
                          //   </InputLabel>
                          //   <Select
                          //     {...logicField}
                          //     labelId={`logic-select-label-${index}`}
                          //     value={logicField.value || 'and'}
                          //     sx={{
                          //       minHeight: '0px',
                          //       fontSize: '0.75rem',
                          //       fontWeight: '600',
                          //       height:'1.6rem'
                          //     }}
                          //   >
                          //     <MenuItem value="and" sx={{fontSize:'0.75rem'}}>AND</MenuItem>
                          //     {/* <MenuItem value="or" sx={{fontSize:'0.75rem'}}>OR</MenuItem> */}
                          //   </Select>
                          // </FormControl>
                          <TextField
                            {...field}
                            variant='standard'
                            size='small'
                            label='Logic'
                            InputProps={{
                              inputProps: {
                                style: {
                                  height: '1.2rem',     
                                  fontSize: '0.75rem',  
                                  textTransform:'uppercase'                   
                                },
                              },
                            }}
                            InputLabelProps={{
                                style: {
                                    fontSize: '0.75em'
                                }
                            }}    
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        name={`filterOption.${index}.fieldName`}
                        control={control}
                        render={({ field: columnField }) => (
                          <FormControl variant='standard' sx={{ width:'100%' }} size="small">
                            <InputLabel id={`column-select-label-${index}`} sx={{fontSize:'.75em', lineHeight:'2em'}}>
                              Column
                            </InputLabel>
                            <Select
                              {...columnField}
                              labelId={`column-select-label-${index}`}
                              value={columnField.value || ''}
                              // onChange={(e) => {
                              //   // Reset the value and operator when fieldName changes
                              //   setValue(`filterOption.${index}.value`, null);
                              //   setValue(`filterOption.${index}.operator`, 'contains');
                              //   columnField.onChange(e);
                              // }}
                              onChange={(e) => handleColumnChange(e, index, columnField)}
                              sx={{
                                minHeight: '0px',
                                height: '1.6rem',
                              fontSize: '0.75rem',
                              fontWeight: '600',
                            }}
                            >
                              {getAvailableColumns(index).map((column) => (
                                <MenuItem key={column.key} value={column.key} sx={{textTransform:'capitalize',fontSize:'0.75rem'}}>
                                  {column.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name={`filterOption.${index}.operator`}
                        control={control}
                        defaultValue='contains'
                        render={({ field: operatorField }) => {
                          const selectedColumn = filterableColumns.find(
                            col => col.key === filterOption[index]?.fieldName
                          );
                          const operators = getOperators(selectedColumn?.type || 'text');

                          return (
                            <FormControl variant='standard' sx={{ width:'100%' }} size="small">
                              <InputLabel id={`operator-select-label-${index}`} sx={{fontSize:'.75em', lineHeight:'2em'}}>
                                Operator
                              </InputLabel>
                              <Select
                                {...operatorField}
                                labelId={`operator-select-label-${index}`}
                                value={operatorField.value || 'contains'}
                                sx={{
                                  minHeight: '0px',
                                  height: '1.6rem',
                                  fontSize: '0.75rem',
                                  fontWeight: '600',
                                }}
                              >
                                {operators.map(op => (
                                  <MenuItem key={op.value} value={op.value} sx={{fontSize:'0.75rem'}}>
                                    {op.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={4.5}>
                      <Controller
                        name={`filterOption.${index}.value`}
                        control={control}
                        render={({ field: valueField }) => renderValueField(valueField, index)}
                      />
                    </Grid>
                    <Grid item xs={.5} sx={{display:"flex", alignItems:'self-end'}}>
                      <IconButton 
                        size='small' 
                        onClick={() => remove(index)} 
                        sx={{p:0,fontSize:'0.75rem'}}
                        color='error'
                      >
                        <Close sx={{fontSize:'1rem'}} />
                      </IconButton>
                    </Grid>
                  </>
                )}
              </Grid>
            ))}

            <Box sx={{display:'flex', justifyContent:'space-between', mt: 1}}>
              <Button 
                size='small'
                disabled={fields.length === filterableColumns.length} 
                onClick={() => append({ 
                  fieldName: '', 
                  operator: 'contains', 
                  value: '', 
                  andOr: 'and' 
                })} 
                startIcon={<Add/>}
              >
                Add Filter
              </Button>
              <Button 
                size='small' 
                onClick={() => {
                  // reset()
                  handleClose()
                } }
                disabled={activeBadgeContent === 0}  
                // startIcon={<DeleteForever/>}
              >
                Apply Filters
              </Button>
            </Box>
          </Box>
        </FormProvider>
      </Popover>
    </>
  );
};

export default Filter;


// format of filterableColumns
// const filterableColumns = [
//   {
//     key: 'providerName',
//     label: 'Provider Name',
//     type: 'text'
//   },
//   {
//     key: 'bookingNumber',
//     label: 'Booking Number',
//     type: 'number'
//   },
//   {
//     key:'customerName',
//     label:'Customer Name',
//     type:'text'
//   },
//   {
//     key:'add_mobileNumber',
//     label:'Mobile Number',
//     type:'number'
//   },
  // {
  //   key: 'status',
  //   label: 'Status',
  //   type: 'select',
  //   options: [
  //     { value: 'active', label: 'Active' },
  //     { value: 'inactive', label: 'Inactive' }
  //   ]
  // },
  // {
  //   key: 'createdAt',
  //   label: 'Created Date',
  //   type: 'date'
  // },
  // {
  //   key: 'serviceDate',
  //   label: 'Service Date',
  //   type: 'date'
  // },
  // {
  //   key: 'isVerified',
  //   label: 'Is Verified',
  //   type: 'boolean'
  // },
  // {
  //   key: 'reviewStatus',
  //   label: 'Review Status',
  //   type: 'select',
  //   options: [
  //     { value: '1', label: 'Approved' },
  //     { value: '0', label: 'Review Pending' },
  //     { value: '2', label: 'Rejected' }
  //   ]
  // }
// ];

// onApplyFilter function
// const handleApplyFilter = (filterData) => {
//   // Process filter data
//   console.log(filterData);
//   setPaginationModel({
//     ...paginationModel,
//     page: 0, // Reset to first page when applying new filters
//     filters: {
//       ...paginationModel.filters,
//       // Convert MultiColumnFilter options to a format your backend can understand
//     filterOptions: filterData.filterOption.filter(filter => filter.fieldName && filter.value) // Remove empty filters
//     }
//   });
// }
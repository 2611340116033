import React, { useEffect, useState } from 'react';
import {Box, TextField, Button, Typography, IconButton, Grid, FormControl, InputLabel, Select, MenuItem, FormLabel, RadioGroup, FormControlLabel, Radio, Autocomplete } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm ,Controller} from 'react-hook-form';
import Swal from "sweetalert2";
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { isMobile } from "react-device-detect";
import { Helpers } from '../../../services/Helpers';
import Loading from '../../../components/Loader/Loading';
import { RoleAndPermissionServices } from '../RoleAndPermissionServices';


export default function AddMember({ closeEvent,memberData }) {
    const [memberDataInitialState,setMemberDataInitialState] = useState({  
        firstName: '',
        lastName: '',
        email: '',
        roleId: ''
    })
    const { control, handleSubmit, reset, setValue, watch, formState: { isDirty,dirtyFields } } = useForm({
        mode: "onChange",
        defaultValues:memberDataInitialState
    });

    const [errorMsg, setErrorMsg] = useState("");
    const [modalTtitle, setModalTitle] = useState("Add New Member");

    const [isLoading,setIsLoading] = useState (false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    
    const [roleList,setRoleList] = useState([]);
    const fetchRoleList= async()=>{
        try {
            const roleListResponse = await RoleAndPermissionServices.roleList();
            // console.log("fetching role list response:",roleListResponse);
            const data = roleListResponse.data;
            if(roleListResponse.success=='1'){
              setRoleList(data);
            }
        } catch (error) {
          
        }
    }
    
    /*================= on component load==================== */
    
    useEffect(() => {
      fetchRoleList();
    }, []);
    
    useEffect(() => {
        // categoryList();
        if (memberData?.id>0) {
            if (memberData.parentServiceId>0) {
                setMemberDataInitialState({serviceType:'child', categoryId: memberData.categoryId,serviceCategoryId:memberData?.serviceCategoryId,...memberData});
            }else{
                setMemberDataInitialState(memberData);
            }
            setModalTitle("Edit Service");
        }

    }, [memberData])
    useEffect(()=>{
        reset(memberDataInitialState);
    },[memberDataInitialState,reset])
           
    const onSubmit=async(data)=> {
        setIsSubmitting(true);
        // console.log("raw data:",data);
        try {
            const createResponse = await RoleAndPermissionServices.createMember(data);
            if (createResponse.success=='1') {
                Swal.fire('Member Created Successfully','','success');
                reset(memberDataInitialState);
                closeEvent();
            }else{
                Swal.fire(createResponse.message,'','error')
            }
        } catch (error) {
            // console.log("member creation error:",error.message)
            Swal.fire('Internal Server Error','','error')
        }finally{
            setIsSubmitting(false);
        }
        // const modifiedData={
            
        // }
        // const createRow = async () => {
        //     setErrorMsg("");
        //     let createRowRes = "";
        //     if (memberData.id>0) {
        //         if (isDirty) {
        //             // Filter out only the modified fields
        //             const modifiedFields = Object.keys(dirtyFields).reduce((acc, key) => {
        //                 if (modifiedData.hasOwnProperty(key)) {
        //                     acc[key] = modifiedData[key];
        //                 }
        //                 return acc;
        //             }, {});
        //             // Always include title in the final data
        //             if (!modifiedFields.hasOwnProperty('title')) {
        //                 modifiedFields.title = modifiedData.title;
        //             }
                    // console.log('Modified fields:', modifiedFields);
        //             // createRowRes = await CrudServices.updateService(memberData.id, modifiedFields);
                    // console.log("service update response:",createRowRes)
        //           } 
                
        //     } else {
                // console.log("data to be submitted for creating new:",modifiedData)
        //         // createRowRes = await CrudServices.addService(modifiedData)
        //         // console.log("service create response:",createRowRes)
        //     }

        //     if (createRowRes.success=='1') {
        //         Swal.fire(memberData.id>0 ?'Service Data Updated Successfully':createRowRes.message, '', 'success')
        //         reset();
        //         closeEvent();
        //         setIsSubmitting(false);

        //     } else {
        //         setErrorMsg(createRowRes.message);
        //         setIsSubmitting(false)
        //     }
        // }
        // createRow();
    }
    return (
        <Box>
            <Box sx={{p:'1% 2%',width:'100%',display:'flex',justifyContent:'space-between',alignItems:'center',borderBottom:'1px solid lightGray'}}>
                <Typography sx={{fontSize:'0.85rem',fontWeight:600}}>
                    {modalTtitle}
                </Typography>
                <IconButton
                    size='small'
                    sx={{
                        p:0
                    }}
                    onClick={closeEvent}
                    color='error'
                >
                    <CloseIcon />
                </IconButton>

            </Box>
            <Box sx={{p:2}}>
                <Typography variant="inherit" color="red">
                    {errorMsg}
                </Typography>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        {isLoading || isSubmitting && <Loading/>}
                        <Grid item xs={12} >
                            <Grid container spacing={2}>
                                <Grid item xs={isMobile?12:6}>
                                    <Typography sx={{fontWeight:'600', textTransform:'capitalize', display:'flex ',alignItems:'center',fontSize:'0.75rem'}}>First Name<span style={{color:'red'}}>*</span>  </Typography>
                                    <Controller
                                        control={control}
                                        name="firstName"
                                        rules={{
                                            required:'first Name is required',
                                            pattern:{
                                                value:/^[a-zA-Z ]*$/,
                                                message:"only alphabets allowed"
                                            },
                                            minLength:{
                                                value:3,
                                                message:"minimum 3 digit"
                                            },
                                            maxLength:{
                                                value:30,
                                                message:"maximum 30 digit"
                                            }
                                        }}
                                        render={({field,fieldState:{error}})=>(
                                            <TextField
                                                {...field}
                                                variant="outlined"
                                                size="small"
                                                inputProps={{
                                                    style:{
                                                        fontSize:'0.75rem'
                                                    }
                                                }}
                                                fullWidth 
                                                error={!!error}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={isMobile?12:6}>
                                    <Typography sx={{fontWeight:'600', textTransform:'capitalize', display:'flex ',alignItems:'center',fontSize:'0.75rem'}}>Last Name<span style={{color:'red'}}>*</span> </Typography>
                                    <Controller
                                        control={control}
                                        name="lastName"
                                        rules={{
                                            required:'Last Name is required',
                                            pattern:{
                                                value:/^[a-zA-Z ]*$/,
                                                message:"only alphabets and space allowed"
                                            },
                                            minLength:{
                                                value:3,
                                                message:"minimum 3 digit"
                                            },
                                            maxLength:{
                                                value:30,
                                                message:"maximum 30 digit"
                                            }
                                        }}
                                        render={({field,fieldState:{error}})=>(
                                            <TextField
                                                {...field}
                                                variant="outlined"
                                                size="small"
                                                inputProps={{
                                                    style:{
                                                        fontSize:'0.75rem'
                                                    }
                                                }}
                                                fullWidth 
                                                error={!!error}
                                            />
                                        )}
                                    />
                                </Grid>
                                
                                <Grid item xs={isMobile?12:8}>
                                    <Typography sx={{fontWeight:'600', textTransform:'capitalize', display:'flex ',alignItems:'center',fontSize:'0.75rem'}}>Email<span style={{color:'red'}}>*</span> </Typography>
                                    <Controller
                                        control={control}
                                        name="email"
                                        rules={{
                                            required:'enter Email',
                                            pattern:{
                                                value:/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                                                message:"Email is not Valid"
                                            }
                                        }}
                                        render={({field,fieldState:{error}})=>(
                                            <TextField
                                                {...field}
                                                variant="outlined"
                                                size="small"
                                                inputProps={{
                                                    style:{
                                                        fontSize:'0.75rem'
                                                    }
                                                }}
                                                fullWidth 
                                                error={!!error}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={isMobile?12:4}>
                                    <Typography sx={{fontWeight:'600', textTransform:'capitalize', display:'flex ',alignItems:'center',fontSize:'0.75rem'}}>Role<span style={{color:'red'}}>*</span> </Typography>
                                    <Controller
                                        control={control}
                                        name="roleId"
                                        rules={{
                                            required:'select role',
                                        }}
                                        render={({field,fieldState:{error}})=>(
                                            <FormControl sx={{ minWidth: "100%" }} size="small">
                                                <Select
                                                    size='small'
                                                    {...field}
                                                    error={!!error}
                                                    placeholder='Select Role'
                                                    sx={{
                                                        // minHeight: '0px',
                                                        height: '2.1rem',
                                                        fontSize: '0.75rem',
                                                        '& .MuiSelect-select': {
                                                        padding: '0 8px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        },
                                                    }}
                                                >
                                                {
                                                    roleList.map((role,index)=>(
                                                    role.isActive && <MenuItem value={role.id} sx={{textTransform:'capitalize',fontSize:'0.75rem'}} key={index}>{role.title}</MenuItem>
                                                    ))
                                                }
                                                </Select>
                                            </FormControl>
                                        )}
                                    />
                                </Grid>
                            </Grid>      
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5" align="center">
                                <Button disabled={!isDirty || isSubmitting} variant="outlined" color="secondary" type="submit" size="small">
                                    {/* {Object.keys(rowId).length ?'update':'Save'} */}
                                    Invite
                                </Button>
                            </Typography>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Box>
    )
}

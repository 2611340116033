import { Card, Stack, Typography } from '@mui/material';
import React from 'react';

const PageHeaderLayout = ({headerTitle,children}) => {
  return (
    <Card sx={{ minWidth: "100%", marginBottom: ".5%" ,padding:'.5% 1%'}}>
        <Stack direction="row" spacing={2} sx={{justifyContent:'center',alignItems:'center'}}>
            <Typography
                gutterBottom
                variant='body1'
                component='div'
                sx={{fontSize:'1rem',fontWeight:'600',color:'#3e3e3e'}}
                // sx={{ padding: '20px' }}
            >
                {headerTitle}
            </Typography>
            <Typography
                variant='h3'
                component='div'
                sx={{ flexGrow: 1 }}
            />
            
            {children}
        </Stack>
    </Card>
  );
}

export default PageHeaderLayout;

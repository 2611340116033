import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Card, Grid, TextField, Typography, IconButton } from '@mui/material';
import { Controller, useForm, useFieldArray, FormProvider } from 'react-hook-form';
import { UserProfileContext } from '../UserProfile';
import { Add as AddIcon, Delete, Remove as RemoveIcon } from '@mui/icons-material';
import { ProviderServices } from '../CrudServices';
import { resetServerContext } from 'react-beautiful-dnd';
import Swal from 'sweetalert2';
import Loading from '../../../components/Loader/Loading';
import PanImageUpload from './PanImageUpload';
import AadhaarImageUpload from './AadhaarImageUpload';

const BusinessDetails = () => {
    const {otrDetail,businessDetailsData,setBusinessDetailsData} = useContext(UserProfileContext);
    const [isSubmitted,setIsSubmitted] = useState(false);
    
    const businessFormMethod = useForm({
        mode:'onChange',
        defaultValues:{
            ...businessDetailsData,
            panImage: businessDetailsData?.panImage || '',
            aadharFrontImage: businessDetailsData?.aadharFrontImage || '',
            aadharRearImage: businessDetailsData?.aadharRearImage || ''
        },
    })

    const {control,watch,setValue,handleSubmit, formState: { errors,isDirty }, setError, clearErrors,reset} = businessFormMethod;
    const { fields, append, remove } = useFieldArray({
        control,
        name: "upiId",
        // rules: { minLength: 1 }
    });
    
    const submitBusinessdetail = async (data) => {
        console.log("Business Detail Data:",data);
        // if (data?.upiId?.filter(id => id.trim() !== '').length === 0) {
        //     setError('upiId', {
        //         type: 'manual',
        //         message: 'At least one valid UPI ID is required'
        //     });
        //     return;
        // }
        // console.log("business detail data:", data);

        try{
            setIsSubmitted(true);
            const businessDetailUpdateResponse = await ProviderServices.updateUserBusinessDetail({providerId:otrDetail.id,...data});
            // console.log("Business Detail Update Response:",businessDetailUpdateResponse);
            if(businessDetailUpdateResponse?.success=='1'){
                const businessData = businessDetailUpdateResponse.data;
                setBusinessDetailsData({
                    "providerId": businessData.providerId,
                    "businessName": businessData.businessName,
                    "businessEmail": businessData.businessEmail,
                    "businessMobileNumber": businessData.businessMobileNumber,
                    "upiId": businessData.upiId,
                    "aadharNumber": businessData.aadharNumber,
                    "panNumber": businessData.panNumber,
                    "gstNumber": businessData.gstNumber,
                    'panImage': businessData?.panImage || '',
                    'aadharFrontImage': businessData?.aadharFrontImage || '',
                    'aadharRearImage': businessData?.aadharRearImage || ''
                });
                // Swal.fire('Business Detail Updated Successfully','','success');
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Business Detail Updated Successfully',
                    confirmButtonText: 'OK'
                });
                setIsSubmitted(false)
            }
            else 
            // if(businessDetailUpdateResponse?.success=='0')
                {
                Swal.fire(businessDetailUpdateResponse.message,'','error');
                Swal.fire({
                    icon: 'error',
                    // title: 'Unauthorized',
                    text: businessDetailUpdateResponse.message,
                    confirmButtonText: 'OK'
                });
                setIsSubmitted(false)
            }
        }catch(error){
            Swal.fire({
                icon: 'error',
                // title: 'Unauthorized',
                text: error.message,
                confirmButtonText: 'OK'
            });
        }
        finally{
            setIsSubmitted(false);
        }
    };
    
    useEffect(()=>{
        reset(businessDetailsData);
    },[businessDetailsData,reset])

    const panNumber = watch('panNumber');
    const aadharNumber = watch('aadharNumber');

    // Custom validation function to check if at least one field is filled
    useEffect(() => {
        if (isDirty) {
            if (panNumber || aadharNumber) {
                clearErrors('atLeastOne');
            } else {
                setError('atLeastOne', {
                    type: 'manual',
                    message: 'At least one of PAN or Aadhaar is required'
                });
            }
        }
      }, [panNumber, aadharNumber, clearErrors, setError]);
  return (
    <FormProvider {...businessFormMethod}>
        <Box component={'form'}>
            {isSubmitted && <Loading/>}
            <Grid container spacing={1}>
                <Grid item xs={12} lg={7}>
                    <Card sx={{}}>
                        <Box sx={{p:'.5% 2%',border:'2px solid lightGray',bgcolor:'lightGray'}}>
                            <Typography sx={{fontWeight:'600',fontSize:'0.85rem'}}>Basic Info</Typography>
                        </Box>
                        <Box sx={{p:'2%'}}>
                            <Box sx={{my:'1%'}}>
                                <Typography sx={{fontSize:'.75rem',fontWeight:'600'}}>Business Name<span style={{color:'red'}}>*</span></Typography>
                                <Controller
                                    name='businessName'
                                    control={control}
                                    rules={{
                                        required: "required",
                                        pattern: {
                                            value: /^[a-zA-Z0-9&.\-' ]{2,100}$/, // Pattern for Business Name
                                            message: "Invalid business name format",
                                        },
                                        minLength: {
                                            value: 2,
                                            message: "Business name should be at least 2 characters long",
                                        },
                                        maxLength: {
                                            value: 100,
                                            message: "Business name should not exceed 100 characters",
                                        },
                                    }}
                                    render={({field,fieldState:{error}})=>(
                                        <TextField
                                            {...field}
                                            fullWidth
                                            sx={{}}
                                            size='small'
                                            InputProps={{
                                                style:{
                                                    // height:'2rem',
                                                },
                                                inputProps:{
                                                    style:{
                                                        fontSize:'.75rem'
                                                    },
                                                }   
                                            }}
                                            error={!!error}
                                            // helperText={error && error?.message}
                                        /> 
                                    )}  
                                />                    
                            </Box>
                            <Box sx={{my:'1%'}}>
                                <Typography sx={{fontSize:'.75rem',fontWeight:'600'}}>Business Email<span style={{color:'red'}}>*</span></Typography>
                                <Controller
                                    name='businessEmail'
                                    control={control}
                                    rules={{
                                        required:'enter Email',
                                        pattern:{
                                            value:/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                                            message:"Email is not Valid"
                                        }
                                    }}
                                    render={({field,fieldState:{error}})=>(
                                    <TextField
                                        {...field}
                                        sx={{}}
                                        fullWidth
                                        size='small'
                                        // transform entered value into lowercase
                                        onChange={(e) => {
                                            field.onChange(e.target.value.toLowerCase());
                                        }}
                                        InputProps={{
                                            style:{
                                                // height:'2rem'
                                            },
                                            inputProps:{
                                                style:{
                                                    fontSize:'.75rem'
                                                },
                                            }   
                                        }}
                                        error={!!error}
                                        // helperText={error && error?.message}
                                    /> 
                                    )}  
                                />                    
                            </Box>
                            <Box sx={{display:'flex',gap:'2%',my:"1%"}}>
                                <Box sx={{flex:1}}>
                                    <Typography sx={{fontWeight:'600', fontSize:'0.75rem'}}>Business Mobile Number <span style={{color:'red'}}>*</span></Typography>
                                    <Controller
                                        name='businessMobileNumber'
                                        control={control}
                                        rules={{
                                            required: 'Mobile number is required',
                                            pattern: {
                                                value: /^[1-9][0-9]{9}$/,
                                                message: "Enter a valid 10-digit number not starting with 0"
                                            },
                                            validate: (value) => value.length === 10 || "Mobile number must be exactly 10 digits"
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                            <TextField
                                                {...field}
                                                size='small'
                                                error={!!error}
                                                helperText={error?.message}
                                                inputProps={{
                                                    maxLength: 10,
                                                    style:{
                                                        fontSize:'.75rem'
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    const value = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
                                                    field.onChange(value);
                                                }}
                                            />
                                        )}
                                    />
                                </Box>
                                
                                <Box sx={{flex:1}}>
                                    <Typography sx={{fontWeight:'600',fontSize:'0.75rem'}}>Gst Number</Typography>
                                    <Controller
                                    name="gstNumber"
                                    control={control}
                                    rules={{
                                        pattern: {
                                        value: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                                        message: "Invalid GST format. It should be in the format 27AAPFU0939F1ZV",
                                        },
                                        validate: (value) => !value || value.length === 15 || "GST Number must be exactly 15 characters"
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                        {...field}
                                        type="text"
                                        fullWidth
                                        size="small"
                                        error={!!error}
                                        helperText={error ? error.message : ""}
                                        inputProps={{
                                            maxLength: 15,
                                            style:{
                                                fontSize:'.75rem'
                                            }
                                        }}
                                        onChange={(e) => {
                                            const value = e.target.value.replace(/[^A-Za-z0-9]/g, '').toUpperCase().slice(0, 15);
                                            field.onChange(value);
                                        }}
                                        />
                                    )}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '2%', my: '1%',alignItems:'end' }}>
                                <Box sx={{ flex: 1 }}>
                                    <Typography sx={{ fontWeight: 600, fontSize:'0.75rem' }}>
                                    Pan Number
                                    </Typography>
                                    <Controller
                                    name="panNumber"
                                    control={control}
                                    rules={{
                                        validate: (value) => {
                                        if (!businessDetailsData.panNumber && value && !/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value)) {
                                            return "Invalid PAN format. It should be in the format ABCDE1234F";
                                        }
                                        return true;
                                        }
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            type="text"
                                            fullWidth
                                            size="small"
                                            disabled={!!businessDetailsData.panNumber}
                                            error={!!error || !!errors.atLeastOne}
                                            // helperText={error?.message || errors.atLeastOne?.message || ""}
                                            inputProps={{
                                                maxLength: 10,
                                                style:{ 
                                                    fontSize:'.75rem'
                                                }
                                            }}
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/[^A-Za-z0-9]/g, '').toUpperCase().slice(0, 10);
                                                field.onChange(value);
                                            }}
                                        />
                                    )}
                                    />
                                </Box>
                                
                                <PanImageUpload/>
                                
                            </Box>
                            <Typography variant='caption' sx={{ color:'#d32f2f'}}>{errors?.panNumber && errors?.panNumber?.message}</Typography>
                            <Box sx={{ display: 'flex', gap: '2%', my: '1%' }}>
                                <Box sx={{ flex: 1 }}>
                                    <Typography sx={{ fontWeight: 600, fontSize:'0.75rem' }}>
                                        Aadhaar Number
                                    </Typography>
                                    <Controller
                                        name='aadharNumber'
                                        control={control}
                                        rules={{
                                            validate: (value) => {
                                            if (!businessDetailsData.aadharNumber &&value && !/^[0-9]{12}$/.test(value)) {
                                                return "Enter a valid 12-digit Aadhaar number";
                                            }
                                            return true;
                                            }
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                            <TextField
                                                {...field}
                                                type='text'
                                                fullWidth
                                                size='small'
                                                disabled={!!businessDetailsData.aadharNumber}
                                                error={!!error || !!errors.atLeastOne}
                                                helperText={error?.message || errors.atLeastOne?.message || ""}
                                                inputProps={{
                                                    maxLength: 12,
                                                    style:{
                                                        fontSize:'.75rem'
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    const value = e.target.value.replace(/[^0-9]/g, '').slice(0, 12);
                                                    field.onChange(value);
                                                }}
                                            />
                                        )}
                                    />
                                </Box>
                                <Box sx={{ flex: 2 }}>
                                    <AadhaarImageUpload/>
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                </Grid>

                {/* =========upi id section=========== */}
                <Grid item xs={12} lg={5}>
                    <Card sx={{}}>
                        <Box sx={{p:'.5% 2%',border:'2px solid lightGray',bgcolor:'lightGray'}}>
                            <Typography sx={{fontWeight:'600',fontSize:'0.85rem'}}>UPI Details</Typography>
                        </Box>
                        <Box sx={{ p: '2%' }}>
                            <Typography sx={{ fontWeight: 600, fontSize:'0.75rem', mb: 1 }}>UPI IDs</Typography>
                            {fields.map((field, index) => (
                                <Box key={field.id} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                    <Controller
                                        name={`upiId.${index}`}
                                        control={control}
                                        rules={{
                                            // required: 'Enter UPI Id',
                                            // pattern: {
                                            //     value: /^[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}$/,
                                            //     message: "Invalid UPI ID format",
                                            // },
                                            // validate: (value) => {
                                            //     const validUpiIds = fields.filter((_, i) => i !== index && control._formValues.upiId[i].trim() !== '');
                                            //     if (value.trim() === '' && validUpiIds.length === 0) {
                                            //         return 'At least one valid UPI ID is required';
                                            //     }
                                            //     return true;
                                            // }
                                            validate: (value) => {
                                                // If value is empty, allow it
                                                // if (!value || value.trim() === '') {
                                                //     return true;
                                                // }
                                                // If there's a value, validate UPI ID pattern
                                                const upiPattern = /^[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}$/;
                                                return upiPattern.test(value) || "Invalid UPI ID format";
                                            }
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                size="small"
                                                error={!!error}
                                                helperText={error ? error.message : ""}
                                                inputProps={{
                                                    style: {
                                                        fontSize: '.75rem'
                                                    }
                                                }}
                                                sx={{
                                                    '& .MuiFormHelperText-root': {
                                                        fontSize: '.5rem'
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    field.onChange(e.target.value.toLowerCase());
                                                    clearErrors('upiId');
                                                }}
                                            />
                                        )}
                                    />
                                    <IconButton
                                        color='error'
                                        size='small'
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: '1rem'
                                            }
                                        }}
                                    onClick={() => {
                                        // if (fields.length > 1) {
                                            remove(index);
                                        // } else {
                                        //     setError('upiId', {
                                        //         type: 'manual',
                                        //         message: 'At least one UPI ID is required'
                                        //     });
                                        // }
                                    }}>
                                        <Delete />
                                    </IconButton>
                                </Box>
                            ))}
                            <Button
                                startIcon={<AddIcon />}
                                onClick={() => {
                                    append("");
                                    clearErrors('upiId');
                                }}
                                variant="outlined"
                                size="small"
                                sx={{ mt: 1 }}
                            >
                                Add UPI ID
                            </Button>
                            {errors.upiId && errors.upiId.type === 'manual' && (
                                <Typography sx={{fontSize:'.5rem', mt: 1}} color="error" >
                                    {errors.upiId.message}
                                </Typography>
                            )}
                        </Box>
                    </Card>
                </Grid>
            </Grid>
            <Box sx={{textAlign:'center',width:'100%',my:'1%'}}>
                <Button disabled={!isDirty || isSubmitted} variant='contained'  size='small' onClick={handleSubmit(submitBusinessdetail)}>Update Business Details</Button>
            </Box>
        </Box>
    </FormProvider>
  );
}

export default BusinessDetails;

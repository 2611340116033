import { commonRequest } from "../../services/ApiCall";
import { Helpers } from "../../services/Helpers";

export const BookingService = {
    // bookingList : async (data) =>{
    //     console.log("data :",data);
    //     // return await commonRequest("get",  `${Helpers.URL()}/api/admin/service-booking/list?page=1&limit=10&searchKey&sortKey&sortDirection&bookingStatus`,{}, Helpers.token());
    //     return await commonRequest("get",  `${Helpers.URL()}/api/admin/service-booking/list?page=${data.page+1}&limit=${data.limit}&searchKey=${data.filters.searchKey}&sortKey=${data.filters.sortKey}&sortDirection=${data.filters.sortDirection}&${data.filters.status=='all'?'bookingStatus=':data.filters.status}`,{}, Helpers.token());
    // },
    bookingList: async (data) => {
        console.log("data:", data);
        const queryParams = new URLSearchParams();
        
        // Basic pagination and sorting parameters
        queryParams.append('page', data.page + 1);
        queryParams.append('limit', data.limit);
        
        // Search key
        if (data.filters.searchKey) {
            queryParams.append('searchKey', data.filters.searchKey);
        }
        
        // Sorting
        if (data.filters.sortKey) {
            queryParams.append('sortKey', data.filters.sortKey);
            queryParams.append('sortDirection', data.filters.sortDirection);
        }
        
        // Status filter - More robust handling
        if (data.filters.status && data.filters.status !== 'all') {
            // Handle both string and array cases
            const statuses = Array.isArray(data.filters.status) 
                ? data.filters.status 
                : [data.filters.status];
            
            statuses.forEach(status => {
                queryParams.append('bookingStatus', status);
            });
        }
        
        // Additional multi-column filters
        if (data.filters.filterOptions) {
            data.filters.filterOptions.forEach((filter, index) => {
                if (filter.fieldName && filter.value) {
                    queryParams.append(`filters[${index}][fieldName]`, filter.fieldName);
                    queryParams.append(`filters[${index}][operator]`, filter.operator);
                    queryParams.append(`filters[${index}][value]`, filter.value);
                    
                    if (index !== 0) {
                        queryParams.append(`filters[${index}][andOr]`, filter.andOr);
                    }
                    
                    if (filter.operator === 'between') {
                        queryParams.append(`filters[${index}][endValue]`, filter.endValue);                        
                    }
                }
            });
        }
        
        console.log("query string:", queryParams.toString());
        
        // Construct the full URL
        const url = `${Helpers.URL()}/api/admin/service-booking/list?${queryParams.toString()}`;
    
        // Make the API call
        return await commonRequest("get", url, {}, Helpers.token());
    },

    bookingListToExport:async (data) => {
        console.log("data:", data);
        const queryParams = new URLSearchParams();
        
        // Search key
        if (data.filters.searchKey) {
            queryParams.append('searchKey', data.filters.searchKey);
        }
        
        // Sorting
        if (data.filters.sortKey) {
            queryParams.append('sortKey', data.filters.sortKey);
            queryParams.append('sortDirection', data.filters.sortDirection);
        }
        
        // Status filter - More robust handling
        if (data.filters.status && data.filters.status !== 'all') {
            // Handle both string and array cases
            const statuses = Array.isArray(data.filters.status) 
                ? data.filters.status 
                : [data.filters.status];
            
            statuses.forEach(status => {
                queryParams.append('bookingStatus', status);
            });
        }
        
        // Additional multi-column filters
        if (data.filters.filterOptions) {
            data.filters.filterOptions.forEach((filter, index) => {
                if (filter.fieldName && filter.value) {
                    queryParams.append(`filters[${index}][fieldName]`, filter.fieldName);
                    queryParams.append(`filters[${index}][operator]`, filter.operator);
                    queryParams.append(`filters[${index}][value]`, filter.value);
                    
                    if (index !== 0) {
                        queryParams.append(`filters[${index}][andOr]`, filter.andOr);
                    }
                    
                    if (filter.operator === 'between') {
                        queryParams.append(`filters[${index}][endValue]`, filter.endValue);                        
                    }
                }
            });
        }
        
        console.log("query string:", queryParams.toString());
        
        // Construct the full URL
        const url = `${Helpers.URL()}/api/admin/service-booking/export-list?${queryParams.toString()}`;
    
        // Make the API call
        return await commonRequest("get", url, {}, Helpers.token());
    },        
    // bookingList: async (data) => {
    //     console.log("data :",data);
    //     // Construct query parameters
    //     const queryParams = new URLSearchParams();
        
    //     // Basic pagination and sorting parameters
    //     queryParams.append('page', data.page + 1);
    //     queryParams.append('limit', data.limit);
        
    //     // Search key
    //     if (data.filters.searchKey) {
    //         queryParams.append('searchKey', data.filters.searchKey);
    //     }
        
    //     // Sorting
    //     if (data.filters.sortKey) {
    //         queryParams.append('sortKey', data.filters.sortKey);
    //         queryParams.append('sortDirection', data.filters.sortDirection);
    //     }
        
    //     // Status filter
    //     if (data.filters.status === 'all') {
    //         queryParams.append('bookingStatus', '');
    //     } else {
    //         queryParams.append('bookingStatus', data.filters.status);
    //         // If status is an array or comma-separated string
    //         // const statusParams = typeof data.filters.status === 'string' 
    //         //     ? data.filters.status.split(',') 
    //         //     : data.filters.status;
            
    //         // statusParams.forEach(status => {
    //         //     queryParams.append('bookingStatus', status);
    //         // });
    //     }
        
    //     // Additional multi-column filters
    //     if (data.filters.filterOptions) {
    //         data.filters.filterOptions.forEach((filter, index) => {
    //             if (filter.fieldName && filter.value) {
    //                 queryParams.append(`filters[${index}][fieldName]`, filter.fieldName);
    //                 queryParams.append(`filters[${index}][operator]`, filter.operator);
    //                 queryParams.append(`filters[${index}][value]`, filter.value);
    //                 if(index!==0){
    //                     queryParams.append(`filters[${index}][andOr]`, filter.andOr);
    //                 }
    //                 if (filter.operator=='between') {
    //                     queryParams.append(`filters[${index}][endValue]`, filter.endValue);                        
    //                 }
    //             }
    //         });
    //     }
    //     console.log("query string :",queryParams.toString());
    //     // Construct the full URL
    //     const url = `${Helpers.URL()}/api/admin/service-booking/list?${queryParams.toString()}`;

    //     // Make the API call
    //     return await commonRequest("get", url, {}, Helpers.token());
    // },
    
    getAddressList : async (customerId) =>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/service-booking/addressList/${customerId}`,{}, Helpers.token());
    },
    categoryListByCoordinates : async (data)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/service-booking/getCategoryByLatLong/${data.latitude}/${data.longitude}`,{}, Helpers.token());
    },
    subCategoryListByCoordinatesAndCategoryId : async (data)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/service-booking/getSubCategoryByLatLong/${data.latitude}/${data.longitude}/${data.categoryId}`,{}, Helpers.token());
    },
    providerListByCoordinatesAndSubCategoryId : async (data)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/service-booking/getProviderList/${data.latitude}/${data.longitude}/${data.subCategoryId}`,{}, Helpers.token());
    },
    categoryList : async (data)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/service-category/getCategories`,{}, Helpers.token());
    },

    subCategoryList : async (data)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/service-category/getSubCategories/${data.categoryId}`,{}, Helpers.token());
    },

    servicablePincodeList:async (data)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/provider-services/ServingPincodes/getAllServingPincodes`,{}, Helpers.token());
    },

    serviceExpertList:async (data)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/provider-services/getProviders/${data.pincode}`,{}, Helpers.token());
    },

    serviceList : async (data)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/provider-services/getServices/${data.providerId}/${data.subCategoryId}`,{}, Helpers.token());
    },

    timeSlots : async (data) =>{
        return await commonRequest("POST", `${Helpers.URL()}/api/admin/booking-time-slots/getProviderTimeSlots`,data, Helpers.token());
    },

    bookingDetailById : async (data) =>{
        return await commonRequest("get", `${Helpers.URL()}/api/admin/service-booking/getBookingDetails/${data.bookingId}`, {}, Helpers.token());
    },

    addBooking : async (data) =>{
        return await commonRequest("POST", `${Helpers.URL()}/api/admin/service-booking`,data, Helpers.token());
    },  
    chargeDetails : async (data) =>{
        return await commonRequest("POST", `${Helpers.URL()}/api/admin/service-booking/getSelectedProviderCharges`,data, Helpers.token());
    }  
}
import {Box, Button, Card, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { CheckCircleOutline, CloseOutlined, CloseRounded } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { Auth } from '../../services/AuthServices';

const ChangePassword = () => {
    const {control,watch,reset,handleSubmit,formState: { errors },trigger} = useForm({
        mode: "onChange",
    });
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const togglePasswordVisibility = (field) => {
        if(field=='current'){
            setShowCurrentPassword(!showCurrentPassword);
        }else{
            setShowNewPassword(!showNewPassword);
        }
    };

    useEffect(() => {
        //trigger confirm password validation when new password changes
        if(watch('newPassword')){
            trigger('confirmPassword');
        }
    }, [watch('newPassword')]);

    const onSubmit = async(data) => {
        // console.log("Submitted data:",data);
        const result = await Swal.fire({
            text: "Do you want to Change Password now?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Change now!',
            cancelButtonText: 'No, Cancel',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCloseButton: false,
        });
        
        if (result.isConfirmed) {
            try {
                // Show loading state
                Swal.fire({
                title: 'Processing...',
                text: 'Please wait while we Changing Password',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading();
                }
                });
        
                // Make the API call
                const passwordChangeResponse = await Auth.changePassword({
                    oldPassword: data.currentPassword,
                    newPassword: data.newPassword,
                });
        
                // Close loading dialog and show success/error message
                if (passwordChangeResponse.success === 1) { 
                    reset({currentPassword:'',newPassword:'',confirmPassword:''});
                    await Swal.fire({
                        icon: 'success',
                        title: 'Success!',
                        text: passwordChangeResponse.message || 'password changed successfully',
                        timer: 1500
                    });
                
                return true;
                } else {
                    await Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: passwordChangeResponse.message || 'Failed to change password'
                    });
                    return false;
                }
        
            } catch (error) {
                // console.error("Error deleting member:", error);
                await Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'An error occurred while deleting the member'
                });
                return false;
            }
        } else {
            return false;
        }
        
    }
  return (
    <Grid container >
        {/* =======================Basic Info section================== */}
        <Grid item xs={12}>
          <Card>
            <Box sx={{p:'.5% 1%',border:'2px solid lightGray',bgcolor:'lightGray'}}>
                <Typography sx={{fontWeight:'600',fontSize:'0.85rem'}}>Change Password</Typography>
            </Box>
            <Box sx={{p:'1%'}}>
              <Box component={'form'} onSubmit={handleSubmit(onSubmit)} sx={{width:'65%',m:'auto'}}>
                <Box sx={{my:'1%',display:'flex',gap:2,alignItems:'center'}}>
                    <Typography sx={{fontWeight:600,fontFamily:'Poppins',fontSize:'0.75rem',width:'40%'}}>Current Password</Typography>
                    <Controller
                        name='currentPassword'
                        control={control}
                        rules={{
                            required: "Enter current password"
                        }}
                        render={({field,fieldState:{error}})=>(
                            <TextField
                                {...field}
                                sx={{width:'50%'}}
                                size="small"
                                type={showCurrentPassword ? "text" : "password"}
                                placeholder="Enter Current Password*"
                                error={!!error}
                                helperText={error?.message}
                                InputProps={{
                                    inputProps: {
                                        style: {fontSize:'.75rem'}
                                    },
                                    endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={()=>togglePasswordVisibility('current')}
                                        edge="end"
                                        size='small'
                                        >
                                        {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    />                    
                </Box>  
                <Box sx={{flex:2,my:'2%',display:'flex',gap:2,alignItems:'center'}}>
                    <Typography sx={{fontWeight:600,fontFamily:'Poppins',fontSize:'0.75rem',width:'40%'}}>New Password</Typography>
                    <Controller
                        name='newPassword'
                        control={control}
                        rules={{
                            required: "Enter New password",
                            // pattern: {
                            //     value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                            //     message: "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character"
                            // },
                            validate: {
                                hasUpperCase: (value) => 
                                    /[A-Z]/.test(value) || "Password must contain at least one uppercase letter",
                                hasLowerCase: (value) => 
                                    /[a-z]/.test(value) || "Password must contain at least one lowercase letter",
                                hasNumber: (value) => 
                                    /\d/.test(value) || "Password must contain at least one number",
                                hasSpecialChar: (value) => 
                                    /[@$!%*?&]/.test(value) || "Password must contain at least one special character",
                                minLength: (value) => 
                                    value.length >= 6 || "Password must be at least 6 characters long",
                                differentFromCurrent: (value) =>  
                                    value !== watch('currentPassword') || "New password must be different from current password"
                            }
                        }}
                        render={({field,fieldState:{error}})=>(
                            <TextField
                                {...field}
                                sx={{width:'50%'}}
                                // fullWidth
                                size="small"
                                type={showNewPassword ? "text" : "password"}
                                placeholder="Enter New Password"
                                error={!!error}
                                helperText={error?.message}
                                InputProps={{
                                    inputProps: {
                                        style: {fontSize:'.75rem'}
                                    },
                                    endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={()=>togglePasswordVisibility('new')}
                                        edge="end"
                                        size='small'
                                        >
                                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    />              
                </Box>
                <Box sx={{flex:2,my:'2%',display:'flex',gap:2,alignItems:'center'}}>
                    <Typography sx={{fontWeight:600,fontFamily:'Poppins',fontSize:'0.75rem',width:'40%'}}>Confirm New Password</Typography>
                    <Controller
                        name='confirmPassword'
                        control={control}
                        rules={{
                            validate: (value)=>{
                                if(!value) return "Re-Enter New password";
                                if(value !== watch('newPassword')) return "Password does not match";
                                return true;
                            }
                        }}
                        render={({field,fieldState:{error}})=>(
                            <TextField
                                {...field}
                                sx={{width:'50%'}}
                                // fullWidth
                                size="small"
                                type='text'
                                placeholder="Re-Enter New Password"
                                error={!!error}
                                inputProps={{
                                    style: {fontSize:'.75rem'}
                                }}
                            />
                        )}
                    /> 
                    {errors.confirmPassword && <CloseRounded color='error'/> } 
                    {watch('confirmPassword') && !errors.confirmPassword && <CheckCircleOutline color='success'/>}             
                </Box>
                <Box sx={{textAlign:'center',my:'5%'}}>       
                    <Button variant='contained' color='primary' type='submit' size='small'>Change Password</Button>
                </Box>
              </Box>           
              
            </Box>
          </Card>
        </Grid>
      </Grid>
  );
}

export default ChangePassword;

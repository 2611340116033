import React, { createContext, useEffect, useState } from 'react';
import RoleList from './RoleList';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import AddRole from './AddRole';
import ViewRole from './ViewRole';
import MainContentLayout from '../../../components/Layout/MainContentLayout';

const RoleDataContext = createContext();
const Role = () => {
    const menuDispatch = useDispatch();
    useEffect(() => {
        menuDispatch({
            type: 'menuSelect',
            payload: 'roles'
        });
    }, []);
    const [actionTab,setActionTab] = useState(0);
    const [selectedRole,setSelectedRole] = useState(null);
    const [roleList,setRoleList] = useState([]);
    return (
        <MainContentLayout>
            <RoleDataContext.Provider value={{actionTab,setActionTab,selectedRole,setSelectedRole,roleList,setRoleList}}>
                
                {actionTab=='0' &&<RoleList/>}
                {actionTab=='1' && <AddRole/>}
                {actionTab=='2' && <ViewRole />}
                
            </RoleDataContext.Provider>
        </MainContentLayout>
    )
}

export default Role;
export {RoleDataContext}
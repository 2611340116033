import { useState, useEffect, useContext } from 'react';
import { Badge,Card,Chip, Typography, Divider,MenuItem, Stack, TextField, Grid, Button, Box, Toolbar, TableCell, FormControl, InputLabel, Select, Tooltip, IconButton, Modal, Switch, Avatar, styled, Paper, CircularProgress } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Swal from 'sweetalert2';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { isMobile } from 'react-device-detect';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { useForm, Controller } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from '@mui/icons-material/Search';
import { useLocation, useNavigate } from 'react-router-dom';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Helpers } from '../../../services/Helpers';
import AddRole from './AddRole';
import RoleCard from './RoleCard';
import { Add } from '@mui/icons-material';
import { RoleAndPermissionServices } from '../RoleAndPermissionServices';
import Loading from '../../../components/Loader/Loading';
import { RoleDataContext } from '.';
import { PermissionGuard } from '../../../components/PermissionGuard';
import LoadingOverlay from '../../../components/Layout/LoadingOverlay';
import PageHeaderLayout from '../../../components/Layout/PageHeaderLayout';

const style = {
    position: 'absolute',
    top: (isMobile) ? '80%' : '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #ddd',
    boxShadow: 24,
    p: 2,
};

  
const RoleList = () => {
    const {actionTab,setActionTab,selectedRole,setSelectedRole,roleList,setRoleList} = useContext(RoleDataContext);
    const { control, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm({
        mode: "onChange"
    });

    const [loading,setLoading] = useState(false);
    
    /************************  selected service/subservice data for edit**************************** */
    const [selectedServiceData,setSelectedServiceData] = useState({});

    const navigate = useNavigate();
    const fetchRoleAndPermissionList = async ()=>{
        setLoading(true)
        try {
        const roleListResponse = await RoleAndPermissionServices.roleList();
        // console.log("fetching role list response:",roleListResponse);
        const data = roleListResponse.permissionArr;
        if(roleListResponse.success=='1'){
            setRoleList(data);
        }
        } catch (error) {
            console.log("error:",error.message)
            // Handle network-related errors
            if (error.message.includes('Failed to fetch') || 
            error.message.includes('Network request failed') || 
            error.message.includes('net::ERR_INTERNET_DISCONNECTED') || 
            error.message.includes('net::ERR_NETWORK_CHANGED')) {
            
            Swal.fire({
                icon: 'error',
                title: 'Network Error',
                text: 'Unable to connect to the server. Please check your internet connection.',
                confirmButtonText: 'Retry',
                showCancelButton: true,
                cancelButtonText: 'Close',
                willClose: () => {
                    setLoading(false);
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    // Retry fetching the role list
                    fetchRoleAndPermissionList();
                }
            });
        } else {
            // For other types of errors
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message || 'An unexpected error occurred',
                confirmButtonText: 'OK'
            });
        }
        }finally{
            setLoading(false)
        }
    };
  
    /************************  Manage Use Effect **************************** */
    useEffect(() => {
        fetchRoleAndPermissionList();
    }, []);   
    const [openModel,setOpenModel] = useState(false);

    /************************ add/Edit Modal Manage **************************** */
    const modalOpen = () => {    
        setOpenModel(true)
    
    }
    /**=========handles to close open add/edit modal=================== */
    const handleClose=()=>{
        // console.log("close handle hitted");
        fetchRoleAndPermissionList();
        setActionTab(0);
        setOpenModel(false);
        setSelectedRole(null);
        
    }
    return (
        <>    
            {/* =========== pop up models like ass/Edit Role ANd Permission modal=============== */} 
            <Modal
                open={openModel}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ overflow: "scroll" }}
            >
                <Box 
                    sx={{
                        ...style,
                        width: isMobile ? "95%" : "75%",
                        maxHeight: "90vh", // Max height for the modal
                        overflowY: "auto", // Enable scrolling within the modal
                        // display: "flex",
                        // flexDirection: "column",
                        p:0
                    }}
                >
                    <AddRole
                        onClose={handleClose} 
                    />
                </Box>
            </Modal>
            {/* ================= page title section and containing add service/sub service button============ */}
            <PageHeaderLayout headerTitle={'Manage Roles'}>
                <PermissionGuard feature="roles-and-permissions" permission="canCreate">
                    <Button variant='contained' onClick={() => modalOpen()} sx={{ height: "30px", marginTop: "10px" }} startIcon={<AddCircleIcon />}>
                        New Role
                    </Button>
                </PermissionGuard>
            </PageHeaderLayout>
            {/* <Card sx={{ minWidth: "100%", marginBottom: "10px" }}>
                <Stack direction="row" spacing={2} className="" alignItems={'center'} sx={{p:'2%'}}>
                    <Typography
                        gutterBottom
                        variant='h5'
                        component='div'
                        // sx={{ padding: '20px' }}
                    >
                        Manage Roles
                    </Typography>
                    <Typography
                        variant='h3'
                        component='div'
                        sx={{ flexGrow: 1 }}
                    ></Typography>
                    <PermissionGuard feature="roles-and-permissions" permission="canCreate">
                        <Button variant='contained' onClick={() => modalOpen()} sx={{ height: "30px", marginTop: "10px" }} startIcon={<AddCircleIcon />}>
                            New Role
                        </Button>
                    </PermissionGuard>
                </Stack>
            </Card> */}
            {/* {loading && <Box sx={{width:"50%",textAlign:'center',m:'auto'}}>
                            <CircularProgress/>
                        </Box>} */}
            <Box
                sx={{ 
                    position: 'relative',
                    minHeight: 200, // Ensure there's always space for the content
                    }}
            >
                {loading && (
                    <LoadingOverlay 
                    message="Loading roles..."
                    blur={true}
                    opacity={0.7}
                    spinnerSize={48}
                    />
                )}
                <Grid container spacing={1}>
                    {
                        roleList.map((role,index)=>(
                            <Grid item xs={isMobile?12:4} key={index} sx={{}}>
                                <RoleCard roleData={role} performEdit={modalOpen} fetchRoleAndPermissionList={fetchRoleAndPermissionList}/>
                            </Grid>
                        ))
                    }
                </Grid>
            </Box>
        </>
    )
}

export default RoleList;




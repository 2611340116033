import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import MainContentLayout from "../../components/Layout/MainContentLayout";
import ReportDashboard from "./ReportDashboard";

export default function Reports(){
    const menuDispatch = useDispatch();
    useEffect(() => {
        menuDispatch({
            type: 'menuSelect',
            payload: 'reports'
        });
    }
    , []);
    return (
        <MainContentLayout>
            <ReportDashboard />
        </MainContentLayout>
    )
}
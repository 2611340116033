// src/pages/Unauthorized.js
import React from 'react';
import { 
  Box, 
  Button, 
  Container, 
  Typography, 
  Paper,
  styled,
  useTheme
} from '@mui/material';
import { 
  LockPerson, 
  ArrowBack, 
  Home 
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { keyframes } from '@mui/system';

// Define animations
const bounceAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled components
const AnimatedIcon = styled(Box)(({ theme }) => ({
  animation: `${bounceAnimation} 2s infinite ease-in-out`,
  color: theme.palette.error.main,
  '& svg': {
    fontSize: 100,
  },
  marginBottom: theme.spacing(3),
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  animation: `${fadeIn} 0.8s ease-out`,
  textAlign: 'center',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(6),
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.mode === 'dark' 
    ? theme.palette.grey[900] 
    : theme.palette.grey[50],
  boxShadow: theme.shadows[8],
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '4px',
    background: `linear-gradient(90deg, ${theme.palette.error.main}, ${theme.palette.error.light})`,
  },
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'center',
  marginTop: theme.spacing(4),
  '& button': {
    minWidth: '150px',
  },
}));

const UnauthorizedPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleBack = () => {
    navigate(-1);
  };

  const handleHome = () => {
    navigate('/');
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          py: 4,
        }}
      >
        <ContentWrapper>
          <StyledPaper elevation={3}>
            <AnimatedIcon>
              <LockPerson fontSize="inherit" />
            </AnimatedIcon>

            <Typography 
              variant="h3" 
              component="h1" 
              gutterBottom
              sx={{ 
                fontWeight: 'bold',
                color: theme.palette.error.main,
              }}
            >
              401: Unauthorized
            </Typography>

            <Typography 
              variant="h6" 
              gutterBottom
              color="textSecondary"
            >
              Oops! You don't have permission to access this page.
            </Typography>

            <Typography 
              variant="body1" 
              color="textSecondary"
              paragraph
              sx={{ maxWidth: '80%', mx: 'auto', mb: 4 }}
            >
              It seems you don't have the necessary permissions to view this content. 
              If you believe this is an error, please contact your service provider.
            </Typography>

            <ButtonContainer>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<ArrowBack />}
                onClick={handleBack}
                size="large"
              >
                Go Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Home />}
                onClick={handleHome}
                size="large"
              >
                Home Page
              </Button>
            </ButtonContainer>
          </StyledPaper>
        </ContentWrapper>
      </Box>
    </Container>
  );
};

export default UnauthorizedPage;
import { Box, Button, Grid, Paper, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import PageHeaderLayout from '../../components/Layout/PageHeaderLayout';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import BookingReport from './Booking/BookingReport';

const ReportDashboard = () => {
  const navigate = useNavigate();
  const [tabValue,setTabValue] = useState(0);


  return (
    <>
      <PageHeaderLayout headerTitle={'Cudel Reports'}>
        <Button variant='contained' onClick={() => navigate(-1)} size='small' endIcon={<ArrowBack />}>
          Back
        </Button>
      </PageHeaderLayout>
      <Box sx={{ }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper sx={{}}>
              <Tabs
                value={tabValue}
                onChange={(event, newValue) => {
                  setTabValue(newValue);
                }}
                textColor='primary'
                indicatorColor='primary'
                // variant='fullWidth'
                sx={{ width: "100%" ,minHeight:0 }}
              >
                <Tab label="Booking" sx={{fontSize:'.8rem',minHeight:0, padding:'1%',textTransform:'capitalize'}}/>
                <Tab label="Customer" sx={{fontSize:'.8rem',minHeight:0, padding:'1%',textTransform:'capitalize'}}/>
                <Tab label="Experts" sx={{fontSize:'.8rem',minHeight:0, padding:'1%',textTransform:'capitalize'}}/>
                <Tab label="Finance" sx={{fontSize:'.8rem',minHeight:0, padding:'1%',textTransform:'capitalize'}}/>
              </Tabs>
            </Paper>
            <Grid item xs={12}>
              <Box sx={{minHeight:400,mt:'.5%'}} >
                {tabValue === 0 && <BookingReport/>}
                {/* {tabValue === 1 && <Box>Customer</Box>}
                {tabValue === 2 && <Box>Experts</Box>}
                {tabValue === 3 && <Box>Finance</Box>} */}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default ReportDashboard;

import { commonRequest } from "../../../services/ApiCall";
import { Helpers } from "../../../services/Helpers";

export const RewardServices = {
    programList : async (data)=>{
        console.log("data",data);
        const queryParams = new URLSearchParams();

        // Basic pagination and sorting parameters
        queryParams.append('page', data.page + 1);
        queryParams.append('limit', data.limit);

        // Search parameters
        queryParams.append('searchKey', data.filters.searchKey || '');
        

        // Sorting parameters
        if (data.filters.sortKey) {
            queryParams.append('sortKey', data.filters.sortKey);            
            queryParams.append('sortDirection', data.filters.sortDirection || 'desc');
        }
        
        // // Active or not filters
        // queryParams.append('isActive', data.filters?.isActive || '');

        // Additional multi-column filters
        if (data.filters.filterOptions) {
            data.filters.filterOptions.forEach((filter, index) => {
                if (filter.fieldName && filter.value) {
                    queryParams.append(`filters[${index}][fieldName]`, filter.fieldName);
                    queryParams.append(`filters[${index}][operator]`, filter.operator);
                    queryParams.append(`filters[${index}][value]`, filter.value);
                    
                    if (index !== 0) {
                        queryParams.append(`filters[${index}][andOr]`, filter.andOr);
                    }
                    
                    if (filter.operator === 'between') {
                        queryParams.append(`filters[${index}][endValue]`, filter.endValue);                        
                    }
                }
            });
        }
        console.log("queryParams",queryParams.toString());
        return await commonRequest("GET",  `${Helpers.URL()}/api/admin/reward/list?${queryParams.toString()}`,{}, Helpers.token());
    },
    createProgram : async (data)=>{
        return await commonRequest("POST", `${Helpers.URL()}/api/admin/reward`,data, Helpers.token());
    },
    updateProgram : async (data)=>{
        return await commonRequest("PUT", `${Helpers.URL()}/api/admin/reward/${data.id}`,data, Helpers.token());
    },
    changeStatus : async (data)=>{
        return await commonRequest("get", `${Helpers.URL()}/api/admin/reward/change-status/${data.id}`,data, Helpers.token());
    },
    changeDisplayStatus : async (data)=>{
        return await commonRequest("get", `${Helpers.URL()}/api/admin/reward/change-display-status/${data.id}`,data, Helpers.token());
    },
    exportList : async (data) =>{
        console.log("data",data);
        const queryParams = new URLSearchParams();

        // Search parameters
        queryParams.append('searchKey', data.filters.searchKey || '');
        

        // Sorting parameters
        if (data.filters.sortKey) {
            queryParams.append('sortKey', data.filters.sortKey);            
            queryParams.append('sortDirection', data.filters.sortDirection || 'desc');
        }
        

        // Additional multi-column filters
        if (data.filters.filterOptions) {
            data.filters.filterOptions.forEach((filter, index) => {
                if (filter.fieldName && filter.value) {
                    queryParams.append(`filters[${index}][fieldName]`, filter.fieldName);
                    queryParams.append(`filters[${index}][operator]`, filter.operator);
                    queryParams.append(`filters[${index}][value]`, filter.value);
                    
                    if (index !== 0) {
                        queryParams.append(`filters[${index}][andOr]`, filter.andOr);
                    }
                    
                    if (filter.operator === 'between') {
                        queryParams.append(`filters[${index}][endValue]`, filter.endValue);                        
                    }
                }
            });
        }
        console.log("queryParams",queryParams.toString());
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/reward/export-list?${queryParams.toString()}`, {}, Helpers.token());
    },
}
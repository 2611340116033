import { Button,Paper, Dialog, DialogActions, DialogContent,TableRow,TableCell,TextField,TableContainer,Table,TableHead,TableBody, DialogTitle, Typography, Box } from '@mui/material';
import React, { useContext, useState } from 'react';
import { FormProvider,useForm, Controller } from 'react-hook-form';
import formatValue from '../../../utils/formatNumber';
import { UserProfileContext } from '../UserProfile';
import Swal from 'sweetalert2';
import { ProviderServices } from '../CrudServices';
import Loading from '../../../components/Loader/Loading';

const EditServiceDialog = ({serviceData,onClose,openUpdateDialog}) => {
    const {serviceDetailsData,setServiceDetailsData} = useContext(UserProfileContext);
    // console.log("service data:",serviceData)
    const {handleSubmit,watch,reset,control,formState:{isDirty}} = useForm({
        mode:'onChange',
        defaultValues:serviceData,
    })
    const [isSubmitted,setIsSubmitted] = useState(false);
    const handleServiceUpdate = async(data)=>{
        setIsSubmitted(true);
        // console.log("submitted form data:",data);
        const {servicesChildId,servicesMasterId,price,serviceTime,...restall} = data;
        const updatedData = {
            servicesChildId,
            servicesMasterId,
            price,
            serviceTime,
            providerId:serviceDetailsData.providerId
        }
        // console.log("updated provider service data:",updatedData);
        try {
            const serviceUpdateResponse = await ProviderServices.updateProviderServices(updatedData);
            // console.log("service update Response:",serviceUpdateResponse)
            if (serviceUpdateResponse.success == '1') {
                setIsSubmitted(false);
                Swal.fire('Success','Service Updated Successfully');
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Service Updated Successfully',
                    timer: 1500
                })
                onClose();
            } else {
                setIsSubmitted(false);
                Swal.fire('Error','Failed To Update Service');
            }
        } catch (error) {
            setIsSubmitted(false);
            Swal.fire("Error",'Something went wrong','error')
        }
    }
  return (
    <Dialog open ={openUpdateDialog}
        fullWidth={true} 
        maxWidth="md" 
        PaperProps={{
        sx: {
            width: 'fit-content', 
            maxWidth: '100%' 
        },
        }}
    >
        {isSubmitted && <Loading/>}
        <Box component={'form'} onSubmit={handleSubmit(handleServiceUpdate)}>
            <DialogTitle sx={{borderBottom:'1px solid lightGray'}}>
                <Typography sx={{fontWeight:'600'}}>Edit Service</Typography>
            </DialogTitle>
            <DialogContent sx={{mt:'2%'}} >
                <TableContainer component={Paper}>
                    <Table size='small' sx={{ minWidth: 'max-content' }} aria-label="selected services table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{fontWeight:'600'}}>Category</TableCell>
                                <TableCell sx={{fontWeight:'600'}}>Master Service</TableCell>
                                <TableCell sx={{fontWeight:'600'}}>Child Service</TableCell>
                                <TableCell align="center" sx={{ width: '1rem',fontWeight:'600' }}>Time (min)</TableCell>
                                <TableCell align="center" sx={{ width: '1rem',fontWeight:'600' }}>Price</TableCell>
                                {/* <TableCell align="center" sx={{ width: '1rem',fontWeight:'600' }}>Actions</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow >
                                <TableCell>{serviceData.subCategoryTitle}</TableCell>
                                <TableCell>{serviceData?.masterServiceTitle}</TableCell>
                                <TableCell>{serviceData.childServiceTitle}</TableCell>
                                <TableCell align="right">
                                    <Controller
                                        name="serviceTime"
                                        control={control}
                                        rules={{
                                        required: 'Value is required',
                                        validate: (value) => {
                                            const intValue = parseInt(value, 10);
                                            return !isNaN(intValue) && intValue >= 0 || 'Value should be a non-negative integer';
                                        }
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            value={formatValue(field.value)}
                                            onChange={(e) => {
                                                const inputValue = e.target.value;
                                                if (inputValue === '' || /^\d+$/.test(inputValue)) {
                                                    field.onChange(formatValue(inputValue));
                                                }
                                            }}
                                            onBlur={(e) => {
                                                field.onBlur();
                                                field.onChange(formatValue(e.target.value));
                                            }}
                                            type="number"
                                            inputMode="numeric"
                                            size="small"
                                            inputProps={{
                                            style: { textAlign: 'center', width: 'fit-content', height: '1rem',width:'3rem' },
                                            min: 0,
                                            step: 1 // Only allow whole numbers
                                            }}
                                            error={!!error}
                                            // helperText={error ? error.message : ''}
                                        />
                                        )}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <Controller
                                        name="price"
                                        control={control}
                                        rules={{
                                            required: 'Price is required',
                                            validate: (value) => {
                                                const floatValue = parseFloat(value);
                                                if (isNaN(floatValue)) return 'Please enter a valid number';
                                                if (floatValue > 0 && floatValue < 1) return 'Price should not be less than ₹1';
                                                return floatValue >= 0 || 'Price should not be negative';
                                            }
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                            <TextField
                                                {...field}
                                                type="number"
                                                value={formatValue(field.value)}
                                                onChange={(e) => {
                                                const inputValue = e.target.value;
                                                if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
                                                    field.onChange(formatValue(inputValue));
                                                }
                                                }}
                                                onBlur={(e) => {
                                                field.onBlur();
                                                field.onChange(formatValue(e.target.value));
                                                }}
                                                // type="text"
                                                inputMode="decimal"
                                                size="small"
                                                inputProps={{
                                                    style: { textAlign: 'center', width: 'fit-content', height: '1rem' },
                                                    min: 0,
                                                    step: 0.01 // Allow two decimal places
                                                }}
                                                error={!!error}
                                                helperText={error ? error.message : ''}
                                            />
                                        )}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button disabled={!isDirty || isSubmitted} variant='contained' color='primary' type='submit' >Update</Button>
                <Button variant='contained' color='error' onClick={()=>onClose()}>Cancel</Button>
            </DialogActions>
        </Box>   
    </Dialog>
  );
}

export default EditServiceDialog;

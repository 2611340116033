import React, { useEffect, useState } from 'react';
import { TextField, Button, Typography, IconButton, Grid, FormControl, InputLabel, Select, MenuItem, FormLabel, RadioGroup, FormControlLabel, Radio, Autocomplete, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm ,Controller} from 'react-hook-form';
import Swal from "sweetalert2";
import ImgUploadDialog from "../../components/ImgUpload/imgUpload";
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { CrudServices } from "./CrudServices";
import { isMobile } from "react-device-detect";
import { BookingService } from "../Booking/Service";
import { Helpers } from '../../services/Helpers';
import Loading from '../../components/Loader/Loading';
import { Edit } from '@mui/icons-material';
import { PermissionGuard } from '../../components/PermissionGuard';


export default function ViewService({ closeEvent,serviceData,handleEdit,sacList }) {
    const [serviceInitialState,setServiceInitialState] = useState({  
        title:'',
        shortTitle:'',
        description:'',
        image:'',
        categoryId:'',
        serviceCategoryId:'',
        parentServiceId:0,
        serviceType:'parent',
        
    })
    const { control, reset, setValue, watch, formState: { errors,isDirty } } = useForm({
        mode: "onChange",
        defaultValues:serviceInitialState,setServiceInitialState
    });
    const [isLoading,setIsLoading] = useState (false);

    
    const [categoryList,setCategoryList] = useState([]);
    const fetchCategoryList= async()=>{
        try {
          const categoryListResponse = await BookingService.categoryList();
        //   console.log("category list Response:",categoryListResponse);
          if(categoryListResponse.success===1){
            setCategoryList(categoryListResponse.data)
          }
        } catch (error) {
          
        }
    }
    
    /*================= on component load==================== */
    
    useEffect(() => {
      fetchCategoryList();
    }, []);
    /*================= on category selection==================== */
    const [subCategoryList,setSubCategoryList] = useState([]);
    const categoryId = watch('categoryId');
    const fetchSubCategoryList= async()=>{
        setIsLoading(true);
        try {
          const subCategoryListResponse = await BookingService.subCategoryList({categoryId:categoryId});
        //   console.log("sub category list Response:",subCategoryListResponse);
          if(subCategoryListResponse.success===1){
            setSubCategoryList(subCategoryListResponse.data)
          }
        } catch (error) {
          
        }finally{
            setIsLoading(false);
        }
    }
    useEffect(()=>{
        if(categoryId){
            fetchSubCategoryList();
        }
    },[categoryId])
    /*============== on SubCategory selection ============ */
    const subCategoryId=watch('serviceCategoryId');
    const serviceType = watch('serviceType', 'parent');
    const [parentServices,setParentServices] = useState([]);
    useEffect(()=>{
      if(subCategoryId && serviceType=='child'){
        // setValue('parentServiceId',0)
        setParentServices([]);
        const fetchServiceList= async()=>{
            setIsLoading(true);
          try {
            const serviceListResponse = await CrudServices.serviceListBySubCategory(subCategoryId);
            // console.log("service list Response:",serviceListResponse);
            if(serviceListResponse.success===1){
                setParentServices(serviceListResponse.data)
            }
          } catch (error) {
            
          }finally{
            setIsLoading(false);
          }
        }
        fetchServiceList();
      }
    },[subCategoryId,serviceType])
    /*============================================*/
    useEffect(() => {
        // categoryList();
        if (serviceData.id>0) {
            if (serviceData.parentServiceId>0) {
                setServiceInitialState({serviceType:'child', categoryId: serviceData.categoryId,serviceCategoryId:serviceData?.serviceCategoryId,...serviceData});
            }else{
                setServiceInitialState(pre=>({...pre,...serviceData}));
            }
        }

    }, [serviceData])
    useEffect(()=>{
        reset(serviceInitialState);
    },[serviceInitialState,reset]) 
    
    return (
        <>
            
            <Box sx={{display:'flex',justifyContent:'space-between',borderBottom:'1px solid #ccc',alignItems:'center',p:'1% 2%'}}>
                <Typography sx={{fontSize:'1rem',fontWeight:600}}>Service Details</Typography>
                <Box sx={{display:'flex',gap:'5%'}}>
                    <PermissionGuard feature='service' permission='canEdit'>
                        <Button sx={{p:0}} size='small' variant='outlined' color='warning' startIcon={<Edit/>} onClick={()=>handleEdit()}>
                            Edit
                        </Button>
                    </PermissionGuard>
                    <Button
                        variant='outlined'
                        size='small'
                        sx={{p:0}}
                        onClick={closeEvent}
                        color='error'
                    >
                        {/* <CloseIcon fontSize='1rem'/> */}
                        close
                    </Button>
                </Box>
            </Box>
            <Grid container spacing={2} sx={{padding:'1%'}}>
                {isLoading && <Loading/>}
                <Grid item xs={3}>
                    {
                        serviceData.image && <img style={{ width: '100%' }}
                            src={`${Helpers.ImagePathUrl()}/service/md/${serviceData.image}`}
                        />
                    }
                </Grid>
                <Grid item xs={9}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography sx={{ fontSize: '0.85rem', fontWeight: 600 }}>Category</Typography>
                            <TextField
                                value={categoryList.find(cat => cat.id == serviceInitialState.categoryId)?.title || ''}
                                InputProps={{
                                    style: { fontSize: '0.75rem' },
                                }}
                                disabled
                                variant="outlined"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{ fontSize: '0.85rem', fontWeight: 600 }}>Sub Category</Typography>
                            <TextField
                                value={subCategoryList.find(subCat => subCat.id == serviceInitialState.serviceCategoryId)?.title || ''}
                                InputProps={{
                                    style: { fontSize: '0.75rem' },
                                }}
                                disabled
                                variant="outlined"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <Typography sx={{fontSize:'0.85rem',fontWeight:600}}>SAC Code </Typography>
                            <TextField
                                value={sacList.find(item => item.id === serviceData.sacId)?.sac || ''}
                                // label="SAC Code"
                                variant="outlined"
                                size="small"
                                disabled
                                inputProps={{
                                    style:{fontSize:'0.75rem'}
                                }}
                                fullWidth
                            />    
                        </Grid>
                        <Grid item xs={7}>
                            <FormControl component="fieldset">
                            <FormLabel component="legend" sx={{fontSize:'0.85rem', fontWeight:600, color:'#000'}}>Service Type</FormLabel>
                                <RadioGroup value={serviceInitialState.serviceType} row >
                                    <FormControlLabel value="parent" 
                                        control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: '0.9rem' } }} disabled/>} 
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                            fontSize: '0.75rem'
                                            }
                                        }} label="Parent Service" 
                                    />
                                    <FormControlLabel value="child" 
                                        control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: '0.9rem' } }} disabled/>}
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                            fontSize: '0.75rem'
                                            }
                                        }} label="Child Service" 
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        {serviceType === 'child' && (
                            <Grid item xs={6} >
                                <Typography sx={{fontSize:'0.85rem',fontWeight:600}}>Parent Service </Typography>
                                <TextField
                                    // label={'Parent Service'}
                                    value={parentServices.filter(parent=>parent.masterServiceId==serviceInitialState.parentServiceId)[0]?.title}
                                    inputProps={{
                                        style:{fontSize:'0.75rem'}   
                                    }}
                                    disabled
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    >
                                </TextField>
                            </Grid>
                        )}
                        <Grid item xs={6} >
                            <Typography sx={{fontSize:'0.85rem',fontWeight:600}}>Service Name </Typography>
                            <TextField
                                value={serviceInitialState.title}
                                // label="Service Name"
                                variant="outlined"
                                size="small"
                                fullWidth 
                                disabled
                                inputProps={{
                                    style:{fontSize:'0.75rem'}
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <Typography sx={{fontSize:'0.85rem',fontWeight:600}}>Service Short Title </Typography>
                            <TextField
                                value={serviceInitialState.shortTitle}
                                // label="Service Short Title*"
                                variant="outlined"
                                size="small"
                                disabled
                                inputProps={{
                                    style:{fontSize:'0.75rem'}
                                }}
                                fullWidth 
                            />
                        </Grid>
                        
                        <Grid item xs={6}>
                            <Typography sx={{fontSize:'0.85rem',fontWeight:600}}>Service Description </Typography>
                            <TextField
                                value={serviceInitialState.description}
                                // label="Service Description"
                                variant="outlined"
                                disabled
                                size="small"
                                inputProps={{
                                    style:{fontSize:'0.75rem'}
                                }}
                                fullWidth 
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

import React, { useEffect, useState } from 'react';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { BarChart } from '@mui/x-charts/BarChart';
import Swal from 'sweetalert2';
import { DashboardServices } from '../Service';
import dayjs from 'dayjs';
import { Box, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import LoadingOverlay from '../../../components/Layout/LoadingOverlay';

const otherSetting = {
  // height: 300,
  // yAxis: [{ label: 'Sale (₹)' }],
  grid: { horizontal: true },
  width: 600,
  height: 300,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translate(0px, 0)', // Increased from -10px to -20px for more space
    },
  },
};

// const dataset = [
//   {    
//     sale: 21,
//     month: 'January',
//   },
//   {
//     sale: 28,
//     month: 'February',
//   },
//   {
//     sale: 41,
//     month: 'March',
//   },
//   {
//     sale: 73,
//     month: 'April',
//   },
//   {
//     sale: 99,
//     month: 'May',
//   },
//   {
//     sale: 144,
//     month: 'June',
//   },
//   {
//     sale: 319,
//     month: 'July',
//   },
//   {
//     sale: 249,
//     month: 'August',
//   },
//   {
//     sale: 131,
//     month: 'September',
//   },
//   {
//     sale: 55,
//     month: 'October',
//   },
//   {
//     sale: 48,
//     month: 'November',
//   },
//   {
//     sale: 25,
//     month: 'December',
//   },
// ];
//list of years current and last 3 years
const years = [
  dayjs().format('YYYY'),
  dayjs().subtract(1, 'year').format('YYYY'),
  dayjs().subtract(2, 'year').format('YYYY'),  
  dayjs().subtract(3, 'year').format('YYYY'),
];
const valueFormatter = (value) => `₹ ${value}`;

export default function SaleChart() {

  const [isLoading, setIsLoading] = useState(false);
  const [saleData,setSaleData]  = useState([
    {    
      sale: 0,
      month: 'January',
    },
    {
      sale: 0,
      month: 'February',
    },
    {
      sale: 0,
      month: 'March',
    },
    {
      sale: 0,
      month: 'April',
    },
    {
      sale: 0,
      month: 'May',
    },
    {
      sale: 0,
      month: 'June',
    },
    {
      sale: 0,
      month: 'July',
    },
    {
      sale: 0,
      month: 'August',
    },
    {
      sale: 0,
      month: 'September',
    },
    {
      sale: 0,
      month: 'October',
    },
    {
      sale: 0,
      month: 'November',
    },
    {
      sale: 0,
      month: 'December',
    },
  ]);

  const [selectedYear, setSelectedYear] = useState(dayjs().format('YYYY'));
  
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const response = await DashboardServices.saleChart({year:selectedYear});
        if (response.success == '1') {
          setSaleData(response.data);
        } 
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops something went wrong...',
          text: error.message,
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [selectedYear]);
  return (
    <Card sx={{ height: 60 + "vh" ,position:'relative'}}>
      {isLoading &&(
        <LoadingOverlay 
          message="Please wait while fetching Sale details..."
          blur={true}
          opacity={0.7}
          spinnerSize={48}
        />
      )}
      <CardContent sx={{p:'0!important',pb:'0!important'}}>
        <Grid container>
          <Grid item xs={12}>
            <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between',borderBottom:'1px solid lightGray',p:'1% 2%'}}>
                <Typography gutterBottom sx={{ flex:1,fontWeight:600,color:'#3e3e3e',fontSize:'.85rem' }} >
                    Cudel Sale
                </Typography>
                <FormControl sx={{}} variant="outlined">
                    <InputLabel sx={{fontSize:'0.65rem',lineHeight: '2.4375em'}}>Year</InputLabel>
                    <Select
                        size="small"
                        value={selectedYear}
                        onChange={handleYearChange}
                        sx={{
                            minHeight: '0px',
                            height: '1.6rem',
                            fontSize: '0.75rem',
                            fontWeight: '600',
                        '& .MuiSelect-select': {
                            display: 'flex',
                            alignItems: 'center',
                        },
                        }}
                        label='Year'
                    >
                      {  years.map((year) => (
                      <MenuItem key={year} value={year} sx={{fontSize:'small'}}>
                          {year}
                      </MenuItem>
                      ))} 
                    </Select>
                </FormControl>
            </Box>
          </Grid>
          <Grid item xs={1} sx={{display:'flex',alignItems:'center'}}>
            {/* i want to be written text vertically from bottom to top 'Sale (₹)' */}
            <Typography sx={{fontSize:'.85rem',fontWeight:500, transform: 'rotate(-90deg)',width:'max-content' }}>
              {'Sale (₹)'}
            </Typography>
          </Grid>

          <Grid item xs={11} sx={{display:'flex',alignItems:'center'}}>
            <BarChart
              dataset={saleData}
              xAxis={[
                {
                  scaleType: 'band',
                  dataKey: 'month',
                  valueFormatter: (month, context) =>
                    context.location === 'tick'
                      ? `${month.slice(0, 3)} \n${selectedYear}`
                      : `${month} ${selectedYear}`,
                },
              ]}
              series={[
                { dataKey: 'sale', valueFormatter ,color: '#382cd5'}
              ]}
              {...otherSetting}
            />
          </Grid>
        </Grid>
      </CardContent>  
      </Card>
  );
}

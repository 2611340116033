import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, IconButton, TextField, Avatar,Divider, CircularProgress, Button } from '@mui/material';
import { Send, CloseOutlined, Chat, CheckCircleOutlineOutlined, Circle, RadioButtonUnchecked } from '@mui/icons-material';
import dayjs from 'dayjs';
import axios from 'axios';
import { formatDateTime } from '../../utils/dateUtils';
import Loading from '../../components/Loader/Loading';

const ChatBox = ({ onClose, bookingDetails }) => {
  const chatEndRef = useRef(null);
  const [isLoading,setIsLoading] = useState(false);

  // Simulated data - replace with actual API calls
  const currentUser = {
    id: 'customer',
    name: bookingDetails?.add_customerFullName || 'Customer',
    role: 'customer'
  };

  const expert = {
    id: 'expert',
    name: bookingDetails?.providerName || 'Expert',
    role: 'expert'
  };

  const [messages, setMessages] = useState([]);
  const fetchMessages = async () => {
    setIsLoading(true);

    try {
      // const response = await axios.get(`https://staging-api-chat.cudel.in/api/getChatDetails/818`, {
        const response = await axios.get(`https://staging-api-chat.cudel.in/api/getChatDetails/${bookingDetails.id}`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        withCredentials: true
      }).
      then((response) => {
        console.log(response.data);
        // setMessages(response.data.messages);
        setIsLoading(false);
      });
    } catch (error) {
      console.error('Error fetching messages:', error);
      setMessages([]);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchMessages();
  }, []);
  // Scroll to bottom when messages update
  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const groupMessagesByDate = (messages) => {
    const groups = {};
    const today = dayjs().startOf('day');
    const yesterday = today.subtract(1, 'day');

    messages.forEach(message => {
      const messageDate = dayjs(message.timestamp).startOf('day');
      let dateKey;

      if (messageDate.isSame(today)) {
        dateKey = 'Today';
      } else if (messageDate.isSame(yesterday)) {
        dateKey = 'Yesterday';
      } else {
        dateKey = dayjs(message.timestamp).format('MMM DD, YYYY');
      }

      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }
      groups[dateKey].push(message);
    });

    // Sort messages within each group in ascending order
    Object.keys(groups).forEach(date => {
      groups[date].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
    });

    // Sort the date groups in descending order
    const sortedGroups = Object.entries(groups)
      .sort((a, b) => {
        if (a[0] === 'Today') return -1;
        if (b[0] === 'Today') return 1;
        if (a[0] === 'Yesterday') return -1;
        if (b[0] === 'Yesterday') return 1;
        return new Date(b[0]) - new Date(a[0]);
      })
      .reduce((acc, [date, messages]) => {
        acc[date] = messages;
        return acc;
      }, {});

    return sortedGroups;
  };
  const showSampleChat = () => {
    setMessages(sampleMessages);
  };

  const MessageBubble = ({ message }) => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: message.receiverUserType == 'PROVIDER' ? 'flex-end' : 'flex-start',
          mb: 1,
          gap: 1,
          position: 'relative'
        }}
      >
        <Box sx={{
          width: '75%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: message.receiverUserType == 'PROVIDER' ? 'flex-end' : 'flex-start'
        }}>
          <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
            
              <Avatar sx={{ width: '.75rem', height: '.75rem', bgcolor:`${message.receiverUserType == 'PROVIDER' ?'primary.main':'secondary.main'}` , fontSize: '0.5rem' }}>
                {message.receiverUserType == 'PROVIDER'?'E':'C'}
              </Avatar>
          
            <Typography variant="caption" color="text.secondary" sx={{fontSize:'0.65rem'}}>
              {message.receiverUserType == 'PROVIDER' ? expert.name : currentUser.name}
            </Typography>
            {/* {message.receiverUserType == 'CUSTOMER' &&
              <Avatar sx={{ width: '.75rem', height: '.75rem', bgcolor: 'secondary.main', fontSize: '0.5rem' }}>
                C
              </Avatar>
            } */}
          </Box>
          {message.mssgType == 'text' && 
            <Box sx={{
              bgcolor: message.receiverUserType == 'PROVIDER' ? 'primary.main' : 'grey.100',
              color: message.receiverUserType == 'PROVIDER' ? 'white' : 'text.primary',
              borderRadius: 2,
              px: 2,
              py: 1,
              wordBreak: 'break-word',
            }}>
              <Typography sx={{fontSize:'0.65rem'}}>{message.content}</Typography>
            </Box>
          }
          {message.mssgType == 'file' && 
            <Box sx={{
              bgcolor: message.receiverUserType == 'PROVIDER' ? 'primary.main' : 'grey.100',
              color: message.receiverUserType == 'PROVIDER' ? 'white' : 'text.primary',
              borderRadius: 2,
              px: 2,
              py: 1,
              wordBreak: 'break-word',
            }}>
              {message.fileType.startsWith('image/') && (
                <img
                  src={message.content}
                  alt={message.fileName}
                  style={{ maxWidth: '100%', maxHeight: '200px', borderRadius: '4px' }}
                />
              )}
              {message.fileType.startsWith('video/') && (
                <video
                  controls
                  preload="none"
                  style={{ maxWidth: '100%', maxHeight: '200px', borderRadius: '4px' }}
                >
                  <source src={message.content} type={message.fileType} />
                  Your browser does not support the video tag.
                </video>
              )}
            </Box>
          }
          <Box sx={{
            display: 'flex', 
            alignItems: 'center',
            justifyContent:'flex-end', 
            gap: 0.5,
            width:'100%'
          }}>
            <Typography color="text.secondary" sx={{fontSize:'0.5rem', ml: 1}}>
              {formatDateTime(message.timestamp,'time')}
            </Typography>
            {message.read ? (
              <CheckCircleOutlineOutlined 
                sx={{ 
                  fontSize: '0.6rem', 
                  color: 'success.main' 
                }} 
              />
            ) : (
              <RadioButtonUnchecked
                sx={{ 
                  fontSize: '0.6rem', 
                  color: '#1565c0' 
                }} 
              />
            )}
          </Box>
        </Box>
      </Box>
    );
  };
  const DateDivider = ({ date }) => (
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      my: 2,
      position: 'relative'
    }}>
      <Divider sx={{ flex: 1 }} />
      <Typography 
        variant="caption" 
        sx={{ 
          px: 2, 
          py: 0.5, 
          bgcolor: 'grey.100', 
          borderRadius: 1,
          fontSize: '0.65rem',
          color: 'text.secondary'
        }}
      >
        {date}
      </Typography>
      <Divider sx={{ flex: 1 }} />
    </Box>
  );

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{
        borderBottom: '1px solid lightGray',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: '1% 2%',
        height: '8%',
        // bgcolor: '#5248db',
        bgcolor:'#3a2bde'
        // bgcolor:'#6256e6'
      }}>
        <Box sx={{display:'flex',alignItems:'center',gap:1}}>
          <Chat sx={{fontSize:'1rem',color:'#e8dfdfde'}} color='#fff'/>
          <Typography sx={{fontSize:'0.75rem',fontWeight:600,color:'#fff' }}> Cudel Chat</Typography>
        </Box>
        <IconButton sx={{p:0,color:'#e8dfdfde'}} onClick={onClose} size='small'><CloseOutlined fontSize='0.85rem'/></IconButton>
      </Box>
      
      <Box sx={{
        height: '90%',
        overflowY: 'auto',
        bgcolor: 'background.default',
        p: '1% 2%',
      }}>
        {isLoading && 
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress/>
          </Box>
        }
        {messages.length === 0 && !isLoading ?
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Box sx={{textAlign:'center'}}>
              <Typography>No Chat History</Typography>
              <Button size='small' variant='contained' onClick={()=>showSampleChat()}>Show Sample Chat</Button>
            </Box>
          </Box>
          :
        
          Object.entries(groupMessagesByDate(messages)).map(([date, dateMessages]) => (
            <Box key={date}>
              <DateDivider date={date} />
              {dateMessages.map((message) => (
                <MessageBubble key={message.id} message={message} />
              ))}
            </Box>
          ))
        }
        
        <div ref={chatEndRef} />
      </Box>
      
    </Box>
  );
};

export default ChatBox;

// const ChatBox = ({ onClose, bookingDetails }) => {
//   const chatEndRef = useRef(null);
//   const [isLoading,setIsLoading] = useState(false);

//   // Simulated data - replace with actual API calls
//   const currentUser = {
//     id: 'customer',
//     name: bookingDetails?.add_customerFullName || 'Customer',
//     role: 'customer'
//   };

//   const expert = {
//     id: 'expert',
//     name: bookingDetails?.providerName || 'Expert',
//     role: 'expert'
//   };

//   const [messages, setMessages] = useState([]);
//   const fetchMessages = async () => {
//     setIsLoading(true);

//     try {
//       // const response = await axios.get(`https://staging-api-chat.cudel.in/api/getChatDetails/818`, {
//         const response = await axios.get(`https://staging-api-chat.cudel.in/api/getChatDetails/${bookingDetails.id}`, {
//         headers: {
//           'Accept': 'application/json',
//           'Content-Type': 'application/json',
//           'Access-Control-Allow-Origin': '*'
//         },
//         withCredentials: true
//       }).
//       then((response) => {
//         console.log(response.data);
//         // setMessages(response.data.messages);
//         setIsLoading(false);
//       });
//     } catch (error) {
//       console.error('Error fetching messages:', error);
//       // setMessages([]);
//       setMessages(sampleMessages);
//       setIsLoading(false);
//     }
//   };
//   useEffect(() => {
//     fetchMessages();
//   }, []);
//   // Scroll to bottom when messages update
//   useEffect(() => {
//     chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
//   }, [messages]);

//   const groupMessagesByDate = (messages) => {
//     const groups = {};
//     const today = dayjs().startOf('day');
//     const yesterday = today.subtract(1, 'day');

//     messages.forEach(message => {
//       const messageDate = dayjs(message.timestamp).startOf('day');
//       let dateKey;

//       if (messageDate.isSame(today)) {
//         dateKey = 'Today';
//       } else if (messageDate.isSame(yesterday)) {
//         dateKey = 'Yesterday';
//       } else {
//         dateKey = dayjs(message.timestamp).format('MMM DD, YYYY');
//       }

//       if (!groups[dateKey]) {
//         groups[dateKey] = [];
//       }
//       groups[dateKey].push(message);
//     });

//     // Sort messages within each group in ascending order
//     Object.keys(groups).forEach(date => {
//       groups[date].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
//     });

//     // Sort the date groups in descending order
//     const sortedGroups = Object.entries(groups)
//       .sort((a, b) => {
//         if (a[0] === 'Today') return -1;
//         if (b[0] === 'Today') return 1;
//         if (a[0] === 'Yesterday') return -1;
//         if (b[0] === 'Yesterday') return 1;
//         return new Date(b[0]) - new Date(a[0]);
//       })
//       .reduce((acc, [date, messages]) => {
//         acc[date] = messages;
//         return acc;
//       }, {});

//     return sortedGroups;
//   };

//   const MessageBubble = ({ message }) => {
//     return (
//       <Box
//         sx={{
//           display: 'flex',
//           justifyContent: message.receiverUserType == 'PROVIDER' ? 'flex-end' : 'flex-start',
//           mb: 1,
//           gap: 1,
//           position: 'relative'
//         }}
//       >
//         <Box sx={{
//           width: '75%',
//           display: 'flex',
//           flexDirection: 'column',
//           alignItems: message.receiverUserType == 'PROVIDER' ? 'flex-end' : 'flex-start'
//         }}>
//           <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
            
//               <Avatar sx={{ width: '.75rem', height: '.75rem', bgcolor:`${message.receiverUserType == 'PROVIDER' ?'primary.main':'secondary.main'}` , fontSize: '0.5rem' }}>
//                 {message.receiverUserType == 'PROVIDER'?'E':'C'}
//               </Avatar>
          
//             <Typography variant="caption" color="text.secondary" sx={{fontSize:'0.65rem'}}>
//               {message.receiverUserType == 'PROVIDER' ? expert.name : currentUser.name}
//             </Typography>
//             {/* {message.receiverUserType == 'CUSTOMER' &&
//               <Avatar sx={{ width: '.75rem', height: '.75rem', bgcolor: 'secondary.main', fontSize: '0.5rem' }}>
//                 C
//               </Avatar>
//             } */}
//           </Box>
//           {message.mssgType == 'text' && 
//             <Box sx={{
              
//             }}>
//               <Box sx={{
//                 bgcolor: message.receiverUserType == 'PROVIDER' ? 'primary.main' : 'grey.100',
//                 color: message.receiverUserType == 'PROVIDER' ? 'white' : 'text.primary',
//                 borderRadius: 2,
//                 px: 2,
//                 py: 1,
//                 wordBreak: 'break-word',
//               }}>
//                 <Typography sx={{fontSize:'0.65rem'}}>{message.content}</Typography>
//               </Box>
//               <Box sx={{
//                 display: 'flex', 
//                 alignItems: 'center',
//                 justifyContent:'flex-end', 
//                 gap: 0.5,
//                 // width:'100%'
//               }}>
//                 <Typography color="text.secondary" sx={{fontSize:'0.5rem', ml: 1}}>
//                   {formatDateTime(message.timestamp,'time')}
//                 </Typography>
//                 {message.read ? (
//                   <CheckCircleOutlineOutlined 
//                     sx={{ 
//                       fontSize: '0.6rem', 
//                       color: 'success.main' 
//                     }} 
//                   />
//                 ) : (
//                   <RadioButtonUnchecked
//                     sx={{ 
//                       fontSize: '0.6rem', 
//                       color: '#1565c0' 
//                     }} 
//                   />
//                 )}
//               </Box>
//             </Box>
//           }
//           {message.mssgType == 'file' && 
//             <Box>
//               <Box 
//                  sx={{
//                   bgcolor: message.receiverUserType == 'PROVIDER' ? 'primary.main' : 'grey.100',
//                   color: message.receiverUserType == 'PROVIDER' ? 'white' : 'text.primary',
//                   borderRadius: 2,
//                   px: 2,
//                   py: 1,
//                   wordBreak: 'break-word',
//                 }}
//               >
//                 {message.fileType.startsWith('image/') && (
//                   <img
//                     src={message.content}
//                     alt={message.fileName}
//                     style={{ maxWidth: '100%', maxHeight: '200px', borderRadius: '4px' }}
//                   />
//                 )}
//                 {message.fileType.startsWith('video/') && (
//                   <video
//                     controls
//                     preload="none"
//                     style={{ maxWidth: '100%', maxHeight: '200px', borderRadius: '4px' }}
//                   >
//                     <source src={message.content} type={message.fileType} />
//                     Your browser does not support the video tag.
//                   </video>
//                 )}
//               </Box>
              
//               <Box sx={{
//                 display: 'flex', 
//                 alignItems: 'center',
//                 justifyContent:'flex-end', 
//                 gap: 0.5,
//                 // width:'100%'
//               }}>
//                 <Typography color="text.secondary" sx={{fontSize:'0.5rem', ml: 1}}>
//                   {formatDateTime(message.timestamp,'time')}
//                 </Typography>
//                 {message.read ? (
//                   <CheckCircleOutlineOutlined 
//                     sx={{ 
//                       fontSize: '0.6rem', 
//                       color: 'success.main' 
//                     }} 
//                   />
//                 ) : (
//                   <RadioButtonUnchecked
//                     sx={{ 
//                       fontSize: '0.6rem', 
//                       color: '#1565c0' 
//                     }} 
//                   />
//                 )}
//               </Box>
//             </Box>
//           }
          
//         </Box>
//       </Box>
//     );
//   };
//   const DateDivider = ({ date }) => (
//     <Box sx={{ 
//       display: 'flex', 
//       alignItems: 'center', 
//       my: 2,
//       position: 'relative'
//     }}>
//       <Divider sx={{ flex: 1 }} />
//       <Typography 
//         variant="caption" 
//         sx={{ 
//           px: 2, 
//           py: 0.5, 
//           bgcolor: 'grey.100', 
//           borderRadius: 1,
//           fontSize: '0.65rem',
//           color: 'text.secondary'
//         }}
//       >
//         {date}
//       </Typography>
//       <Divider sx={{ flex: 1 }} />
//     </Box>
//   );

//   return (
//     <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
//       <Box sx={{
//         borderBottom: '1px solid lightGray',
//         display: 'flex',
//         justifyContent: 'space-between',
//         alignItems: 'center',
//         p: '1% 2%',
//         height: '8%',
//         // bgcolor: '#5248db',
//         // bgcolor:'#3a2bde'
//         bgcolor:'#6256e6'
//       }}>
//         <Box sx={{display:'flex',alignItems:'center',gap:1}}>
//           <Chat sx={{fontSize:'1rem',color:'#e8dfdfde'}} color='#fff'/>
//           <Typography sx={{fontSize:'0.75rem',fontWeight:600,color:'#fff' }}> Cudel Chat</Typography>
//         </Box>
//         <IconButton sx={{p:0,color:'#e8dfdfde'}} onClick={onClose} size='small'><CloseOutlined fontSize='0.85rem'/></IconButton>
//       </Box>
      
//       <Box sx={{
//         height: '90%',
//         overflowY: 'auto',
//         bgcolor: 'background.default',
//         p: '1% 2%',
//       }}>
//         {isLoading && 
//           <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
//             <CircularProgress/>
//           </Box>
//         }
//         {messages.length === 0 && !isLoading ?
//           <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
//             <Typography>No Chat History</Typography>
//           </Box>
//           :
        
//           Object.entries(groupMessagesByDate(messages)).map(([date, dateMessages]) => (
//             <Box key={date}>
//               <DateDivider date={date} />
//               {dateMessages.map((message) => (
//                 <MessageBubble key={message.id} message={message} />
//               ))}
//             </Box>
//           ))
//         }
        
//         <div ref={chatEndRef} />
//       </Box>
      
//     </Box>
//   );
// };

// export default ChatBox;

const sampleMessages = [
      {
        "id": "678e51bf870dd206254b331f",
        "senderId": "10003",
        "receiverUserType": "CUSTOMER",
        "bookingId": "3001",
        "content": "https://sfaone.s3.ap-south-1.amazonaws.com/uploads/VID_20241218_173036.mp4",
        "timestamp": "2025-01-20T19:08:07.421000",
        "read": true,
        "mssgType": "file",
        "fileType": "video/mp4",
        "fileName": "VID_20241218_173036.mp4",
        "height": 300,
        "width": 300,
        "size": 26519853
      },
      {
        "id": "678e51a8870dd206254b331e",
        "senderId": "10003",
        "receiverUserType": "CUSTOMER",
        "bookingId": "3001",
        "content": "https://sfaone.s3.ap-south-1.amazonaws.com/uploads/scaled_IMG-20241216-WA0002.jpg",
        "timestamp": "2025-01-20T19:07:44.071000",
        "read": false,
        "mssgType": "file",
        "fileType": "image/jpeg",
        "fileName": "scaled_IMG-20241216-WA0002.jpg",
        "height": 479,
        "width": 719,
        "size": 36387
      },
      {
        "id": "678e519b870dd206254b331d",
        "senderId": "10001",
        "receiverUserType": "PROVIDER",
        "bookingId": "3001",
        "content": "hi, ram this side",
        "timestamp": "2025-01-20T19:07:31.903000",
        "read": false,
        "mssgType": "text",
        "fileType": null,
        "fileName": null,
        "height": null,
        "width": null,
        "size": null
      },
      {
        "id": "678e5192870dd206254b331c",
        "senderId": "10003",
        "receiverUserType": "CUSTOMER",
        "bookingId": "3001",
        "content": "hi ram",
        "timestamp": "2025-01-20T19:07:22.602000",
        "read": false,
        "mssgType": "text",
        "fileType": null,
        "fileName": null,
        "height": null,
        "width": null,
        "size": null
      },
      {
        "id": "678e518e870dd206254b331b",
        "senderId": "10003",
        "receiverUserType": "PROVIDER",
        "bookingId": "3001",
        "content": "10003 has joined the chat",
        "timestamp": "2025-01-20T19:07:18.010000",
        "read": false,
        "mssgType": "text",
        "fileType": null,
        "fileName": null,
        "height": null,
        "width": null,
        "size": null
      },
      {
        "id": "678e515e870dd206254b3315",
        "senderId": "10001",
        "receiverUserType": "CUSTOMER",
        "bookingId": "3001",
        "content": "10001 has joined the chat",
        "timestamp": "2025-01-20T19:06:30.097000",
        "read": false,
        "mssgType": "text",
        "fileType": null,
        "fileName": null,
        "height": null,
        "width": null,
        "size": null
      }
    ]
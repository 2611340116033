import { useState, useEffect } from 'react';
import { Card,Chip, Typography, Divider,MenuItem, Stack, TextField, Grid, Button, Box, Toolbar, TableCell, FormControl, InputLabel, Select, Tooltip, IconButton, Modal, InputAdornment, Skeleton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Swal from 'sweetalert2';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { isMobile } from 'react-device-detect';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useForm, Controller } from 'react-hook-form';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from '@mui/icons-material/Search';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Search } from '@mui/icons-material';
import PageHeaderLayout from '../../../components/Layout/PageHeaderLayout';
import { PermissionGuard } from '../../../components/PermissionGuard';
import Loading from '../../../components/Loader/Loading';
import NoRowsLayout from '../../../components/Layout/NoRowsLayout';
import { Helpers } from '../../../services/Helpers';
import AddEditProgram from './AddEditProgram';
import { formatDateTime } from '../../../utils/dateUtils';
import ViewProgram from './ViewProgram';
import { RewardServices } from './RewardServices';
import QuickSearchTool from '../../../components/QuickSearch/QuickSearchTool';
import MultiColumnFilter from '../../../components/Filter/MultiColumnFilter';
import ExportAll from './ExportAll';

const style = {
    position: 'absolute',
    top: (isMobile) ? '80%' : '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #ddd',
    boxShadow: 24,
    // p: 2,
};


export default function RewardProgramList() {
    
    const { control, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const [populateTable, setPopulateTable] = useState(0);
    
    /************************  selected service/subservice data for edit**************************** */
    const [selectedProgramData,setSelectedProgramData] = useState({});

    const [allRowCount, setAllRowCount] = useState(0);
    const [list, setList] = useState([]);
    const [deleteRowData, setDeleteRowData] = useState({});
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 50,
        filters: {
          searchKey: "",
          sortBy:'createdAt',
          sortType:'desc',
          filterOptions: []
        }
    });

  const [loading, setLoading] = useState(false);

  const fetchRewardProgramList = async ()=>{
    try {
        setLoading(true)
        const listResponse = await RewardServices.programList({
          page: paginationModel.page,
          limit: paginationModel.pageSize,
          filters: {
            searchKey: paginationModel.filters.searchKey,   // Use the title filter from the paginationModel
            sortBy: paginationModel.filters.sortBy,
            sortType: paginationModel.filters.sortType,
            filterOptions: paginationModel.filters.filterOptions || []
          }
        });
        console.log("Reward Program list Response:",listResponse)
        setValue('status', paginationModel.filters.status)
        if (listResponse.success=='1') {
          setList(listResponse.data.list);
          setAllRowCount(listResponse.data.totalItems);
        } 
        else {
        //   Swal.fire('Request failed','','error')
        }
      } catch (error) {
        Swal.fire(error.message,'','error')
      }
      setLoading(false)
    };
  
  /************************  Manage Use Effect **************************** */
  useEffect(() => {
    fetchRewardProgramList();
  }, [paginationModel, deleteRowData]);

  
    /************************  Table Column Manage **************************** */
    const columns = [
      { 
        field: 'title', 
        headerName: 'Program', 
        headerAlign:'center',
        flex: 2,
        // filterable: false,
        sortable: false,
        valueGetter: (params) => {
          // Join all subcategory titles into a single searchable string
          const title = params.row.title;
          const referenceType = params.row.referenceType;
          const issueDate = formatDateTime(params.row.issueDate,'date');
          const rewardValue = params.row.rewardValue;
          const walletType = params.row.walletType;
          const expireDate = formatDateTime(params.row.expireDate,'date');
          const maxRewardValue = params.row.maxRewardValue;
          return `${title} ${referenceType} ${issueDate} ${rewardValue} ${walletType} ${expireDate} ${maxRewardValue}`.toLowerCase();
        },
        renderCell: (params) => (
          <Box sx={{ display: 'flex',alignItems:'center' ,width:'100%',p:1}}>
            {/* <Box sx={{ width:'15%'}}>
              {params.row.images ? (
                <img 
                //   src={params.row.images} 
                  src={`${Helpers.ImagePathUrl()}/service/sm/${params.row?.images}`}
                  alt={params.row.title}
                  style={{ width: '2rem', height: '2rem', objectFit: 'cover' }}
                />
              ) : (
                <Skeleton variant="rectangular" width={35} height={35} animation={false} />
              )}
            </Box> */}
            <Box display={'flex'} flexDirection={'column'} 
                sx={{ 
                  ml: 1 ,
                  // width:'85%'
                }}>
              <Typography variant="body2" sx={{ fontWeight: 500,color:'#3e3e3e',fontSize:'.7rem' }}>{params.row.title}</Typography>
              <Typography sx={{fontSize:'0.5rem'}}> <Box component={'span'} sx={{fontWeight:600}}>Valid For : </Box>
                
                <Chip
                // icon={statusConfigs[params.row.reviewStatus]?.icon}
                label={params.row.isProvider? 'Service Expert':'Customer'}
                color={params.row.isProvider? 'success':'warning'}
                size="small"
                // variant="outlined"
                sx={{fontSize:'0.5rem',height:'fit-content','& .MuiChip-icon':{fontSize:'0.65rem'}}}
              />
              </Typography>
              <Box sx={{display:'flex',gap:.5,alignItems:'center'}}>
                <Box flex={1}>
                  <Typography sx={{fontSize:'0.5rem'}}> <Box component={'span'} sx={{fontWeight:600}}>Reference Type : </Box>{params.row.referenceType}</Typography>
                  <Typography sx={{fontSize:'0.5rem'}}> <Box component={'span'} sx={{fontWeight:600}}>Reward Value : </Box>{params.row.rewardValue}</Typography>                
                </Box>
                <Box>
                  <Typography sx={{fontSize:'0.5rem'}}> <Box component={'span'} sx={{fontWeight:600}}>Wallet Type : </Box>{params.row.walletType}</Typography>
                  <Typography sx={{fontSize:'0.5rem'}}> <Box component={'span'} sx={{fontWeight:600}}>Max Reward : </Box>{params.row.maxRewardValue}</Typography>
                </Box>
              </Box>
              
            </Box>
          </Box>
        ),
      },
      
      { 
        field: 'description', 
        headerName: 'Description', 
        headerAlign:'center',
        flex: 1.5,
        sortable: false,
        renderCell: (params) => (
          <Box sx={{ p: 1 }}>
            <Typography variant="body2" sx={{fontSize:'.65rem'}}>{params.row.description}</Typography>
          </Box>
        ),
      },
      {
        field: 'offerPeriod',
        headerName: 'Offer Period',
        headerAlign:'center',
        flex: 1,
        filterable: false,
        sortable:false,
        valueGetter: (params) => {
          const startDate = params.row.issueDate ? formatDateTime(params.row.issueDate,'date'):'N/A';
          const endDate = params.row.expireDate ? formatDateTime(params.row.expireDate,'date'):'N/A';
          return `${startDate} to ${endDate}`;
          // return `${formatDateTime(params.row.issueDate,'date')} to ${formatDateTime(params.row.expireDate,'date')}`;
        },
        renderCell:(params)=>{
          return (
            <Box sx={{display:'flex',flexDirection:'column',gap:1}}>
              <Typography sx={{fontSize:'0.5rem'}}> <Box component={'span'} sx={{fontWeight:600}}>Start Date: </Box>{params.row.issueDate ? formatDateTime(params.row.issueDate,'date'):'N/A'}</Typography>            
              <Typography sx={{fontSize:'0.5rem'}}> <Box component={'span'} sx={{fontWeight:600}}>Expiry Date: </Box>{params.row.expireDate ? formatDateTime(params.row.expireDate,'date'):'N/A'}</Typography>
            </Box>
          )
        }
      },
      { 
        field: 'status', 
        headerName: '', 
        headerAlign:'center',
        flex: 1,
        sortable: false,
        valueGetter: (params) => {
          return params.row.status ? 'Active' : 'Inactive';
        } ,
        renderCell: (params) => (
          <Box sx={{width:'100%'}}>
            <Typography sx={{fontSize:'0.5rem',mb:1}}> <Box component={'span'} sx={{fontWeight:600}}>Status : </Box>
                
              <Chip
                // icon={statusConfigs[params.row.reviewStatus]?.icon}
                label={params.row.status ? 'Active' : 'Inactive'} 
                color={params.row.status ? 'success' : 'error'} 
                size="small"
                // variant="outlined"
                sx={{fontSize:'0.5rem',height:'fit-content','& .MuiChip-icon':{fontSize:'0.65rem'}}}
              />
            </Typography>
            <Typography sx={{fontSize:'0.5rem'}}> <Box component={'span'} sx={{fontWeight:600}}>Display : </Box>
                
              <Chip
                // icon={statusConfigs[params.row.reviewStatus]?.icon}
                label={params.row.isDisplay? 'Yes':'No'}
                color={params.row.isDisplay? 'success':'error'}
                size="small"
                // variant="outlined"
                sx={{fontSize:'0.5rem',height:'fit-content','& .MuiChip-icon':{fontSize:'0.65rem'}}}
              />
            </Typography>
            {/* <Chip 
              label={params.row.status ? 'Active' : 'Inactive'} 
              color={params.row.status ? 'success' : 'error'} 
              size="small" 
              sx={{fontSize:'0.65rem',height:'fit-content'}}
            /> */}
          </Box>
        ),
      },
      {
        field:'createdAt',
        headerName:'Created On',
        headerAlign:'center',
        flex: 1,
        filterable: false,
        sortable:false,
        valueGetter: (params) => {
          return params.row.createdAt? formatDateTime(params.row.createdAt):'';
        },
        renderCell:(params)=>{
          return (
            <Typography sx={{fontSize:'0.55rem'}}>{params.row.createdAt? formatDateTime(params.row.createdAt):''}</Typography>
          )
        }
      },
      {
        field: 'actions',
        headerName: 'Actions',
        headerAlign:'center',
        flex: 1,
        filterable: false,
        sortable: false,
        renderCell: (params) => (
          <Box sx={{ p: 1, display: 'flex',gap:1 ,justifyContent: 'center',alignItems:'center',width:'100%' }}>
            <IconButton 
              sx={{color: "blue",}}
              onClick={() => modalOpen(params.row,'view')} 
              size="small">
              <RemoveRedEyeIcon sx={{fontSize:'0.95rem'}}/>
            </IconButton>
            <PermissionGuard feature='reward-program' permission='canEdit'>
              <IconButton onClick={() => modalOpen(params.row,'edit')} size="small" color='warning'>
                <EditIcon sx={{fontSize:'0.95rem'}}/>
              </IconButton>
            </PermissionGuard>
            {/* <IconButton onClick={() => deleteService(params.row.id)} size="small">
              <DeleteIcon />
            </IconButton> */}
          </Box>
        ),
      },
    ];
    /************************  Action Handler Related table row **************************** */   
    const handlePaginationChange = (newPagination) => {
        setPaginationModel({
            ...paginationModel,
            page: newPagination.page,
            pageSize: newPagination.pageSize
        });
    }

    function onSubmit(data) {
        const filtersData = getValues();
        setPaginationModel({
            ...paginationModel,
            filters: {
                ...paginationModel.filters,
                searchKey: filtersData.searchKey,
            }
        });
    }

    const clearFilters = () => {
      reset();
      setPaginationModel({
          ...paginationModel,
          filters: {
            // ...paginationModel.filters,
            searchKey: "",
            sortBy: 'createdAt',
            sortType: 'desc',
            filterOptions: []
          }
      });
    
      // Trigger clear for MultiColumnFilter
      setClearMultiFilter(true);
  
      // Reset the clear trigger after a short delay
      setTimeout(() => setClearMultiFilter(false), 100);
    }

    /************************  Advance Filter **************************** */
    
  
    const [clearMultiFilter, setClearMultiFilter] = useState(false);
    const filterableColumns = [
      // {
      //   key: 'name',
      //   label: 'Customer Name',
      //   type: 'text'
      // },
      // {
      //   key: 'mobileNumber',
      //   label: 'Mobile Number',
      //   type: 'number'
      // },
      // {
      //   key:'email',
      //   label:'Email',
      //   type:'email'
      // },
      // {
      //   key:'age',
      //   label:'Age',
      //   type:'number'
      // },
      {
        key: 'walletType',
        label: 'Wallet Type',
        type: 'select',
        options: [
          { value: 'COIN', label: 'COIN' },
          { value: 'CASH', label: 'CASH' }
        ]
      },
      {
        key: 'referenceType',
        label: 'Program Type',
        type: 'select',
        options: [
          { value: 'REFERAL', label: 'REFERAL' },
          { value: 'BOOKING', label: 'BOOKING' },
          { value: 'BOOKINGEARN', label: 'BOOKINGEARN' },
          { value: 'JOINING', label: 'JOINING' },
          { value: 'TOPUP', label: 'TOPUP' },
          { value: 'WITHDRAW', label: 'WITHDRAW' },
          { value: 'REWARD', label: 'REWARD' },
          { value: 'BONUS', label: 'BONUS' }
        ]
      },
      {
        key: 'isProvider',
        label: 'For',
        type: 'select',
        options: [
          { value: 'true', label: 'Service Expert' },
          { value: 'false', label: 'Customer' }
        ]
      },
      {
        key: 'status',
        label: 'Is Active',
        type: 'select',
        options: [
          { value: 'true', label: 'Active' },
          { value: 'false', label: 'In-active' }
        ]
      },
      {
        key: 'isDisplay',
        label: 'Display',
        type: 'select',
        options: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' }
        ]
      },
      {
        key: 'isExpired',
        label: 'Is Expired',
        type: 'select',
        options: [
          { value: 'true', label: 'expired' },
          { value: 'false', label: 'not-expired' }
        ]
      },
      {
        key: 'issueDate',
        label: 'Start Date',
        type: 'date'
      },
      {
        key: 'expireDate',
        label: 'Expiry Date',
        type: 'date'
      },
      {
        key: 'createdAt',
        label: 'Created Date',
        type: 'date'
      }
    ];
    const handleApplyFilter = (filterData) => {
      // Process filter data
      console.log(filterData);
      setPaginationModel({
        ...paginationModel,
        page: 0, // Reset to first page when applying new filters
        filters: {
          ...paginationModel.filters,
          // Convert MultiColumnFilter options to a format your backend can understand
        filterOptions: filterData.filterOption.filter(filter => filter.fieldName && filter.value) // Remove empty filters
        }
      });
    }

    /************* quick search related **************/
    // Properly structured filter model
    const [filterModel, setFilterModel] = useState({
      items: [],
      quickFilterValues: []
    });

    /******************* Sorting model  ******************************/
    // const [sortModel, setSortModel] = useState([
    //   {
    //     field: paginationModel.filters.sortBy,
    //     sort: paginationModel.filters.sortType,
    //   },
    // ]);

    // const handleSortingChange = (newSortModel)=>{
    //   if (newSortModel && newSortModel.length > 0) {
    //     setSortModel(newSortModel);
    //     setPaginationModel(prev => ({
    //       ...prev,
    //       filters: {
    //         ...prev.filters,
    //         sortBy: newSortModel[0].field,
    //         sortType: newSortModel[0].sort
    //       }
    //     }));
    //   }
    // }

    /************************  Modal State Manage **************************** */
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [viewModalOpen, setViewModalOpen] = useState(false);

    /************************ add/Edit/view Modal Manage **************************** */
    const modalOpen = (rowData,action) => {
      // console.log(rowData,action)
      const manageData =()=>{
        setSelectedProgramData(rowData);
      }
      if(action=='add'){
        setSelectedProgramData({})
        setEditModalOpen(true);
      }else if(action=='edit'){
        manageData();
        setEditModalOpen(true)
      }
      else if(action=='view'){
        manageData();
        setViewModalOpen(true);
      }
    }

    const handleClose = () => {
        fetchRewardProgramList();
        setEditModalOpen(false);
    };

    return (
        <>
            {/* =========== pop up models for edit/Add programs =============== */} 
            <Modal
                open={editModalOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ overflow: "scroll" }}
            >
                <Box sx={style} width={(isMobile) ? "95%" : "50%"}>
                    <AddEditProgram 
                        closeEvent={handleClose} 
                        programData = {selectedProgramData}
                    />
                </Box>
            </Modal>
            {/* =========== pop up models for view program =============== */}
            <Modal
                open={viewModalOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ overflow: "scroll" }}
            >
                <Box sx={style} width={(isMobile) ? "95%" : "50%"}>
                    <ViewProgram 
                      closeEvent={()=>setViewModalOpen(false)} 
                      handleEdit={()=>{
                        setEditModalOpen(true);
                        setViewModalOpen(false);
                      }}
                      programData = {selectedProgramData}
                      fetchList = {fetchRewardProgramList}
                    />
                </Box>
            </Modal>
            <PageHeaderLayout headerTitle={'Manage Reward Programs'}>
              <PermissionGuard feature='reward-program' permission='canEdit'>
                  <Button variant='contained' onClick={() => modalOpen({},'add')} sx={{ height: "30px", marginTop: "10px" }} endIcon={<AddCircleIcon />}>
                    Add New Program
                  </Button>
                </PermissionGuard>
            </PageHeaderLayout>
            {/* =================== Searching and sorting section ================== */}
            {/* <Card sx={{ minWidth: "100%", marginBottom: ".5%" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1} sx={{ p: '1%' }}>
                        <Grid item xs={12} lg={3}>
                            <Controller
                                name="searchKey"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        placeholder="Search here....."
                                        // label="Search"
                                        size="small"
                                        fullWidth
                                        sx={{
                                          '& .MuiOutlinedInput-root': {                              
                                            paddingLeft: '0px',                              
                                          }
                                        }}
                                        InputProps={{  // Changed from slotProps to InputProps
                                          inputProps:{
                                            style:{
                                                height:'0.6rem',
                                                textTransform:'uppercase',   
                                                fontSize:'x-small'                       
                                            },
                                          },
                                          startAdornment: (
                                              <InputAdornment position="start">
                                                <IconButton>
                                                  <Search sx={{fontSize:'x-small'}}/>
                                                </IconButton>
                                              </InputAdornment>
                                          ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} lg={2}>
                          <Tooltip title="Search" sx={{ml:1,p:0}}>
                            <IconButton
                              size='small'
                              color="primary"
                              type="submit"
                              sx={{ border: "1px solid", borderRadius: "7px", padding: "1%" ,ml:1}}
                              >
                              <SearchIcon sx={{fontSize:'.85rem'}} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Clear All Filters">
                              <IconButton
                                size='small'
                                color="primary"
                                sx={{ marginLeft: "10px", border: "1px solid", color: "#f00", borderRadius: "7px", padding: "1%" }}
                                onClick={clearFilters}
                              >
                                <RestartAltIcon sx={{fontSize:'.85rem'}}/>
                              </IconButton>
                          </Tooltip>
                        </Grid>
                    </Grid>
                </form>
            </Card> */}

            <Box sx={{ overflow: "auto" }}>
              {loading && <Loading/>}
                <Box sx={{ width: "100%", display: "table", tableLayout: "fixed", backgroundColor: "#fff", borderRadius: '.5rem' }}>
                  <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between',alignItems:'center',px:'1%' }}>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <MultiColumnFilter
                        filterableColumns={filterableColumns}
                        onApplyFilter={handleApplyFilter}
                        externalClear={clearMultiFilter}
                      />
                      <ExportAll
                        paginationModel={paginationModel} 
                        columns={columns} 
                        // statusOptions={statusOptions} 
                      />
                    </Box>
                    
                    <QuickSearchTool filterModel={filterModel} setFilterModel={setFilterModel} />
                  </Box>
                  <DataGrid
                    rows={list}
                    columns={columns}
                    getRowId={(row) => row.id}
                    //checkboxSelection={true}
                    rowCount={allRowCount}  // Use the length of the filtered list
                    pageSizeOptions={[5, 10, 20, 50, 100]}
                    paginationModel={paginationModel}
                    paginationMode="server"
                    onPaginationModelChange={handlePaginationChange}

                    filterModel={filterModel}

                    // sortingMode='server'
                    // sortModel={sortModel}                  
                    // onSortModelChange={handleSortingChange}

                    loading={loading}

                    disableColumnMenu
                    disableRowSelectionOnClick
                    slots={{
                        // toolbar: GridToolbar,                        
                        loadingOverlay: Loading,
                        noRowsOverlay: NoRowsLayout,
                    }}
                    autoHeight
                    getRowHeight={() => 'auto'}
                    sx={{
                        '& .MuiDataGrid-cell': {
                        border: 1,
                        borderRight: 0,
                        borderTop: 0,
                        borderColor: 'lightgrey',
                        display: 'flex',
                        alignItems: 'center',
                        // justifyContent: 'center',
                        },
                        '& .MuiDataGrid-columnHeaders': {
                          borderBottom: '2px solid rgba(224, 224, 224, 1)',
                          borderTop: '2px solid rgba(224, 224, 224, 1)',
                          fontWeight: 600,
                          fontSize: '0.75rem',
                          p:0,
                          height: 'fit-content',
                          minHeight:'0 !important',
                          height:'2rem'
                        },
                        '& .MuiDataGrid-columnHeader': {
                        borderLeft: '1px solid rgba(224, 224, 224, 1)',
                        '&:last-child': {
                            borderRight: 'none',
                        },
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 600,
                        color: '#3e3e3e'
                        },
                        '& .MuiDataGrid-row': {
                        '&:last-child .MuiDataGrid-cell': {
                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                        },
                        '& .MuiDataGrid-cell:last-child': {
                            borderRight: 'none',
                        },
                        },
                        '& .MuiDataGrid-columnSeparator': {
                        display: 'none',
                        },
                    }}
                    />
                </Box>
            </Box>
        </>
    )
}
import { useMemo } from 'react';
import { getPermissions } from '../utils/permissions';

export const usePermission = (feature) => {
  const permissions = useMemo(() => getPermissions(), []);
  
  return {
    canView: permissions[feature]?.canView ?? false,
    canCreate: permissions[feature]?.canCreate ?? false,
    canEdit: permissions[feature]?.canEdit ?? false,
    canDelete: permissions[feature]?.canDelete ?? false
  };
};
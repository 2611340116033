import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const Time24to12 = ({time24}) => {
//   const time24 = "19:20:00";

  const time12 = dayjs(time24, 'HH:mm:ss').format('h:mm A');

  return time12;
};

export default Time24to12;
import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ServiceList from "./ServiceList";
import MainContentLayout from "../../components/Layout/MainContentLayout";

export default function Service(){
    const menuDispatch = useDispatch();
    useEffect(() => {
        menuDispatch({
            type: 'menuSelect',
            payload: 'service'
        });
    } , []);
    return (
        
        <MainContentLayout>
            <ServiceList />
        </MainContentLayout>
        
    )
}


import { commonRequest } from "../../services/ApiCall";
import { Helpers } from "../../services/Helpers";

export const CustomerService = {
    unsortedCustomerList : async (data) =>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/customer/customerList`, {}, Helpers.token());
    },
    sortedCustomerList : async (data) =>{
        console.log("data",data);
        const queryParams = new URLSearchParams();

        // Basic pagination and sorting parameters
        queryParams.append('page', data.page + 1);
        queryParams.append('limit', data.limit);

        // Search parameters
        queryParams.append('searchKey', data.filters.searchKey || '');
        

        // Sorting parameters
        if (data.filters.sortKey) {
            queryParams.append('sortKey', data.filters.sortKey);            
            queryParams.append('sortDirection', data.filters.sortDirection || 'desc');
        }
        
        // Active or not filters
        queryParams.append('isActive', data.filters?.isActive || '');

        // Additional multi-column filters
        if (data.filters.filterOptions) {
            data.filters.filterOptions.forEach((filter, index) => {
                if (filter.fieldName && filter.value) {
                    queryParams.append(`filters[${index}][fieldName]`, filter.fieldName);
                    queryParams.append(`filters[${index}][operator]`, filter.operator);
                    queryParams.append(`filters[${index}][value]`, filter.value);
                    
                    if (index !== 0) {
                        queryParams.append(`filters[${index}][andOr]`, filter.andOr);
                    }
                    
                    if (filter.operator === 'between') {
                        queryParams.append(`filters[${index}][endValue]`, filter.endValue);                        
                    }
                }
            });
        }
        console.log("queryParams",queryParams.toString());
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/customer?${queryParams.toString()}`, {}, Helpers.token());
    },

    customerListToExport : async (data) =>{
        console.log("data",data);
        const queryParams = new URLSearchParams();

        // Search parameters
        queryParams.append('searchKey', data.filters.searchKey || '');
        

        // Sorting parameters
        if (data.filters.sortKey) {
            queryParams.append('sortKey', data.filters.sortKey);            
            queryParams.append('sortDirection', data.filters.sortDirection || 'desc');
        }
        
        // Active or not filters
        queryParams.append('isActive', data.filters?.isActive || '');

        // Additional multi-column filters
        if (data.filters.filterOptions) {
            data.filters.filterOptions.forEach((filter, index) => {
                if (filter.fieldName && filter.value) {
                    queryParams.append(`filters[${index}][fieldName]`, filter.fieldName);
                    queryParams.append(`filters[${index}][operator]`, filter.operator);
                    queryParams.append(`filters[${index}][value]`, filter.value);
                    
                    if (index !== 0) {
                        queryParams.append(`filters[${index}][andOr]`, filter.andOr);
                    }
                    
                    if (filter.operator === 'between') {
                        queryParams.append(`filters[${index}][endValue]`, filter.endValue);                        
                    }
                }
            });
        }
        console.log("queryParams",queryParams.toString());
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/customer/export-list?${queryParams.toString()}`, {}, Helpers.token());
    },
    // customorDetailById : async (id) =>{
    //     return await commonRequest("get", `${Helpers.URL()}/customer/${id}`, {}, Helpers.token());
    // },

    addCustomer : async (data) =>{
        return await commonRequest("POST", `${Helpers.URL()}/api/admin/customer/createCustomer`,data, Helpers.token());
    },
    fetchCustomerDetailById : async (customerId) =>{
        return await commonRequest("GET", `${Helpers.URL()}/api/admin/customer/getCustomerDetails/${customerId}`,{}, Helpers.token());
    },
    fetchBookingHistory : async (customerId) =>{
        return await commonRequest("GET", `${Helpers.URL()}/api/admin/customer/getBookingHistory/${customerId}`,{}, Helpers.token());
    },
    updateCustomerDetails : async (data,customerId) =>{
        return await commonRequest("PUT", `${Helpers.URL()}/api/admin/customer/updateCustomer/${customerId}`,data, Helpers.token());
    },
    updateCustomerStatus : async (data,customerId) =>{
        return await commonRequest("PUT", `${Helpers.URL()}/api/admin/customer/updateCustomer/${customerId}`,data, Helpers.token());
    },
    verifyCustomerByMobileNo: async (data)=>{
        return await commonRequest("POST", `${Helpers.URL()}/api/admin/customer/verifyCustomerByMobileNo`,data, Helpers.token());
    },
    verifyMobileNoOtp: async (data)=>{
        return await commonRequest("POST", `${Helpers.URL()}/api/admin/customer/verifyOtp`,data, Helpers.token());
    },

    deleteCustomerById:async (customerId)=>{
        return await commonRequest('DELETE',`${Helpers.URL()}/api/admin/customer/deleteCustomer/${customerId}`,{},Helpers.token()) 
    },
    updateMobileNumber: async (data) =>{
        return await commonRequest('POST',`${Helpers.URL()}/api/admin/customer/changeCustomerMobileNo`,data,Helpers.token());
    }
    

    // updateCustomer : async (id, data) =>{
    //     return await commonRequest("PUT",  `${Helpers.URL()}/customer/${id}`, data, Helpers.token());
    // },

    // changeStatus: async (data) =>{
    //     return await commonRequest("POST", `${Helpers.URL()}/customer/statusChange`,data, Helpers.token());
    // },

    // addBankDetail : async (data) =>{
    //     return await commonRequest("POST", `${Helpers.URL()}/users/bankDetail/create`,data, Helpers.token());
    // },

    // updateBankDetail : async (id, data) =>{
    //     return await commonRequest("PUT",  `${Helpers.URL()}/users/bankDetail/${id}`, data, Helpers.token());
    // },

    // addAddressDetail : async (data) =>{
    //     return await commonRequest("POST", `${Helpers.URL()}/users/addressDetail/create`,data, Helpers.token());
    // },

    // updateAddressDetail : async (id, data) =>{
    //     return await commonRequest("PUT",  `${Helpers.URL()}/users/addressDetail/${id}`, data, Helpers.token());
    // },

    // deleteBrand : async (id) =>{
    //     return await commonRequest("DELETE",  `${Helpers.URL()}/brand/${id}`, {}, Helpers.token());
    // },
    
}
import { Search } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

const QuickSearchTool = (props) => {
    const { filterModel, setFilterModel } = props;
    const [quickFilterText, setQuickFilterText] = useState('');   

    // Update filter model when quick filter text changes
    useEffect(() => {
        setFilterModel(prevModel => ({
        ...prevModel,
        quickFilterValues: quickFilterText ? [quickFilterText] : []
        }));
    }, [quickFilterText]);
  return (
    <Box>
        <TextField
            value={quickFilterText}
            onChange={(event) => setQuickFilterText(event.target.value)}
            placeholder="Find in Shown List..."
            size="small"
            fullWidth
            sx={{
                '& .MuiOutlinedInput-root': {
                paddingLeft: '0px',
                }
            }}
            InputProps={{
                inputProps: {
                    style: {
                        height: '0.6rem',
                        // textTransform: 'uppercase',
                        fontSize: 'x-small'
                    },
                },
                startAdornment: (
                    <InputAdornment position="start">
                        <IconButton>
                        <Search sx={{fontSize:'x-small'}}/>
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    </Box>
  );
}

export default QuickSearchTool;

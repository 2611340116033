import React, { useState,useEffect } from 'react';
import { Box, Button, Avatar, Typography, IconButton, Tabs, Tab, TextField, Grid, Paper, Card, Chip } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { ArrowBack } from '@mui/icons-material';
import { Helpers } from '../../services/Helpers';
import {PermissionGuard} from '../../components/PermissionGuard';
import PersonalDetails from './PersonalDetail';
import { LocalStore } from '../../storage/authStore';
import ChangePassword from './ChangePassword';
import PageHeaderLayout from '../../components/Layout/PageHeaderLayout';



  const coverImage = 'https://via.placeholder.com/1200x300'
  const profileImage = 'https://via.placeholder.com/150'

const ViewProfile = () => {
  const {id} = useParams();
  const navigate =  useNavigate();

  const userDetail = LocalStore.getLoggedUserDetails();


  const [activeTab, setActiveTab] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [isLoading,setIsLoading] = useState(false);

  useEffect(() => {
    // fetchUserDetails();
  }, [id]);
  

  const handleProfileImageChange = (event) => {
    // const file = event.target.files[0];
    // if (file) {
    //   const reader = new FileReader();
    //   reader.onload = (e) => setProfileImage(e.target.result);
    //   reader.readAsDataURL(file);
    // }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleEditToggle = () => {
    setEditMode(!editMode);
  };

  const handleSave = () => {
    // Here you would typically send the updated data to your backend
    setEditMode(false);
  };

  const renderForm = () => {
    switch (activeTab) {
      case 0:
        return (
          <Grid container spacing={0}>
            <PersonalDetails personalDetails={userDetail}/>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={0}>
            <ChangePassword/>
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {/* <Card sx */}
      <PageHeaderLayout headerTitle={'Profile'}>
            <Button endIcon={<ArrowBack/>} variant='contained' size='small' onClick={()=>navigate(-1)} sx={{ height: "30px", marginTop: "10px" }} >
            Back
            </Button>
      </PageHeaderLayout>
      <Grid container spacing={1} sx={{}}>
        <Grid item xs={12} lg={3}>
          <Paper>
            <Box sx={{ p: 2, textAlign: 'center', borderBottom:'1px dashed lightGray' }}>
              <Box sx={{display:'flex',justifyContent:'center'}}>
                <Avatar
                  src={userDetail?.image ? `${Helpers.ImagePathUrl()}/profile/sm/${userDetail?.image}`:profileImage}
                  // alt="Profile"
                  sx={{ width: 150, height: 150, border: '4px solid white'}}
                />
              </Box>
              <Typography variant="body1" sx={{fontSize:'0.85rem'}}>{userDetail?.firstName} {userDetail?.middileName} {userDetail?.lastName}</Typography>
              <Chip
                label={userDetail?.role?.title}
                color="primary"
                variant="outlined"
                size="small"
                sx={{ fontSize:'0.65rem' }}
              />
            </Box>
            <Tabs
              
              orientation="vertical"
              variant="scrollable"
              value={activeTab}
              onChange={handleTabChange}
              sx={{ borderRight: 1, borderColor: 'divider', width: '100%' }}
              >
              <Tab label="Personal Details" sx={{borderBottom:'1px solid lightGray', fontSize:'.8rem'}} />
              <Tab label="Change Password" sx={{borderBottom:'1px solid lightGray', fontSize:'.8rem'}} />
              {/* <Tab label="Address Details" />
              <Tab label="Business Details" />
              <Tab label="Service Details" />
              <Tab label="Availabilty Details" />
              <Tab label="Storefront Details" /> */}
            </Tabs>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={9}>
          <Box sx={{ flexGrow: 1, }}>
            <Paper elevation={3} >
              {renderForm()}
            </Paper>
            {/* <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
              <PermissionGuard feature='service-expert' permission='canEdit'>
                <Button startIcon={<BorderColorOutlinedIcon/>} color='warning' variant='contained' size='small' onClick={()=>navigate(`/admin/userProfile/${id}`)}>
                  Edit
                </Button>
              </PermissionGuard>
            </Box> */}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ViewProfile;

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import MemberList from './MemberList';
import { Box } from '@mui/material';
import MainContentLayout from '../../../components/Layout/MainContentLayout';

const Member = () => {
    const menuDispatch = useDispatch();
    useEffect(() => {
        menuDispatch({
            type: 'menuSelect',
            payload: 'members'
        });
    }, []);
    return (
        <MainContentLayout>
            <MemberList/>
        </MainContentLayout>
    )
}

export default Member;

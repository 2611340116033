import {Box,Button,Checkbox, FormControl,FormControlLabel,FormGroup , FormHelperText, Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { Controller,useForm } from 'react-hook-form'
import { BookingDetailContext } from '../AddBooking';
import { StepperContext } from '../BookingForm';

const PaymentMethod = () => {
    const {bookingData,setBookingData} = useContext(BookingDetailContext);
    const {activeStep, setActiveStep,completed, setCompleted,handleNext,handleBack,handleComplete,handleReset} = useContext(StepperContext);
    const { control, handleSubmit, setValue, formState: { errors ,isDirty},watch,reset } = useForm({
        mode: "onChange",
        defaultValues:{
            paymentMode:bookingData.paymentMode||'',
        }
    });
    const completeStep4=(data)=>{
        // console.log(" submitted Services and Expert:",data);
        setBookingData(pre=>{
            return {...pre,paymentMode:data.paymentMode}
        })
        handleComplete();
        handleNext();
    }
  return (
    <Box component={'form'} onSubmit={handleSubmit(completeStep4)} sx={{pb:'1%'}}>
        <Grid container spacing={2} >
            <Grid item lg={9} xs={12} sx={{margin:'auto'}}>
                <Grid container spacing={2} >
                {/* ............left section................... */}
                    <Grid item xs={12} lg={5}>
                        <Grid container>
                            <Grid item xs={12} sx={{my:'1%'}}>
                                <Grid container sx={{mb:'1%'}}>
                                    <Grid item lg={7} xs={12}>
                                        <Typography sx={{fontWeight:'600'}}>Payment Method :</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item lg={12} xs={12}>
                                    <Controller
                                        name="paymentMode"
                                        control={control}
                                        rules={{ required: "Please select paymentMode" }}
                                        render={({ field, fieldState: { error } }) => (
                                            <FormControl sx={{ minWidth: "100%" }} component="fieldset" error={!!error}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={field.value === 'pay after service'}
                                                                onChange={(e) => {
                                                                    // If checkbox is checked, set to 'pay after service'
                                                                    // If unchecked, set to empty string
                                                                    const newValue = e.target.checked ? 'pay after service' : '';
                                                                    field.onChange(newValue);
                                                                    setValue('paymentMode', newValue);
                                                                }}
                                                            />
                                                        }
                                                        label="Pay After Service"
                                                    />
                                                </FormGroup>
                                                {error && <FormHelperText>{error.message}</FormHelperText>}
                                            </FormControl>
                                        )}
                                    />

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        {/* =======Form Action Button Section ========*/}
        <Box sx={{display:'flex',justifyContent:'center',gap:2,mt:'.5%'}}>
            <Button disabled={activeStep === 0} onClick={handleBack} variant='contained' color='secondary'>Back</Button>
            <Button 
            disabled={!watch('paymentMode') || !isDirty} 
            type='submit' 
            // onClick={handleNext}
            variant='contained' color='primary'>Submit & Next</Button>
        </Box>
    </Box>
  );
}

export default PaymentMethod;

import { Box } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import CustomerProfile from './CustomerProfile';
import MainContentLayout from '../../../components/Layout/MainContentLayout';

const ViewCustomerProfile = () => {
    const menuDispatch = useDispatch();
    menuDispatch({
        type: 'menuSelect',
        payload: 'customer'
    });
  return (
    <MainContentLayout>
        <CustomerProfile />
    </MainContentLayout>
  );
}

export default ViewCustomerProfile;

import { Box } from '@mui/material';
import React from 'react';

const MainContentLayout = ({children}) => {
  return (
    <Box component="main" sx={{ flexGrow: 1, p: '.5%' }}>            
      <Box sx={{ width: '100%', marginBottom: 1 }}>
          {children}                    
      </Box>
    </Box>
  );
}

export default MainContentLayout;

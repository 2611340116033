import { commonRequest } from "../../services/ApiCall";
import { Helpers } from "../../services/Helpers";


export const RoleAndPermissionServices = {
    roleList : async ()=>{
        return await commonRequest("GET",  `${Helpers.URL()}/api/admin/role-permission/roleList`,{}, Helpers.token());
    },
    createMember: async (data)=>{
        return await commonRequest("POST",  `${Helpers.URL()}/api/admin/auth/register`,data, Helpers.token());
    }, 
    memberList:async(data)=>{
        return await commonRequest("GET",  `${Helpers.URL()}/api/admin/role-permission/memberList?page=${data.page}&limit=${data.limit}&searchKey=${data.filters.searchKey}&isActive=${data.filters.status}&roleId=${data.filters.roleId}`,{}, Helpers.token());
    },
    changeMemberRole: async(data)=>{
        return await commonRequest("PUT" , `${Helpers.URL()}/api/admin/role-permission/updateMemberRole/${data.roleId}/${data.memberId}`,{}, Helpers.token())
    },
    
    changeMemberStatus: async(memberId)=>{
        return await commonRequest("PUT" , `${Helpers.URL()}/api/admin/role-permission/changeMemberStatus/${memberId}`,{}, Helpers.token())
    },
    deleteMember: async (memberId) =>{
        return await commonRequest("DELETE",`${Helpers.URL()}/api/admin/role-permission/deleteMember/${memberId}`,{},Helpers.token());
    },
    roleAndPermissionList: async (roleId)=>{
        return await commonRequest("GET",  `${Helpers.URL()}/api/admin/role-permission/rolePermissionList?roleId=${roleId}&searchKey=`,{}, Helpers.token());
    },
    createRole: async (data)=>{
        return await commonRequest("POST",  `${Helpers.URL()}/api/admin/role-permission/role`,data, Helpers.token());
    },
    changeRoleStatus: async(roleId)=>{
        return await commonRequest("PUT" , `${Helpers.URL()}/api/admin/role-permission/changeRoleStatus/${roleId}`,{}, Helpers.token())
    },
    deleteRole: async (roleId) =>{
        return await commonRequest("DELETE",`${Helpers.URL()}/api/admin/role-permission/deleteRole/${roleId}`,{},Helpers.token());
    },
    updateRoleAndPermissions: async (data)=>{
        return await commonRequest("POST",  `${Helpers.URL()}/api/admin/role-permission/rolePermission`,data, Helpers.token());
    },
    createFeature: async (data)=>{
        return await commonRequest("POST",  `${Helpers.URL()}/api/admin/role-permission/feature`,data, Helpers.token());
    },
    featureList : async ()=>{
        return await commonRequest("GET",  `${Helpers.URL()}/api/admin/role-permission/featureList`,{}, Helpers.token());
    },
    changeFeatureStatus: async(featureId)=>{
        return await commonRequest("PUT" , `${Helpers.URL()}/api/admin/role-permission/changeFeatureStatus/${featureId}`,{}, Helpers.token())
    },
    deleteFeature: async (featureId) =>{
        return await commonRequest("DELETE",`${Helpers.URL()}/api/admin/role-permission/deleteFeature/${featureId}`,{},Helpers.token());
    }
}
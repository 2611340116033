import React, { useEffect, useState } from 'react';
import {  Box,  Chip,  Tooltip,  Menu,  MenuItem,  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, IconButton, Stack, FormControl, InputLabel, Select, Grid, Typography} from '@mui/material';
import {  CheckCircle,  Warning,  Block,  Pending, KeyboardArrowDown,  Edit, Cancel} from '@mui/icons-material';
import Swal from 'sweetalert2';
import { Controller,set,useForm, useWatch } from 'react-hook-form';
import { PermissionGuard } from '../../../components/PermissionGuard';
import { isMobile } from 'react-device-detect';
import { RewardServices } from './RewardServices';

const StatusIndicator = ({ activeStatus,id, onStatusChange,fetchList }) => {
    const [previousStatus, setPreviousStatus] = useState(activeStatus);
    const { control,setValue } = useForm({
        defaultValues: {
            status: activeStatus
        }
    });
    const [editStatus,setEditStatus] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState(false);
    const handleStatusChange = async () => {
        const result = await Swal.fire({
            title: 'Confirm Active Status Change',
            text: `Are you sure you want to change the program active status to ${status != activeStatus && status?'Active':'In-active'}? This action will change the Active Status of reward program.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        });
        if (result.isConfirmed) {
            try {
                Swal.fire({
                  title: 'Processing...',
                  text: 'Please wait while we updaing program active status',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  showConfirmButton: false,
                  didOpen: () => {
                    Swal.showLoading();
                  }
                });
                // Call your API to update the status
                const response = await RewardServices.changeStatus({
                  id: id,
                  status: status,
                });
                console.log('Response:', response);
                if (response.success == '1') {
                  // fetchUserDetails();
                  Swal.fire({
                    title: 'Status Updated',
                    text: 'The display status of reward program has been successfully updated',
                    icon: 'success',
                    timer: 1500
                  });
                  fetchList();
                  setEditStatus(false);
                  setPreviousStatus(status);
                  return true;
                } else {
                    await Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: response.message || 'Failed to update program display status'
                    });
                    setValue('status',activeStatus);
                    setEditStatus(false);
                    return false;
                }
            } catch (error) {
                await Swal.fire({
                  title: 'Update Failed',
                  text: error.message || 'Failed to update program display status',
                  icon: 'error'
                });
                setValue('status',activeStatus);
                setEditStatus(false);
                return false;
            }
        }else{
            setValue('status',activeStatus);
            setEditStatus(false);
        }
      };
    const status = useWatch({
            control,
            name: 'status',
        }); 
    useEffect(() => {
        if(status != previousStatus){
            handleStatusChange()
        }
    }, [status]);
  return (
    <>
    <Grid item sx={{pt:'1%!important'}} xs={isMobile?12:4}>
        <Typography sx={{color:'#3e3e3e',fontWeight:'500',fontSize:'0.75rem'}}>Status</Typography>
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Controller
                name='status'
                control={control}
                render={({ field }) => (
                    <FormControl fullWidth>
                        <Select
                            {...field}
                            size='small'
                            // value={activeStatus}
                            disabled={!editStatus}
                            sx={{
                                // minHeight: '0px',
                                // height: '1.6rem',
                                fontSize: '0.75rem',
                                '& .MuiSelect-select': {
                                // padding: '0 8px',
                                display: 'flex',
                                alignItems: 'center',
                                },
                            }}
                        >
                            <MenuItem value={true}>Active</MenuItem>
                            <MenuItem value={false}>In-active</MenuItem>
                        </Select>
                    </FormControl>
                )}
            />
            <PermissionGuard feature='reward-program' permission='canEdit'>                            
                <IconButton size='small' color={editStatus?'error':'warning'} onClick={()=>setEditStatus(!editStatus)}>
                    {editStatus ?<Cancel/>:<Edit/>}
                </IconButton> 
            </PermissionGuard>
        </Box>
    </Grid>
    </>
  );
}

export default StatusIndicator;

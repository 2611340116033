import { useState, useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import LogoutIcon from '@mui/icons-material/Logout';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import MuiAppBar from '@mui/material/AppBar';
import { useDispatch, useSelector } from 'react-redux';
import { LocalStore } from '../../storage/authStore';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import logo from "../../assets/images/b2b.svg"
import Cudel_logo from '../../assets/images/cudel_logo.svg'
import { Avatar } from '@mui/material';
import { RoleAndPermissionServices } from '../../pages/Settings/RoleAndPermissionServices';

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1
}));

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

export default function Navbar() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const barTrig = useSelector(state => state.utilsSto.menu);
    // const updateOpen = useAppStore((state) => state.updateOpen);
    // const dopen = useAppStore((state) => state.dopen);
    const userDetail = LocalStore.getLoggedUserDetails();
    const navigate = useNavigate();
    const logoutEvent = () => {
        const logout = LocalStore.doLogout()        
        window.location.assign("/login");
    }
    const dispatch = useDispatch();

    const updateOpen = () => {
        dispatch({
            type: 'menuStatus'
        })
    };
    const open = useSelector(state => state.utilsSto.menuStatus);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    
    const navigateToProfile = () => {
        handleMenuClose();
        navigate('/admin/logged/userProfile');
    }

    const [roleList,setRoleList] = useState([]);
    const fetchRoleList= async()=>{
        try {
            const roleListResponse = await RoleAndPermissionServices.roleList();
            // console.log("fetching role list response:",roleListResponse);
            const data = roleListResponse.data;
            if(roleListResponse.success=='1'){
              setRoleList(data);
            }
        } catch (error) {
          
        }
    }
    useEffect(()=>{
        // fetchRoleList();
    },[])

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
            sx={{
                '& .MuiMenu-list': {
                    padding: 0
                },
                '& .MuiMenu-paper': {
                    padding: 0,
                    width:'10rem'
                }
            }}
        >
            <MenuItem onClick={navigateToProfile} sx={{lineHeight:'.15',borderBottom:'1px solid lightGray',p:1}}>
                {/* <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                    sx={{px:1}}
                > */}
                    <AccountCircle sx={{mr:1,fontSize:'1rem'}} />
                {/* </IconButton> */}
                <Typography sx={{fontSize:'.85rem'}}>Profile</Typography>
            </MenuItem>
            <MenuItem onClick={logoutEvent} sx={{lineHeight:'.15',p:1}}>
                {/* <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                    sx={{px:1}}
                > */}
                    <LogoutIcon sx={{mr:1,fontSize:'1rem'}} />
                {/* </IconButton> */}
                <Typography sx={{textAlign:'center',fontSize:'0.85rem'}}>Logout</Typography>
            </MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                    <Badge badgeContent={4} color="error">
                        <MailIcon />
                    </Badge>
                </IconButton>
                <p>Messages</p>
            </MenuItem>
            <MenuItem>
                <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                >
                    <Badge badgeContent={17} color="error">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
            <MenuItem onClick={logoutEvent}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <LogoutIcon />
                </IconButton>
                <p>Logout</p>
            </MenuItem>
        </Menu>
    );
    let constMenu = "";
    if(isMobile){
        constMenu = (
            (!barTrig) ? <MenuIcon /> : <CloseIcon />
        )
    }else{
        constMenu = (
            (!barTrig) ? <MenuIcon fontSize='.5rem'/> : <ArrowBackIosIcon fontSize='.5rem'/>
        )
    }
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" elevation={0} sx={{ background: "#ffffff", color: "#2f2f2f",minWidth:0 }}>
                <Toolbar>
                    <IconButton
                        size="small"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{ mr: 2 }}
                        onClick={() => updateOpen()}
                    >
                       {constMenu} 
                    </IconButton>
                    <Typography
                        variant="body1"
                        noWrap
                        component="div"
                        sx={{width:'10%', display: { xs: 'none', sm: 'block' }, cursor: 'pointer' }}
                        onClick={(e) => navigate("/admin")}
                    >
                        <img src={Cudel_logo} style={{width:'90%'}}/>
                    </Typography>
                    {/* <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </Search> */}
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex',width:'15%' } }}>
                        
                        <Box sx={{display:'flex',gap:'5%',width:'100%'}}>
                            <Avatar alt='profile_img' sx={{width:'2rem',height:'2rem'}}/>
                            <Box>
                                <Typography variant='body2' sx={{textTransform:'capitalize',fontWeight:'600'}}>{userDetail?.firstName || ''} {userDetail?.lastName || ''}</Typography>     
                                {/* <Typography variant='body2'>{roleList && (roleList.find(role=>role.id == userDetail?.roleId))?.title}</Typography> */}
                                <Typography sx={{lineHeight:1,fontSize:'.65rem'}}>{userDetail?.role?.title}</Typography>
                            </Box>
                        </Box>
                        <IconButton
                            size="small"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            // onClick={logoutEvent}
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>
                    
                    {/* <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box> */}
                </Toolbar>
            </AppBar>
            {/* {renderMobileMenu} */}
            {renderMenu}
        </Box>
    );
}
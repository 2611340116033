// import { commonRequest } from "../../services/ApiCall";
// import { Helpers } from "../../services/Helpers";

import { commonRequest } from "../../services/ApiCall";
import { Helpers } from "../../services/Helpers";

export const ProviderServices = {
    categoryList : async (data)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/service-category/getCategories`,{}, Helpers.token());
    },
    
    subCategoryList : async (data)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/service-category/getSubCategories/${data.categoryId}`,{}, Helpers.token());
    },

    serviceList: async (data)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/service-master/getServicesBySubcategory/${data.subCategoryId}`,{}, Helpers.token());
    },

    createUserProfile: async(data) =>{
        return await commonRequest("POST",  `${Helpers.URL()}/api/admin/user/addUserProfile`,data, Helpers.token());
    },

    updateUserBusinessDetail: async(data) =>{
        return await commonRequest("POST",  `${Helpers.URL()}/api/admin/user/addUpdateBusinessDetails`,data, Helpers.token());
    },

    updateUserServiceDetail: async(data) =>{
        return await commonRequest("POST",  `${Helpers.URL()}/api/admin/provider-services/`,data, Helpers.token());
    },

    // updateUserBankDetail: async(data) =>{
    //     return await commonRequest("POST",  `${Helpers.URL()}/api/bank-detail`,data, Helpers.token());
    // },

    updateTimeSlot: async(data) =>{
        return await commonRequest("POST",  `${Helpers.URL()}/api/admin/provider-time-slots/`,data, Helpers.token());
    },

    fetchProviderServices: async(data) =>{
        return await commonRequest("GET",  `${Helpers.URL()}/api/admin/provider-services/list?page=${data.page}&limit=${data.pageSize}&providerId=${data.providerId}&searchKey=`,{}, Helpers.token());
    },
    fetchProviderServiceDetailsById: async(data) =>{
        return await commonRequest("GET",  `${Helpers.URL()}/api/admin/provider-services/providerServiceDetails/${data.serviceId}`,{}, Helpers.token());
    },
    updateProviderServices: async(data) =>{
        return await commonRequest("PUT",  `${Helpers.URL()}/api/admin/provider-services/1`,data, Helpers.token());
    },
    deleteProviderService: async(serviceId) =>{
        return await commonRequest("DELETE",  `${Helpers.URL()}/api/admin/provider-services/${serviceId}`,{}, Helpers.token());
    },
    fetchProviderTimeSlots: async(providerId) =>{
        return await commonRequest("GET",  `${Helpers.URL()}/api/admin/provider-time-slots/${providerId}`,{}, Helpers.token());
    },

    fetchProviderDetailsById: async(providerId) =>{
        return await commonRequest("GET",  `${Helpers.URL()}/api/admin/user?userId=${providerId}`,{}, Helpers.token());
    },

    fetchProviderList: async(data) =>{
        console.log("data",data);
        const queryParams = new URLSearchParams();

        // Basic pagination and sorting parameters
        queryParams.append('page', data.page + 1);
        queryParams.append('limit', data.limit);

        // Search parameters
        queryParams.append('searchKey', data.filters.searchKey || '');
        

        // Sorting parameters
        queryParams.append('sortBy', data.filters.sortBy);            
        queryParams.append('sortType', data.filters.sortType || 'desc');
        

        // Additional multi-column filters
        if (data.filters.filterOptions) {
            data.filters.filterOptions.forEach((filter, index) => {
                if (filter.fieldName && filter.value) {
                    queryParams.append(`filters[${index}][fieldName]`, filter.fieldName);
                    queryParams.append(`filters[${index}][operator]`, filter.operator);
                    queryParams.append(`filters[${index}][value]`, filter.value);
                    
                    if (index !== 0) {
                        queryParams.append(`filters[${index}][andOr]`, filter.andOr);
                    }
                    
                    if (filter.operator === 'between') {
                        queryParams.append(`filters[${index}][endValue]`, filter.endValue);                        
                    }
                }
            });
        }
        console.log("queryParams",queryParams.toString());
        return await commonRequest("GET",  `${Helpers.URL()}/api/admin/user/list?${queryParams.toString()}`,{}, Helpers.token());
        // return await commonRequest("GET",  `${Helpers.URL()}/api/admin/user/list?page=${data.page+1}&limit=${data.limit}&searchKey=${data.filters.searchKey}&sortBy=createdAt&sortType=DESC`,{}, Helpers.token());
    },

    providerListToExport: async(data) =>{
        console.log("data",data);
        const queryParams = new URLSearchParams();

        // Search parameters
        queryParams.append('searchKey', data.filters.searchKey || '');
        

        // Sorting parameters
        queryParams.append('sortBy', data.filters.sortBy);            
        queryParams.append('sortType', data.filters.sortType || 'desc');
        

        // Additional multi-column filters
        if (data.filters.filterOptions) {
            data.filters.filterOptions.forEach((filter, index) => {
                if (filter.fieldName && filter.value) {
                    queryParams.append(`filters[${index}][fieldName]`, filter.fieldName);
                    queryParams.append(`filters[${index}][operator]`, filter.operator);
                    queryParams.append(`filters[${index}][value]`, filter.value);
                    
                    if (index !== 0) {
                        queryParams.append(`filters[${index}][andOr]`, filter.andOr);
                    }
                    
                    if (filter.operator === 'between') {
                        queryParams.append(`filters[${index}][endValue]`, filter.endValue);                        
                    }
                }
            });
        }
        console.log("queryParams",queryParams.toString());
        return await commonRequest("GET",  `${Helpers.URL()}/api/admin/user/export-list?${queryParams.toString()}`,{}, Helpers.token());
        // return await commonRequest("GET",  `${Helpers.URL()}/api/admin/user/list?page=${data.page+1}&limit=${data.limit}&searchKey=${data.filters.searchKey}&sortBy=createdAt&sortType=DESC`,{}, Helpers.token());
    },

    createStorefront: async(data) =>{
        return await commonRequest("POST",  `${Helpers.URL()}/api/admin/storefront/createNewStorefront`,data, Helpers.token());
    },   
    updateStorefrontData: async(data,id) =>{
        return await commonRequest("PUT",  `${Helpers.URL()}/api/admin/storefront/updateStorefront/${id}`,data, Helpers.token());
    },
    modifyReviewStatus: async(data) =>{
        return await commonRequest("POST",  `${Helpers.URL()}/api/admin/user/changeReviewStatus`,data, Helpers.token());
    },
    modifyAccountStatus: async(providerId) =>{
        return await commonRequest("GET",  `${Helpers.URL()}/api/admin/user/changeUserStatus/${providerId}`,{}, Helpers.token());
    },
    addUpdateproviderPaymentDetail: async(data) =>{
        return await commonRequest("POST",  `${Helpers.URL()}/api/admin/payment/addUpdateProviderPayment`,data, Helpers.token());
    }
}
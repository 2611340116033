import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Card, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Layout = ({children,pageName}) => {
    const navigate = useNavigate();
  return (
    <Box sx={{ flexGrow: 1, p: .75 }}>
        <Card sx={{ minWidth: "100%", marginBottom: ".5%" ,padding:'.5% 1%'}}>
            <Stack direction="row" spacing={2} sx={{justifyContent:'center',alignItems:'center'}}>
            <Typography
                gutterBottom
                variant='body1'
                component='div'
                sx={{fontSize:'1rem',fontWeight:'600',color:'#3e3e3e'}}
                // sx={{ padding: '20px' }}
            >
                {pageName}
            </Typography>
            <Typography
                variant='h3'
                component='div'
                sx={{ flexGrow: 1 }}
            ></Typography>
            
                <Button size='small' variant='contained' onClick={() => navigate(-1)} endIcon={<ArrowBack sx={{fontSize:'.85rem!important'}}/>} sx={{height:'fit-content',fontSize:'x-small',p:'.5% 1.25%'}}>
                Back
                </Button>
            

            </Stack>
        </Card>
        <Box>
            {children}
        </Box>
    </Box>
  );
}

export default Layout;

import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import CustomerList from './CustomerList'
import MainContentLayout from "../../components/Layout/MainContentLayout";

export default function Customer(){
    const menuDispatch = useDispatch();
    useEffect(() => {
        menuDispatch({
            type: 'menuSelect',
            payload: 'customer'
        });
    }, []);
    return (
        <MainContentLayout>
            <CustomerList/>
        </MainContentLayout>
    )
}

const getDeviceType = () => {
    const userAgent = navigator.userAgent;
    const width = window.innerWidth;
  
    if (/Mobi|Android/i.test(userAgent) || width <= 768) {
      return 'mobile';
    } else if (/Tablet|iPad/i.test(userAgent) || (width > 768 && width <= 1024)) {
      return 'tablet';
    } else {
      return 'desktop';
    }
  };

  export default getDeviceType;
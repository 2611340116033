import React, { useState,useEffect } from 'react';
import { Box, Button, Avatar, Typography, IconButton, Tabs, Tab, TextField, Grid, Paper, Card, Chip, Modal, Stack } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { ArrowBack, Close } from '@mui/icons-material';
import { CustomerService } from '../Service';
import PageHeaderLayout from '../../../components/Layout/PageHeaderLayout';
import AccountStatusIndicator from './AccountStatusIndicator';
import VerifyOtp from './VerifyOtp';
import { isMobile } from 'react-device-detect';
import Loading from '../../../components/Loader/Loading';
import BookingHistory from './BookingHistory';
import { PermissionGuard } from '../../../components/PermissionGuard';
import BasicDetails from './PersonalDetail';
import PersonalDetails from '../PersonalDetail';


const CustomerProfile = () => {
  const {id} = useParams();
  const navigate =  useNavigate();

  const [activeTab, setActiveTab] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [isLoading,setIsLoading] = useState(false);
  const [personalDetails, setPersonalDetails] = useState({});
  const [bookingList, setBookingList] = useState([]);

 /************ customer mobile/otp verification related ***********/
     const [openOtpModal,setOpenOtpModal] = useState(false);
     const [selectedCustomerDetail,setSelectedCustomerDetail] = useState(null);
     const sendOtp = async (customerData) => {
      //  setAnchorEl(null);
       // console.log("selected customer :",customerData)
       const result = await Swal.fire({
         text: "Do you want to Verify Customer now?",
         icon: 'question',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Yes, Verify now!',
         cancelButtonText: 'No, Cancel',
         allowOutsideClick: false,
         allowEscapeKey: false,
         showCloseButton: false,
       });
     
       if (result.isConfirmed) {
        //  setSelectedCustomerDetail(customerData);
         try {
           // Show loading state
           const loadingDialog = Swal.fire({
             title: 'Processing...',
             text: 'Please wait while sending the otp',
             allowOutsideClick: false,
             allowEscapeKey: false,
             showConfirmButton: false,
             didOpen: () => {
               Swal.showLoading();
             }
           });
     
           // Make the API call
           const response = await CustomerService.verifyCustomerByMobileNo({ mobileNumber: customerData.mobileNumber });
           // console.log("mobile number submission response:", response);
     
           // Always close the loading dialog
           await Swal.close();
     
           if (response.success == '1') {
             setOpenOtpModal(true);
             return true;
           } else {
             await Swal.fire({
               icon: 'error',
               title: 'Error!',
               text: response.message || 'Failed to send otp'
             });
             return false;
           }
     
         } catch (error) {
           // Always close the loading dialog in case of error
           await Swal.close();
           
           // console.error("Error Verifying Customer mobile number:", error);
           await Swal.fire({
             icon: 'error',
             title: 'Error!',
             text: 'An error occurred while Verifying customer'
           });
           return false;
         }
       } else {
         return false;
       }
     }
     const handleModalClose = ()=>{
       fetchData();
      //  setSelectedCustomerDetail(null);
       setOpenOtpModal(false);
     }
  

  const fetchData = async () => {
    setIsLoading(true);
    const response = await CustomerService.fetchCustomerDetailById(id);
    console.log('response:',response)
    const {bookingList,...restAll} =response.data
    setPersonalDetails(restAll);
    setBookingList(bookingList);
    setIsLoading(false);
  }

  useEffect(() => {
    
    if (id && id>0) {
      fetchData();
    }
  }, [id]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderForm = () => {
    switch (activeTab) {
      case 0:
        return (
          <Grid container spacing={0}>
            <BasicDetails personalDetails={personalDetails}/>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={0}>
            <BookingHistory bookingList={bookingList}/>
          </Grid>
        );
      default:
        return null;
    }
  };

  /************* Edit cuistomer Related *************/
  const [openAddEditModal,setOpenAddEditModal] =  useState(false);
  // const customerDataForEdit = {
  //   id: personalDetails.id,
  //   firstName: personalDetails?.profile?.firstName,
  //   middleName: personalDetails?.profile?.middleName,
  //   lastName: personalDetails?.profile?.lastName,
  //   mobileNumber: personalDetails?.mobileNumber,
  //   gender: personalDetails?.profile?.gender,
  //   email: personalDetails?.profile?.email,
  //   dob: personalDetails?.profile?.dob
  // }
  const handleEdit = async()=>{
    const result = await Swal.fire({
      text: "Do you want to Edit Customer Detail now?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Edit now!',
      cancelButtonText: 'No, Cancel',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCloseButton: false,
    });
    if (result.isConfirmed) {
      setSelectedCustomerDetail({
        id: personalDetails.id,
        firstName: personalDetails?.profile?.firstName,
        middleName: personalDetails?.profile?.middleName,
        lastName: personalDetails?.profile?.lastName,
        mobileNumber: personalDetails?.mobileNumber,
        gender: personalDetails?.profile?.gender,
        email: personalDetails?.profile?.email,
        dob: personalDetails?.profile?.dob
      });
      setOpenAddEditModal(true)
    }else{
      return false
    }
  }
  const handleAddEditCustomerModal = async ()=>{
    fetchData();
    setSelectedCustomerDetail(null);
    setOpenAddEditModal(false);
  }

  return (
    <>
      {/* <Card sx */}
      {isLoading && <Loading/>}
      <PageHeaderLayout headerTitle={'Customer Profile'}>
        <PermissionGuard feature='customer' permission='canEdit'>
          <Button startIcon={<BorderColorOutlinedIcon/>} color='warning' variant='contained' size='small' onClick={handleEdit}>
            Edit
          </Button>
        </PermissionGuard>
      </PageHeaderLayout>
      <Grid container spacing={1} sx={{}}>
        <Grid item xs={12} lg={3}>
          <Paper>
            <Box sx={{ p: 2, textAlign: 'center', borderBottom:'1px dashed lightGray' }}>
              <Box sx={{display:'flex',justifyContent:'center'}}>
                <Avatar
                  src={''}
                  // alt="Profile"
                  sx={{ width: 150, height: 150, border: '4px solid white'}}
                />
              </Box>
              <Typography variant="body1" sx={{fontSize:'0.85rem'}}>{`${personalDetails?.profile?.firstName || ''} ${personalDetails?.profile?.middleName || ''} ${personalDetails?.profile?.lastName || ''}`}</Typography>
              {!isLoading &&
                <AccountStatusIndicator customerData={personalDetails} fetchUserDetails={fetchData} setOpenOtpModal={setOpenOtpModal} />
              }
              </Box>
            <Tabs
              
              orientation="vertical"
              variant="scrollable"
              value={activeTab}
              onChange={handleTabChange}
              sx={{ borderRight: 1, borderColor: 'divider', width: '100%' }}
              >
              <Tab label="Personal Details" sx={{borderBottom:'1px solid lightGray', fontSize:'.8rem'}} />
              <Tab label="Booking History" sx={{borderBottom:'1px solid lightGray', fontSize:'.8rem'}} />
            </Tabs>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={9}>
          <Box sx={{ flexGrow: 1, }}>
            <Box elevation={3} >
              {renderForm()}
            </Box>
            {/* <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
              <PermissionGuard feature='service-expert' permission='canEdit'>
                <Button startIcon={<BorderColorOutlinedIcon/>} color='warning' variant='contained' size='small' onClick={()=>navigate(`/admin/userProfile/${id}`)}>
                  Edit
                </Button>
              </PermissionGuard>
            </Box> */}
          </Box>
        </Grid>
      </Grid>

      {/* ================ Verify Customer Modal=============== */}
      <Modal
        open={openOtpModal}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflow: "scroll" }}
      >
        <Box sx={{
          position: 'absolute',
          top: (isMobile) ? '80%' : '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          // border: '2px solid #ddd',
          boxShadow: 24,
          p: 2,}}
          width={(isMobile) ? "95%" : "50%"}
        >
          <Box sx={{textAlign:'end'}}>
            <Button size='small' variant='outlined' color='error' startIcon={<Close/>} onClick={handleModalClose}>close</Button>
          </Box>
          <VerifyOtp customerMobileNumber={personalDetails?.mobileNumber}
            selectedCustomerDetail={personalDetails}
            sendOtp = {sendOtp}
            setOpenOtpModal={setOpenOtpModal}
            onClose={handleModalClose}
          />
        </Box>
      </Modal>

      {/* ================== Add/Edit Customer Modal ================ */}
      <Modal
        open={openAddEditModal}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflow: "scroll" }}
      >
        <Box 
          sx={{
            position: 'absolute',
            top: (isMobile) ? '80%' : '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            // border: '2px solid #ddd',
            boxShadow: 24,
            // p: 2,
          }}
          width={(isMobile) ? "95%" : "65%"}
        >
          <Card sx={{ minWidth: "100%" ,padding:'1%'}}>
            <Stack direction="row" spacing={2} sx={{justifyContent:'center',alignItems:'center'}}>
              <Typography
                  gutterBottom
                  sx={{
                    fontSize:'0.85rem',
                    fontWeight:600
                  }}
                  // sx={{ padding: '20px' }}
              >
                {'Edit Customer Detail'}
              </Typography>
              <Typography
                variant='h3'
                component='div'
                sx={{ flexGrow: 1 }}
              />
              <Button size='small' variant='outlined' color='error' startIcon={<Close/>} onClick={handleAddEditCustomerModal}>close</Button>
            </Stack>
          </Card>
          <Box sx={{}}>
            <Grid container spacing={1}>
                <Grid item xs={12} lg={12}>
                    <Paper elevation={3} style={{ padding: 16 }}>
                        <PersonalDetails
                          personalDetails={selectedCustomerDetail}
                          onSubmitPersonal={handleAddEditCustomerModal} 
                        />
                    </Paper>
                </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CustomerProfile;


// import { Close } from '@mui/icons-material';
// import { Box, Card, Dialog, DialogContent, IconButton, Paper, Typography } from '@mui/material';
// import React, { useState } from 'react';
// import DefaultMale from '../../../assets/images/default_customer_male.jpg'
// import DefaultFemale from '../../../assets/images/default_customer_female.jpg'
// import { Helpers } from '../../../services/Helpers';

// const ViewProfile = ({customerDetails,onClose}) => {
//   // console.log('customer details:',customerDetails)
//   return (
//     <Dialog fullWidth open>
//         <Card sx={{display:'flex',justifyContent:'space-between',alignItems:'center',p:'1% 2%',borderBottomLeftRadius:0,borderBottomRightRadius:0}}>
//             <Typography sx={{fontWeight:600,fontSize:'larger',color:'#464166'}}>Customer Profile</Typography>
//             <IconButton size='small' color='error' onClick={()=>onClose()}><Close/></IconButton>
//         </Card>
//       <DialogContent>
//         <Box sx={{display:'flex',gap:'2%',}}>
//           <Box sx={{width:'25%'}}>
//             <img
//               src={customerDetails.profile.profileImage?`${Helpers.ImagePathUrl()}/sm/${customerDetails.profile.profileImage}`
//                 : customerDetails.profile.gender == 'Male'?DefaultMale:DefaultFemale
//               }
//               // alt='customer_profile_image'
//               style={{
//                 // width:'100%',
//                 borderRadius:'7rem',
//                 width: '8rem',
//                 height: '8rem',
//               }}
//             />
//           </Box>
//           <Box sx={{width:'75%'}}>
//             <Typography sx={{fontWeight:'600',my:'1%'}}>Basic Detail's <Box component={'span'} sx={{alignSelf:'right'}}>:</Box></Typography>
//             <Paper sx={{p:'2%',width:'100%'}}>
//                 <Box sx={{display:'flex',alignItems:'flex-end'}}>
//                   <Typography fontWeight={'bold'} sx={{width:'30%',color:'#778899',fontSize:'smaller'}}>Name</Typography>
//                   <Typography fontWeight={'bold'} sx={{mr:'1%'}}>:</Typography>
//                   <Typography fontWeight={'medium'} sx={{fontSize:'small',textTransform:'capitalize'}}>{customerDetails.profile.firstName}{' '}{customerDetails.profile.middleName}{' '}{customerDetails.profile.lastName}</Typography>    
//                 </Box>
//                 <Box sx={{display:'flex',alignItems:'flex-end'}}>
//                   <Typography fontWeight={'bold'} sx={{width:'30%',color:'#778899',fontSize:'smaller'}}>Gender</Typography>
//                   <Typography fontWeight={'bold'} sx={{mr:'1%'}}>:</Typography>
//                   <Typography fontWeight={'medium'} sx={{fontSize:'small'}}>{customerDetails.profile.gender}</Typography>         
//                 </Box>
//                 <Box sx={{display:'flex',alignItems:'flex-end'}}>
//                   <Typography fontWeight={'bold'} sx={{width:'30%',color:'#778899',fontSize:'smaller'}}>Contact</Typography>
//                   <Typography fontWeight={'bold'} sx={{mr:'1%'}}>:</Typography>
//                   <Typography fontWeight={'medium'} sx={{fontSize:'small'}}>{customerDetails.mobileNumber}</Typography>         
//                 </Box>
//             </Paper>
//           </Box>
//         </Box>
        
//         <Box sx={{my:'2%'}}>
//           <Typography sx={{fontWeight:'600',my:'1%'}}>Address Details <Box component={'span'} sx={{alignSelf:'right'}}>:</Box></Typography>
//           { customerDetails?.customerAllAddress&&customerDetails.customerAllAddress.length>0 ?
//             customerDetails.customerAllAddress.map((address,index)=>(
//             <Paper sx={{p:'2%',mb:'2%'}} key={index} >
//               <Box sx={{display:'flex',alignItems:'center',mb:'1%',gap:'2%'}}>
//                 <Typography fontWeight={'600'} sx={{fontSize:'small',color:'#778899'}}>{address.fullName}</Typography>
//                 <Typography variant='caption' sx={{fontSize:'xx-small',bgcolor:'#d2d2d2',color:'#696969',textTransform:"uppercase",p:'.5% 1%',lineHeight:1.3,borderRadius:'.15rem'}}>{address.addressType=='1'?'Home':'Work'}</Typography>
//               </Box>
//               <Typography fontWeight={'500'} sx={{fontSize:'small',textTransform:'capitalize'}}>{address.address}</Typography>
//               <Typography fontWeight={'500'} sx={{fontSize:'small',mt:'1%'}}>{address.mobileNumber}</Typography>
//             </Paper>
//             ))
//             :
//             <Paper sx={{p:'2%'}}>
//               <Typography fontWeight={'medium'} sx={{fontSize:'small',textTransform:'capitalize'}}>No Address found</Typography>
//             </Paper>
//           }
//         </Box>
//       </DialogContent>
//     </Dialog>
//   );
// }

// export default ViewProfile;

import { commonRequest } from "../../services/ApiCall";
import { Helpers } from "../../services/Helpers";

export const CrudServices = {
    categoryList : async (data)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/service-category/getCategories`,{}, Helpers.token());
    },

    subCategoryList : async (data)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/service-category/getSubCategories/${data.categoryId}`,{}, Helpers.token());
    },

    serviceListBySubCategory: async (subCategoryId)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/service-master/getServicesBySubcategory/${subCategoryId}`,{}, Helpers.token());
    },

    allServices : async (data) =>{
        console.log('Received parameters data:',data);

        const queryParams = new URLSearchParams();

        // Basic pagination and sorting parameters
        queryParams.append('page', data.page + 1);
        queryParams.append('limit', data.limit);

        // Search parameters
        queryParams.append('searchKey', data.filters.searchKey || '');
        

        // Sorting parameters
        if (data.filters.sortBy) {
            queryParams.append('sortBy', data.filters.sortBy);            
            queryParams.append('sortType', data.filters.sortType || 'desc');
        }

        // Additional multi-column filters
        if (data.filters.filterOptions) {
            data.filters.filterOptions.forEach((filter, index) => {
                if (filter.fieldName && filter.value) {
                    queryParams.append(`filters[${index}][fieldName]`, filter.fieldName);
                    queryParams.append(`filters[${index}][operator]`, filter.operator);
                    queryParams.append(`filters[${index}][value]`, filter.value);
                    
                    if (index !== 0) {
                        queryParams.append(`filters[${index}][andOr]`, filter.andOr);
                    }
                    
                    if (filter.operator === 'between') {
                        queryParams.append(`filters[${index}][endValue]`, filter.endValue);                        
                    }
                }
            });
        }
        console.log("queryParams",queryParams.toString());
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/service-master/list?${queryParams.toString()}`,{}, Helpers.token());

        // return await commonRequest("get",  `${Helpers.URL()}/api/admin/service-master/list?page=${data.page+1}&limit=${data.limit}&searchKey=${data.filters.searchKey}&sortBy=createdAt&sortType=DESC`,{}, Helpers.token());
    },

    serviceExportList : async (data) =>{
        console.log('Received parameters data:',data);

        const queryParams = new URLSearchParams();

        // Search parameters
        queryParams.append('searchKey', data.filters.searchKey || '');
        

        // Sorting parameters
        if (data.filters.sortBy) {
            queryParams.append('sortBy', data.filters.sortBy);            
            queryParams.append('sortType', data.filters.sortType || 'desc');
        }

        // Additional multi-column filters
        if (data.filters.filterOptions) {
            data.filters.filterOptions.forEach((filter, index) => {
                if (filter.fieldName && filter.value) {
                    queryParams.append(`filters[${index}][fieldName]`, filter.fieldName);
                    queryParams.append(`filters[${index}][operator]`, filter.operator);
                    queryParams.append(`filters[${index}][value]`, filter.value);
                    
                    if (index !== 0) {
                        queryParams.append(`filters[${index}][andOr]`, filter.andOr);
                    }
                    
                    if (filter.operator === 'between') {
                        queryParams.append(`filters[${index}][endValue]`, filter.endValue);                        
                    }
                }
            });
        }
        console.log("queryParams",queryParams.toString());
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/service-master/export-list?${queryParams.toString()}`,{}, Helpers.token());
    },

    addService : async (data) =>{
        return await commonRequest("POST", `${Helpers.URL()}/api/admin/service-master`,data, Helpers.token());
    },

    updateService : async (id, data) =>{
        return await commonRequest("PUT",  `${Helpers.URL()}/api/admin/service-master/${id}`, data, Helpers.token());
    },

    serviceListBySubCategory : async (subCategoryId)=>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/service-master/getServicesBySubcategory/${subCategoryId}`,{}, Helpers.token());
    },
    // deleteService : async (id) =>{
    //     return await commonRequest("DELETE",  `${Helpers.URL()}/category/${id}`, {}, Helpers.token());
    // },
    fetchSacCodes : async () =>{
        return await commonRequest("get",  `${Helpers.URL()}/api/admin/gst/sac-list`,{}, Helpers.token());
    },
    updateServiceSac : async (id, data) =>{
        return await commonRequest("PUT",  `${Helpers.URL()}/api/admin/service-master//updateSac/${id}`, data, Helpers.token());
    },

   
}

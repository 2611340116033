import { Box, CircularProgress, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";

const LoadingOverlay = ({ 
    message = "Loading...", 
    opacity = 0.8,
    blur = false,
    spinnerSize = 40,
    spinnerColor = "primary"
  }) => (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: (theme) => alpha(theme.palette.background.paper, opacity),
        backdropFilter: blur ? 'blur(3px)' : 'none',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <CircularProgress 
        color={spinnerColor} 
        size={spinnerSize}
        sx={{ mb: 2 }}
      />
      {message && (
        <Typography 
          variant="body2" 
          color="text.secondary"
          sx={{ 
            mt: 1,
            fontWeight: 600,
            fontSize:'large'
          }}
        >
          {message}
        </Typography>
      )}
    </Box>
  );
  export default LoadingOverlay
import { SaveAlt } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import React from 'react';
import { BookingService, CustomerService } from './Service';
import Swal from 'sweetalert2';
import { formatDateTime } from '../../utils/dateUtils';
import dayjs from 'dayjs';

const ExportAll = (props) => {
  const { paginationModel, columns, statusOptions } = props;

  // Define custom headers and their corresponding data mapping
    const exportMapping = [
      {
        header: 'Customer Id',
        value: (row) => `${row.id}`
      },
      {
        header: 'Customer Name',
        value: (row) => `${row.profile?.firstName || ''} ${row.profile?.middleName || ''} ${row.profile?.lastName || ''} ${!row.profile?.firstName ? 'Incomplete profile':''}`
      },
      {
        header: 'Email Id',
        value: (row) => `${row?.profile?.email || ''}`
      },
      { 
        header: 'Mobile Number',
        value: (row) => `${row?.mobileNumber || ''}`
      },
      {
        header: 'Gender',
        value: (row) => row.profile?.gender || 'others'
      },
      {
        header: 'DOB',
        value: (row) => row.profile?.dob ? formatDateTime(row.profile.dob,'date') : ''
      },
      {
        header: 'Account Status',
        value: (row) => row.isActive ? 'Active' : 'Inactive'
      },
      {
        header: 'Creation Date',
        value: (row) => formatDateTime(row.createdAt)
      }
    ];

  // Custom export function for server-side pagination

  const handleExportAllRows = async (options) => {
    try {
      // Show loading notification
      Swal.fire({
        title: 'Exporting Data',
        text: 'Please wait while we prepare your download...',
        icon: 'info',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
      
      const listResponse = await CustomerService.customerListToExport(
        {
        filters: {
          searchKey: paginationModel.filters.searchKey,
          sortKey: paginationModel.filters.sortKey,
          sortDirection: paginationModel.filters.sortDirection,
          filterOptions: paginationModel.filters.filterOptions || []
        }
      }
    );
  
    // Prepare export data
    const exportData = listResponse.data.customers.map(row => {
            return exportMapping.reduce((acc, field) => {
              acc[field.header] = field.value(row);
              return acc;
            }, {});
          });
    
          // Create CSV content
          const headers = exportMapping.map(field => `"${field.header}"`).join(',');
          const csvContent = exportData.map(row => 
            exportMapping.map(field => 
              `"${String(row[field.header] ?? '').replace(/"/g, '""')}"`
            ).join(',')
          );
    
          csvContent.unshift(headers);
    
          // Create and download file
          const csvString = csvContent.join('\n');
          const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
          const url = URL.createObjectURL(blob);
    
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Customer-Report.csv');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
    
          Swal.fire({
            title: 'Export Successful',
            html: `
              <p>If download doesn't start automatically, click on provided link:
              <a href="${url}" download="ServiceExpert-report.csv" style="color: blue; text-decoration: underline;">
                Download CSV
              </a></p>
            `,
            icon: 'success',
            showConfirmButton: true,
            confirmButtonText: 'Close'
          });
    
    } catch (error) {
      console.error('Export failed', error);
      
      Swal.fire({
        title: 'Export Failed',
        text: 'There was an issue exporting the data. Please try again.',
        icon: 'error'
      });
    }
  };
return (
  <Box>        
      <Button 
          onClick={handleExportAllRows}
          startIcon={<SaveAlt />}
          sx={{textTransform:'none'}}
      >
          Export
      </Button>      
  </Box>
);
}

export default ExportAll;

// const ExportAll = (props) => {
//     const { paginationModel, columns, statusOptions } = props;
//     // Custom export function for server-side pagination

//     const handleExportAllRows = async (options) => {
//         try {
//           // Show loading notification
//           Swal.fire({
//             title: 'Exporting Data',
//             text: 'Please wait while we prepare your download...',
//             icon: 'info',
//             allowOutsideClick: false,
//             didOpen: () => {
//               Swal.showLoading();
//             }
//           });
          
//           const listResponse = await CustomerService.customerListToExport(
//             {
//             filters: {
//               searchKey: paginationModel.filters.searchKey,
//               sortKey: paginationModel.filters.sortKey,
//               sortDirection: paginationModel.filters.sortDirection,
//               filterOptions: paginationModel.filters.filterOptions || []
//             }
//           }
//         );
      
//           // Prepare export data
//           const exportData = listResponse.data.customers.map(row => {
//             // Custom transformation for specific columns
//             const processedRow = columns.reduce((acc, col) => {
//               if (col.field === 'name') {
//                 acc[col.field] = `${row.profile?.firstName || ''} ${row.profile?.middleName} ${row.profile?.lastName || ''} ${!row.profile?.firstName ? 'Incomplete profile':''}`;
//               } else if (col.field === 'gender') {
//                 console.log(row.profile.gender)
//                 acc[col.field] = row.profile.gender;
//               } else if (col.field === 'isActive') {
//                 if(row.isActive){
//                   acc[col.field] = 'Active';
//                 }else{
//                   acc[col.field] = 'Inactive';
//                 }
//               } else if (col.field === 'createdAt') {
                
//                 acc[col.field] = formatDateTime(row[col.field]);
//               } else if (col.field !== 'action') { // Exclude details column
//                 acc[col.field] = row[col.field] ?? '';
//               }
//               return acc;
//             }, {});
      
//             return processedRow;
//           });
      
//           // Convert to CSV
//           const exportColumns = columns.filter(col => col.field !== 'action'); // Exclude details column
//           const csvContent = exportData.map(row => 
//             exportColumns.map(col => 
//               `"${String(row[col.field] ?? '').replace(/"/g, '""')}"`
//             ).join(',')
//           );
      
//           // Add headers
//           const headers = exportColumns.map(col => `"${col.headerName || col.field}"`).join(',');
//           csvContent.unshift(headers);
      
//           // Create blob
//           const csvString = csvContent.join('\n');
//           const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
//           const url = URL.createObjectURL(blob);
      
//           // Try automatic download
//           const link = document.createElement('a');
//           link.href = url;
//           link.setAttribute('download', 'customer-report.csv');
//           document.body.appendChild(link);
//           link.click();
//           document.body.removeChild(link);
      
//           // Show success with download link as fallback
//           Swal.fire({
//             title: 'Export Successful',
//             html: `
//               <p>If download not start automatically, click on provided link:
//               <a href="${url}" download="all-rows-export.csv" style="color: blue; text-decoration: underline;">
//                 Download CSV
//               </a></p>
//             `,
//             icon: 'success',
//             showConfirmButton: true,
//             confirmButtonText: 'Close'
//           });
      
//         } catch (error) {
//           console.error('Export failed', error);
          
//           // Show error notification
//           Swal.fire({
//             title: 'Export Failed',
//             text: 'There was an issue exporting the data. Please try again.',
//             icon: 'error'
//           });
//         }
//     };
//   return (
//     <Box>        
//         <Button 
//             onClick={handleExportAllRows}
//             startIcon={<SaveAlt />}
//             sx={{textTransform:'none'}}
//         >
//             Export
//         </Button>      
//     </Box>
//   );
// }

// export default ExportAll;
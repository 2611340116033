// import { commonRequest } from "../../services/ApiCall";
// import { Helpers } from "../../services/Helpers";

// export const ImageUploadServices = {
    
//     uploadImage : async (data) =>{
//         return await commonRequest("POST",  `${Helpers.URL()}/api/upload/uploadSingleImage`, data, Helpers.token());
//     },
// }

import { commonRequest } from "../../services/ApiCall";
import { Helpers } from "../../services/Helpers";

export const ImageUploadServices = {
    uploadImage: async (data, onProgressCallback) => {
        return await commonRequest(
            "POST",
            `${Helpers.URL()}/api/admin/upload/uploadSingleImage`,
            data,
            Helpers.token(),
            onProgressCallback
        );
    },
};
import React, { useContext, useEffect, useState } from 'react';
import { Box, IconButton, Avatar, Button,Typography } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ImgUploadDialog from '../../../components/ImgUpload/imgUpload';
import { Helpers } from '../../../services/Helpers';
import panImageDefault from '../../../assets/images/pan_defult.png'
import { UserProfileContext } from '../UserProfile';
import { Controller, useFormContext } from 'react-hook-form';
import Swal from 'sweetalert2';

const PanImageUpload = () => {
    const {businessDetailsData,setBusinessDetailsData} = useContext(UserProfileContext);
    const {control,setValue,watch} =  useFormContext();

    const panNumber = watch('panNumber');
    const panImage = watch('panImage');

    const [trigStatus, setTrigStatus] = useState(null);
    const [imgModalOpen, setImgModalOpen] = useState(false);
 

    const closeModal = () => {
        setImgModalOpen(false);
    };
    
    const imgData = {
        path: "kyc",
        // image_name: `img-${GenerateUniqueVal(1000000)}-${personalDetails.id}.jpeg`,
        img_type: "single"
    };
    

    const handleImageUpload = (uploadedImageData) => {
        console.log(uploadedImageData)
        // Set the uploaded PAN image URL to the form
        setValue('panImage', uploadedImageData.imageName, { 
            shouldValidate: true,
            shouldDirty: true 
        });
        closeModal();
    };
    const viewImage = (image) => {
        // using swal to view image...not close on outside click
        Swal.fire({
            html: `<div style="text-align:left;font-size: 0.9rem; font-weight: 500; color: #333;">${image}</div><img src="${Helpers.ImagePathUrl()}/kyc/lg/${image}" alt="PAN Image" style="width:100%;height:auto;margin-top:1rem"/>`,
            showCloseButton: true,
            showConfirmButton: false,
            showCancelButton: false,
            focusConfirm: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });
    }

    useEffect(()=>{
        // setProfileImage('')
    },[])
  return (
    <Box sx={{ flex: 2,alignSelf:'flex-start',mt:'3.25%'}}>
        <ImgUploadDialog
            open={imgModalOpen}
            onClose={closeModal}
            imgData={imgData}
            imgUploadResponse={(data) => handleImageUpload(data)}
            // trigStatus={handlePImgTrig}
        />

        <Controller
                name="panImage"
                control={control}
                rules={{
                    // validate: (value) => {
                    //     // Only require image if PAN number is present
                    //     if (panNumber && !value) {
                    //         return "PAN image is required when PAN number is provided";
                    //     }
                    //     return true;
                    // }
                }}
                render={({ fieldState: { error } }) => (
                    <Box sx={{display:'flex', gap:'3%'}}>
                        <Box sx={{display:`${(businessDetailsData.panNumber && businessDetailsData.panImage) || !panNumber ? 'none':'flex'}`, height:'100%', alignItems:'center',alignSelf:'flex-end', flexDirection: 'column'}}>
                            <Button
                                sx={{width:'max-content',textTransform:'none'}}
                                variant="contained"
                                size='small'
                                onClick={() => setImgModalOpen(true)}
                            >
                                {(panImage || businessDetailsData.panImage) ?'Re-Select Pan Image':'Select Pan Image'}
                            </Button>
                            {error && (
                                <Typography 
                                    color="error" 
                                    sx={{ mt: 1, textAlign: 'center',fontSize:'.5rem' }}
                                >
                                    {error.message}
                                </Typography>
                            )}
                        </Box>
                        {(panImage || businessDetailsData.panImage) && <Typography sx={{cursor:'pointer',fontSize:'.75rem',color:'blue'}} onClick={()=>viewImage(panImage || businessDetailsData.panImage)}>View Pan Image</Typography>}
                        {/* <Box sx={{width:'60%'}}>
                            <img
                                src={panImage && `${Helpers.ImagePathUrl()}/kyc/sm/${panImage}` || businessDetailsData?.panImage && `${Helpers.ImagePathUrl()}/kyc/sm/${businessDetailsData?.panImage}` || panImageDefault}
                                alt="PAN Default"
                                style={{ width: '100%', height: 'auto',maxHeight:'20vh',borderRadius:'1rem', }}
                            />
                        </Box> */}
                    
                        
                    </Box>
                )}
            />
    </Box>
  );
}

export default PanImageUpload;

import React, { useEffect, useState } from 'react';
import {  Box,  Chip,  Tooltip,  Menu,  MenuItem,  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, IconButton, Stack, FormControl, InputLabel, Select, Grid, Typography} from '@mui/material';
import {  CheckCircle,  Warning,  Block,  Pending, KeyboardArrowDown,  Edit, Cancel} from '@mui/icons-material';
import Swal from 'sweetalert2';
import { Controller,set,useForm, useWatch } from 'react-hook-form';
import { PermissionGuard } from '../../../components/PermissionGuard';
import { isMobile } from 'react-device-detect';
import { RewardServices } from './RewardServices';

const DisplayIndicator = ({ displayStatus,id, onStatusChange,fetchList }) => {
    const [previousDisplayStatus, setPreviousDisplayStatus] = useState(displayStatus);
    const { control,setValue } = useForm({
        defaultValues: {
            isDisplay: displayStatus
        }
    });
    const [editDisplay,setEditDisplay] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState(false);
    const handleStatusChange = async () => {
        const result = await Swal.fire({
            title: 'Confirm Display Status Change',
            text: `Are you sure you want to change the display status to ${isDisplay != displayStatus && isDisplay? 'Yes':'No'}? This action will change the display property of reward program.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        });
        if (result.isConfirmed) {
            try {
                Swal.fire({
                  title: 'Processing...',
                  text: 'Please wait while we updaing program display status',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  showConfirmButton: false,
                  didOpen: () => {
                    Swal.showLoading();
                  }
                });
                // Call your API to update the status
                const response = await RewardServices.changeDisplayStatus({
                  id: id,
                  isDisplay: isDisplay,
                });
                console.log('Response:', response);
                if (response.success == '1') {
                  // fetchUserDetails();
                  Swal.fire({
                    title: 'Status Updated',
                    text: 'The display status of reward program has been successfully updated',
                    icon: 'success',
                    timer: 1500
                  });
                  fetchList();
                  setEditDisplay(false);
                  setPreviousDisplayStatus(isDisplay);
                  return true;
                } else {
                    await Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: response.message || 'Failed to update program display status'
                    });
                    setValue('isDisplay',displayStatus);
                    setEditDisplay(false);
                    return false;
                }
            } catch (error) {
                await Swal.fire({
                  title: 'Update Failed',
                  text: error.message || 'Failed to update program display status',
                  icon: 'error'
                });
                setValue('isDisplay',displayStatus);
                setEditDisplay(false);
                return false;
            }
        }else{
            setValue('isDisplay',displayStatus);
            setEditDisplay(false);
        }
      };
    const isDisplay = useWatch({
            control,
            name: 'isDisplay',
        }); 
    useEffect(() => {
        if(isDisplay != previousDisplayStatus){
            handleStatusChange()
        }
    }, [isDisplay]);
  return (
    <>
    <Grid item sx={{pt:'1%!important'}} xs={isMobile?12:4}>
        <Typography sx={{color:'#3e3e3e',fontWeight:'500',fontSize:'0.75rem'}}>Display</Typography>
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Controller
                name='isDisplay'
                control={control}
                render={({ field }) => (
                    <FormControl fullWidth>
                        <Select
                            {...field}
                            size='small'
                            // value={displayStatus}
                            disabled={!editDisplay}
                            sx={{
                                // minHeight: '0px',
                                // height: '1.6rem',
                                fontSize: '0.75rem',
                                '& .MuiSelect-select': {
                                // padding: '0 8px',
                                display: 'flex',
                                alignItems: 'center',
                                },
                            }}
                        >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                        </Select>
                    </FormControl>
                )}
            />
            <PermissionGuard feature='reward-program' permission='canEdit'>                            
                <IconButton size='small' color={editDisplay?'error':'warning'} onClick={()=>setEditDisplay(!editDisplay)}>
                    {editDisplay ?<Cancel/>:<Edit/>}
                </IconButton> 
            </PermissionGuard>
        </Box>
    </Grid>
    </>
  );
}

export default DisplayIndicator;

import {Avatar,Badge, Box, Button, Card,IconButton, Modal, Stack,Switch, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddFeature from './AddFeature';
import { isMobile } from 'react-device-detect';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { RoleAndPermissionServices } from '../RoleAndPermissionServices';
import Swal from 'sweetalert2';

import DeleteIcon from '@mui/icons-material/Delete';
import { PermissionGuard } from '../../../components/PermissionGuard';
import dayjs from 'dayjs';
import PageHeaderLayout from '../../../components/Layout/PageHeaderLayout';

const style = {
    position: 'absolute',
    top: (isMobile) ? '80%' : '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #ddd',
    boxShadow: 24,
    // p: 2,
};


const FeatureList = () => {
    const [isLoading,setIsLoading] = useState(false);
    const [openModel,setOpenModel] = useState(false);
    const handleModalClose = ()=>{
        setOpenModel(false);
    }

    /************************ add/Edit Modal Manage **************************** */
    const modalOpen = () => {    
        setOpenModel(true)
    
    }
    /**=========handles to close open add/edit modal=================== */
    const handleClose=()=>{
        setOpenModel(false);
        fetchFeatureList();
    }
    const[featureDataList,setFeatureDataList] = useState([]);
    const columns = [
        {
          field: 'title',
          headerName: 'Name',
          headerAlign:'center',
          flex: 2,
          renderCell: (params) => (
            <Box>
                <Typography sx={{textTransform:'capitalize',fontWeight:'600',fontSize:'0.75rem'}}>{params.row.title}</Typography>     
            </Box>
          ),
        },
        {
            field: 'createdAt',
            headerName: 'Created Date',
            headerAlign:'center',
            flex: 2,
            renderCell: (params) => (
              <Typography sx={{fontSize:'0.75rem'}}>
                  {dayjs(params.row.createdAt).format('DD MMM YYYY, h:mm a')}    
              </Typography>
            ),
          },
        { 
          field: 'isActive', 
          headerName: 'Status', 
          headerAlign:'center',
          flex: 1,
          // filterable: false,
          renderCell: (params) => {
            const handleStatusChange = async () => {
                const result = await Swal.fire({
                  text: "Do you want to Change Status now?",
                  icon: 'question',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes, Change now!',
                  cancelButtonText: 'No, Cancel',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  showCloseButton: false,
                });
              
                if (result.isConfirmed) {
                  try {
                    // Show loading state
                    Swal.fire({
                      title: 'Processing...',
                      text: 'Please wait while we update the status',
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                      showConfirmButton: false,
                      didOpen: () => {
                        Swal.showLoading();
                      }
                    });
              
                    // Make the API call
                    const statusChangeResponse = await RoleAndPermissionServices.changeFeatureStatus(params.row.id);
                    // console.log("feature status change response:", statusChangeResponse);
              
                    // Close loading dialog and show success/error message
                    if (statusChangeResponse.success === 1) {
                      await fetchFeatureList();
                      await Swal.fire({
                        icon: 'success',
                        title: 'Success!',
                        text: statusChangeResponse.message || 'Status updated successfully',
                        timer: 1500
                      });
                      
                      return true;
                    } else {
                      await Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: statusChangeResponse.message || 'Failed to update status'
                      });
                      return false;
                    }
              
                  } catch (error) {
                    // console.error("Error changing role status:", error);
                    await Swal.fire({
                      icon: 'error',
                      title: 'Error!',
                      text: 'An error occurred while updating the status'
                    });
                    return false;
                  }
                } else {
                  return false;
                }
            };
            return(
              <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
                <Switch
                  size='small'
                  checked={params.row.isActive}
                  onChange={handleStatusChange}
                  sx={{
                    '& .MuiSwitch-switchBase.Mui-checked': {
                        color: 'success.main',
                    },
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: 'success.main'
                    },
                    '& .MuiSwitch-switchBase': {
                        color: 'error.main',
                    },
                    '& .MuiSwitch-track': {
                        backgroundColor: 'error.main'
                    }
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    color: params.row?.isActive ? 'success.main' : 'error.main',
                    fontWeight: 500,
                    fontSize: '0.75rem',
                  }}
                >
                  {params.row?.isActive ? 'Active' : 'Inactive'}
                </Typography> 
            </Box>
          )},
        },
        {
        field: 'actions',
        headerName: 'Actions',
        headerAlign: 'center',
        flex: 1,
        filterable: false,
        renderCell: (params) => {
            const  handleDeleteFeature= async () => {
                const result = await Swal.fire({
                  text: "Do you want to Delete Feature now?",
                  icon: 'question',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes, Delete now!',
                  cancelButtonText: 'No, Cancel',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  showCloseButton: false,
                });
              
                if (result.isConfirmed) {
                  try {
                    // Show loading state
                    Swal.fire({
                      title: 'Processing...',
                      text: 'Please wait while we Deleting Feature',
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                      showConfirmButton: false,
                      didOpen: () => {
                        Swal.showLoading();
                      }
                    });
              
                    // Make the API call
                    const featureDeleteResponse = await RoleAndPermissionServices.deleteFeature(params.row.id);
                    // console.log("feature delete response:", featureDeleteResponse);
              
                    // Close loading dialog and show success/error message
                    if (featureDeleteResponse.success === 1) {
                      await fetchFeatureList();
                      await Swal.fire({
                        icon: 'success',
                        title: 'Success!',
                        text: featureDeleteResponse.message || 'feature deleted successfully',
                        timer: 1500
                      });
                      
                      return true;
                    } else {
                      await Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: featureDeleteResponse.message || 'Failed to delete feature'
                      });
                      return false;
                    }
              
                  } catch (error) {
                    // console.error("Error deleting feature:", error);
                    await Swal.fire({
                      icon: 'error',
                      title: 'Error!',
                      text: 'An error occurred while deleting the feature'
                    });
                    return false;
                  }
                } else {
                  return false;
                }
              };
            return(
            <Box sx={{ p: 1, display: 'flex', justifyContent: 'space-between' }}>
                <PermissionGuard feature='register-admin' permission='canDelete'>
                    <IconButton size="small" color='error'>
                        <DeleteIcon onClick={handleDeleteFeature} sx={{fontSize:'0.95rem'}}/>
                    </IconButton>
                </PermissionGuard>
            </Box>
        )},
        },
    ];
    const fetchFeatureList = async ()=>{
        setIsLoading(true)
        try {
            const listResponse = await RoleAndPermissionServices.featureList();
            if (listResponse.success == '1') {
                setFeatureDataList(listResponse.data);
            }
        } catch (error) {
            Swal.fire('Internal Server Error...','','error');
            // console.log(error.message);
        }finally{
            setIsLoading(false)
        }
    }
    useEffect(()=>{
        fetchFeatureList();
    },[])
  return ( 
    <Box>
        <Modal
            open={openModel}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ overflow: "scroll" }}
        >
            <Box 
                sx={{
                    ...style,
                    width: isMobile ? "95%" : "50%",
                    maxHeight: "90vh", // Max height for the modal
                    overflowY: "auto", // Enable scrolling within the modal
                    // display: "flex",
                    // flexDirection: "column",
                }}
            >
                <AddFeature onClose={handleClose} />
            </Box>
        </Modal>
        {/* ===========page title================= */}
        <PageHeaderLayout headerTitle={'Manage Features'}>
          <Button variant='contained' onClick={()=>setOpenModel(true)}  sx={{ height: "30px", marginTop: "10px" }} startIcon={<AddCircleIcon />}>
            Add Feature
          </Button>
        </PageHeaderLayout>
        {/* <Card sx={{ minWidth: "100%", marginBottom: "10px" }}>
            <Stack direction="row" spacing={2} className="" alignItems={'center'} sx={{p:'2%'}}>
                <Typography
                    gutterBottom
                    variant='h5'
                    component='div'
                    // sx={{ padding: '20px' }}
                >
                    Manage Features
                </Typography>
                <Typography
                    variant='h3'
                    component='div'
                    sx={{ flexGrow: 1 }}
                ></Typography>
                <Button variant='contained' onClick={()=>setOpenModel(true)}  sx={{ height: "30px", marginTop: "10px" }} startIcon={<AddCircleIcon />}>
                    Add Feature
                </Button>
            </Stack>
        </Card> */}
        <Box sx={{ overflow: "auto" }}>
            <Box sx={{ width: "100%", display: "table", tableLayout: "fixed", backgroundColor: "#fff", borderRadius: '.5rem' }}>
                <DataGrid
                    
                    rows={featureDataList}
                    columns={columns}
                    getRowId={(row) => row.id}
                    //checkboxSelection={true}
                    // rowCount={allRowCount}  // Use the length of the filtered list
                    pageSizeOptions={[5, 10, 20, 50, 100]}
                    initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 20,
                          },
                        },
                      }}
                    // paginationModel={controller}
                    // paginationMode="server"
                    // onPaginationModelChange={handlePaginationChange}

                    loading={isLoading}

                    disableColumnMenu
                    disableRowSelectionOnClick
                    slots={{
                        // toolbar: GridToolbar,
                    }}
                    autoHeight
                    getRowHeight={() => 'auto'}
                    sx={{
                        '& .MuiDataGrid-cell': {
                        border: 1,
                        borderRight: 0,
                        borderTop: 0,
                        borderColor: 'lightgrey',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        },
                        '& .MuiDataGrid-columnHeaders': {
                          borderBottom: '1px solid rgba(224, 224, 224, 1)',
                          // borderTop: '2px solid rgba(224, 224, 224, 1)',
                          fontWeight: 600,
                          fontSize: '0.75rem',
                          p:0,
                          height: 'fit-content',
                          minHeight:'0 !important',
                          height:'2rem'
                        },
                        '& .MuiDataGrid-columnHeader': {
                        borderLeft: '1px solid rgba(224, 224, 224, 1)',
                        '&:last-child': {
                            borderRight: 'none',
                        },
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 600,
                        color: '#000000'
                        },
                        '& .MuiDataGrid-row': {
                        '&:last-child .MuiDataGrid-cell': {
                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                        },
                        '& .MuiDataGrid-cell:last-child': {
                            borderRight: 'none',
                        },
                        },
                        '& .MuiDataGrid-columnSeparator': {
                        display: 'none',
                        },
                    }}
                />
            </Box>
        </Box>
    </Box>
  );
}

export default FeatureList;

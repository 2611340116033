import React, { useEffect, useState } from 'react';
import { useForm, Controller ,useFormContext} from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
  Box,
  Chip,
  Stack,
  Divider,
  Autocomplete,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Card,FormControlLabel,Checkbox,CardContent,
  ListItemText
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { CalendarIcon, DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { BookingService } from '../../Booking/Service';


const formatValue = (value) => {
  if (!value) return '';  // Return empty string instead of 0
  const numValue = parseFloat(value);
  return isNaN(numValue) ? '' : numValue.toString();
}

const RegistrationFeeInfo = () => {
    const { control, handleSubmit, setValue, formState: { errors },watch } = useFormContext();
    const taxSlab = watch('taxSlab');
    const totalAmount = watch('totalAmount');
    const discount = watch('discountPercent');
    const calculate = () => {
        //initialize 
        let sgst = 0;
        let cgst = 0;
        let igst = 0;
        let discountRate = discount || 0;
        let discountAmount = 0;
        let baseAmount = 0;
        let paidAmount = totalAmount || 0;
        let taxableAmount = 0;
        let gstAmount = 0;

        // Remove GST from total amount
        taxableAmount = totalAmount / (1 + 18/100);
        // Calculate GST amount
        gstAmount = totalAmount - taxableAmount;

        // Calculate base amount before discount
        baseAmount = taxableAmount / (1 - discountRate/100);
        // Calculate discount amount
        discountAmount = baseAmount - taxableAmount;
        
        if(taxSlab === 'igst'){
            igst = gstAmount      
        }else if(taxSlab === 'gst'){
            sgst = cgst = gstAmount / 2;
        }
        setValue('sgst',sgst,{shouldValidate:true});
        setValue('cgst',cgst,{shouldValidate:true});
        setValue('igst',igst,{shouldValidate:true});
        setValue('baseAmount',baseAmount,{shouldValidate:true});
        setValue('taxableAmount',taxableAmount,{shouldValidate:true});
        setValue('gstAmount',gstAmount,{shouldValidate:true});
        setValue('discount',discountAmount,{shouldValidate:true});
    }
    useEffect(() => {
        if(totalAmount){
            calculate();
        }
    }, [totalAmount,taxSlab,discount]);
  return (
    <Grid item xs={12} lg={7}>
      <Card sx={{}}>
        <Box sx={{p:'.5% 2%',border:'2px solid lightGray',bgcolor:'lightGray'}}>
            <Typography sx={{fontWeight:'600',fontSize:'.85rem'}}>Registration Fee Info</Typography>
        </Box>
        <Grid container spacing={1} sx={{p:'1%'}}>
            <Grid item xs={3} sx={{}}>
                <Typography sx={{fontWeight:'600', display:'flex ',alignItems:'center',fontSize:'.75rem'}}>Payment Category</Typography>
                <Controller
                    name='paymentType'
                    control={control}
                    // rules={{
                    //   required:'enter Paid Amount',
                    //   validate: (value) => {
                    //       const numValue = parseInt(value, 10);                  
                    //       if (isNaN(numValue)) return 'Please enter a valid Amount';
                    //       if (numValue <= 0) return 'Paid Amount should be greater than 0';
                    //       // if (numValue > 9999) return 'Waive Visit Charge cannot exceed Rs. 9999';
                    //       return true;                    
                    //   }
                    // }}
                    defaultValue={[]}
                    render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        disabled
                        size='small'
                        // error={!!error}
                        type='number'
                        placeholder='Registration Fee'

                        // Add these props to prevent increment/decrement
                        inputProps={{
                        style: { fontSize: '0.65rem' },
                        onWheel: (e) => e.target.blur(),  // Prevent scroll wheel
                        onKeyDown: (e) => {
                            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                e.preventDefault();
                            }
                        }
                        }}
                        // onChange={(e) => {
                        //   const inputValue = e.target.value;
                        //   if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
                        //     // field.onChange(formatValue(inputValue));
                        //     let finalValue = formatValue(inputValue);
                        
                        //     // Check if the value exceeds 10000 and constrain it
                        //     const numValue = parseFloat(finalValue);
                        //     // if (!isNaN(numValue) && numValue > 9999) {
                        //     //     finalValue = '9999';
                        //     // }
                            
                        //     field.onChange(finalValue);
                        //   }
                        // }}
                    />
                    )}
                /> 
            </Grid>
            <Grid item xs={3} sx={{}}>
                <Typography sx={{fontWeight:'600', display:'flex ',alignItems:'center',fontSize:'.75rem'}}>Transaction Type</Typography>
                {/* <Controller
                    name='discount'
                    control={control}
                    rules={{
                    required:'enter Paid Amount',
                    validate: (value) => {
                        const numValue = parseInt(value, 10);                  
                        if (isNaN(numValue)) return 'Please enter a valid Amount';
                        if (numValue <= 0) return 'Paid Amount should be greater than 0';
                        // if (numValue > 9999) return 'Waive Visit Charge cannot exceed Rs. 9999';
                        return true;                    
                    }
                    }}
                    defaultValue={[]}
                    render={({ field, fieldState: { error } }) => ( */}
                    <TextField
                        // {...field}
                        disabled
                        size='small'
                        // error={!!error}
                        type='number'
                        placeholder='Credit'

                        // Add these props to prevent increment/decrement
                        inputProps={{
                        style: { fontSize: '0.65rem' },
                        onWheel: (e) => e.target.blur(),  // Prevent scroll wheel
                        onKeyDown: (e) => {
                            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                e.preventDefault();
                            }
                        }
                        }}
                        // onChange={(e) => {
                        //   const inputValue = e.target.value;
                        //   if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
                        //     // field.onChange(formatValue(inputValue));
                        //     let finalValue = formatValue(inputValue);
                        
                        //     // Check if the value exceeds 10000 and constrain it
                        //     const numValue = parseFloat(finalValue);
                        //     // if (!isNaN(numValue) && numValue > 9999) {
                        //     //     finalValue = '9999';
                        //     // }
                            
                        //     field.onChange(finalValue);
                        //   }
                        // }}
                    />
                    {/* )}
                /> */}
            </Grid>
            <Grid item xs={2} sx={{}}></Grid>
            <Grid item xs={4} sx={{display:'flex',alignItems:'center',gap:2}}>
                <Typography sx={{fontWeight:'600', display:'flex ',alignItems:'center',fontSize:'.75rem'}}>Payment Status</Typography>
                <Chip
                    label={watch('status') || 'PENDING'}
                    size='small'
                    color={watch('status') === 'COMPLETED' ? 'success' : 'warning'}
                    sx={{fontSize:'.75rem'}}
                />
            </Grid>
            <Grid item xs={3} sx={{}}>
                <Typography sx={{fontWeight:'600', display:'flex ',alignItems:'center',fontSize:'.75rem'}}>Payment Method <span style={{color:'red'}}>*</span></Typography>
                <Controller
                    name='paymentMethod'
                    control={control}
                    rules={{required:'select gender'}}
                    render={({field,fieldState:{error}})=>(
                      <FormControl fullWidth>
                            <Select
                                {...field}
                                size='small'
                                sx={{
                                    minHeight: '0px',
                                    // height: '1.6rem',
                                    fontSize: '0.75rem',
                                    // fontWeight: '600',
                                    textTransform:'capitalize'
                                }}
                                error={!!error}
                                // helperText={error && error?.message}
                            >
                                <MenuItem sx={{fontSize:'0.75rem'}} value='CASH'>CASH</MenuItem>
                                <MenuItem sx={{fontSize:'0.75rem'}} value='CARD'>CARD</MenuItem>
                                <MenuItem sx={{fontSize:'0.75rem'}} value='WALLET'>WALLET</MenuItem>
                                <MenuItem sx={{fontSize:'0.75rem'}} value='ONLINE'>ONLINE</MenuItem>
                                <MenuItem sx={{fontSize:'0.75rem'}} value='NEFT'>NEFT</MenuItem>
                                <MenuItem sx={{fontSize:'0.75rem'}} value='UPI'>UPI</MenuItem>
                            </Select>
                      </FormControl>
                    )}  
                />                  
            </Grid>
            <Grid item xs={4} sx={{}}>
                <Typography sx={{width:'60%' ,fontWeight:'600', display:'flex ',alignItems:'center',fontSize:'.75rem'}}>Transaction Date<span style={{color:'red'}}>*</span></Typography>
                <Controller
                    name="txnDate"
                    control={control}
                    // defaultValue={dayjs().subtract(18, 'year').format('YYYY-MM-DD')}
                    rules={{
                    required: "Transaction Date is required",
                    validate: (value) => {
                        if (!value) return "Transaction Date is required";
                        const date = dayjs(value);
                        const today = dayjs().startOf('day');
                        
                        if (!date.isValid()) return "Invalid Transaction Date";
                        if (date.isAfter(today)) return "Transaction Date must be today or before";                     
                        return true;
                    }
                    }}
                    render={({ field: { onChange, value, ...restField }, fieldState: { error } }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker                      
                        {...restField}
                        sx={{
                            '& . iconButton': {
                            padding: 0.5,
                            fontSize: '1rem'
                            }
                        }}
                        value={value ? dayjs(value) : null}
                        onChange={(newValue) => {
                            // Store the date in YYYY-MM-DD format for database compatibility
                            onChange(newValue ? newValue.format("YYYY-MM-DD") : null);
                        }}
                        format="DD-MM-YYYY" // Display format for user
                        maxDate={dayjs().startOf('day')}
                        slots={{
                            openPickerIcon: (props) => (
                            <CalendarIcon 
                                {...props} 
                                sx={{ 
                                fontSize: '1rem', // Control icon size
                                color: 'gray',    // Optional: control icon color
                                padding:0
                                }} 
                            />
                            ),
                        }}
                        slotProps={{
                            textField: {
                            error: !!error,
                            // helperText: error?.message,
                            fullWidth: true,
                            // required: true,
                            size: 'small',
                            inputProps: {
                                style: { fontSize: '.75rem' }
                            }
                            }
                        }}
                        />
                    </LocalizationProvider>
                    )}
                />
            </Grid>
            <Grid item xs={5} sx={{}}>
              <Typography sx={{fontWeight:'600', display:'flex ',alignItems:'center',fontSize:'.75rem'}}>Transaction Id<span style={{color:'red'}}>*</span></Typography>
              <Controller
                name='txnId'
                control={control}
                rules={{
                    required: "required",
                    pattern:{
                        value:/^[a-z0-9]*$/,
                        message:"only alphanumeric allowed"
                    }
                }}
                render={({field,fieldState:{error}})=>(
                  <TextField
                    {...field}
                    sx={{}}
                    fullWidth
                    size='small'
                    InputProps={{
                        style:{
                          // height:'2rem',
                          fontSize:'.75rem'
                        },
                        // inputProps:{
                        //     style:{textTransform:"uppercase"},
                        // }   
                    }}
                    error={!!error}
                    // helperText={error && error?.message}
                  /> 
                )}  
              />                    
            </Grid>
            <Grid item xs={3} sx={{}}>
                <Typography sx={{fontWeight:'600', display:'flex ',alignItems:'center',fontSize:'.75rem'}}>Tax Category <span style={{color:'red'}}>*</span></Typography>
                <Controller
                    name='taxSlab'
                    control={control}
                    rules={{required:'select Tax Category'}}
                    render={({field,fieldState:{error}})=>(
                      <FormControl fullWidth>
                            <Select
                                {...field}
                                size='small'
                                sx={{
                                    minHeight: '0px',
                                    // height: '1.6rem',
                                    fontSize: '0.75rem',
                                    // fontWeight: '600',
                                    textTransform:'capitalize'
                                }}
                                error={!!error}
                                // helperText={error && error?.message}
                            >
                                <MenuItem sx={{fontSize:'0.75rem'}} value='igst'>IGST_18%</MenuItem>
                                <MenuItem sx={{fontSize:'0.75rem'}} value='gst'>GST_18%</MenuItem>
                            </Select>
                      </FormControl>
                    )}  
                />                  
            </Grid>
            <Grid item xs={3} sx={{}}>
              <Typography sx={{fontWeight:'600', display:'flex ',alignItems:'center',fontSize:'.75rem'}}>SGST<span style={{color:'red'}}>*</span></Typography>
              <Controller
                name='sgst'
                control={control}
                render={({field,fieldState:{error}})=>(
                  <TextField
                    {...field}
                    sx={{}}
                    value={parseFloat(field.value).toFixed(2)}
                    disabled
                    fullWidth
                    size='small'
                    InputProps={{
                        style:{
                          fontSize:'.75rem'
                        },
                    }}
                    error={!!error}
                  /> 
                )}  
              />                    
            </Grid>
            <Grid item xs={3} sx={{}}>
              <Typography sx={{fontWeight:'600', display:'flex ',alignItems:'center',fontSize:'.75rem'}}>CGST<span style={{color:'red'}}>*</span></Typography>
              <Controller
                name='cgst'
                control={control}
                render={({field,fieldState:{error}})=>(
                  <TextField
                    {...field}
                    sx={{}}
                    value={parseFloat(field.value).toFixed(2)}
                    disabled
                    fullWidth
                    size='small'
                    InputProps={{
                        style:{
                          fontSize:'.75rem'
                        },
                    }}
                  /> 
                )}  
              />                    
            </Grid>
            <Grid item xs={3} sx={{}}>
              <Typography sx={{fontWeight:'600', display:'flex ',alignItems:'center',fontSize:'.75rem'}}>IGST<span style={{color:'red'}}>*</span></Typography>
              <Controller
                name='igst'
                control={control}
                render={({field,fieldState:{error}})=>(
                  <TextField
                    {...field}
                    sx={{}}
                    value={parseFloat(field.value).toFixed(2)}
                    disabled
                    fullWidth
                    size='small'
                    InputProps={{
                        style:{
                          fontSize:'.75rem'
                        },   
                    }}
                  /> 
                )}  
              />                    
            </Grid>
            <Grid item xs={3} sx={{}}>
              <Typography sx={{fontWeight:'600', display:'flex ',alignItems:'center',fontSize:'.75rem'}}>{`Discount(%)`}</Typography>
              <Controller
                name='discountPercent'
                control={control}
                // rules={{
                //   required:'enter Paid Amount',
                //   validate: (value) => {
                //     const numValue = parseInt(value, 10);                  
                //     if (isNaN(numValue)) return 'Please enter a valid Amount';
                //     if (numValue <= 0) return 'Paid Amount should be greater than 0';
                //     // if (numValue > 9999) return 'Waive Visit Charge cannot exceed Rs. 9999';
                //     return true;                    
                //   }
                // }}
                defaultValue={[]}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    disabled
                    size='small'
                    error={!!error}
                    type='number'
                    placeholder='0'

                    // Add these props to prevent increment/decrement
                    inputProps={{
                      style: { fontSize: '0.65rem' },
                      onWheel: (e) => e.target.blur(),  // Prevent scroll wheel
                      onKeyDown: (e) => {
                          if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                              e.preventDefault();
                          }
                      }
                    }}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
                        // field.onChange(formatValue(inputValue));
                        let finalValue = formatValue(inputValue);
                    
                        // Check if the value exceeds 10000 and constrain it
                        const numValue = parseFloat(finalValue);
                        // if (!isNaN(numValue) && numValue > 9999) {
                        //     finalValue = '9999';
                        // }
                        
                        field.onChange(finalValue);
                      }
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3} sx={{}}>
              <Typography sx={{fontWeight:'600', display:'flex ',alignItems:'center',fontSize:'.75rem'}}>Amount Paid <span style={{color:'red'}}>*</span></Typography>
              <Controller
                name='totalAmount'
                control={control}
                rules={{
                  required:'enter Paid Amount',
                  validate: (value) => {
                    const numValue = parseInt(value, 10);                  
                    if (isNaN(numValue)) return 'Please enter a valid Amount';
                    if (numValue <= 0) return 'Paid Amount should be greater than 0';
                    // if (numValue > 9999) return 'Waive Visit Charge cannot exceed Rs. 9999';
                    return true;                    
                  }
                }}
                defaultValue={[]}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    size='small'
                    error={!!error}
                    type='number'
                    placeholder='0'

                    // Add these props to prevent increment/decrement
                    inputProps={{
                      style: { fontSize: '0.65rem' },
                      onWheel: (e) => e.target.blur(),  // Prevent scroll wheel
                      onKeyDown: (e) => {
                          if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                              e.preventDefault();
                          }
                      }
                    }}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
                        // field.onChange(formatValue(inputValue));
                        let finalValue = formatValue(inputValue);
                    
                        // Check if the value exceeds 10000 and constrain it
                        const numValue = parseFloat(finalValue);
                        // if (!isNaN(numValue) && numValue > 9999) {
                        //     finalValue = '9999';
                        // }
                        
                        field.onChange(finalValue);
                      }
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} sx={{}}>
              <Typography sx={{fontWeight:'600', display:'flex ',alignItems:'center',fontSize:'.75rem'}}>Comment<span style={{color:'red'}}>*</span></Typography>
              <Controller
                control={control}
                name="comment"
                rules={{
                    required:'Enter comment',
                }}
                render={({field,fieldState:{error}})=>(
                    <TextField
                        {...field}
                        // label="Enter Service Description"
                        variant="outlined"
                        size="small"
                        inputProps={{style:{fontSize:'0.65rem'}}}
                        fullWidth 
                        error={!!error}
                        // multiline
                        // rows={1}
                        // maxRows={4}
                    />
                )}
              />
            </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}

export default RegistrationFeeInfo;

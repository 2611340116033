import { Autocomplete,Box,Checkbox, CircularProgress, FormControl, Grid,IconButton, MenuItem,Paper, Select,TableContainer,Table,TableHead,TableRow,TableCell,TableBody,TextField, Typography, Button, Divider, Card } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DeleteIcon from '@mui/icons-material/Delete';
import { BookingDetailContext } from '../AddBooking';
import { StepperContext } from '../BookingForm';
import { BookingService } from '../Service';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Add, Remove } from '@mui/icons-material';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const chargeList = {
  couponId: '0',
  couponAmount: '0.00',
  discount: '0.00',
  platformCharges: '0.00',
}

const ServicesAndExpert = () => {
  const {bookingData,setBookingData,chargeDetails, setChargeDetails} = useContext(BookingDetailContext);
  const {activeStep, setActiveStep,completed, setCompleted,handleNext,handleBack,handleComplete,handleReset,setPreviousServices} = useContext(StepperContext);
  const { control, handleSubmit, setValue, formState: { errors ,isDirty},watch,reset } = useForm({
    mode: "onChange",
    defaultValues:{
      categoryId:'',
      subCategoryId:'',
      serviceExpert:'',
      services:[],
    }
  });

  useEffect(() => {
    // console.log("Current bookingData:", bookingData);
    // console.log("Current form values before reset:", watch());
    
    reset({
      categoryId: bookingData?.categoryId || '',
      subCategoryId: bookingData?.subCategoryId || '',
      serviceExpert: bookingData?.serviceExpert || '',
      services: bookingData?.services || [],
    });
    
    // console.log("After reset - form values:", watch());
  }, [bookingData, reset]);
  
  const [loadingCategoryList,setLoadingCategoryList] = useState(false);
  const [categoryList,setCategoryList] = useState([]);
  const [loadingSubCategoryList,setLoadingSubCategoryList] = useState(false);
  const [subCategoryList,setSubCategoryList] = useState([]);
  const [loadingServiceList,setLoadingServiceList] = useState(false);
  const [serviceList,setServiceList] = useState([]);
  const [loadingServiceExpertList,setLoadingServiceExpertList] = useState(false);
  const [serviceExpertList,setServiceExpertList]=useState([]);  
  const [selectedServices, setSelectedServices] = useState([]);

  const customer= watch('customer');
  const categoryId= watch('categoryId');
  const subCategoryId=watch('subCategoryId');
  const serviceArea = watch('serviceArea');
  const serviceExpert = watch('serviceExpert');
  const services = watch('services');

  const handleQuantityChange = (id, quantity) => {
    const items = services.map(service => 
      service.id === id ? { ...service, quantity: parseInt(quantity) || 0,serviceTime:(service.serviceTime/service.quantity)* parseInt(quantity) || 0} : service
    );
    setValue('services',items,{
      shouldDirty: true,
      shouldTouch: true
    });
  };
  const fetchCategoryList= async()=>{
    setLoadingCategoryList(true);
    try {
      const categoryListResponse = await BookingService.categoryListByCoordinates({latitude:bookingData?.latitude,longitude:bookingData?.longitude});
      // console.log("category list Response:",categoryListResponse);
      if(categoryListResponse.success===1){
        setCategoryList(categoryListResponse.data)
      }
    } catch (error) {
      
    }finally{
      setLoadingCategoryList(false);
    }
  }
  useEffect(()=>{  
    // Update previous services
    setPreviousServices(bookingData.services);
    fetchCategoryList();
  },[])

  // whenever categoryid changes reset all the dependent fields like subcategory,serviceexpert,services and fech subcategory list
  useEffect(() => {
    if (categoryId) {
      if (categoryId!==bookingData.categoryId) {
        reset({
          categoryId: categoryId,
          subCategoryId: '',
          serviceExpert: '',
          services: [],
        });
      }
      const fetchSubCategoryList = async () => {
        setLoadingSubCategoryList(true);

        try {
          const subCategoryListResponse = await BookingService.subCategoryListByCoordinatesAndCategoryId({
            latitude: bookingData?.latitude,
            longitude: bookingData?.longitude,
            categoryId,
          });
          // console.log("subCategoryListResponse:",subCategoryListResponse);
          if (subCategoryListResponse.success === 1) {
            setSubCategoryList(subCategoryListResponse.data);
          }
        } catch (error) {
          // console.error("Failed to fetch sub-category list:", error);
        } finally {
          setLoadingSubCategoryList(false);
        }
      };
      fetchSubCategoryList();
    }
  }, [categoryId]);
  
  // whenever subcategory changes reset all the dependent fields like serviceexpert,services and fech service list and fetch service expert list
  useEffect(() => {
    if (subCategoryId) {
      if (subCategoryId!==bookingData.subCategoryId) {
        reset({
          categoryId: categoryId,
          subCategoryId: subCategoryId,
          serviceExpert: '',
          services: [],
        });
      }
      const fetchServiceList = async () => {
        setLoadingServiceExpertList(true);

        try {
          const providerListResponse = await BookingService.providerListByCoordinatesAndSubCategoryId({
            latitude: bookingData?.latitude,
            longitude: bookingData?.longitude,
            subCategoryId,
          });
          console.log("providerListResponse:",providerListResponse);
          if (providerListResponse.success === 1) {
            setServiceExpertList(providerListResponse.data);
          }
        } catch (error) {
          // console.error("Failed to fetch service list:", error);
        } finally {
          setLoadingServiceExpertList(false);
        }
      };
      fetchServiceList();
    }
  }, [subCategoryId]);

  // whenever serviceExpert changes reset all the dependent fields like services and fetch service list
  useEffect(() => {
    if (serviceExpert && subCategoryId) {
      if (serviceExpert!==bookingData.serviceExpert) {
        reset({
          categoryId: categoryId,
          subCategoryId: subCategoryId,
          serviceExpert: serviceExpert,
          services: [],
        });
      }
      const fetchServiceList = async () => {
        setLoadingServiceList(true);

        try {
          const serviceListResponse = await BookingService.serviceList({subCategoryId:subCategoryId,providerId:serviceExpert});
          // console.log("serviceListResponse:",serviceListResponse);
          if (serviceListResponse.success === 1) {
            setServiceList(serviceListResponse.data);
          }
        } catch (error) {
          // console.error("Failed to fetch service list:", error);
        } finally {
          setLoadingServiceList(false);
        }
      };
      fetchServiceList();
    }
  }, [serviceExpert]);

  const fetchChargeDetails = async () => {
    const servicesids = services.map(service => service.id);
    console.log("servicesids:", servicesids);
    try {
      const chargeDetailsResponse = await BookingService.chargeDetails({
        "providerId": serviceExpert,
        "providerServiceIds": servicesids
        
      });
      console.log("chargeDetailsResponse:", chargeDetailsResponse);
      if (chargeDetailsResponse.success === 1) {
        setChargeDetails({
          additionalCharge: chargeDetailsResponse.data?.charges?.additionalCharge,
          vistingCharge: chargeDetailsResponse.data?.charges?.vistingCharge,
          waiveVisitCharge: chargeDetailsResponse.data?.waiveVisitCharge,
        });
      }
    } catch (error) {
      console.error("Failed to fetch charge details:", error);
    }
  }
  useEffect(() => {
    if (services.length > 0) {
      console.log("Selected services:", services);
      // fetch charge details
     fetchChargeDetails();
    }
  }, [services]);

  const handleDeleteService = (id) => {
    const updatedServices = services.filter(service => service.id !== id);
    // setSelectedServices(updatedServices);
    setValue('services', updatedServices); // Update the form value
  };
  const completeStep2=(data)=>{
    // console.log(" submitted Services and Expert:",data);
    const serviceExpertProfile = serviceExpertList.find(expert => expert.userId === data.serviceExpert);
    setBookingData(pre=>{
      return {...pre,...data,serviceExpertProfile:serviceExpertProfile}
    })
    handleComplete();
    handleNext();
    handleComplete();
  }
  return (
    <Box component={'form'} onSubmit={handleSubmit(completeStep2)} sx={{pb:'1%'}}>
      <Grid container spacing={2} >
        <Grid item lg={9} xs={12} sx={{margin:'auto'}}>

          <Grid container spacing={2} >
            {/* ............left section................... */}
            <Grid item xs={12} lg={5}>
              <Grid container>
                <Grid item xs={12} sx={{my:'1%'}}>
                  <Grid container sx={{mb:'1%'}}>
                    <Grid item lg={7} xs={12}>
                      <Typography sx={{fontWeight:'600'}}>Select Category<span style={{color:'red'}}>*</span></Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={loadingCategoryList?'10':'12'}>
                      <Controller
                        name='categoryId'
                        control={control}
                        rules={{required:'select category'}}
                        render={({field,fieldState:{error}})=>(
                          <FormControl size='small' sx={{ minWidth: "100%" }}>
                            {/* <InputLabel id="">Select Gender</InputLabel> */}
                              <Select
                                  {...field}
                                  // disabled={!customer}
                                  // label="Select Gender"
                                  // sx={{height:'2rem'}}
                                  error={!!error}
                                  // helperText={error && error?.message}
                              >
                                { categoryList &&
                                  categoryList.map((category,index)=>(
                                    <MenuItem value={category.catId} key={index}>{category.catTitle}</MenuItem>
                                  ))

                                }
                              </Select>
                          </FormControl>
                        )}  
                      />
                    </Grid>
                    {loadingCategoryList && <Grid item xs={2}>
                        <CircularProgress/>
                    </Grid>}
                  </Grid>
                </Grid>
                
              </Grid>
            </Grid>
            {/* ............central gap................... */}
            <Grid item xs={'none'} lg={2}/>
            {/* ............right section................... */}
            <Grid item xs={12} lg={5}>
              <Grid container>
                <Grid item xs={12} sx={{my:'1%'}}>
                  <Grid container sx={{mb:'1%'}}>
                    <Grid item lg={7} xs={12}>
                      <Typography sx={{fontWeight:'600'}}>Select Sub-Category<span style={{color:'red'}}>*</span></Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={loadingSubCategoryList?'10':'12'}>
                      <Controller
                        name='subCategoryId'
                        control={control}
                        rules={{required:'select sub-category'}}
                        render={({field,fieldState:{error}})=>(
                          <FormControl size='small' sx={{ minWidth: "100%" }}>
                          <Select
                            {...field}
                            disabled={!categoryId || loadingSubCategoryList}
                            error={!!error}
                          >
                            {subCategoryList?.map((subCategory, scIndex) => (
                                <MenuItem value={subCategory.subCatId} key={scIndex}>
                                  {subCategory.subCatTitle}
                                </MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>
                        )}  
                      />
                      
                    </Grid>
                    {loadingSubCategoryList && <Grid item xs={2}>
                        <CircularProgress/>
                    </Grid>}
                  </Grid>
                </Grid> 
              </Grid>
            </Grid>
            {/* ............right section ends................... */}
          </Grid>

          <Grid container spacing={2} mb={1}>
            <Grid item xs={12} lg={5}>
              <Grid item xs={12} sx={{my:'1%'}}>
                <Grid container sx={{mb:'1%'}}>
                  <Grid item lg={7} xs={12}>
                    <Typography sx={{fontWeight:'600'}}>Select Service Expert<span style={{color:'red'}}>*</span></Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={loadingServiceExpertList?'10':'12'}>
                  <Controller
                    name='serviceExpert'
                    control={control}
                    rules={{required:'Select service Expert'}}
                    render={({field,fieldState:{error}}) => (
                      <FormControl fullWidth size='small' error={!!error}>
                        <Select {...field} 
                          // disabled={!customer || !serviceArea}
                        >
                          {serviceExpertList.map((expert) => (
                            <MenuItem key={expert.userId} value={expert.userId}>{expert?.firstName} {expert?.lastName} | {expert?.mobileNumber} | {`(${expert?.distance} km)`}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>
                {loadingServiceExpertList && <Grid item xs={2}><CircularProgress/></Grid>}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{borderBlockStyle:'dashed',borderBlockWidth:'1px'}}/>
            </Grid>
            
            <Grid item xs={5}>
              <Grid item xs={12} sx={{}}>
                <Grid container sx={{mb:'1%'}}>
                  <Grid item lg={7} xs={12}>
                    <Typography sx={{fontWeight:'600'}}>Select Services<span style={{color:'red'}}>*</span></Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={loadingServiceList?'10':'12'}>
                  <Controller
                    name='services'
                    control={control}
                    rules={{ required: 'Select service' }}
                    render={({ field, fieldState: { error } }) => (
                      <Autocomplete
                        {...field}
                        multiple
                        disabled={!serviceExpert}
                        disableCloseOnSelect
                        options={serviceList}
                        getOptionLabel={(option) => option.title}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.title}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size='small'
                            error={!!error}
                            variant="outlined"
                            placeholder="Select services"
                          />
                        )}
                        onChange={(_, newValue) => {
                          const servicesWithQuantity = newValue.map(service => ({
                            ...service,
                            quantity: service.quantity || 1
                          }));
                          field.onChange(servicesWithQuantity);
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderTags={() => null}
                      />
                    )}
                  />
                </Grid>
                {loadingServiceList && <Grid item xs={2}><CircularProgress/></Grid>}
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{display:services.length>0?'block':'none'}}>
              <Box>
                <Typography sx={{fontWeight:'600',color:'#494949'}}>Service Details</Typography>
                <Card sx={{bgcolor:'#dadada4d'}}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box sx={{p:'1% 2%',display:'flex',justifyContent:'space-between',borderBottom:'1px solid #BEC2C2'}}>
                        <Typography sx={{fontWeight:500,color:'#3E3E3E'}}>Your Cart </Typography>
                        <Typography sx={{fontWeight:500,fontSize:'small',color:'#3E3E3E'}}>{services.length>0? services.length:''} Services</Typography>
                      </Box>
                      <Box>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{fontWeight:600,p:'1% 2%'}} width="40%" >Service</TableCell>
                                <TableCell sx={{fontWeight:600,p:'1% 2%'}} align="center" width="12%">Quantity</TableCell>
                                <TableCell sx={{fontWeight:600,p:'1% 2%'}} align="center" width="12%">Time</TableCell>
                                <TableCell sx={{fontWeight:600,p:'1% 2%'}} align="center" width="12%">Price</TableCell>
                                <TableCell sx={{fontWeight:600,p:'1% 2%'}} align="center" width="12%">Total</TableCell>
                                <TableCell sx={{fontWeight:600,p:'1% 2%'}} align="right" width="12%" >Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {services.map((service) => (
                                <TableRow key={service.id}>
                                  <TableCell sx={{ p: 1 }}>{service.title}</TableCell>
                                  <TableCell align="center" sx={{ p: 1 }}>
                                    <Paper sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                      <IconButton
                                        size="small"
                                        onClick={() => handleQuantityChange(service.id, Math.max(1, service.quantity - 1))}
                                      >
                                        <Remove sx={{color:'#1A44B8'}} fontSize='small'/>
                                      </IconButton>
                                      
                                      <TextField
                                        type="number"
                                        value={service.quantity}
                                        onChange={(e) =>{
                                          // i want always value 1 or greater than 1
                                          if(e.target.value<1){
                                            e.target.value=1;
                                          }
                                          handleQuantityChange(service.id, e.target.value)
                                        } }
                                        inputProps={{ 
                                          min: 1,
                                          // className: 'quantity-input',
                                          style: { 
                                            textAlign: 'center', 
                                            width: '2em',
                                            '-moz-appearance': 'textfield',
                                            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                                              '-webkit-appearance': 'none',
                                              margin: 0
                                            }
                                          } 
                                        }}
                                        size="small"
                                        variant="standard"
                                        sx={{ 
                                          mx: 1,
                                          "& .MuiInput-underline:before": { borderBottom: "none" },
                                          "& .MuiInput-underline:after": { borderBottom: "none" },
                                          "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                                            borderBottom: "none"
                                          },
                                          "& .MuiInputBase-input": {
                                            borderLeft: "1px solid #ccc",
                                            borderRight: "1px solid #ccc",
                                            px: 0  // padding left and right
                                          }
                                        }}
                                      />

                                      <IconButton
                                        size="small"
                                        onClick={() => handleQuantityChange(service.id, service.quantity + 1)}
                                      >
                                        <Add fontSize='small' sx={{color:'#1A44B8'}}/>
                                      </IconButton>
                                    </Paper>
                                  </TableCell>
                                  <TableCell align="center" sx={{ p: 1 }}>
                                    {service.serviceTime} mins
                                  </TableCell>
                                  <TableCell sx={{ p: 1 ,textAlign:'center'}}>₹{service.price}</TableCell>                                                                       
                                  <TableCell sx={{ p: 1 ,textAlign:'center'}} >₹{parseFloat(service.price*service.quantity).toFixed(2)}</TableCell>
                                  <TableCell align="right" sx={{ p: 1 }}>
                                    <IconButton
                                      aria-label="delete"
                                      onClick={() => handleDeleteService(service.id)}
                                      size="small"
                                      color='error'
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                              {/* Charges Section */}
                              <TableRow sx={{P:0}}>
                                  <TableCell rowSpan={'3'} colSpan={2}/>
                                  <TableCell  sx={{textAlign:'right', fontWeight:'600',borderBottom:'none',p:1}} colSpan={2}>SubTotal:</TableCell>
                                  {/* // sum of total of each service */}
                                  <TableCell sx={{textAlign:'right',borderBottom:'none',p:1}}>₹{services.reduce((acc, service) => acc + (service.price * service.quantity), 0).toFixed(2)}</TableCell>
                                  {/* <TableCell sx={{textAlign:'right',borderBottom:'none'}}>{}</TableCell> */}
                              </TableRow>
                              
                              <TableRow>
                                  <TableCell  sx={{textAlign:'right',borderBottom:'none',p:1}} colSpan={2}>Visiting Charge:</TableCell>
                                  <TableCell sx={{textAlign:'right',borderBottom:'none',p:1}}>
                                  {/* ₹{ parseFloat(chargeDetails?.vistingCharge).toFixed(2)} */}
                                  ₹{parseFloat(services.reduce((acc, service) => acc + (service.price * service.quantity), 0)).toFixed(2)>parseFloat(chargeDetails?.waiveVisitCharge)? parseFloat(0).toFixed(2):parseFloat(chargeDetails?.vistingCharge).toFixed(2)}

                                      {/* if subtotal is greater than 500 then visiting 0 otherwise 50 */}
                                      {/* ₹{parseFloat(services.reduce((acc, service) => acc + (service.price * service.quantity), 0) > 500 ? 0.00 : 50.00).toFixed(2)} */}
                                  </TableCell>
                              </TableRow>                          
                              <TableRow>
                                  <TableCell  sx={{textAlign:'right',p:1}} colSpan={2}>Additional Charge:</TableCell>
                                  <TableCell 
                                      sx={{textAlign:'right',
                                        // borderBottom:'none', 
                                          
                                          position: 'relative',
                                          '&::before': {
                                          content: '"₹"',
                                          }
                                          ,p:1
                                      }}
                                  >
                                    {parseFloat(chargeDetails?.additionalCharge).toFixed(2)}
                                  </TableCell>
                              </TableRow>
                              {/* <TableRow>
                                  <TableCell sx={{textAlign:'right',p:1}} colSpan={2}>waive Visit Charge:</TableCell>
                                  <TableCell sx={{textAlign:'right',p:1}} >₹{parseFloat(chargeDetails?.waiveVisitCharge).toFixed(2)}</TableCell>
                              </TableRow> */}
                              
                              <TableRow>
                                  <TableCell colSpan={4} sx={{
                                      textAlign:'right', 
                                      fontWeight:'600',
                                      borderBottom: 'none',p:1
                                  }}>Total:</TableCell>
                                  <TableCell sx={{
                                      textAlign:'right',
                                      fontWeight:'600',
                                      borderBottom: 'none',p:1
                                  }}>
                                      {/* sum of subtotal and above all charges */}
                                      ₹{parseFloat(services.reduce((acc, service) => acc + (service.price * service.quantity), 0)).toFixed(2)>parseFloat(chargeDetails?.waiveVisitCharge)? parseFloat(services.reduce((acc, service) => acc + (service.price * service.quantity), 0)).toFixed(2):parseFloat(services.reduce((acc, service) => acc + (service.price * service.quantity), 0)+parseFloat(chargeDetails?.vistingCharge)).toFixed(2)}
                                      {/* {selectedBookingDetails?.total} */}
                                  </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Grid>
                    {/* <Grid item xs={3} sx={{borderLeft:'1px solid #BEC2C2'}}>
                      <Box sx={{p:'3%',borderBottom:'1px solid #BEC2C2'}}>
                        <Typography sx={{fontWeight:600}}>Payment Summary</Typography>
                      </Box>
                      <Box sx={{ }}>
                        <Box sx={{ p: 2 ,display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <Typography variant="body1" color="text.secondary">
                            Subtotal
                          </Typography>
                          <Typography variant="body1">
                            ₹549
                          </Typography>
                        </Box>
                        
                        <Box sx={{px: 2 , display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <Typography variant="body1" color="text.secondary">
                            Visiting Charges
                          </Typography>
                          <Typography variant="body1">
                            ₹50
                          </Typography>
                        </Box>
                        
                        <Divider sx={{ my: 2 }} />
                        
                        <Box sx={{px: 2 , display: 'flex', justifyContent: 'space-between' }}>
                          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                            Total Amount
                          </Typography>
                          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                            ₹599
                          </Typography>
                        </Box>
                      </Box>
                    </Grid> */}
                  </Grid>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* =======Form Action Button Section ========*/}
      <Box sx={{display:'flex',justifyContent:'center',gap:2,mt:'.5%'}}>
          <Button disabled={activeStep === 0} onClick={handleBack} variant='contained' color='secondary'>Back</Button>
          <Button disabled={!isDirty} 
            type='submit' 
            // onClick={handleNext}
            variant='contained' color='primary'>Submit & Next</Button>
      </Box>
    </Box>
  );
}

export default ServicesAndExpert;
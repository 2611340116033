import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import UserList from "./UserList";
import MainContentLayout from "../../components/Layout/MainContentLayout";

export default function User(){
    const menuDispatch = useDispatch();
    useEffect(() => {
        menuDispatch({
            type: 'menuSelect',
            payload: 'user'
        });
    }
    , []);
    return (
        <MainContentLayout>
            <UserList />
        </MainContentLayout>
    )
}
import React, { useEffect, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  TextField,
  Grid,
  Box,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Card,
  FormControlLabel,
  Checkbox,
  CardContent
} from '@mui/material';

export default function AddressDetails({PersonalDetails}) {
  const renderAddressFields = (prefix, disabled = true) => (
    <>
      <Box>
        <Typography sx={{ fontWeight: '600',fontSize:'.75rem' }}>Address</Typography>
            <TextField
              value={PersonalDetails[prefix]?.address}
              fullWidth
              size='small'
              inputProps={{
                      style: {fontSize:'.75rem'}
                    }}
              multiline
              disabled={disabled}
            />
        
        
      </Box> 
      <Box>
          <Typography sx={{ fontWeight: '600',fontSize:'.75rem' }}>Landmark</Typography>
          
              <TextField
                value={PersonalDetails[prefix]?.landmark}
                fullWidth
                size='small'
                inputProps={{
                      style: {fontSize:'.75rem'}
                    }}
                disabled={disabled}
              />
        </Box>
      
      <Box sx={{ display: 'flex', gap: '2%', my: '1%' }}>
        <Box sx={{ width: '50%' }}>
          <Typography sx={{ fontWeight: '600',fontSize:'.75rem' }}>State</Typography>
              <TextField
                value={PersonalDetails[prefix]?.state}
                fullWidth
                disabled
                size='small'
                inputProps={{
                      style: {fontSize:'.75rem'}
                    }}
              />
        </Box>
        <Box sx={{ width: '50%' }}>
          <Typography sx={{ fontWeight: '600',fontSize:'.75rem' }}>City</Typography>
          
              <TextField
                value={PersonalDetails[prefix]?.city}
                fullWidth
                size='small'
                inputProps={{
                      style: {fontSize:'.75rem'}
                    }}
                disabled={disabled}
              />
        </Box>
      </Box>
      
      <Box sx={{ display: 'flex', gap: '2%', my: '1%' }}>
        <Box sx={{ width: '49%' }}>
          <Typography sx={{ fontWeight: '600',fontSize:'.75rem' }}>Pincode</Typography>
          
              <TextField
                value={PersonalDetails[prefix]?.pincode}
                fullWidth
                size='small'
                inputProps={{
                      style: {fontSize:'.75rem'}
                    }}
                disabled={disabled}
              />
        </Box>
        <Box sx={{width:'49%'}}>
          <Typography sx={{fontWeight:'600',fontSize:'.75rem', texttTransform:'uppercase'}}>Contact Number </Typography>
          
            <TextField
                value={PersonalDetails[prefix]?.mobileNumber}
                size='small'
                inputProps={{
                      style: {fontSize:'.75rem'}
                    }}
            />
            
        </Box>
        
      </Box>
      <Box sx={{ display: 'flex', gap: '2%', my: '1%' }}>
        <Box sx={{ width: '49%' }}>
          <Typography sx={{ fontWeight: '600',fontSize:'.75rem' }}>Latitude</Typography>
          
              <TextField
                value={PersonalDetails[prefix]?.latitude}
                fullWidth
                size='small'
                inputProps={{
                      style: {fontSize:'.75rem'}
                    }}
                disabled={disabled}
              />
        </Box>
        <Box sx={{width:'49%'}}>
          <Typography sx={{ fontWeight: '600',fontSize:'.75rem' }}>Longitude</Typography>
          
              <TextField
                value={PersonalDetails[prefix]?.longitude}
                fullWidth
                disabled={disabled} 
                size='small'
                inputProps={{
                      style: {fontSize:'.75rem'}
                    }}
              />
        </Box>
      </Box>
    </>
  );

  return (
    <Grid item xs={12}>
      <Card >
        <Box sx={{ p: '.5% 2%', border: '2px solid lightGray', bgcolor: 'lightGray' }}>
          <Typography sx={{ fontWeight: '600',fontSize:'.75rem' }}>Address Info</Typography>
        </Box>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={6} sx={{pt:'0%!important',pl:'0%!important'}}>
              <Card sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                <Box sx={{ borderBottom: '2px solid lightGray' }}>
                  <Typography sx={{ p: '.5% 2%', fontWeight: '600',fontSize:'.85rem', bgcolor: 'lightGray', width: 'fit-content' }}>Home Address</Typography>
                </Box>
                <Box sx={{ p: '2%', pt: 0 }}>
                  {renderAddressFields('selfAddress')}
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6} sx={{pt:'0%!important'}}>
              <Card sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                <Box sx={{ borderBottom: '2px solid lightGray' }}>
                  <Typography sx={{ p: '.5% 2%', fontWeight: '600',fontSize:'.85rem', bgcolor: 'lightGray', width: 'fit-content' }}>Service Address</Typography>
                </Box>
                <Box sx={{ p: '2%', pt: 0 }}>
                  {renderAddressFields('serviceAddress')}
                </Box>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
import { configureStore } from "@reduxjs/toolkit";
import { customReducer, serviceExpertTableReducer } from "./reducers";

const store = configureStore({
    reducer: {
        utilsSto: customReducer,
        expertTableState: serviceExpertTableReducer        
    }
});

export default store;
import { SaveAlt } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import React from 'react';
import Swal from 'sweetalert2';
import { CrudServices } from '../CrudServices';
import { formatDateTime } from '../../../utils/dateUtils';

const ExportAll = (props) => {
  const { paginationModel } = props;

  // Define custom headers and their corresponding data mapping
  const exportMapping = [
    {
      header: 'Service ID',
      value: (row) => `${row.id}`
    },
    {
      header: 'Service Name',
      value: (row) => `${row.title ||''}`
    },
    {
      header: 'Service Image',
      value: (row) => `${row.images || ''}`
    },
    { 
      header: 'Parent Service',
      value: (row) => `${row?.parentService?.title || ''}`
    },
    {
      header: 'Sub Category',
      value: (row) => row.ServiceCategoryRels[0]?.ServiceCategory?.title || ''
    },
    {
      header: 'Category',
      value: (row) => row.ServiceCategoryRels[0]?.ServiceCategory?.parentCategory?.title || ''
    },
    {
      header: 'Status',
      value: (row) => row.isActive ? 'Active' : 'Inactive'
    },
    {
      header: 'Creation Date',
      value: (row) => formatDateTime(row.createdAt)
    }
  ];

  const handleExportAllRows = async () => {
    try {
      Swal.fire({
        title: 'Exporting Data',
        text: 'Please wait while we prepare your download...',
        icon: 'info',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      const listResponse = await CrudServices.serviceExportList({
        filters: {
          searchKey: paginationModel.filters.searchKey,
          sortBy: paginationModel.filters.sortBy,
          sortType: paginationModel.filters.sortType,
          filterOptions: paginationModel.filters.filterOptions || []
        }
      });

      // Transform data using custom mapping
      const exportData = listResponse.data.list.map(row => {
        return exportMapping.reduce((acc, field) => {
          acc[field.header] = field.value(row);
          return acc;
        }, {});
      });

      // Create CSV content
      const headers = exportMapping.map(field => `"${field.header}"`).join(',');
      const csvContent = exportData.map(row => 
        exportMapping.map(field => 
          `"${String(row[field.header] ?? '').replace(/"/g, '""')}"`
        ).join(',')
      );

      csvContent.unshift(headers);

      // Create and download file
      const csvString = csvContent.join('\n');
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'MasterService-Report.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      Swal.fire({
        title: 'Export Successful',
        html: `
          <p>If download doesn't start automatically, click on provided link:
          <a href="${url}" download="ServiceExpert-report.csv" style="color: blue; text-decoration: underline;">
            Download CSV
          </a></p>
        `,
        icon: 'success',
        showConfirmButton: true,
        confirmButtonText: 'Close'
      });

    } catch (error) {
      console.error('Export failed', error);
      
      Swal.fire({
        title: 'Export Failed',
        text: 'There was an issue exporting the data. Please try again.',
        icon: 'error'
      });
    }
  };

  return (
    <Box>        
      <Button 
        onClick={handleExportAllRows}
        startIcon={<SaveAlt />}
        sx={{textTransform:'none'}}
      >
        Export
      </Button>      
    </Box>
  );
}

export default ExportAll;
import { commonRequest } from "./ApiCall";
import { Helpers } from "./Helpers";
export const Auth = {
    checkMobile : async (data) =>{
        return await commonRequest("POST",  `${Helpers.URL()}/api/auth/verifyMobileNumber`, data);
    },

    verifyOtp : async (data) =>{
        return await commonRequest("POST",  `${Helpers.URL()}/api/auth/verifyOtp`, data);
    },

    loginWithEmailPassword: async (data)=>{
        return await commonRequest("POST",`${Helpers.URL()}/api/admin/auth`,data)
    },

    resetPasswordByEmail: async(email)=>{
        return await commonRequest("GET",`${Helpers.URL()}/api/admin/auth/verifyEmail/${email}`,{});
    },
    changePassword: async (data) =>{        
        return await commonRequest("POST", `${Helpers.URL()}/api/admin/auth/changePassword`,data, Helpers.token());
    }
}